// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';
import classNames from 'classnames';
import _ from 'lodash';

class EkButton extends Component {
  constructor() {
    // noinspection JSCheckFunctionSignatures
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    if (!this.props.href) {
      e.preventDefault();
    }
    if (this.props.onAction) {
      this.props.onAction();
    }
  }

  render() {
    if (this.props.href) {
      return (
        <a
          id={_.get(this, 'props.id')}
          href={this.props.href}
          className={classNames(
            'ekButton',
            {
              [`ekButton--${this.props.skinType}`]: this.props.skinType,
              'ekButton--fullWidth': this.props.fullWidth
            },
            this.props.customClass
          )}
          onClick={this.handleClick}
          type={this.props.type}
        >
          {this.props.children}
        </a>
      );
    } else {
      return (
        <button
          id={_.get(this, 'props.id')}
          className={classNames(
            'ekButton',
            {
              [`ekButton--${this.props.skinType}`]: this.props.skinType,
              'ekButton--fullWidth': this.props.fullWidth
            },
            this.props.customClass
          )}
          onClick={this.handleClick}
          type={this.props.type}
        >
          {this.props.children}
        </button>
      );
    }
  }
}

EkButton.displayName = 'EkButton';

EkButton.propTypes = {
  id: PropTypes.string,
  children: PropTypes.element,
  customClass: PropTypes.string,
  onAction: PropTypes.func,
  skinType: PropTypes.string,
  type: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default EkButton;
