import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedTime } from 'react-intl';

import config from '../../constants/config-constants';

class Version extends Component {
  render() {
    return (
      <div className="VersionPage">
        <h1>Version</h1>

        <dl>
          <dt>Package name:</dt>
          <dd>{config.version.packageName}</dd>
          <dt>Package version:</dt>
          <dd>{config.version.packageVersion}</dd>
          <dt>Commit hash:</dt>
          <dd>{config.version.commitHash}</dd>
          <dt>Commit message:</dt>
          <dd>{config.version.commitMessage}</dd>
          <dt>Commit date:</dt>
          <dd>{config.version.commitDate}</dd>
          <dt>Build date:</dt>
          <dd>{config.version.buildDate}</dd>
        </dl>
      </div>
    );
  }
}

Version.displayName = 'Version';

Version.propTypes = {};

export default Version;
