module.exports={
  "cs-i18n.json": "cs-i18n-ae35ec10f3.json",
  "da-i18n.json": "da-i18n-910e0548eb.json",
  "de-i18n.json": "de-i18n-ac6976da12.json",
  "en-i18n.json": "en-i18n-32bac1f0ae.json",
  "es-i18n.json": "es-i18n-256ed341bc.json",
  "fr-i18n.json": "fr-i18n-2a8e74e549.json",
  "it-i18n.json": "it-i18n-3e9e760c85.json",
  "nl-i18n.json": "nl-i18n-6e388d9dc0.json",
  "pl-i18n.json": "pl-i18n-9c88f02b3c.json",
  "pt-i18n.json": "pt-i18n-327c429136.json",
  "pt_BR-i18n.json": "pt_BR-i18n-e54548cbba.json",
  "sk-i18n.json": "sk-i18n-6e8a1bd7b8.json",
  "sv-i18n.json": "sv-i18n-61a8a28a95.json"
}