import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import _isString from 'lodash/lang/isString';
import _get from 'lodash/object/get';
import classNames from 'classnames';
import { namedCompose } from '../../utils/utils';
import { companyInfosSelector } from '../../selectors/all-selectors';

const shares = defineMessages({
  linkedin: {
    id: 'footer_share_linkedin_url',
    defaultMessage: 'Linkedin'
  },
  twitter: {
    id: 'footer_share_tw_url',
    defaultMessage: ' '
  }
});

const externalURLs = defineMessages({
  about: {
    id: 'footer_society_about_url',
    defaultMessage: '/#/home'
  },
  mentions: {
    id: 'footer_society_mentions_url',
    defaultMessage: '/#/home'
  }
});

class Footer extends Component {
  getValidUrl(url) {
    return _isString(url) ? url.trim() : '';
  }

  getTermUrl() {
    const { formatMessage } = this.props.intl;
    return _get(this.props.companyInfos, 'termsOfUseUrl') || formatMessage(externalURLs.mentions);
  }

  getTermsOfUse() {
    const { formatMessage } = this.props.intl;
    const termsOfUseTitle = formatMessage({ id: 'footer_society_term' });
    let termUrl = this.getValidUrl(this.getTermUrl());

    if (termUrl) {
      return (
        <li className="footer_subItem">
          <a href={termUrl} className="footer_link">
            {termsOfUseTitle}
          </a>
        </li>
      );
    }
  }

  getPolicyPrivacyLink() {
    const { formatMessage } = this.props.intl;
    const policyPrivacyTitle = formatMessage({ id: 'footer_society_policy_privacy' });
    let policyUrl = this.getValidUrl(_get(this.props.companyInfos, 'privacyPolicyUrl'));

    if (policyUrl) {
      return (
        <li className="footer_subItem">
          <a href={policyUrl} className="footer_link">
            {policyPrivacyTitle}
          </a>
        </li>
      );
    }
  }

  innerFooter() {
    const { formatMessage } = this.props.intl;
    const hotlineInfo = _get(this.props.companyInfos, 'computedConfiguration.hotline');
    const hotlineEmail = _get(hotlineInfo, 'customerServiceEmail', false);
    const linkedUrl = (formatMessage(shares.linkedin) || '').trim();
    const twitterUrl = (formatMessage(shares.twitter) || '').trim();

    if (!this.props.whiteBrand)
      return (
        <div className="footer_innerWrapper">
          <blockquote className="footer_quote">
            <p className="footer_quote_text">
              <FormattedMessage
                id="footer_quote"
                values={{ productName: formatMessage({ id: 'values_productName' }) }}
              />
            </p>
          </blockquote>

          {(linkedUrl || twitterUrl) && (
            <div className="footer_share">
              <p className="footer_share_label">
                <FormattedMessage id="footer_share" />
              </p>
              {linkedUrl && (
                <a href={linkedUrl} className="footer_shareLink footer_shareLink--linkedin" target="_blank">
                  <FormattedMessage id="footer_share_linkedin" />
                </a>
              )}
              {twitterUrl && (
                <a href={twitterUrl} className="footer_shareLink footer_shareLink--twitter" target="_blank">
                  <FormattedMessage id="footer_share_tw" />
                </a>
              )}
            </div>
          )}

          <ul className="footer_items">
            <li className="footer_item">
              <span className="footer_item_title">
                <FormattedMessage id="footer_callUs" />
              </span>
              <div className="footer_item_section">
                <ul className="footer_subItems">
                  {_get(hotlineInfo, 'phoneNumbers', []).map((item, idx) => {
                    return (
                      <li className="footer_subItem" key={'contact' + idx}>
                        <FormattedMessage
                          id="contact_hotline"
                          values={{
                            name: _get(item, 'name') || formatMessage({ id: 'footer_default_phone_name' }),
                            prefix: _get(item, 'countryCode'),
                            number: _get(item, 'nationalNumber')
                          }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </li>
            <li className="footer_item">
              <span className="footer_item_title">
                <FormattedMessage id="footer_writeUs" />
              </span>
              <div className="footer_item_section">
                <a
                  href={
                    hotlineEmail ? 'mailto:' + hotlineEmail : 'mailto:' + formatMessage({ id: 'values_contactEmail' })
                  }
                  className="footer_link footer_link--email"
                >
                  {hotlineEmail ? hotlineEmail : <FormattedMessage id="values_contactEmail" />}
                </a>
              </div>
            </li>
            <li className="footer_item">
              <span className="footer_item_title">
                <FormattedMessage id="footer_society" />
              </span>
              <div className="footer_item_section">
                <ul className="footer_subItems">
                  <li className="footer_subItem">
                    <a href={formatMessage(externalURLs.about)} className="footer_link">
                      <FormattedMessage id="footer_society_about" />
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="footer_item">
              <span className="footer_item_title">
                <FormattedMessage id="footer_others" />
              </span>
              <div className="footer_item_section">
                <ul className="footer_subItems">
                  {this.getTermsOfUse()}
                  {this.getPolicyPrivacyLink()}
                </ul>
              </div>
            </li>
          </ul>
        </div>
      );
  }

  render() {
    return (
      <footer className={classNames(this.props.whiteBrand ? 'footer-white-brand' : 'footer-regular')}>
        <div className="footer">
          {this.innerFooter()}
          <div className="footer_bottom">
            <p className="footer_item" id="footer-powered-by">
              <FormattedMessage id="footer_powered_by" />
              <FormattedMessage id="footer_rci_mobility" />
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.defaultProps = {
  whiteBrand: false,
  companyInfos: {}
};

Footer.propTypes = {
  whiteBrand: PropTypes.bool,
  intl: PropTypes.object,
  companyInfos: PropTypes.object
};

export default namedCompose(connect(state => ({ companyInfos: companyInfosSelector(state) })), injectIntl)(Footer);
