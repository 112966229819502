import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import TabsItems from '../../components/TabsItems/TabsItems';
import { updateHelpActiveTabIndex, toggleAnswerIssueOpenedState } from '../../actions/all-actions';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import config from '../../constants/config-constants';
import { bundleSelector, companyInfosSelector } from '../../selectors/all-selectors';

const tabList = [
  { id: 0, name: 'helpPage_tab_vehicles' },
  { id: 1, name: 'helpPage_tab_carSharing' },
  { id: 2, name: 'helpPage_tab_damages' }
];

function helpItemsVehicleList() {
  let vehicleItems = [];

  for (let i = 0; i < config.numberIssuesVehicleHelpSection; i++) {
    let vehicleItem = {
      id: 'issue_vehicles_' + (i + 1),
      issueKey: 'helpPage_vehicles_issue' + (i + 1) + '_title',
      answerKey: 'helpPage_vehicles_issue' + (i + 1) + '_answer'
    };
    vehicleItems.push(vehicleItem);
  }
  return vehicleItems;
}

function helpItemsCarSharingList() {
  let carSharingItems = [];

  for (let i = 0; i < config.numberIssuesCarSharingHelpSection; i++) {
    let carSharingItem = {
      id: 'issue_carSharing_' + (i + 1),
      issueKey: 'helpPage_carSharing_issue' + (i + 1) + '_title',
      answerKey: 'helpPage_carSharing_issue' + (i + 1) + '_answer'
    };
    carSharingItems.push(carSharingItem);
  }
  return carSharingItems;
}

function helpItemsDamagesList() {
  let damagesItems = [];

  for (let i = 0; i < config.numberIssuesDamagesHelpSection; i++) {
    let damagesItem = {
      id: 'issue_damages_' + (i + 1),
      issueKey: 'helpPage_damages_issue' + (i + 1) + '_title',
      answerKey: 'helpPage_damages_issue' + (i + 1) + '_answer'
    };
    damagesItems.push(damagesItem);
  }
  return damagesItems;
}

class Help extends Component {
  constructor() {
    super();
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  handleChangeTab(tab) {
    const { dispatch } = this.props;
    dispatch(updateHelpActiveTabIndex(tab.id));
  }

  handleDisplayAnswer(id) {
    const { dispatch } = this.props;
    dispatch(toggleAnswerIssueOpenedState(id));
  }

  renderHelpList(list) {
    const { showAnswersToIssuesList, bundle, contactEmail, hotlineNumber } = this.props;

    let helpItems;

    helpItems = list.map(function(item, index) {
      let handleDisplayAnswerBound = this.handleDisplayAnswer.bind(this, item.id);
      let showAnswer = showAnswersToIssuesList.indexOf(item.id) !== -1;

      const i18nValues = {
        productName: bundle.values_productName,
        contactPhone: hotlineNumber || bundle.values_contactPhone,
        contactEmail: contactEmail || bundle.values_contactEmail
      };

      return (
        <li className={` helpPage_listItem ${showAnswer ? '_is_active' : ''}`} key={item.id}>
          <dl className="helpPage_listItemInner">
            <dt className="helpPage_term">
              <button className="helpPage_termButton" onClick={handleDisplayAnswerBound}>
                <FormattedMessage id={item.issueKey} values={i18nValues} />
              </button>
            </dt>
            {showAnswersToIssuesList.indexOf(item.id) === -1 ? (
              ''
            ) : (
              <dd className="helpPage_description">
                <FormattedMessage id={item.answerKey} values={i18nValues} />
              </dd>
            )}
          </dl>
        </li>
      );
    }, this);

    return helpItems;
  }

  render() {
    const { helpActiveTabIndex, isMobileView } = this.props;

    return (
      <div className="helpPage">
        {isMobileView ? '' : <Header />}
        <FlashMessageDisplayer />

        <div className="mainContent">
          <div className="mainContent_inner">
            <section className="helpPage_section">
              <div className="helpPage_head">
                <h3 className="helpPage_title">
                  <FormattedMessage id="helpPage_title" />
                </h3>
                <p className="helpPage_subtitle">
                  <FormattedMessage id="helpPage_subtitle" />
                </p>
              </div>

              <TabsItems
                tabList={tabList}
                onChangeTab={this.handleChangeTab}
                currentTab={helpActiveTabIndex}
                actionButtons
              />

              <div className="helpPage_list">
                <ol className="helpPage_listItems">
                  {helpActiveTabIndex === tabList[0].id ? this.renderHelpList(helpItemsVehicleList()) : ''}
                  {helpActiveTabIndex === tabList[1].id ? this.renderHelpList(helpItemsCarSharingList()) : ''}
                  {helpActiveTabIndex === tabList[2].id ? this.renderHelpList(helpItemsDamagesList()) : ''}
                </ol>
              </div>
            </section>
          </div>
        </div>

        {isMobileView ? '' : <Footer />}
      </div>
    );
  }
}

Help.displayName = 'Help';

Help.propTypes = {};

function computePhoneNumber(phoneNumbers) {
  let firstNumber = '';

  phoneNumbers.forEach((number, index) => {
    const { countryCode, nationalNumber } = number || {};
    const lastIndex = phoneNumbers.length - 1;
    const fullNumber = ((countryCode || '') + ' ' + (nationalNumber || '')).trim();

    if (index === 0) {
      firstNumber = fullNumber;
    } else if (index === 1) {
      firstNumber = firstNumber + ' ' + '(' + fullNumber;
    } else {
      firstNumber = firstNumber + ', ' + fullNumber;
    }

    if (index !== 0 && index === lastIndex) {
      if (index === 1) firstNumber += ')';
      else firstNumber += ')';
    }
  });

  return firstNumber;
}

export default connect(state => {
  const {
    help: { helpActiveTabIndex, showAnswersToIssuesList, isMobileView }
  } = state;

  const companyInfos = companyInfosSelector(state);
  const { computedConfiguration } = companyInfos || {};
  const { hotline } = computedConfiguration || {};
  const { customerServiceEmail, phoneNumbers } = hotline || {};

  let firstNumber = '';

  if (phoneNumbers) {
    firstNumber = computePhoneNumber(phoneNumbers);
  }

  return {
    helpActiveTabIndex,
    showAnswersToIssuesList,
    isMobileView,
    contactEmail: customerServiceEmail,
    hotlineNumber: firstNumber,
    bundle: bundleSelector(state)
  };
})(Help);
