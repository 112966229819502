import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormattedTime } from 'react-intl';
import _ from 'lodash';

import EkButton from '../EkButton/EkButton';
import TextIcon from '../TextIcon/TextIcon';
import { BOOKING_TYPE_CAR_SHARING, BOOKING_TYPE_RIDE_SHARING } from '../../constants/backend-constants';
import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import { requestUpdateDefaultSite, callEditProfileSuccess, addFlashMessage } from '../../actions/all-actions';
import { addErrorMessage, createDate, getAppFormattedDate } from '../../utils/utils';
import { bundleSelector } from '../../selectors/all-selectors';

class TripDetails extends Component {
  constructor() {
    super();
    this.handleFavoriteStart = this.handleFavoriteStart.bind(this);
    this.handleFavoriteEndSite = this.handleFavoriteEndSite.bind(this);
  }

  dayOfBookingStart(searchContext, item) {
    const startDate = _.get(item, 'start.date', _.get(searchContext, 'pickupDate'));

    if (startDate) return <span>{getAppFormattedDate(startDate, { short: true })}</span>;
  }

  dayOfBookingEnd(searchContext, item) {
    const endDate = _.get(item, 'end.date', _.get(searchContext, 'returnDate'));

    if (endDate) return <span>{getAppFormattedDate(endDate, { short: true })}</span>;
  }

  hourOfBookingStart(searchContext, item) {
    const startDate = _.get(item, 'start.date', _.get(searchContext, 'pickupDate'));

    if (startDate) return <FormattedTime value={createDate(startDate)} hour="numeric" minute="numeric" />;
  }

  hourOfBookingEnd(searchContext, item) {
    const endDate = _.get(item, 'end.date', _.get(searchContext, 'returnDate'));

    if (endDate) return <FormattedTime value={createDate(endDate)} hour="numeric" minute="numeric" />;
  }

  handleFavoriteStart() {
    const { dispatch, item, userInfo, bundle } = this.props;
    const isDefault =
      _.get(item, 'start.parking.site.id', 'site_id') === _.get(userInfo, 'defaultSite', 'default_site');
    let siteId = isDefault ? '' : _.get(item, 'start.parking.site.id');

    dispatch(requestUpdateDefaultSite(siteId)).then(
      userInfo =>
        Promise.all([
          dispatch(callEditProfileSuccess(userInfo)),
          dispatch(
            addFlashMessage({
              contentKey: 'profileForm_update_member_succeeded',
              type: FLASH_MESSAGE_TYPE_SUCCESS
            })
          )
        ]),
      error => dispatch(addErrorMessage({ error, bundle, def: 'profileForm_update_member_error' }))
    );
  }

  handleFavoriteEndSite() {
    const { dispatch, item, userInfo, bundle } = this.props;
    const isDefault = _.get(item, 'end.parking.site.id', 'site_id') === _.get(userInfo, 'defaultSite', 'default_site');
    let siteId = isDefault ? '' : _.get(item, 'end.parking.site.id');

    dispatch(requestUpdateDefaultSite(siteId)).then(
      userInfo =>
        Promise.all([
          dispatch(callEditProfileSuccess(userInfo)),
          dispatch(
            addFlashMessage({
              contentKey: 'profileForm_update_member_succeeded',
              type: FLASH_MESSAGE_TYPE_SUCCESS
            })
          )
        ]),
      error => dispatch(addErrorMessage({ error, bundle, def: 'profileForm_update_member_error' }))
    );
  }

  render() {
    const { item, userInfo, searchContext } = this.props;
    const isFavoriteStart =
      _.get(item, 'start.parking.site.id', 'site_id') === _.get(userInfo, 'defaultSite', 'default');
    const isFavoriteEnd = _.get(item, 'end.parking.site.id', 'site_id') === _.get(userInfo, 'defaultSite', 'default');
    const startAdress = _.get(
      item,
      'start.parking.site.address.formattedAddress',
      _.get(item, 'start.address.formattedAddress')
    );
    const roundTrip = _.get(this.props, 'oneWayTrip') === false;
    let endAdress = _.get(
      item,
      'end.parking.site.address.formattedAddress',
      _.get(item, 'end.address.formattedAddress')
    );

    if (roundTrip) endAdress = startAdress;

    return (
      <div className="tripDetails">
        <div className="tripDetails_titleWrapper">
          <h3 className="tripDetails_title bookingSummary_section_title">
            {_.get(item, 'type') === BOOKING_TYPE_CAR_SHARING && (
              <FormattedMessage id="booking_summary_section_car_sharing" />
            )}
            {_.get(item, 'type') === BOOKING_TYPE_RIDE_SHARING && (
              <FormattedMessage id="booking_summary_section_ride_sharing" />
            )}
          </h3>
        </div>
        <div className="tripDetails_content">
          <div className="tripDetails_toGo">
            <span className="tripDetails_address">{startAdress}</span>
            <div className="tripDetails_searchRecap">
              <TextIcon
                iconName="calendar"
                label={this.dayOfBookingStart(searchContext, item)}
                customClass="tripDetails_searchRecap_item"
              />
              <TextIcon
                iconName="time"
                label={this.hourOfBookingStart(searchContext, item)}
                customClass="tripDetails_searchRecap_item"
              />
              {_.get(item, 'start.parking') && (
                <EkButton
                  formRowItemKey="tripDetails_favorite_start"
                  skinType="variant4"
                  type="button"
                  customClass={`tripDetails_favoritesButton ${
                    isFavoriteStart ? '_is_favorite' : ''
                  } tripDetails_searchRecap_item`}
                  onAction={this.handleFavoriteStart}
                >
                  <FormattedMessage id="bookingSummary_add_favorite" />
                </EkButton>
              )}
            </div>
          </div>
          {endAdress && (
            <div className="tripDetails_back">
              <span className="tripDetails_address">{endAdress}</span>
              {
                <div className="tripDetails_searchRecap">
                  <TextIcon
                    customClass="tripDetails_searchRecap_item"
                    iconName="calendar"
                    label={this.dayOfBookingEnd(searchContext, item)}
                  />
                  <TextIcon
                    customClass="tripDetails_searchRecap_item"
                    iconName="time"
                    label={this.hourOfBookingEnd(searchContext, item)}
                  />
                  {_.get(item, 'start.parking.site.id') !== _.get(item, 'end.parking.site.id') && !roundTrip && (
                    <EkButton
                      formRowItemKey="tripDetails_favorite_end_site"
                      skinType="variant4"
                      type="button"
                      customClass={`tripDetails_favoritesButton ${
                        isFavoriteEnd ? '_is_favorite' : ''
                      } tripDetails_searchRecap_item`}
                      onAction={this.handleFavoriteEndSite}
                    >
                      <FormattedMessage id="bookingSummary_add_favorite" />
                    </EkButton>
                  )}
                </div>
              }
            </div>
          )}
        </div>
      </div>
    );
  }
}

TripDetails.displayName = 'TripDetails';

TripDetails.propTypes = {
  item: PropTypes.object,
  oneWayTrip: PropTypes.bool
};

export default connect(state => {
  const {
    user: { userInfo },
    booking: { searchContext }
  } = state;

  return {
    userInfo,
    searchContext,
    bundle: bundleSelector(state)
  };
})(TripDetails);
