import { PROFILE_EDIT_MEMBER_APPROVED } from './backend-constants';
import { defineMessages } from 'react-intl';

export const booleanOptions = [
  {
    labelKey: 'common_yes',
    value: 'true'
  },
  {
    labelKey: 'common_no',
    value: 'false'
  }
];

export const errorCodes = {
  [PROFILE_EDIT_MEMBER_APPROVED]: 'error_profile_edit_member_approved',
  ['account.user.driving.licence.already.exist']: 'subscribeForm_account.user.driving.licence.already.exist',
  ['account.user.login.already.exist']: 'subscribeForm_account.user.login.already.exist',
  ['The driving licence is not valid.']: 'profile_error_driver_license_invalid',
  ['The phone number is not valid']: 'validation_french_phone_number_invalid'
};

export const partialErrorCodes = {
  drivingLicenseMissing: {
    partialErrorMsg: 'drivingLicence.',
    translationKey: 'driving.license.missing.generic'
  },
  phoneNumberInvalid: {
    partialErrorMsg: 'The phone number is not valid',
    translationKey: 'validation_french_phone_number_invalid'
  }
};

export const autocompletePlaceholderMessages = defineMessages({
  generic: {
    id: 'common_search_placeholder',
    defaultMessage: 'Search for location ...'
  },
  sitesTitle: {
    id: 'autocomplete_company_sites_title',
    defaultMessage: 'Sites'
  }
});
