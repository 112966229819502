//component for file upload
import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import SuccessIcon from 'material-ui/lib/svg-icons/action/check-circle';
import ErrorIcon from 'material-ui/lib/svg-icons/alert/error-outline';
import CloseIcon from 'material-ui/lib/svg-icons/navigation/close';
import ImageIcon from 'material-ui/lib/svg-icons/image/image';
import PdfIcon from 'material-ui/lib/svg-icons/image/picture-as-pdf';
import { hideUpload } from '../../actions/all-actions';
import { uploadFileImageSvg, errorSvg, successSvg } from '../../constants/style-constants';
import {
  FILE_UPLOADING,
  FILE_UPLOADING_SUCCESS,
  FILE_UPLOADING_ERROR,
  ERROR,
  UPLOADING,
  SUCCESS
} from '../../constants/generic-constants';

class FileUpload extends Component {
  constructor() {
    super();
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.dispatch(hideUpload());
  }

  render() {
    const { files, hide } = this.props;
    const uploadedCount = _.filter(files, file => file.status === SUCCESS);
    const uploadFailedCount = _.filter(files, file => file.status === ERROR);

    return (
      <div className={(!hide ? 'fileUpload_show' : 'fileUpload_hide') + ' fileUpload_wrap'}>
        <div className="fileUpload_header">
          {uploadedCount.length > 1 && (
            <span>
              {uploadedCount.length} <FormattedMessage id="files_uploaded" />
            </span>
          )}
          {uploadedCount.length === 1 && (
            <span>
              {uploadedCount.length} <FormattedMessage id="file_uploaded" />
            </span>
          )}
          {uploadFailedCount.length === 1 && (
            <span>
              {uploadFailedCount.length} <FormattedMessage id="file_upload_failed" />
            </span>
          )}
          {uploadFailedCount.length > 1 && (
            <span>
              {uploadFailedCount.length} <FormattedMessage id="files_upload_failed" />
            </span>
          )}
          <div className="fileUpload_close" onClick={this.handleClose}>
            <CloseIcon style={{ fill: 'white' }} />
          </div>
        </div>
        <div className="fileUpload_files">
          {files.map((file, index) => {
            return (
              <div key={index} className="fileUpload_file">
                <div className="fileUpload_file_type">
                  {(file.mimeType === 'image/jpeg' || file.mimeType === 'image/png') && (
                    <ImageIcon style={uploadFileImageSvg} />
                  )}
                  {file.mimeType === 'application/pdf' && <PdfIcon style={uploadFileImageSvg} />}
                </div>
                <div className="fileUpload_file_name">
                  <span>{file.name}</span>
                </div>
                <div className="fileUpload_file_status">
                  {file.status === UPLOADING && (
                    <div className="lds-rolling">
                      <div></div>
                    </div>
                  )}
                  {file.status === ERROR && (
                    <div className="fileUpload_error_wrap">
                      {file.errorMessage && <FormattedMessage id={file.errorMessage} />}
                      <ErrorIcon style={errorSvg} />
                    </div>
                  )}
                  {file.status === SUCCESS && <SuccessIcon style={successSvg} />}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

FileUpload.displayName = 'FileUpload';

FileUpload.propTypes = {
  files: PropTypes.array,
  hide: PropTypes.bool
};

export default connect(state => {
  const {
    fileUpload: { files, hide }
  } = state;

  return {
    files,
    hide
  };
})(FileUpload);
