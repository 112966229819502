import * as types from '../../constants/actionTypes-constants';
import _reduce from 'lodash/collection/reduce';
import _get from 'lodash/object/get';
import { DRIVER_LICENCE_BACK_ID, DRIVER_LICENCE_FRONT_ID, ERROR } from '../../constants/generic-constants';

export default function uploadFile(
  state = {
    files: [],
    hide: true
  },
  action
) {
  switch (action.type) {
    case types.UPLOAD_FILE_REQUEST:
      let fileIdAlreadyInQueue = false;
      const newFilesArray = state.files.map(file => {
        const errorFile = _get(file, 'status') === ERROR;
        if (file.id === action.file.id && !errorFile) {
          fileIdAlreadyInQueue = true;
          return action.file;
        }
        return file;
      });

      if (!fileIdAlreadyInQueue) newFilesArray.push(action.file);

      return {
        ...state,
        files: [...newFilesArray]
      };

    case types.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        files: _reduce(
          state.files,
          (array, file) => {
            if (file.id === action.file.id) {
              const errorFile = _get(file, 'status') === ERROR;
              if (!errorFile) array.push(action.file);
            } else array.push(file);
            return array;
          },
          []
        ),
        hide: false
      };

    case types.UPLOAD_CLEAR_DRIVER_FILES:
      return {
        ...state,
        files: _reduce(
          state.files,
          (accumulator, currentValue) => {
            const id = _get(currentValue, 'id');
            const driverLicence = id === DRIVER_LICENCE_FRONT_ID || id === DRIVER_LICENCE_BACK_ID;
            if (!driverLicence) accumulator.push(currentValue);
            return accumulator;
          },
          []
        )
      };

    case types.UPLOAD_FILE_ERROR:
      return {
        ...state,
        files: [...state.files, action.file],
        hide: false
      };

    case types.UPLOAD_FILE_CLOSE:
      return {
        ...state,
        hide: true
      };

    default:
      return state;
  }
}
