import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import BaseTitle from '../BaseTitle/BaseTitle';
import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty, length, match, password } from '../../validation/all-validation';
import { togglePasswordVisibility } from '../../actions/all-actions';
import { PASSWORD_CONFIRM_FIELD_NAME } from '../../constants/generic-constants';
import { scrollToFirstError } from '../../utils/utils';

class PasswordForm extends Component {
  constructor() {
    super();
    this.handleTogglePasswordVisibility = this.handleTogglePasswordVisibility.bind(this);
  }

  handleTogglePasswordVisibility() {
    const { dispatch } = this.props;
    dispatch(togglePasswordVisibility());
  }

  render() {
    const {
      fields: { oldPassword, newPassword, confirmationPassword },
      handleSubmit
    } = this.props;

    return (
      <div className="passwordFormPage_form">
        <form
          className="passwordForm"
          method="post"
          action="#"
          onSubmit={handleSubmit(this.props.onCallback)}
          noValidate
        >
          <section className="passwordForm_section">
            <BaseTitle title="passwordForm_password_title" />

            <div className="passwordForm_container">
              <FormRow customClass="passwordForm_row formRow--halfWidth">
                <BoxedInput
                  formRowItemKey="passwordForm_oldPassword"
                  type="password"
                  id="oldPassword"
                  labelKey="passwordForm_password_current"
                  customClass="resetPassword_boxedInput"
                  field={oldPassword}
                  mandatory
                >
                  <FieldErrorMsg field={oldPassword} customClass="passwordForm_fieldErrorMsg" />
                </BoxedInput>
              </FormRow>

              <FormRow customClass="passwordForm_row formRow--halfWidth">
                <BoxedInput
                  formRowItemKey="passwordForm_newPassword"
                  type="password"
                  id="newPassword"
                  labelKey="passwordForm_password_new"
                  customClass="resetPassword_boxedInput"
                  actionText="loginForm_password_actionText"
                  showPassword={this.props.showPassword}
                  onAction={this.handleTogglePasswordVisibility}
                  field={newPassword}
                  mandatory
                >
                  <FieldErrorMsg field={newPassword} customClass="passwordForm_fieldErrorMsg" />
                </BoxedInput>
              </FormRow>

              <FormRow customClass="passwordForm_row formRow--halfWidth">
                <BoxedInput
                  formRowItemKey="passwordForm_confirmPassword"
                  type="password"
                  id="confirmPassword"
                  labelKey="passwordForm_password_confirm"
                  customClass="resetPassword_boxedInput"
                  field={confirmationPassword}
                  mandatory
                >
                  <FieldErrorMsg field={confirmationPassword} customClass="passwordForm_fieldErrorMsg" />
                </BoxedInput>
              </FormRow>
            </div>
          </section>

          <FormRow customClass="passwordForm_row">
            <EkButton
              formRowItemKey="passwordForm_submitBtn"
              type="submit"
              customClass="passwordForm_actionsButton _is_alone"
              onAction={handleSubmit(this.props.onCallback)}
            >
              <FormattedMessage id="ekButton_defaultSaveButton" />
            </EkButton>
          </FormRow>
        </form>
      </div>
    );
  }
}

PasswordForm.displayName = 'PasswordForm';

PasswordForm.propTypes = {
  action: PropTypes.func,
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func
};

PasswordForm = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'editPassword',
    fields: ['oldPassword', 'newPassword', PASSWORD_CONFIRM_FIELD_NAME],
    validate: createValidator({
      oldPassword: [notEmpty(), password(), length({ min: 8, max: 25 })],
      newPassword: [notEmpty(), password(), length({ min: 8, max: 25 })],
      confirmationPassword: [notEmpty(), password(), length({ min: 8, max: 25 }), match('newPassword')]
    })
  },
  state => {
    // mapStateToProps
    let initialValues = {
      password: '',
      newPassword: '',
      confirmationPassword: ''
    };

    return {
      initialValues
    };
  }
)(PasswordForm);

export default connect(state => {
  const {
    user: { showPassword }
  } = state;
  return {
    showPassword
  };
})(PasswordForm);
