import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, reset } from 'redux-form';
import { routeActions } from 'react-router-redux';
import _ from 'lodash';

import {
  bookingCustomFieldsSelector,
  bundleSelector,
  customFieldsValidatorsSelector,
  selectCustomFieldNames
} from '../../selectors/all-selectors';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import CustomFields from '../../components/CustomFields';
import BookingCar from '../../components/BookingCar/BookingCar';
import TripDetails from '../../components/TripDetails/TripDetails';
import FormRow from '../../components/FormRow/FormRow';
import EkButton from '../../components/EkButton/EkButton';
import LabeledCheckbox from '../../components/LabeledCheckbox/LabeledCheckbox';
import BoxedInput from '../../components/BoxedInput/BoxedInput';
import BoxedSelect from '../../components/BoxedSelect/BoxedSelect';
import AddressAutocomplete from '../../components/AddressAutocomplete/AddressAutocomplete';
import BaseTitle from '../../components/BaseTitle/BaseTitle';
import FieldErrorMsg from '../../components/FieldErrorMsg/FieldErrorMsg';
import EkRadio from '../../components/EkRadio/EkRadio';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import config from '../../constants/config-constants';
import { requestConfirmBooking, callConfirmBookingSuccess } from '../../actions/all-actions';
import { createValidator, notEmpty, stopValidationIf } from '../../validation/all-validation';
import { BOOKING_TYPE_CAR_SHARING } from '../../constants/backend-constants';
import {
  addErrorMessage,
  dateFromString,
  namedCompose,
  rideSharingDateValid,
  scrollToFirstError
} from '../../utils/utils';
import routes from '../../constants/routes-constants';
import { truthy } from '../../validation/sync-validation';
import { autocompletePlaceholderMessages } from '../../constants/options-constants';

class BookingSummary extends Component {
  constructor(props) {
    super(props);
    this.handleConfirmBooking = this.handleConfirmBooking.bind(this);
    this.handleCancelAction = this.handleCancelAction.bind(this);
    this.handleBackLink = this.handleBackLink.bind(this);
  }

  handleConfirmBooking() {
    const { dispatch, bundle } = this.props;

    if (!rideSharingDateValid(this.props, dispatch)) return false;

    dispatch(requestConfirmBooking())
      .then(booking => {
        dispatch(callConfirmBookingSuccess(booking));
        dispatch(routeActions.push(routes.bookingSuccess.path));
      })
      .catch(error => {
        dispatch(
          addErrorMessage({
            error,
            bundle,
            errorCodePrefixes: ['booking_', 'bookingResult_'],
            def: 'booking_confirm_failed'
          })
        );
      });
  }

  handleCancelAction() {
    const { dispatch } = this.props;
    dispatch(reset('bookingDetails'));
  }

  handleBackLink() {
    const { dispatch, searchContext } = this.props;
    dispatch(
      routeActions.push(routes.bookingResult.path.replace(':search', encodeURIComponent(JSON.stringify(searchContext))))
    );
  }

  render() {
    const {
      fields: {
        message,
        sendSMS,
        cgv,
        openRideSharing,
        availableSeats,
        crossingAddress,
        pickupDate,
        pickupDateHour,
        pickupDateMin,
        fillCrossingAddress,
        voucherCode,
        ...fields
      },
      customFields,
      currentBooking,
      handleSubmit,
      intl: { formatMessage },
      searchContext,
      companyInfos
    } = this.props;

    const availableSeatsOptions = [];
    if (currentBooking.vehicle) {
      const maxNumberOfSeats = currentBooking.vehicle.seats - currentBooking.reservedSeats;
      for (let i = 0; i < maxNumberOfSeats; i++) {
        let number = (i + 1).toString();
        let seatOption = {
          label: number,
          value: number
        };
        availableSeatsOptions.push(seatOption);
      }
    }

    const now = new Date();
    let maxDateDefault = new Date();
    maxDateDefault.setFullYear(now.getFullYear() + 100);

    const pickupDateStart = dateFromString(currentBooking.start.date);
    const pickupDateEnd = dateFromString(currentBooking.end.date);

    const submitForm = handleSubmit(this.handleConfirmBooking);

    return (
      <div className="BookingSummaryPage">
        <Header />
        <FlashMessageDisplayer />

        <div className="mainContent">
          <div className="mainContent_inner">
            <button className="BookingSummaryPage_backlink" onClick={this.handleBackLink}>
              <FormattedMessage id="booking_summary_backlink" />
            </button>

            <div className="bookingSummary">
              <form action="#" method="post" className="bookingDetailsForm" onSubmit={submitForm} noValidate>
                <section className="bookingSummary_section">
                  <BaseTitle customClass="bookingSummary_subtitle" title="booking_summary_subtitle" />

                  <TripDetails item={currentBooking} oneWayTrip={_.get(searchContext, 'oneWayTrip')} />

                  <div className="bookingSummary_details">
                    <h3 className="bookingSummary_section_title">
                      <FormattedMessage id="booking_summary_othersubtitle" />
                    </h3>

                    <BookingCar item={currentBooking} isCurrentBooking />
                  </div>
                </section>

                <CustomFields customFields={customFields} formFields={fields} />

                {!companyInfos.contract.rideSharing || currentBooking.type !== BOOKING_TYPE_CAR_SHARING ? (
                  ''
                ) : (
                  <section className="bookingSummary_section">
                    <LabeledCheckbox
                      formRowItemKey="bookingSummary_openRideSharing"
                      id="openRideSharing"
                      customClass="bookingSummary_openRideSharingOption"
                      field={openRideSharing}
                    >
                      <FormattedMessage id="booking_summary_propose_rideSharing" />
                    </LabeledCheckbox>

                    {!openRideSharing.value ? (
                      ''
                    ) : (
                      <EkRadio
                        formRowItemKey="bookingSummary_availableSeats"
                        customContainerClass="bookingSummary_ekRadioContainer"
                        customWrapperClass="bookingSummary_ekRadioWrapper"
                        customTitleClass="bookingSummary_ekRadioTitle"
                        titleKey="bookingSummary_availableSeats"
                        items={availableSeatsOptions}
                        namespace="availableSeats"
                        field={availableSeats}
                        isEkRadioAlt
                      >
                        <FieldErrorMsg field={availableSeats} customClass="fieldErrorMsg--bookingSummary" />
                      </EkRadio>
                    )}

                    <BoxedInput
                      formRowItemKey="bookingSummary_fill_crossingAddress"
                      type="hidden"
                      id="fillCrossingAddress"
                      field={fillCrossingAddress}
                    />

                    {!openRideSharing.value ? (
                      ''
                    ) : searchContext.oneWayTrip && searchContext.to ? (
                      ''
                    ) : (
                      <div className="bookingSummary_crossingPoint">
                        <h3 className="bookingSummary_section_title">
                          <FormattedMessage id="bookingSummary_crossingPoint" />
                        </h3>

                        <FormRow customWrapperClass="bookingSummary_row">
                          <label formRowItemKey="bookingSummary_address_label" className="boxedInput_label">
                            <AddressAutocomplete
                              field={crossingAddress}
                              placeholder={formatMessage(autocompletePlaceholderMessages.generic)}
                              className="bookingSummary_AddressAutocomplete"
                            />

                            <FieldErrorMsg field={crossingAddress} customClass="fieldErrorMsg--bookingSummary" />
                          </label>

                          <BoxedInput
                            formRowItemKey="bookingSummary_address_pickupDate"
                            skinType="date"
                            type="date"
                            customClass="boxedInputWrapper--label"
                            customLabelTextClass="bookingSummary_boxedInputLabel"
                            labelKey="bookingSummary_pickup"
                            field={pickupDate}
                            minDate={pickupDateStart}
                            maxDate={searchContext.oneWayTrip ? maxDateDefault : pickupDateEnd}
                            hideLabel
                          >
                            <FieldErrorMsg field={pickupDate} customClass="fieldErrorMsg--bookingSummary" />
                          </BoxedInput>

                          <BoxedSelect
                            formRowItemKey="bookingSummary_address_pickupDateHour"
                            labelKey="bookingSummary_pickup_hour"
                            options={config.availableHours}
                            customClass="bookingSummary_boxedSelectWrapper"
                            customLabelClass="bookingSummary_boxedSelectLabel"
                            hideLabel
                            field={pickupDateHour}
                          >
                            <FieldErrorMsg field={pickupDateHour} customClass="fieldErrorMsg--bookingSummary" />
                          </BoxedSelect>

                          <BoxedSelect
                            formRowItemKey="bookingSummary_address_pickupDateMin"
                            labelKey="bookingSummary_pickup_min"
                            options={config.availableMinutes}
                            customClass="bookingSummary_boxedSelectWrapper"
                            customLabelClass="bookingSummary_boxedSelectLabel"
                            field={pickupDateMin}
                            hideLabel
                          >
                            <FieldErrorMsg field={pickupDateMin} customClass="fieldErrorMsg--bookingSummary" />
                          </BoxedSelect>
                        </FormRow>
                      </div>
                    )}
                  </section>
                )}

                <section className="bookingSummary_section">
                  <h3 className="bookingSummary_section_title">
                    <FormattedMessage id="booking_summary_form_title" />
                  </h3>

                  <FormRow customClass="bookingSummary_row">
                    <label formRowItemKey="bookingSummary_message_label" htmlFor="message">
                      {currentBooking.type === BOOKING_TYPE_CAR_SHARING ? (
                        <textarea
                          className="bookingSummary_ekTextarea"
                          name="message"
                          id="message"
                          rows="3"
                          placeholder="My message..."
                          {...message}
                        />
                      ) : (
                        <textarea
                          className="bookingSummary_ekTextarea"
                          name="message"
                          id="message"
                          rows="3"
                          placeholder="My message..."
                          readOnly
                          {...message}
                        />
                      )}
                    </label>
                  </FormRow>

                  {currentBooking.type !== BOOKING_TYPE_CAR_SHARING && (
                    <FormRow customClass="bookingSummary_row">
                      <LabeledCheckbox formRowItemKey="bookingSummary_sendSMS" id="sendSMS" field={sendSMS}>
                        <FormattedMessage id="booking_summary_send_note_sms" />
                      </LabeledCheckbox>
                    </FormRow>
                  )}

                  <FormRow customClass="bookingSummary_row bookingSummary_cgv">
                    <LabeledCheckbox formRowItemKey="bookingSummary_cgv" id="cgv" field={cgv}>
                      <FormattedMessage
                        id="booking_summary_cgv"
                        values={{
                          cguLink: (
                            <a target="_blank" href={companyInfos.termsOfUseUrl}>
                              <FormattedMessage id="booking_summary_cgu_link" />
                            </a>
                          )
                        }}
                      />
                    </LabeledCheckbox>
                  </FormRow>

                  <FieldErrorMsg field={cgv} customClass="fieldErrorMsg--subscribeForm" />
                </section>

                <FormRow customClass="bookingSummary_row bookingSummary_row_actions">
                  <EkButton
                    formRowItemKey="bookingSummary_submitBtn"
                    type="button"
                    customClass="bookingSummary_actionsButton submit"
                    onAction={submitForm}
                  >
                    <FormattedMessage id="booking_summary_confirm_button" />
                  </EkButton>
                </FormRow>
              </form>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

BookingSummary.displayName = 'BookingSummary';

BookingSummary.propTypes = {
  handleSubmit: PropTypes.func
};

const nonCustomFieldsValidation = {
  cgv: [truthy()],
  availableSeats: [
    stopValidationIf({
      condition: props => {
        return !_.get(props, 'form.openRideSharing.value');
      }
    }),
    notEmpty()
  ],
  crossingAddress: [
    stopValidationIf({
      condition: props => {
        return !_.get(props, 'form.openRideSharing.value');
      }
    }),
    notEmpty()
  ],
  pickupDate: [
    stopValidationIf({
      condition: props => {
        return !_.get(props, 'form.openRideSharing.value');
      }
    }),
    notEmpty()
  ],
  pickupDateHour: [
    stopValidationIf({
      condition: props => {
        return !_.get(props, 'form.openRideSharing.value');
      }
    }),
    notEmpty()
  ],
  pickupDateMin: [
    stopValidationIf({
      condition: props => {
        return !_.get(props, 'form.openRideSharing.value');
      }
    }),
    notEmpty()
  ]
};

const nonCustomFields = [
  'message',
  'sendSMS',
  'cgv',
  'openRideSharing',
  'availableSeats',
  'crossingAddress',
  'pickupDate',
  'pickupDateHour',
  'pickupDateMin',
  'fillCrossingAddress',
  'voucherCode'
];

export default namedCompose(
  reduxForm(
    {
      onSubmitFail: scrollToFirstError,
      form: 'bookingDetails'
    },
    state => {
      // mapStateToProps
      const {
        booking: { currentBooking, searchContext, showVoucherInput },
        company: { companyInfos }
      } = state;

      const customFields = bookingCustomFieldsSelector(state);

      return {
        currentBooking,
        searchContext,
        companyInfos,
        customFields,
        // redux-form props
        // Here to fix issue with form initialisation & validators
        initialValues: {
          message: state.booking.currentBooking.comment ? state.booking.currentBooking.comment : '',
          pickupDateHour: '00',
          pickupDateMin: '00',
          fillCrossingAddress: !(state.booking.searchContext.oneWayTrip && state.booking.searchContext.to)
        },
        validate: createValidator({
          ...nonCustomFieldsValidation,
          ...customFieldsValidatorsSelector(customFields)
        }),
        fields: [...nonCustomFields, ...selectCustomFieldNames(customFields)],
        bundle: bundleSelector(state)
      };
    }
  ),
  injectIntl
)(BookingSummary);
