// This component tries to create an abstracton of a field in a form regardless of its type
// It tries to reuse what already exists by mapping their API, this way it can offer
// a consistent API regardless of the type of input

// noinspection ES6CheckImport
import React, { PropTypes as T } from 'react';
import cn from 'classnames';
import { compose, mapProps } from 'recompose';

import BoxedSelect from '../BoxedSelect/BoxedSelect';
import BoxedInput from '../BoxedInput/BoxedInput';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import RciToggle from '../RciToggle/RciToggle';
import EkButtonUpload from '../EkButtonUpload/EkButtonUpload';

// handle API differences
const RciToggleMapper = compose(
  mapProps(({ label, customLabelClass, ...props }) => ({
    customTitleClass: customLabelClass,
    title: label,
    ...props
  }))
)(RciToggle);

const BoxedSelectMapper = compose(mapProps(props => props))(BoxedSelect);

const BoxedInputMapper = compose(
  mapProps(({ customLabelClass, ...props }) => ({
    customLabelTextClass: customLabelClass,
    ...props
  }))
)(BoxedInput);

const EkButtonUploadMapper = compose(
  mapProps(({ customLabelClass, ...props }) => ({
    customLabelTextClass: customLabelClass,
    ...props
  }))
)(EkButtonUpload);

function FormField({ wrapperClass, type, ...props }) {
  let input = null;
  const errorField = <FieldErrorMsg field={props.field} customClass="fieldErrorMsg--vehicleForm" />;

  switch (type) {
    case 'select':
      input = <BoxedSelectMapper {...props} children={errorField} />;
      break;
    case 'number':
    case 'date':
    case 'text':
      input = <BoxedInputMapper type={type} children={errorField} {...props} />;
      break;
    case 'radio':
      input = <RciToggleMapper {...props} children={errorField} />;
      break;
    case 'file':
      input = <EkButtonUploadMapper {...props} children={errorField} />;
      break;
    default:
      input = null;
  }

  return <div className={cn(wrapperClass || 'formFieldWrapper')}>{input}</div>;
}

FormField.displayName = 'FormField';
FormField.propTypes = {
  wrapperClass: T.string,
  field: T.object,
  type: T.any // select, number, date, text, radio, file
};

export default FormField;
