import Colors from 'material-ui/lib/styles/colors';
import Spacing from 'material-ui/lib/styles/spacing';
import zIndex from 'material-ui/lib/styles/zIndex';
import currentTheme from './theme-constants.js';

let basicTheme = {
  ...Colors,
  white: '#fff',
  greyBg: '#ebebeb',
  greyBorder: '#d9d9d9',
  greyBorderDark: '#979797',
  colorPrimary1: '#fe5001',
  colorPrimary2: '#ff7900',
  brown: '#e2e2e2',
  yellow: '#ffd600'
};

let defaultFont = 'Roboto, Arial, sans-serif';
const fonts = {
  nissan: 'NissanBrand, Arial, sans-serif',
  renault: 'RenaultLife, Helvetica, sans-serif',
  engie: 'Lato, Arial, sans-serif'
};

if (currentTheme.currentTheme === 'nissan') {
  basicTheme.colorPrimary1 = '#0076ab';
  basicTheme.colorPrimary2 = '#008dc7';
  defaultFont = fonts.nissan;
}

if (currentTheme.currentTheme === 'engie') {
  basicTheme.colorPrimary1 = '#009de9';
  basicTheme.colorPrimary2 = '#0af';
  defaultFont = fonts.engie;
}

if (currentTheme.currentTheme === 'renaultB2B') {
  basicTheme.colorPrimary1 = '#333';
  basicTheme.colorPrimary2 = '#ffce00';
  defaultFont = fonts.renault;
}

if (currentTheme.currentTheme === 'ego') {
  basicTheme.colorPrimary1 = '#3CB4E5';
  basicTheme.colorPrimary2 = '#3CB4E5';
}

if (currentTheme.currentTheme === 'share-mobility') {
  basicTheme.colorPrimary1 = '#D5751F';
  basicTheme.colorPrimary2 = '#D5751F';
}

export default {
  spacing: Spacing,
  zIndex: zIndex,
  fontFamily: defaultFont,
  palette: {
    primary1Color: basicTheme.colorPrimary1,
    primary2Color: basicTheme.colorPrimary2,
    primary3Color: basicTheme.lightBlack,
    accent1Color: basicTheme.yellow,
    accent2Color: basicTheme.brown,
    accent3Color: basicTheme.brown,
    textColor: basicTheme.darkBlack,
    alternateTextColor: basicTheme.white,
    canvasColor: basicTheme.white,
    borderColor: basicTheme.greyBorderDark,
    disabledColor: basicTheme.greyBorder,
    pickerHeaderColor: basicTheme.greyBg
  }
};
