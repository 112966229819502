import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import autoBind from 'react-autobind';
import _get from 'lodash/object/get';

// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';
import { injectIntl } from 'react-intl';

import BaseTitle from '../BaseTitle/BaseTitle';
import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import EkButtonUpload from '../EkButtonUpload/EkButtonUpload';
import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import {
  createValidator,
  stopValidationIf,
  notEmpty,
  driverLicence,
  length,
  validDate,
  validBirthDate
} from '../../validation/all-validation';
import { formatDateToStringDayFirst, namedCompose, scrollToFirstError } from '../../utils/utils';
import { FILE_TYPE_BACK, FILE_TYPE_FRONT, MEMBER_STATUS_APPROVED } from '../../constants/backend-constants';
import { getDrivingLicenseFileInfos } from '../../actions/all-actions';
import { fileTypeSelector, driverFileSelector, driverLicenceSelector } from '../../selectors/all-selectors';
import {
  DATE_PLACEHOLDER,
  DRIVER_LICENCE_BACK_ID,
  DRIVER_LICENCE_FRONT_ID,
  DRIVER_TAB_FORM_NAME
} from '../../constants/generic-constants';
import PdfPreview from '../PdfPreview/PdfPreview';

class DriverForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
  }

  componentWillMount() {
    const { dispatch, fileIdFront, fileIdBack } = this.props;
    if (fileIdFront) dispatch(getDrivingLicenseFileInfos(fileIdFront));
    if (fileIdBack) dispatch(getDrivingLicenseFileInfos(fileIdBack));
  }

  render() {
    const {
      fields: {
        birthDate,
        driverLicence,
        driverLicenceBack,
        driverLicenceNumber,
        deliveranceDate,
        expirationDate,
        optionalExpirationDate,
        cityDeliverance
      },
      userInfo,
      handleSubmit
    } = this.props;

    const isMemberValidated = _get(userInfo, 'status') === MEMBER_STATUS_APPROVED;

    return (
      <div className="driverFormPage_form">
        <form className="driverForm" method="post" action="#" onSubmit={handleSubmit(this.props.onCallback)} noValidate>
          <section className="driverForm_section">
            {userInfo.drivingLicence && !userInfo.drivingLicence.validated ? (
              <BaseTitle title="driverForm_driver_title" />
            ) : (
              ''
            )}
            <div className="driverForm_container">
              {!isMemberValidated && (
                <FormRow customClass="driverForm_row">
                  <EkButtonUpload
                    formRowItemKey="driverForm_driverLicenceUploadBtn"
                    id={DRIVER_LICENCE_FRONT_ID}
                    customClass="driverForm_ekButtonUpload"
                    labelKey="driverForm_driverLicence_label"
                    buttonLabel="driverForm_uploadLicence_label"
                    field={driverLicence}
                    mandatory
                  >
                    <FieldErrorMsg field={driverLicence} customClass="fieldErrorMsg--driverForm" />
                  </EkButtonUpload>
                </FormRow>
              )}
              {!isMemberValidated && (
                <FormRow customClass="driverForm_row">
                  <EkButtonUpload
                    formRowItemKey="driverForm_driverLicenceBackUploadBtn"
                    id={DRIVER_LICENCE_BACK_ID}
                    customClass="driverForm_ekButtonUpload"
                    labelKey="subscribeForm_driverLicenceBack_label"
                    buttonLabel="driverForm_uploadLicence_label"
                    field={driverLicenceBack}
                    mandatory
                  >
                    <FieldErrorMsg field={driverLicenceBack} customClass="fieldErrorMsg--driverForm" />

                    {/* {this.displayPreview(backMimeType, {
                      src: backImgContent,
                      className: 'initial'
                    })} */}
                  </EkButtonUpload>
                </FormRow>
              )}

              <FormRow customClass="driverForm_row">
                <BoxedInput
                  formRowItemKey="driverForm_driverLicenceNumber"
                  type="text"
                  id="driverLicenceNumber"
                  customClass="driverForm_boxedInput"
                  labelKey="driverForm_driverLicenceNumber_label"
                  maxlength="25"
                  field={driverLicenceNumber}
                  mandatory
                  readOnly={isMemberValidated}
                  disabled={isMemberValidated}
                >
                  <FieldErrorMsg field={driverLicenceNumber} customClass="fieldErrorMsg--driverForm" />
                </BoxedInput>

                <BoxedInput
                  formRowItemKey="driverForm_cityDeliverance"
                  type="text"
                  id="cityDeliverance"
                  customClass="driverForm_boxedInput"
                  labelKey="driverForm_deliveranceCountry_label"
                  field={cityDeliverance}
                  mandatory
                  readOnly={isMemberValidated}
                  disabled={isMemberValidated}
                >
                  <FieldErrorMsg field={cityDeliverance} customClass="fieldErrorMsg--driverForm" />
                </BoxedInput>
              </FormRow>

              <FormRow customClass="driverForm_row">
                <BoxedInput
                  formRowItemKey="subscribeForm_deliveranceDate"
                  id="deliveranceDate"
                  customClass="subscribeForm_boxedInputWrapper--date"
                  labelKey="common_deliveranceDate"
                  type="text"
                  placeholder={DATE_PLACEHOLDER}
                  maxlength="10"
                  field={deliveranceDate}
                  mandatory
                  disabled={isMemberValidated}
                  readOnly={isMemberValidated}
                >
                  <FieldErrorMsg field={deliveranceDate} customClass="fieldErrorMsg--subscribeForm" />
                </BoxedInput>

                <BoxedInput
                  formRowItemKey="driverForm_maxBirthDate"
                  id="birthDate"
                  customClass="driverForm_boxedInputWrapper--date"
                  labelKey="subscribeForm_birthDate_label"
                  type="text"
                  placeholder={DATE_PLACEHOLDER}
                  field={birthDate}
                  maxlength="10"
                  disabled={isMemberValidated}
                  readOnly={isMemberValidated}
                  mandatory
                >
                  <FieldErrorMsg field={birthDate} customClass="fieldErrorMsg--subscribeForm" />
                </BoxedInput>
              </FormRow>

              <FormRow customClass="driverForm_row">
                <BoxedInput
                  formRowItemKey="driverForm_expirationDate"
                  id="expirationDate"
                  customClass="driverForm_boxedInputWrapper--date"
                  labelKey="subscribeForm_expirationDate_label"
                  type="text"
                  placeholder={DATE_PLACEHOLDER}
                  maxlength="10"
                  field={expirationDate}
                  disabled={isMemberValidated}
                  readOnly={isMemberValidated}
                  mandatory={optionalExpirationDate && !optionalExpirationDate.checked}
                >
                  <FieldErrorMsg field={expirationDate} customClass="fieldErrorMsg--subscribeForm" />
                </BoxedInput>
              </FormRow>
              <FormRow customClass="subscribeForm_row">
                <LabeledCheckbox
                  formRowItemKey="subscribeForm_optionalExpirationDate"
                  id="optionalExpirationDate"
                  field={optionalExpirationDate}
                  customClass={isMemberValidated ? 'readOnly disabled' : ''}
                >
                  <FormattedMessage id="subscribeForm_optionalExpirationDate_label" />
                </LabeledCheckbox>
              </FormRow>
            </div>
          </section>
          {!isMemberValidated && (
            <FormRow customWrapperClass="driverForm_actions" customClass="driverForm_row">
              <EkButton
                formRowItemKey="driverForm_submitBtn"
                type="submit"
                customClass="driverForm_actionsButton _is_alone"
                onAction={handleSubmit(this.props.onCallback)}
              >
                <FormattedMessage id="ekButton_defaultSaveButton" />
              </EkButton>
            </FormRow>
          )}
        </form>
      </div>
    );
  }
}

DriverForm.displayName = 'DriverForm';

DriverForm.propTypes = {
  action: PropTypes.func,
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func
};

DriverForm = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: DRIVER_TAB_FORM_NAME,
    destroyOnUnmount: false,
    fields: [
      'birthDate',
      'driverLicence',
      'driverLicenceBack',
      'driverLicenceNumber',
      'expirationDate',
      'optionalExpirationDate',
      'deliveranceDate',
      'cityDeliverance'
    ],
    validate: createValidator({
      birthDate: [notEmpty(), validBirthDate()],
      driverLicence: [notEmpty(), driverLicence()],
      driverLicenceBack: [notEmpty(), driverLicence()],
      driverLicenceNumber: [notEmpty(), length({ max: 25 })],
      expirationDate: [
        stopValidationIf({ condition: (props, value, values) => values.optionalExpirationDate }),
        notEmpty(),
        validDate()
      ],
      deliveranceDate: [validDate(), notEmpty()],
      cityDeliverance: [notEmpty()]
    })
  },
  state => {
    const drivingLicence = driverLicenceSelector(state);

    const fi = fileTypeSelector(state, FILE_TYPE_FRONT);
    const bi = fileTypeSelector(state, FILE_TYPE_BACK);

    const fileIdFront = _get(fi, 'fileId');
    const fileIdBack = _get(bi, 'fileId');

    const dataFrontImg = driverFileSelector(state, fileIdFront);
    const dataBackImg = driverFileSelector(state, fileIdBack);

    const frontImgContent = _get(dataFrontImg, 'content');
    const backImgContent = _get(dataBackImg, 'content');

    const frontMimeType = _get(dataFrontImg, 'mimeType');
    const backMimeType = _get(dataBackImg, 'mimeType');

    let initialValues = {};
    let initialFiles = [];

    if (fileIdFront) {
      initialValues.driverLicence = fileIdFront;
      initialFiles.push({ fileId: fileIdFront, type: FILE_TYPE_FRONT });
      initialValues.files = initialFiles;
    }
    if (fileIdBack) {
      initialValues.driverLicenceBack = fileIdBack;
      initialFiles.push({ fileId: fileIdBack, type: FILE_TYPE_BACK });
      initialValues.files = initialFiles;
    }

    if (drivingLicence) {
      initialValues = Object.assign({}, initialValues, {
        driverLicenceNumber: drivingLicence.licenceNumber,
        cityDeliverance: drivingLicence.cityDeliverance,
        files: initialFiles
      });

      if (drivingLicence.expirationDate)
        initialValues.expirationDate = formatDateToStringDayFirst(drivingLicence.expirationDate);
      else initialValues.optionalExpirationDate = true;
      if (drivingLicence.deliveranceDate)
        initialValues.deliveranceDate = formatDateToStringDayFirst(drivingLicence.deliveranceDate);
    }

    const birthDate = _get(state.user.userInfo, 'birthDate');

    initialValues.birthDate = birthDate ? formatDateToStringDayFirst(birthDate) : '';

    return {
      initialValues,
      fileIdFront,
      frontImgContent,
      backImgContent,
      frontMimeType,
      backMimeType,
      fileIdBack
    };
  }
)(DriverForm);

export default namedCompose(
  connect(state => {
    const {
      user: { userInfo }
    } = state;

    return {
      userInfo
    };
  }),
  injectIntl
)(DriverForm);
