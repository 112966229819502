import { UPDATE_LOCATION } from 'react-router-redux';

import * as types from '../constants/actionTypes-constants';
import _forEach from 'lodash/collection/forEach';
import _get from 'lodash/object/get';

function handleSetBookingList(state, action, page, total) {
  const localObject = state.listBookingsUser || {};
  const serverObject = _get(action.params, 'results') || [];
  const pageInfo = _get(action.params, 'pageInfo');
  const bookingPage = _get(pageInfo, 'currentPage') || state[page];
  const bookingTotalPages = _get(pageInfo, 'totalPages') || state[total];

  _forEach(serverObject, (v, k) => {
    const localList = localObject[k] || [];
    const serverList = v || [];
    localObject[k] = localList.concat(serverList);
  });

  return {
    ...state,
    [page]: bookingPage,
    [total]: bookingTotalPages,
    userHasReservationsList: true,
    listBookingsUser: {
      ...localObject
    }
  };
}

export const bookingMaxItems = 3;

const defaultBookingPage = 0;
const defaultBookingTotal = 1;

export const bookingResultsCounterDefaults = {
  bookingResultCarSharingBusinessMaxItems: bookingMaxItems,
  bookingResultCarSharingPrivateMaxItems: bookingMaxItems,
  bookingResultRideSharingMaxItems: bookingMaxItems,
  pastBookingPage: defaultBookingPage,
  upcomingBookingPage: defaultBookingPage,
  pastBookingTotalPages: defaultBookingTotal,
  upcomingBookingTotalPages: defaultBookingTotal
};

const getBookingListUserDefault = () => ({
  past: [],
  upcoming: []
});

export default function booking(
  state = {
    searchError: null,
    lists: null,
    bookingResultActiveTabIndex: 0,
    bookingMemberActiveTabIndex: 0,
    ...bookingResultsCounterDefaults,
    listBookingsUser: getBookingListUserDefault(),
    moreDetailsOpenedList: [],
    latestBooking: null,
    currentBooking: null,
    bookingResultDisplaySearch: false,
    searchContext: null,
    openedCancelModalBookingId: null,
    bookingToCancel: null,
    privatePaymentModal: false,
    bookingResultDisplayFilters: false,
    allFiltersSelected: null,
    facets: null,
    openedDamageReportingModalBooking: null,
    feedBack: null,
    userHasReservationsList: false,
    timeZoneId: '',
    showVoucherInput: false
  },
  action
) {
  switch (action.type) {
    case types.BOOKING_SEARCH_REQUEST:
      return state;

    case types.BOOKING_SEARCH_FAILED:
      return Object.assign({}, state, {
        searchError: action.error
      });

    case types.BOOKING_SEARCH_SUCCESS:
      return Object.assign({}, state, {
        lists: action.list,
        searchError: null
      });

    case types.BOOKING_RESET_SEARCH:
      return Object.assign({}, state, {
        lists: null
      });

    case types.BOOKING_SEARCH_CONTEXT_SET:
      return Object.assign({}, state, {
        searchContext: action.search
      });

    case types.BOOKING_SEARCH_CONTEXT_RESET:
      return Object.assign({}, state, {
        searchContext: null
      });

    case types.BOOKING_RESULT_ACTIVE_TAB_INDEX_UPDATE:
      return Object.assign({}, state, {
        bookingResultActiveTabIndex: action.index
      });

    case types.BOOKING_RESULT_CAR_SHARING_BUSINESS_MAX_ITEMS_INCREASE:
      return Object.assign({}, state, {
        bookingResultCarSharingBusinessMaxItems: state.bookingResultCarSharingBusinessMaxItems + 3
      });

    case types.BOOKING_RESULT_CAR_SHARING_PRIVATE_MAX_ITEMS_INCREASE:
      return Object.assign({}, state, {
        bookingResultCarSharingPrivateMaxItems: state.bookingResultCarSharingPrivateMaxItems + 3
      });

    case types.BOOKING_RESULT_RIDE_SHARING_MAX_ITEMS_INCREASE:
      return Object.assign({}, state, {
        bookingResultRideSharingMaxItems: state.bookingResultRideSharingMaxItems + 3
      });

    case types.BOOKING_RESULT_SEARCH_OPENED_STATE_TOGGLE:
      return Object.assign({}, state, {
        bookingResultDisplaySearch: !state.bookingResultDisplaySearch
      });

    case types.BOOKING_RESULT_FILTERS_OPENED_STATE_TOGGLE:
      return Object.assign({}, state, {
        bookingResultDisplayFilters: !state.bookingResultDisplayFilters
      });

    case types.BOOKING_MEMBER_ACTIVE_TAB_INDEX_UPDATE:
      return Object.assign({}, state, {
        bookingMemberActiveTabIndex: action.index
      });

    case types.BOOKING_PAST_LIST_UPDATE:
      return handleSetBookingList(state, action, 'pastBookingPage', 'pastBookingTotalPages');

    case types.BOOKING_UPCOMING_LIST_UPDATE:
      return handleSetBookingList(state, action, 'upcomingBookingPage', 'upcomingBookingTotalPages');

    case types.BOOKING_CANCEL_MODAL_OPEN:
      return Object.assign({}, state, {
        openedCancelModalBookingId: action.booking.id
      });

    case types.BOOKING_CANCEL_MODAL_CLOSE:
      return Object.assign({}, state, {
        openedCancelModalBookingId: null
      });

    case types.BOOKING_OPEN_PRIVATE_PAYMENT_MODAL:
      return Object.assign({}, state, {
        privatePaymentModal: true
      });

    case types.BOOKING_CLOSE_PRIVATE_PAYMENT_MODAL:
      return Object.assign({}, state, {
        privatePaymentModal: false
      });

    case types.BOOKING_DETAILS_OPENED_STATE_TOGGLE:
      let newMoreDetailsOpenedList = state.moreDetailsOpenedList.slice(0);
      let index = null;
      for (let i = 0, length = newMoreDetailsOpenedList.length; i < length; i++) {
        if (newMoreDetailsOpenedList[i] === action.id) {
          index = i;
          break;
        }
      }
      if (index === null) {
        newMoreDetailsOpenedList.push(action.id);
      } else {
        newMoreDetailsOpenedList.splice(index, 1);
      }
      return Object.assign({}, state, {
        moreDetailsOpenedList: newMoreDetailsOpenedList
      });

    case types.BOOKING_CURRENT_BOOKING_SET:
      return Object.assign({}, state, {
        currentBooking: action.booking
      });

    case types.BOOKING_CONFIRM_REQUEST:
      return state;

    case types.BOOKING_CONFIRM_SUCCESS:
      return Object.assign({}, state, {
        latestBooking: action.confirmedBooking
      });

    case types.BOOKING_SET_BOOKING_CANCEL:
      return Object.assign({}, state, {
        bookingToCancel: action.bookingId
      });

    case types.BOOKING_SET_BOOKING_ALL_FILTERS_SELECTED:
      return Object.assign({}, state, {
        allFiltersSelected: action.allFiltersSelected
      });

    case types.BOOKING_RESET_BOOKING_ALL_FILTERS_SELECTED:
      return Object.assign({}, state, {
        allFiltersSelected: null
      });

    case types.BOOKING_SET_BOOKING_FACETS:
      return Object.assign({}, state, {
        facets: action.facets
      });

    case types.BOOKING_DAMAGE_REPORTING_MODAL_OPEN:
      return Object.assign({}, state, {
        openedDamageReportingModalBooking: action.booking
      });

    case types.BOOKING_DAMAGE_REPORTING_MODAL_CLOSE:
      return Object.assign({}, state, {
        openedDamageReportingModalBooking: null
      });

    case types.BOOKING_GET_BOOKING_FEEDBACK_SUCCESS:
      return Object.assign({}, state, {
        feedBack: action.params
      });

    case types.BOOKING_GET_TIMEZONE_SUCCESS:
      return Object.assign({}, state, {
        timeZoneId: action.timeZoneId
      });

    case types.BOOKING_CLEAR_TIMEZONE:
      return Object.assign({}, state, {
        timeZoneId: ''
      });

    case types.BOOKING_TOGGLE_VOUCHER_INPUT:
      return {
        ...state,
        showVoucherInput: !state.showVoucherInput
      };

    case UPDATE_LOCATION:
      return {
        ...state,
        searchError: null,
        ...bookingResultsCounterDefaults,
        listBookingsUser: getBookingListUserDefault(),
        moreDetailsOpenedList: [],
        bookingResultDisplaySearch: false,
        userHasReservationsList: false
      };
    default:
      return state;
  }
}
