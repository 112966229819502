import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import SuccessIcon from 'material-ui/lib/svg-icons/navigation/check';
import ErrorIcon from 'material-ui/lib/svg-icons/alert/error-outline';
import WarningIcon from 'material-ui/lib/svg-icons/alert/warning';
import InfoIcon from 'material-ui/lib/svg-icons/action/info-outline';
import CloseIcon from 'material-ui/lib/svg-icons/navigation/close';
import pure from 'recompose/pure';
import _ from 'lodash';

import {
  FLASH_MESSAGE_TYPE_INFO,
  FLASH_MESSAGE_TYPE_WARNING,
  FLASH_MESSAGE_TYPE_ERROR,
  FLASH_MESSAGE_TYPE_SUCCESS
} from '../../constants/generic-constants';
import { removeFlashMessage } from '../../actions/flashMessage-actions';
import { namedCompose } from '../../utils/utils';
import { localeSelector } from '../../selectors/all-selectors';

class FlashMessageDisplayer extends Component {
  constructor(props) {
    super(props);
    this.closeFlashMessage = this.closeFlashMessage.bind(this);
    this.configureValues();
  }

  componentWillReceiveProps(nextProps) {
    const {
      messages,
      dispatch,
      intl: { formatMessage }
    } = nextProps;

    if (Object.keys(messages).length > 0) {
      for (const prop in messages) {
        const item = messages[prop];
        const msg = item.content || formatMessage({ id: item.contentKey }) || '';
        const onlyChars = msg.replace(/\s/g, '');
        const msgLength = onlyChars.length * this.characterReadTimeMs + this.staticBufferMs;

        setTimeout(() => {
          dispatch(removeFlashMessage(item._uid));
        }, Math.max(this.minReadTimeMs, msgLength));
      }
    }
  }

  configureValues() {
    this.characterReadTimeMs = 60;
    this.staticBufferMs = 1000;
    this.minReadTimeMs = 4500;
  }

  closeFlashMessage(id) {
    const { dispatch } = this.props;
    dispatch(removeFlashMessage(id));
  }

  render() {
    const { messages } = this.props;
    const list = [];

    for (const prop in messages) {
      const message = messages[prop];
      const boundCloseFlashMessage = this.closeFlashMessage.bind(this, message._uid);

      list.push(
        <div key={message._uid} className="flashMessageDisplayer_message">
          <div className="flashMessageDisplayer_message_wrap">
            <div className="flashMessageDisplayer_close" onClick={boundCloseFlashMessage}>
              <CloseIcon style={{ fill: 'white' }} />
            </div>
            <div className="flashMessageDisplayer_left_icon">
              {message.type === FLASH_MESSAGE_TYPE_SUCCESS && (
                <SuccessIcon className="flashMessageDisplayer_message--success" />
              )}
              {message.type === FLASH_MESSAGE_TYPE_ERROR && (
                <ErrorIcon className="flashMessageDisplayer_message--error" />
              )}
              {message.type === FLASH_MESSAGE_TYPE_WARNING && (
                <WarningIcon className="flashMessageDisplayer_message--warning" />
              )}
              {message.type === FLASH_MESSAGE_TYPE_INFO && <InfoIcon className="flashMessageDisplayer_message--info" />}
            </div>
            <span className="flashMessageDisplayer_message_raw">
              {_.get(message, 'content')
                ? message.content
                : _.get(message, 'contentKey') && (
                    <FormattedMessage id={message.contentKey} values={message.contentData} />
                  )}
            </span>
          </div>
        </div>
      );
    }

    return (
      <div
        className={
          (!list.length ? 'flashMessageDisplayer_hide' : 'flashMessageDisplayer_show') + ' flashMessageDisplayer'
        }
      >
        {list}
      </div>
    );
  }
}

FlashMessageDisplayer.propTypes = {
  messages: PropTypes.object
};

export default namedCompose(
  connect(state => {
    const {
      flashMessage: { messages }
    } = state;

    return {
      messages,
      locale: localeSelector(state) // pure exception
    };
  }),
  injectIntl,
  pure
)(FlashMessageDisplayer);
