export const API_SET_TOKEN_REQUEST = 'API_SET_TOKEN_REQUEST';
export const API_UNSET_TOKEN_REQUEST = 'API_UNSET_TOKEN_REQUEST';
export const API_CALL_START = 'API_CALL_START';
export const API_CALL_END = 'API_CALL_END';

export const I18N_BUNDLE_REQUEST = 'I18N_BUNDLE_REQUEST';
export const I18N_BUNDLE_SUCCESS = 'I18N_BUNDLE_SUCCESS';

export const FLASH_MESSAGE_ADD = 'FLASH_MESSAGE_ADD';
export const FLASH_MESSAGE_REMOVE = 'FLASH_MESSAGE_REMOVE';

export const VALIDATION_CHECK_EMAIL_AVAILABILITY_REQUEST = 'VALIDATION_CHECK_EMAIL_AVAILABILITY_REQUEST';
export const VALIDATION_CHECK_EMAIL_AVAILABILITY_SUCCESS = 'VALIDATION_CHECK_EMAIL_AVAILABILITY_SUCCESS';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_PASSWORD_VISIBILITY_TOGGLE = 'USER_PASSWORD_VISIBILITY_TOGGLE';
export const USER_STORED_LOGIN_EXPOSE = 'USER_STORED_LOGIN_EXPOSE';
export const USER_FORGOT_PASSWORD_MODE_TOGGLE = 'USER_FORGOT_PASSWORD_MODE_TOGGLE';
export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_GET_URL_REQUEST = 'CHANGE_PASSWORD_GET_URL_REQUEST';
export const CHANGE_PASSWORD_GET_URL_SUCCESS = 'CHANGE_PASSWORD_GET_URL_SUCCESS';
export const CHANGE_PASSWORD_GET_URL_ERROR = 'CHANGE_PASSWORD_GET_URL_ERROR';
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS';
export const USER_RESET_PASSWORD_ERROR = 'USER_RESET_PASSWORD_ERROR';
export const USER_SUBSCRIBE_REQUEST = 'USER_SUBSCRIBE_REQUEST';
export const USER_SUBSCRIBE_SUCCESS = 'USER_SUBSCRIBE_SUCCESS';
export const USER_PRE_SUBSCRIBE_SUCCESS = 'USER_PRE_SUBSCRIBE_SUCCESS';
export const USER_EDIT_PROFILE_REQUEST = 'USER_EDIT_PROFILE_REQUEST';

export const SET_TEMPORARY_IMPERSONATE_TOKEN = 'SET_TEMPORARY_IMPERSONATE_TOKEN';
export const CLEAR_TEMPORARY_IMPERSONATE_TOKEN = 'CLEAR_TEMPORARY_IMPERSONATE_TOKEN';

export const USER_GET_PROFILE_REQUEST = 'USER_GET_PROFILE_REQUEST';
export const USER_GET_PROFILE_SUCCESS = 'USER_GET_PROFILE_SUCCESS';
export const USER_GET_PROFILE_ERROR = 'USER_GET_PROFILE_ERROR';

export const USER_EDIT_PROFILE_SUCCESS = 'USER_EDIT_PROFILE_SUCCESS';
export const USER_EDIT_DRIVER_LICENCE_REQUEST = 'USER_EDIT_DRIVER_LICENCE_REQUEST';
export const USER_EDIT_DRIVER_LICENCE_SUCCESS = 'USER_EDIT_DRIVER_LICENCE_SUCCESS';
export const USER_EDIT_PASSWORD_REQUEST = 'USER_EDIT_PASSWORD_REQUEST';
export const USER_EDIT_PASSWORD_SUCCESS = 'USER_EDIT_PASSWORD_SUCCESS';
export const USER_EDIT_PAYMENT_INFOS_REQUEST = 'USER_EDIT_PAYMENT_INFOS_REQUEST';
export const USER_EDIT_PAYMENT_INFOS_SUCCESS = 'USER_EDIT_PAYMENT_INFOS_SUCCESS';
export const USER_EDIT_DRIVER_LICENCE_FILE_INFOS_SUCCESS = 'USER_EDIT_DRIVER_LICENCE_FILE_INFOS_SUCCESS';
export const USER_EDIT_DRIVER_LICENCE_FILE_REFRESH = 'USER_EDIT_DRIVER_LICENCE_FILE_REFRESH';
export const USER_EDIT_DRIVER_LICENCE_FILE_REFRESH_DONE = 'USER_EDIT_DRIVER_LICENCE_FILE_REFRESH_DONE';
export const USER_ACCOUNT_ACTIVE_TAB_INDEX_UPDATE = 'USER_ACCOUNT_ACTIVE_TAB_INDEX_UPDATE';
export const USER_GEOLOCATION_REQUEST = 'USER_GEOLOCATION_REQUEST';
export const USER_GEOLOCATION_SUCCESS = 'USER_GEOLOCATION_SUCCESS';
export const USER_GEOLOCATION_ERROR = 'USER_GEOLOCATION_ERROR';
export const USER_TOKEN_REFRESH_REQUEST = 'USER_TOKEN_REFRESH_REQUEST';
export const USER_TOKEN_REFRESH_SUCCESS = 'USER_TOKEN_REFRESH_SUCCESS';
export const USER_PAYMENT_INFOS_REQUEST = 'USER_PAYMENT_INFOS_REQUEST';
export const USER_PAYMENT_INFOS_SUCCESS = 'USER_PAYMENT_INFOS_SUCCESS';
export const USER_PAYMENT_INFOS_STORE = 'USER_PAYMENT_INFOS_STORE';
export const CREDIT_CARD_MODAL_OPEN = 'CREDIT_CARD_MODAL_OPEN';
export const CREDIT_CARD_MODAL_CLOSE = 'CREDIT_CARD_MODAL_CLOSE';
export const USER_VALIDATE_ACCOUNT_REQUEST = 'USER_VALIDATE_ACCOUNT_REQUEST';
export const USER_VALIDATE_ACCOUNT_SUCCESS = 'USER_VALIDATE_ACCOUNT_SUCCESS';
export const USER_VALIDATE_ACCOUNT_ERROR = 'USER_VALIDATE_ACCOUNT_ERROR';
export const COMPANY_GET_CUSTOMIZATIONS = 'COMPANY_GET_CUSTOMIZATIONS';
export const COMPANY_GET_CUSTOMIZATIONS_SUCCESS = 'COMPANY_GET_CUSTOMIZATIONS_SUCCESS';
export const COMPANY_GET_CUSTOMIZATIONS_ERROR = 'COMPANY_GET_CUSTOMIZATIONS_ERROR';
export const USER_SUBSCRIBE_FORM_STORE = 'USER_SUBSCRIBE_FORM_STORE';
export const USER_SUBSCRIBE_FORM_RESTORE = 'USER_SUBSCRIBE_FORM_RESTORE';
export const USER_SUBSCRIBE_FORM_UNSTORE = 'USER_SUBSCRIBE_FORM_UNSTORE';
export const USER_RESET_PASSWORD_CHECK_TOKEN_REQUEST = 'USER_RESET_PASSWORD_CHECK_TOKEN_REQUEST';
export const CHECK_WHITE_BRAND_PASSWORD_REQUEST = 'CHECK_WHITE_BRAND_PASSWORD_REQUEST';
export const CHECK_WHITE_BRAND_PASSWORD_ERROR = 'CHECK_WHITE_BRAND_PASSWORD_ERROR';
export const USER_RESET_PASSWORD_CHECK_TOKEN_SUCCESS = 'USER_RESET_PASSWORD_CHECK_TOKEN_SUCCESS';
export const USER_RESET_PASSWORD_APPLY_REQUEST = 'USER_RESET_PASSWORD_APPLY_REQUEST';
export const CHANGE_PASSWORD_APPLY_REQUEST = 'CHANGE_PASSWORD_APPLY_REQUEST';
export const CHANGE_PASSWORD_APPLY_SUCCESS = 'CHANGE_PASSWORD_APPLY_SUCCESS';
export const CHANGE_PASSWORD_APPLY_ERROR = 'CHANGE_PASSWORD_APPLY_ERROR';
export const USER_RESET_PASSWORD_STORE_TOKEN = 'USER_RESET_PASSWORD_STORE_TOKEN';
export const USER_EDIT_SETTINGS_REQUEST = 'USER_EDIT_SETTINGS_REQUEST';
export const USER_EDIT_SETTINGS_SUCCESS = 'USER_EDIT_SETTINGS_SUCCESS';
export const USER_GET_SETTINGS_REQUEST = 'USER_GET_SETTINGS_REQUEST';
export const USER_GET_SETTINGS_SUCCESS = 'USER_GET_SETTINGS_SUCCESS';
export const USER_SET_USED_BY_BACKUSER = 'USER_SET_USED_BY_BACKUSER';
export const USER_SET_INITIAL_LOGIN_VALUE = 'USER_SET_INITIAL_LOGIN_VALUE';
export const SEND_ACTIVATION_LINK_REQUEST = 'SEND_ACTIVATION_LINK_REQUEST';
export const SEND_ACTIVATION_LINK_SUCCESS = 'SEND_ACTIVATION_LINK_SUCCESS';
export const SEND_ACTIVATION_LINK_ERROR = 'SEND_ACTIVATION_LINK_ERROR';
export const USER_GET_PAYMENT_URL_REQUEST = 'USER_GET_PAYMENT_URL_REQUEST';
export const USER_GET_PAYMENT_URL_SUCCESS = 'USER_GET_PAYMENT_URL_SUCCESS';
export const USER_CLOSE_COOKIE_CONSENT = 'USER_CLOSE_COOKIE_CONSENT';
export const USER_GET_CUSTOM_FIELDS_SUCCESS = 'USER_GET_CUSTOM_FIELDS_SUCCESS';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const UPLOAD_FILE_CLOSE = 'UPLOAD_FILE_CLOSE';
export const UPLOAD_CLEAR_DRIVER_FILES = 'UPLOAD_CLEAR_DRIVER_FILES';
export const UPDATE_USER_INFO_FILES = 'UPDATE_USER_INFO_FILES';

export const BOOKING_SEARCH_REQUEST = 'BOOKING_SEARCH_REQUEST';
export const BOOKING_SEARCH_SUCCESS = 'BOOKING_SEARCH_SUCCESS';
export const BOOKING_SEARCH_FAILED = 'BOOKING_SEARCH_FAILED';
export const BOOKING_SEARCH_CONTEXT_SET = 'BOOKING_SEARCH_CONTEXT_SET';
export const BOOKING_SEARCH_CONTEXT_RESET = 'BOOKING_SEARCH_CONTEXT_RESET';
export const BOOKING_RESULT_ACTIVE_TAB_INDEX_UPDATE = 'BOOKING_RESULT_ACTIVE_TAB_INDEX_UPDATE';
export const BOOKING_RESULT_CAR_SHARING_BUSINESS_MAX_ITEMS_INCREASE =
  'BOOKING_RESULT_CAR_SHARING_BUSINESS_MAX_ITEMS_INCREASE';
export const BOOKING_RESULT_CAR_SHARING_PRIVATE_MAX_ITEMS_INCREASE =
  'BOOKING_RESULT_CAR_SHARING_PRIVATE_MAX_ITEMS_INCREASE';
export const BOOKING_RESULT_RIDE_SHARING_MAX_ITEMS_INCREASE = 'BOOKING_RESULT_RIDE_SHARING_MAX_ITEMS_INCREASE';
export const BOOKING_RESULT_SEARCH_OPENED_STATE_TOGGLE = 'BOOKING_RESULT_SEARCH_OPENED_STATE_TOGGLE';
export const BOOKING_RESULT_FILTERS_OPENED_STATE_TOGGLE = 'BOOKING_RESULT_FILTERS_OPENED_STATE_TOGGLE';
export const BOOKING_MEMBER_ACTIVE_TAB_INDEX_UPDATE = 'BOOKING_MEMBER_ACTIVE_TAB_INDEX_UPDATE';

export const BOOKING_PAST_LIST_UPDATE = 'BOOKING_PAST_LIST_UPDATE';
export const BOOKING_UPCOMING_LIST_UPDATE = 'BOOKING_UPCOMING_LIST_UPDATE';

export const BOOKING_DETAILS_OPENED_STATE_TOGGLE = 'BOOKING_DETAILS_OPENED_STATE_TOGGLE';
export const BOOKING_CURRENT_BOOKING_SET = 'BOOKING_CURRENT_BOOKING_SET';
export const BOOKING_CONFIRM_REQUEST = 'BOOKING_CONFIRM_REQUEST';
export const BOOKING_CONFIRM_SUCCESS = 'BOOKING_CONFIRM_SUCCESS';
export const BOOKING_CANCEL_MODAL_OPEN = 'BOOKING_CANCEL_MODAL_OPEN';
export const BOOKING_CANCEL_MODAL_CLOSE = 'BOOKING_CANCEL_MODAL_CLOSE';
export const BOOKING_CANCEL_REQUEST = 'BOOKING_CANCEL_REQUEST';
export const BOOKING_CANCEL_SUCCESS = 'BOOKING_CANCEL_SUCCESS';
export const BOOKING_RESET_SEARCH = 'BOOKING_RESET_SEARCH';
export const BOOKING_SET_BOOKING_CANCEL = 'BOOKING_SET_BOOKING_CANCEL';
export const BOOKING_OPEN_PRIVATE_PAYMENT_MODAL = 'BOOKING_OPEN_PRIVATE_PAYMENT_MODAL';
export const BOOKING_CLOSE_PRIVATE_PAYMENT_MODAL = 'BOOKING_CLOSE_PRIVATE_PAYMENT_MODAL';
export const BOOKING_SET_BOOKING_ALL_FILTERS_SELECTED = 'BOOKING_SET_BOOKING_ALL_FILTERS_SELECTED';
export const BOOKING_RESET_BOOKING_ALL_FILTERS_SELECTED = 'BOOKING_RESET_BOOKING_ALL_FILTERS_SELECTED';
export const BOOKING_SET_BOOKING_FACETS = 'BOOKING_SET_BOOKING_FACETS';
export const BOOKING_DAMAGE_REPORTING_MODAL_OPEN = 'BOOKING_DAMAGE_REPORTING_MODAL_OPEN';
export const BOOKING_DAMAGE_REPORTING_MODAL_CLOSE = 'BOOKING_DAMAGE_REPORTING_MODAL_CLOSE';
export const BOOKING_GET_BOOKING_FEEDBACK_REQUEST = 'BOOKING_GET_BOOKING_FEEDBACK_REQUEST';
export const BOOKING_GET_BOOKING_FEEDBACK_SUCCESS = 'BOOKING_GET_BOOKING_FEEDBACK_SUCCESS';
export const BOOKING_SET_ERROR_KEY = 'BOOKING_SET_ERROR_KEY';
export const BOOKING_GET_TIMEZONE_SUCCESS = 'BOOKING_GET_TIMEZONE_SUCCESS';
export const BOOKING_CLEAR_TIMEZONE = 'BOOKING_CLEAR_TIMEZONE';
export const BOOKING_TOGGLE_VOUCHER_INPUT = 'BOOKING_TOGGLE_VOUCHER_INPUT';

export const COMPANY_ID_FOR_SUBSCRIBE_STORE = 'COMPANY_ID_FOR_SUBSCRIBE_STORE';
export const COMPANY_GET_COMPANY_INFOS_REQUEST = 'COMPANY_GET_COMPANY_INFOS_REQUEST';
export const COMPANY_GET_COMPANY_INFOS_SUCCESS = 'COMPANY_GET_COMPANY_INFOS_SUCCESS';
export const CUSTOM_FIELDS_BOOKINGS_GET_SUCCESS = 'CUSTOM_FIELDS_BOOKINGS_GET_SUCCESS';
export const CUSTOM_FIELDS_SUBSCRIPTION_GET_SUCCESS = 'CUSTOM_FIELDS_SUBSCRIPTION_GET_SUCCESS';

export const HEADER_PROFILE_MENU_OPEN = 'HEADER_PROFILE_MENU_OPEN';
export const HEADER_PROFILE_MENU_CLOSE = 'HEADER_PROFILE_MENU_CLOSE';
export const HEADER_PROFILE_MENU_TOGGLE = 'HEADER_PROFILE_MENU_TOGGLE';

export const HEADER_LANG_MENU_OPEN = 'HEADER_LANG_MENU_OPEN';
export const HEADER_LANG_MENU_CLOSE = 'HEADER_LANG_MENU_CLOSE';
export const HEADER_LANG_MENU_TOGGLE = 'HEADER_LANG_MENU_TOGGLE';
export const HEADER_CURRENT_PAGE_UPDATE = 'HEADER_CURRENT_PAGE_UPDATE';

export const NOTIFICATIONS_NOTIFICATIONS_LIST_REQUEST = 'NOTIFICATIONS_NOTIFICATIONS_LIST_REQUEST';
export const NOTIFICATIONS_NOTIFICATIONS_LIST_SUCCESS = 'NOTIFICATIONS_NOTIFICATIONS_LIST_SUCCESS';
export const NOTIFICATIONS_MAX_ITEMS_SET = 'NOTIFICATIONS_MAX_ITEMS_SET';
export const NOTIFICATIONS_MORE_DETAILS_STATE_TOGGLE = 'NOTIFICATIONS_MORE_DETAILS_STATE_TOGGLE';
export const NOTIFICATIONS_MAX_ITEMS_INCREASE = 'NOTIFICATIONS_MAX_ITEMS_INCREASE';
export const NOTIFICATIONS_IS_READ_REQUEST = 'NOTIFICATIONS_IS_READ_REQUEST';
export const NOTIFICATIONS_IS_READ_SUCCESS = 'NOTIFICATIONS_IS_READ_SUCCESS';

export const GOOGLE_MAP_OPENED_STATE_TOGGLE = 'GOOGLE_MAP_OPENED_STATE_TOGGLE';
export const GOOGLE_MAP_ACTIVE_MARKER_INDEX_UPDATE = 'GOOGLE_MAP_ACTIVE_MARKER_INDEX_UPDATE';
export const GOOGLE_MAP_POSITION_MARKER_SET = 'GOOGLE_MAP_POSITION_MARKER_SET';
export const GOOGLE_MAP_RESET_FLAG_SET = 'GOOGLE_MAP_RESET_FLAG_SET';
export const GOOGLE_MAP_RESET_FLAG_UNSET = 'GOOGLE_MAP_RESET_FLAG_UNSET';

export const HELP_ACTIVE_TAB_INDEX_UPDATE = 'HELP_ACTIVE_TAB_INDEX_UPDATE';
export const HELP_ANSWER_ISSUE_OPENED_STATE_TOGGLE = 'HELP_ANSWER_ISSUE_OPENED_STATE_TOGGLE';
export const HELP_STATUS_MOBILE_VIEW_SET = 'HELP_STATUS_MOBILE_VIEW_SET';
