import i18n from './i18n-reducers';
import flashMessage from './flashMessage-reducers';
import api from './api-reducers';
import company from './company-reducers';
import user from './user-reducers';
import booking from './booking-reducers';
import header from './header-reducers';
import notifications from './notifications-reducers';
import googleMap from './googleMap-reducers';
import help from './help-reducers';
import fileUpload from '../components/UploadFile/reducer';

export default {
  i18n,
  flashMessage,
  api,
  company,
  user,
  booking,
  header,
  notifications,
  googleMap,
  help,
  fileUpload
};
