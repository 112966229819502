import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import { getPaymentUrlWithMember, openCreditCardModal, closeCreditCardModal } from '../../actions/all-actions';
import config from '../../constants/config-constants';
import { CreditCardModalStyles } from '../../constants/style-constants';
import BaseTitle from '../BaseTitle/BaseTitle';
import EkButton from '../EkButton/EkButton';
import ConfirmationForm from '../ConfirmationForm/ConfirmationForm';

class PaymentSection extends Component {
  constructor() {
    super();

    this.handleClickPaymentButton = this.handleClickPaymentButton.bind(this);
    this.handleConfirmCreditCardRedirect = this.handleConfirmCreditCardRedirect.bind(this);
    this.handleAbortCreditCardRedirectc = this.handleAbortCreditCardRedirectc.bind(this);
  }

  handleClickPaymentButton() {
    const { dispatch } = this.props;

    dispatch(openCreditCardModal());
  }

  handleConfirmCreditCardRedirect() {
    const { dispatch } = this.props;

    dispatch(closeCreditCardModal());
    dispatch(getPaymentUrlWithMember(this.props.userInfo.id));
  }

  handleAbortCreditCardRedirectc() {
    const { dispatch } = this.props;

    dispatch(closeCreditCardModal());
  }

  render() {
    const { paymentInfos, companyInfos, openedCreditCardModal } = this.props;
    const currencySymbol = <FormattedMessage id={`unit_${companyInfos.currency}`} />;
    let allowedToPay = _.get(companyInfos, 'contract.privateCarSharing');

    return (
      <div className="paymentSectionPage">
        <section className="paymentSection">
          <BaseTitle title="paymentSection_payment_title" />

          {paymentInfos !== null ? (
            <div>
              <span className="paymentSection_text">
                <FormattedMessage id="paymentSection_payment_text" />
              </span>

              <ul className="paymentSection_list">
                <li className="paymentSection_list_item">
                  <span className="paymentSection_list_label">
                    <FormattedMessage id="paymentSection_payment_card_number" />
                  </span>
                  <span className="paymentSection_list_data">{paymentInfos.number}</span>
                </li>
                <li className="paymentSection_list_item">
                  <span className="paymentSection_list_label">
                    <FormattedMessage id="paymentSection_payment_card_expiration" />
                  </span>
                  <span className="paymentSection_list_data">
                    {paymentInfos.expiryMonth}/{paymentInfos.expiryYear}
                  </span>
                </li>
              </ul>

              <div className="paymentSection_buttonContainer">
                <button className="ekButton paymentSection_button" onClick={this.handleClickPaymentButton}>
                  <FormattedMessage id="paymentSection_payment_button" />
                </button>
              </div>
            </div>
          ) : (
            <div>
              <span className="paymentSection_text">
                <FormattedMessage id="paymentSection_payment_missing_content" />
              </span>
              {!allowedToPay ? (
                ''
              ) : (
                <div className="paymentSection_buttonContainer">
                  <button className="ekButton paymentSection_button" onClick={this.handleClickPaymentButton}>
                    <FormattedMessage id="subscribeForm_savePaymentBtn" />
                  </button>
                </div>
              )}
            </div>
          )}

          {openedCreditCardModal && (
            <Modal isOpen={openedCreditCardModal !== null} style={CreditCardModalStyles}>
              <ConfirmationForm
                confirmationMessage={`confirmationForm_creditCard_text`}
                confirmationMessageValues={{ amountToTake: config.amountToTake, currency: currencySymbol }}
                confirmationText={`confirmationForm_save_button`}
                abortText={`confirmationForm_abort_button`}
                onCallback={this.handleConfirmCreditCardRedirect}
                onAbort={this.handleAbortCreditCardRedirectc}
              />
            </Modal>
          )}
        </section>
      </div>
    );
  }
}

PaymentSection.displayName = 'PaymentSection';

PaymentSection.propTypes = {
  action: PropTypes.func
};

export default connect(state => {
  const {
    user: { paymentInfos, userInfo, paymentUrl, openedCreditCardModal },
    company: { companyInfos }
  } = state;
  return {
    paymentInfos,
    userInfo,
    paymentUrl,
    companyInfos,
    openedCreditCardModal
  };
})(PaymentSection);
