import { getValues } from 'redux-form';
import {
  visibleSubscriptionCustomFieldsSelector,
  customFieldsSubscribeIdsValues,
  bundleSelector
} from '../selectors/all-selectors';
import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { LOGIN_MISSING_ARGUMENT, USER_RESET_PASSWORD_MISSING_ARGUMENT } from '../constants/errors-constants';
import { unsetToken, hideUpload } from '../actions/all-actions';
import {
  formatDateStringDayFirst,
  createCustomFieldsValues,
  getCountryAndLang,
  parseOrderCode,
  trimValues,
  addErrorMessage,
  trySet,
  append,
  toUpperSafe,
  toBoolean
} from '../utils/utils';
import routes from '../constants/routes-constants';
import {
  DRIVER_TAB_FORM_NAME,
  PROFILE_TAB_FORM_NAME,
  PARAMETERS_TAB_FORM_NAME,
  PASSWORD_CONFIRM_FIELD_NAME,
  PASSWORD_FIELD_NAME
} from '../constants/generic-constants';
import {
  EXTERNAL_INVOICE_ITALIAN,
  FILE_TYPE_BACK,
  FILE_TYPE_FRONT,
  MEMBER_STATUS_TO_COMPLETE
} from '../constants/backend-constants';
import _cloneDeep from 'lodash/lang/cloneDeep';
import _find from 'lodash/collection/find';
import _get from 'lodash/object/get';
import { ADDRESS_FIELDS } from '../constants/field-constants';
import { BE_ADDRESS_PROPS } from '../constants/backend-constants';
import { getFormattedAddress } from '../api/data-enhancer';

//---------------------------------------------
// Login
//---------------------------------------------

export function requestLogin() {
  return (dispatch, getState) => {
    const { login, password, rememberMe } = trimValues(getState().form.login, PASSWORD_FIELD_NAME);

    dispatch({
      type: types.USER_LOGIN_REQUEST,
      params: {
        login,
        password,
        rememberMe
      }
    });

    if (typeof login !== 'string' || typeof password !== 'string') {
      return Promise.reject({
        name: LOGIN_MISSING_ARGUMENT
      });
    }

    if (rememberMe === true) {
      window.localStorage.setItem('login', login);
    } else {
      window.localStorage.removeItem('login');
    }

    return dispatch(callApi('user', 'login', { login, password })).then(userInfo => {
      dispatch(callLoginSuccess(userInfo));
      return userInfo;
    });
  };
}

export function exposeLoginIfStored() {
  return dispatch => {
    const login = window.localStorage.getItem('login');

    if (login) {
      dispatch({
        type: types.USER_STORED_LOGIN_EXPOSE,
        login
      });
    }
  };
}

export function callLoginSuccess(userInfo) {
  let pendingSubscription = userInfo.status === MEMBER_STATUS_TO_COMPLETE;

  if (pendingSubscription) localStorage.setItem('userInfo', JSON.stringify(userInfo));

  return {
    type: types.USER_LOGIN_SUCCESS,
    userInfo,
    pendingSubscription
  };
}

export function requestTokenRefresh() {
  return dispatch => {
    dispatch({
      type: types.USER_TOKEN_REFRESH_REQUEST
    });

    return dispatch(callApi('user', 'refreshToken')).then(userInfo => {
      dispatch(callTokenRefreshSuccess(userInfo));
      return userInfo;
    });
  };
}

export function callTokenRefreshSuccess(userInfo) {
  return {
    type: types.USER_TOKEN_REFRESH_SUCCESS,
    userInfo
  };
}

export function requestResetPassword() {
  return (dispatch, getState) => {
    const { login } = trimValues(getState().form.resetPassword);

    dispatch({
      type: types.USER_RESET_PASSWORD_REQUEST,
      params: {
        login
      }
    });

    if (typeof login !== 'string') {
      return Promise.reject({
        name: USER_RESET_PASSWORD_MISSING_ARGUMENT
      });
    }

    return dispatch(callApi('user', 'resetPassword', { login }));
  };
}

export function changePasswordGetUrl() {
  return (dispatch, getState) => {
    const state = getState();
    const login = state.user.tokenEmail;

    dispatch({ type: types.CHANGE_PASSWORD_GET_URL_REQUEST });
    if (!login) return dispatch({ type: types.CHANGE_PASSWORD_GET_URL_ERROR });

    return dispatch(callApi('user', 'resetPassword', { login }))
      .then(() => dispatch({ type: types.CHANGE_PASSWORD_GET_URL_SUCCESS }))
      .catch(() => dispatch({ type: types.CHANGE_PASSWORD_GET_URL_ERROR }));
  };
}

export function togglePasswordVisibility() {
  return {
    type: types.USER_PASSWORD_VISIBILITY_TOGGLE
  };
}

export function toggleForgotPasswordMode() {
  return {
    type: types.USER_FORGOT_PASSWORD_MODE_TOGGLE
  };
}

export function callResetPasswordSuccess() {
  return {
    type: types.USER_RESET_PASSWORD_SUCCESS
  };
}

export function callLoginError(error) {
  return {
    type: types.USER_LOGIN_ERROR,
    error
  };
}

export function callResetPasswordError(error) {
  return {
    type: types.USER_RESET_PASSWORD_ERROR,
    error
  };
}

export function logout() {
  return dispatch => {
    dispatch({
      type: types.HEADER_PROFILE_MENU_CLOSE
    });

    dispatch(unsetToken());

    dispatch({
      type: types.USER_LOGOUT
    });
  };
}

export function setTemporaryImpersonateToken(temporaryImpersonateToken) {
  return {
    type: types.SET_TEMPORARY_IMPERSONATE_TOKEN,
    temporaryImpersonateToken
  };
}

export function clearTemporaryImpersonateToken() {
  return {
    type: types.CLEAR_TEMPORARY_IMPERSONATE_TOKEN
  };
}

export function requestValidateAccount(validationToken) {
  return dispatch => {
    dispatch({
      type: types.USER_VALIDATE_ACCOUNT_REQUEST,
      token: validationToken
    });

    return dispatch(callApi('user', 'validateAccount', validationToken)).then(
      data => dispatch(callValidateAccountSuccess(data)),
      error => dispatch(callValidateAccountError(error))
    );
  };
}

export function callValidateAccountSuccess(data) {
  return {
    type: types.USER_VALIDATE_ACCOUNT_SUCCESS,
    data
  };
}

export function requestActivationLink() {
  return (dispatch, getState) => {
    const state = getState();
    const login = state.user.tokenEmail;

    dispatch({
      type: types.SEND_ACTIVATION_LINK_REQUEST,
      login
    });

    return dispatch(callApi('user', 'sendActivationLink', login)).then(
      () => dispatch(requestActivationLinkSuccess()),
      error => dispatch(requestActivationLinkError(error))
    );
  };
}

export function requestActivationLinkSuccess() {
  return {
    type: types.SEND_ACTIVATION_LINK_SUCCESS
  };
}

export function requestActivationLinkError(error) {
  return {
    type: types.SEND_ACTIVATION_LINK_ERROR,
    error
  };
}

export function callValidateAccountError(error) {
  return {
    type: types.USER_VALIDATE_ACCOUNT_ERROR,
    error
  };
}

function setItalianInvoiceParams(toSendParams = {}, formValues = {}, globalState = {}) {
  const { companyInfos } = globalState.company || {};
  const { useExternalInvoiceSystem } = companyInfos || {};
  const { italian, fiscalCode } = formValues;

  if (useExternalInvoiceSystem === EXTERNAL_INVOICE_ITALIAN) {
    const italianValue = toBoolean(italian);

    if (italianValue) {
      toSendParams.italianInvoicing = {
        italian: italianValue,
        fiscalCode
      };
    } else {
      toSendParams.italianInvoicing = {
        italian: false
      };
    }
  } else if (toSendParams.italianInvoicing) {
    delete toSendParams.italianInvoicing;
  }
}

//---------------------------------------------
// Subscribe
//---------------------------------------------

export function requestSubscribe(usedByBackUser, userInfo = null) {
  return (dispatch, getState) => {
    const state = getState();
    const formValues = trimValues(state.form.subscribe, PASSWORD_FIELD_NAME);

    const {
      login,
      password,
      firstName,
      lastName,
      mobilePhonePrefix,
      phoneNumber,
      birthDate,
      driverLicence,
      driverLicenceBack,
      driverLicenceNumber,
      expirationDate,
      deliveranceDate,
      cityDeliverance,
      tosDownload,
      carSharingEnabled,
      commercialOffers,
      optionalExpirationDate,
      managerEmail
    } = formValues;

    const { drivingLicenceRequired } = state.company.companyInfos;
    const customFields = visibleSubscriptionCustomFieldsSelector(state);

    let params = {
      usedByBackUser,
      login,
      password,
      birthDate,
      driverLicence,
      driverLicenceBack,
      driverLicenceNumber,
      expirationDate,
      deliveranceDate,
      cityDeliverance,
      tosDownload,
      carSharingEnabled,
      commercialOffers,
      companyId: state.company.companyId,
      memberCustomValues: createCustomFieldsValues(customFields, trimValues(state.form.subscribe, PASSWORD_FIELD_NAME)),
      locale: state.i18n.locale,
      drivingLicenceRequired
    };

    trySet(params, 'managerEmail', managerEmail);

    if (optionalExpirationDate) delete params.expirationDate;

    if (!usedByBackUser) {
      params.firstName = firstName;
      params.lastName = lastName;
      params.mobilePhonePrefix = mobilePhonePrefix;
      params.phoneNumber = phoneNumber;
    }

    if (!usedByBackUser) setProfileAddress(state, formValues, params);

    setItalianInvoiceParams(params, formValues, state);

    const orderCode = parseOrderCode(state.user.worldPayReturnInfos);

    if (orderCode) params.paymentOrderCode = orderCode;

    dispatch({
      type: types.USER_SUBSCRIBE_REQUEST,
      params
    });

    return dispatchSubscribeApiCall(dispatch, params, userInfo);
  };
}

function dispatchSubscribeApiCall(dispatch, params, userInfo) {
  if (userInfo) params.userId = userInfo.id;
  return dispatch(callApi('user', 'subscribe', params)).then(userInfos => {
    dispatch(unstoreStoredSubscribeFormState());
    dispatch(hideUpload());
    return userInfos;
  });
}

export function callSubscribeSuccess(userInfo) {
  return {
    type: types.USER_SUBSCRIBE_SUCCESS,
    userInfo
  };
}

export function callPreSubscribeSuccess(userInfo, pendingSubscription = null) {
  return {
    type: types.USER_PRE_SUBSCRIBE_SUCCESS,
    userInfo,
    pendingSubscription
  };
}

//---------------------------------------------
// ACCOUNT TABS
//---------------------------------------------

export function updateUserAccountActiveTabIndex(index) {
  return {
    type: types.USER_ACCOUNT_ACTIVE_TAB_INDEX_UPDATE,
    index
  };
}

//---------------------------------------------
// EDIT PROFILE
//---------------------------------------------

function setProfileAddress(state, formValues, payload) {
  let {
    [ADDRESS_FIELDS.STREET]: street,
    [ADDRESS_FIELDS.POSTAL_CODE]: postalCode,
    [ADDRESS_FIELDS.CITY]: city,
    [ADDRESS_FIELDS.COUNTRY_ISO]: countryIso
  } = formValues;

  countryIso = toUpperSafe(countryIso);

  const addressObj = {
    [BE_ADDRESS_PROPS.CITY]: city,
    [BE_ADDRESS_PROPS.COUNTRY_ISO]: toUpperSafe(countryIso),
    [BE_ADDRESS_PROPS.POSTAL_CODE]: postalCode,
    [BE_ADDRESS_PROPS.STREET_NAME]: street,
    [BE_ADDRESS_PROPS.FORMATTED_ADDRESS]: getFormattedAddress({
      streetName: street,
      postalCode,
      city,
      countryIso
    })
  };

  trySet(payload, 'address', append(addressObj));
}

export function requestEditProfile() {
  return (dispatch, getState) => {
    const state = getState();
    const customFieldsIdsValues = customFieldsSubscribeIdsValues(state, true);
    const formValues = trimValues(state.form[PROFILE_TAB_FORM_NAME]);

    const {
      firstName,
      lastName,
      mobilePhonePrefix,
      phoneNumber,
      secondMobilePhonePrefix,
      secondPhoneNumber,
      birthDate,
      managerEmail
    } = formValues;

    let formParams = {
      firstName,
      lastName,
      phoneNumber: {
        countryCode: mobilePhonePrefix,
        nationalNumber: phoneNumber
      },
      locale: state.i18n.locale,
      memberCustomValues: customFieldsIdsValues
    };

    trySet(formParams, 'managerEmail', managerEmail);

    setProfileAddress(state, formValues, formParams);

    if (birthDate) {
      formParams.birthDate = formatDateStringDayFirst(birthDate);
    }

    if (secondPhoneNumber) {
      formParams = Object.assign(formParams, {
        secondaryPhoneNumber: {
          countryCode: secondMobilePhonePrefix,
          nationalNumber: secondPhoneNumber
        }
      });
    }

    const _userInfo = state.user.userInfo;
    let drivingTab = trimValues(state.form[DRIVER_TAB_FORM_NAME]);

    if (drivingTab) {
      drivingTab.expirationDate = formatDateStringDayFirst(drivingTab.expirationDate);
      drivingTab.deliveranceDate = formatDateStringDayFirst(drivingTab.deliveranceDate);

      let initialFiles = [];

      if (drivingTab.driverLicence) {
        initialFiles.push({ fileId: drivingTab.driverLicence, type: FILE_TYPE_FRONT });
        drivingTab.files = initialFiles;
        delete drivingTab.driverLicence;
      }

      if (drivingTab.driverLicenceNumber) {
        drivingTab.licenceNumber = drivingTab.driverLicenceNumber;
        delete drivingTab.driverLicenceNumber;
      }

      if (drivingTab.driverLicenceBack) {
        initialFiles.push({ fileId: drivingTab.driverLicenceBack, type: FILE_TYPE_BACK });
        drivingTab.files = initialFiles;
        delete drivingTab.driverLicenceBack;
      }
      formParams.birthDate = formatDateStringDayFirst(drivingTab.birthDate);
      delete drivingTab.birthDate;
    }

    if (_userInfo.company.drivingLicenceRequired) {
      formParams.drivingLicence = drivingTab || _userInfo.drivingLicence;
    }

    const sendParams = { ...state.user.userInfo, ...formParams };

    setItalianInvoiceParams(sendParams, formValues, state);

    dispatch({
      type: types.USER_EDIT_PROFILE_REQUEST,
      sendParams
    });

    return dispatch(callApi('user', 'updateProfile', sendParams));
  };
}

export function getProfileData(id) {
  return (dispatch, getState) => {
    const state = getState();
    const bundle = bundleSelector(state);

    dispatch({ type: types.USER_GET_PROFILE_REQUEST, id });

    return dispatch(callApi('user', 'getProfile', id)).then(
      data =>
        dispatch({
          type: types.USER_GET_PROFILE_SUCCESS,
          data
        }),
      error =>
        Promise.all([
          dispatch({ type: types.USER_GET_PROFILE_ERROR, error }),
          dispatch(
            addErrorMessage({
              error,
              bundle
            })
          )
        ])
    );
  };
}

export function callEditProfileSuccess(userInfo) {
  return {
    type: types.USER_EDIT_PROFILE_SUCCESS,
    userInfo
  };
}

export function requestUpdateDefaultSite(address) {
  return (dispatch, getState) => {
    const state = getState();

    let params = state.user.userInfo;
    params.defaultSite = address;

    dispatch({
      type: types.USER_EDIT_PROFILE_REQUEST,
      params
    });

    return dispatch(callApi('user', 'updateProfile', params));
  };
}

//---------------------------------------------
// EDIT DRIVER LICENCE
//---------------------------------------------

const updateUserInfoFiles = data => ({
  type: types.UPDATE_USER_INFO_FILES,
  data
});

export function requestEditDriverLicence() {
  return (dispatch, getState) => {
    const state = getState();

    const {
      birthDate,
      driverLicence,
      driverLicenceBack,
      driverLicenceNumber,
      expirationDate,
      deliveranceDate,
      cityDeliverance,
      optionalExpirationDate
    } = trimValues(state.form[DRIVER_TAB_FORM_NAME]);

    let formParams = {
      birthDate: formatDateStringDayFirst(birthDate),
      drivingLicence: {
        cityDeliverance,
        licenceNumber: driverLicenceNumber,
        files: []
      }
    };
    if (expirationDate) formParams.drivingLicence.expirationDate = formatDateStringDayFirst(expirationDate);
    if (optionalExpirationDate) delete formParams.drivingLicence.expirationDate;
    if (deliveranceDate) formParams.drivingLicence.deliveranceDate = formatDateStringDayFirst(deliveranceDate);

    const customFieldsIdsValues = customFieldsSubscribeIdsValues(state, false);
    const copyUser = _cloneDeep(state.user.userInfo);

    const sendParams = Object.assign(copyUser, formParams, {
      memberCustomValues: customFieldsIdsValues
    });

    if (driverLicence) sendParams.drivingLicence.files.push({ fileId: driverLicence, type: FILE_TYPE_FRONT });
    if (driverLicenceBack) sendParams.drivingLicence.files.push({ fileId: driverLicenceBack, type: FILE_TYPE_BACK });

    dispatch({
      type: types.USER_EDIT_DRIVER_LICENCE_REQUEST,
      sendParams
    });

    return dispatch(callApi('user', 'updateProfile', sendParams)).then(data => {
      const files = _get(state.fileUpload, 'files');
      const frontFile = _find(files, { fileId: driverLicence });
      const backFile = _find(files, { fileId: driverLicenceBack });

      if (frontFile) dispatch(updateUserInfoFiles(frontFile));
      if (backFile) dispatch(updateUserInfoFiles(backFile));

      dispatch({ type: types.UPLOAD_CLEAR_DRIVER_FILES });

      return data;
    });
  };
}

export function callEditDriverLicenceSuccess(userInfo) {
  return {
    type: types.USER_EDIT_DRIVER_LICENCE_SUCCESS,
    userInfo
  };
}

//---------------------------------------------
// MAP
//---------------------------------------------

const geoLocation = navigator.geolocation || {
  getCurrentPosition: (success, error) => {
    error("Your browser doesn't support geolocation.");
  }
};

export function getLocationInfos() {
  return dispatch => {
    let promise = new Promise(function(resolve) {
      geoLocation.getCurrentPosition(
        position => {
          let location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          };

          dispatch(callLocationInfosSuccess(location));
          resolve(location);
        },
        () => {
          resolve(null);
        }
      );
    });

    return dispatch(() => {
      return promise;
    });
  };
}

export function callLocationInfosSuccess(location) {
  return {
    type: types.USER_GEOLOCATION_SUCCESS,
    location
  };
}

export function callLocationInfosError(error) {
  return {
    type: types.USER_GEOLOCATION_ERROR,
    error
  };
}

//---------------------------------------------
// EDIT PAYMENT
//---------------------------------------------

export function getPaymentInfos(userId) {
  return dispatch => {
    dispatch({
      type: types.USER_PAYMENT_INFOS_REQUEST,
      userId
    });

    return dispatch(callApi('user', 'getPaymentInfos', userId)).then(function(data) {
      dispatch({
        type: types.USER_PAYMENT_INFOS_SUCCESS,
        params: data
      });
      return data;
    });
  };
}

export function openCreditCardModal() {
  return {
    type: types.CREDIT_CARD_MODAL_OPEN
  };
}

export function closeCreditCardModal() {
  return {
    type: types.CREDIT_CARD_MODAL_CLOSE
  };
}

export function getPaymentUrl(companyId) {
  return dispatch => {
    // this is needed
    const successURL = encodeURIComponent(
      window.location.origin + '/' + window.location.hash + '?paymentInfosSet=true'
    );
    const failureURL = encodeURIComponent(
      window.location.origin + '/' + window.location.hash + '?paymentInfosSet=false'
    );

    dispatch({
      type: types.USER_GET_PAYMENT_URL_REQUEST
    });

    return dispatch(callApi('user', 'getPaymentUrl', companyId)).then(function(data) {
      dispatch({
        type: types.USER_GET_PAYMENT_URL_SUCCESS,
        paymentUrl: data.url + '&successURL=' + successURL + '&failureURL=' + failureURL
      });
      return data;
    });
  };
}

export function getPaymentUrlWithMember(memberId) {
  return (dispatch, getState) => {
    const successURL = encodeURIComponent(
      window.location.origin + '/' + window.location.hash.split('?')[0] + '?paymentInfosSet=true'
    );
    const failureURL = encodeURIComponent(
      window.location.origin + '/' + window.location.hash.split('?')[0] + '?paymentInfosSet=false'
    );
    const locale = getCountryAndLang();

    dispatch({
      type: types.USER_GET_PAYMENT_URL_REQUEST
    });

    return dispatch(callApi('user', 'getPaymentUrlWithMember', memberId)).then(function(data) {
      const paymentUrl = `${data.url}&successURL=${successURL}&failureURL=${failureURL}
      &country=${locale.country}&language=${locale.language}`;

      dispatch({
        type: types.USER_GET_PAYMENT_URL_SUCCESS,
        paymentUrl
      });

      if (getState().routing.location.pathname === routes.account.path) {
        return (location.href = paymentUrl);
      } else {
        return data;
      }
    });
  };
}

//---------------------------------------------
// STORE USER PAYMENT INFOS
//---------------------------------------------

export function storeUserPaymentInfos(query) {
  return {
    type: types.USER_PAYMENT_INFOS_STORE,
    query
  };
}

export function storeSubscribeFormState() {
  return (dispatch, getState) => {
    const state = getState();
    const values = JSON.stringify(trimValues(state.form.subscribe, [PASSWORD_FIELD_NAME, PASSWORD_CONFIRM_FIELD_NAME]));

    const driverLicence = _find(state.fileUpload.files, function(item) {
      return item.id === 'driverLicence';
    });
    if (driverLicence) window.localStorage.setItem('driverLicence', JSON.stringify(driverLicence));

    const driverLicenceBack = _find(state.fileUpload.files, function(item) {
      return item.id === 'driverLicenceBack';
    });
    if (driverLicenceBack) window.localStorage.setItem('driverLicenceBack', JSON.stringify(driverLicenceBack));
    const userStatus = _get(state, 'user.userInfo.status');

    window.localStorage.setItem('subscribeForm', values);
    window.localStorage.setItem('userStatus', userStatus);

    return {
      type: types.USER_SUBSCRIBE_FORM_STORE,
      values
    };
  };
}

export function restoreSubscribeFormState() {
  let values = null;
  let userInfo = null;
  let driverLicence = null;
  let driverLicenceBack = null;

  const storedValues = window.localStorage.getItem('subscribeForm');
  if (storedValues) values = JSON.parse(storedValues);

  const storedUserInfo = window.localStorage.getItem('userInfo');
  if (storedUserInfo) userInfo = JSON.parse(storedUserInfo);

  const storedDriverLicence = window.localStorage.getItem('driverLicence');
  if (storedDriverLicence) driverLicence = JSON.parse(storedDriverLicence);

  const storedDriverLicenceBack = window.localStorage.getItem('driverLicenceBack');
  if (storedDriverLicenceBack) driverLicenceBack = JSON.parse(storedDriverLicenceBack);

  const status = _get(userInfo, 'status');

  return {
    type: types.USER_SUBSCRIBE_FORM_RESTORE,
    values,
    status,
    userInfo,
    driverLicence,
    driverLicenceBack
  };
}

export function unstoreStoredSubscribeFormState() {
  window.localStorage.removeItem('subscribeForm');
  window.localStorage.removeItem('userInfo');
  window.localStorage.removeItem('userStatus');
  window.localStorage.removeItem('driverLicence');
  window.localStorage.removeItem('driverLicenceBack');
  return {
    type: types.USER_SUBSCRIBE_FORM_UNSTORE
  };
}

//---------------------------------------------
// RESET PASSWORD
//---------------------------------------------

export function checkResetPasswordTokenRequest(token) {
  return dispatch => {
    dispatch({
      type: types.USER_RESET_PASSWORD_CHECK_TOKEN_REQUEST
    });

    return dispatch(callApi('user', 'resetPasswordGET', token)).then(() =>
      dispatch(checkResetPasswordTokenSuccess(token))
    );
  };
}

export function checkResetPasswordWhiteBrand(token) {
  return dispatch => {
    dispatch({
      type: types.CHECK_WHITE_BRAND_PASSWORD_REQUEST,
      token
    });

    return dispatch(callApi('user', 'resetPasswordGET', token))
      .then(() => dispatch(storeResetPasswordToken(token)))
      .catch(error =>
        dispatch({
          type: types.CHECK_WHITE_BRAND_PASSWORD_ERROR,
          code: error.code
        })
      );
  };
}

export function checkResetPasswordTokenSuccess(validToken) {
  return {
    type: types.USER_RESET_PASSWORD_CHECK_TOKEN_SUCCESS,
    validToken
  };
}

export function storeResetPasswordToken(validToken) {
  return {
    type: types.USER_RESET_PASSWORD_STORE_TOKEN,
    validToken
  };
}

export function callResetPasswordApplyRequest() {
  return (dispatch, getState) => {
    const state = getState();

    const { password, confirmationPassword } = trimValues(state.form.newPassword, [
      PASSWORD_FIELD_NAME,
      PASSWORD_CONFIRM_FIELD_NAME
    ]);

    let params = {
      password,
      confirmationPassword,
      token: state.user.resetPasswordToken
    };

    dispatch({
      type: types.USER_RESET_PASSWORD_APPLY_REQUEST
    });

    return dispatch(callApi('user', 'resetPasswordPUT', params));
  };
}

export function changePasswordApply() {
  return (dispatch, getState) => {
    const state = getState();
    const { password, confirmationPassword } = trimValues(state.form.newPassword, [
      PASSWORD_FIELD_NAME,
      PASSWORD_CONFIRM_FIELD_NAME
    ]);

    let params = {
      password,
      confirmationPassword,
      token: state.user.resetPasswordToken
    };

    dispatch({
      type: types.CHANGE_PASSWORD_APPLY_REQUEST
    });

    return dispatch(callApi('user', 'resetPasswordPUT', params))
      .then(() =>
        dispatch({
          type: types.CHANGE_PASSWORD_APPLY_SUCCESS
        })
      )
      .catch(() =>
        dispatch({
          type: types.CHANGE_PASSWORD_GET_URL_ERROR
        })
      );
  };
}

//---------------------------------------------
// EDIT PASSWORD
//---------------------------------------------

export function requestEditPassword() {
  return (dispatch, getState) => {
    const state = getState();

    const { newPassword, oldPassword } = getValues(state.form.editPassword);

    let formParams = {
      newPassword,
      oldPassword
    };

    dispatch({
      type: types.USER_EDIT_PASSWORD_REQUEST,
      formParams
    });

    return dispatch(callApi('user', 'updatePassword', formParams));
  };
}

export function callEditPasswordSuccess() {
  return {
    type: types.USER_EDIT_PASSWORD_SUCCESS
  };
}

//---------------------------------------------
// EDIT PAYMENT INFOS
//---------------------------------------------

export function requestEditPaymentInfos() {
  return (dispatch, getState) => {
    const state = getState();

    let params = {
      id: state.user.userInfo.id
    };

    const orderCode = parseOrderCode(state.user.worldPayReturnInfos);
    if (orderCode) params.orderCode = orderCode;

    dispatch({
      type: types.USER_EDIT_PAYMENT_INFOS_REQUEST,
      params
    });

    return dispatch(callApi('user', 'updatePaymentInfos', params));
  };
}

export function callPaymentInfosSuccess() {
  return {
    type: types.USER_EDIT_PAYMENT_INFOS_SUCCESS
  };
}

//---------------------------------------------
// EDIT & GET SETTINGS
//---------------------------------------------

export function requestEditSettings() {
  return (dispatch, getState) => {
    const state = getState();

    const {
      sendMailForPersonalChange,
      sendMailForCallCenterChange,
      smsBeforeDepartureTime,
      smsBeforeArrivalTime,
      commercialOffers
    } = trimValues(state.form[PARAMETERS_TAB_FORM_NAME]);

    let formParams = {
      memberId: state.user.userInfo.id,
      sendMailForPersonalChange,
      sendMailForCallCenterChange,
      smsBeforeDepartureTime,
      smsBeforeArrivalTime
    };

    const customFieldsIdsValues = customFieldsSubscribeIdsValues(state, false);
    const form2Params = Object.assign(
      state.user.userInfo,
      { commercialOffers },
      {
        drivingLicenceFiles: [],
        memberCustomValues: customFieldsIdsValues
      }
    );

    dispatch({
      type: types.USER_EDIT_SETTINGS_REQUEST,
      formParams,
      form2Params
    });

    return Promise.all([
      dispatch(callApi('user', 'updateSettings', formParams)),
      dispatch(callApi('user', 'updateProfile', form2Params))
    ]);
  };
}

export function callEditSettingsSuccess() {
  return {
    type: types.USER_EDIT_SETTINGS_SUCCESS
  };
}

export function getSettings(memberId) {
  return dispatch => {
    dispatch({
      type: types.USER_GET_SETTINGS_REQUEST,
      memberId
    });

    return dispatch(callApi('user', 'getSettings', memberId)).then(function(data) {
      dispatch({
        type: types.USER_GET_SETTINGS_SUCCESS,
        params: data
      });
      return data;
    });
  };
}

export function setUsedByBackUser(bool) {
  return {
    type: types.USER_SET_USED_BY_BACKUSER,
    bool
  };
}

export function setInitialLoginValue(value) {
  return {
    type: types.USER_SET_INITIAL_LOGIN_VALUE,
    value
  };
}

export function getDrivingLicenseFileInfos(fileId) {
  return dispatch => {
    return dispatch(callApi('files', 'getFileInfos', fileId)).then(infos => {
      dispatch({
        type: types.USER_EDIT_DRIVER_LICENCE_FILE_INFOS_SUCCESS,
        fileInfos: infos
      });
    });
  };
}

export function getCustomFieldsProfile(memberId) {
  return dispatch => {
    return dispatch(callApi('user', 'getCustomFields', memberId)).then(data => {
      dispatch({
        type: types.USER_GET_CUSTOM_FIELDS_SUCCESS,
        customFields: data
      });
    });
  };
}

//---------------------------------------------
// COOKIES
//---------------------------------------------

export function closeCookieConsent() {
  localStorage.setItem('hideCookieConsent', 'true');

  return {
    type: types.USER_CLOSE_COOKIE_CONSENT
  };
}
