import * as types from '../constants/actionTypes-constants';

export default function i18n(
  state = {
    callsCount: 0,
    bundle: null,
    latestLanguageChangeDate: new Date()
  },
  action
) {
  switch (action.type) {
    case types.I18N_BUNDLE_REQUEST:
      return Object.assign({}, state, {
        callsCount: state.callsCount + 1
      });

    case types.I18N_BUNDLE_SUCCESS:
      window.localStorage.setItem('locale', action.locale);
      return Object.assign({}, state, {
        callsCount: state.callsCount - 1,
        locale: action.locale,
        bundle: action.bundle,
        latestLanguageChangeDate: new Date()
      });

    default:
      return state;
  }
}
