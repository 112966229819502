const colors = {
  grey1: '#676767', // $color-secondary-dark2
  white: '#ffffff', // $color-white
  pdf: '#DB4437',
  error: '#E95350',
  success: '#0F9D58'
};

export const uploadFileImageSvg = { fill: colors.pdf };
export const errorSvg = { fill: colors.error };
export const successSvg = { fill: colors.success };

export const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

export const CreditCardModalStyles = {
  content: {
    ...modalStyles.content,
    padding: '0 30px',
    width: '370px'
  }
};

const buttonLabel1 = {
  textTransform: 'capitalize',
  fontWeight: 'normal',
  fontSize: 16 + 'px',
  color: colors.white
};

const buttonHeight1 = 40;

export const whiteBrand = {
  colors: {
    default: colors.grey1
  },
  component: {
    activateAccount: {
      button: {
        label: buttonLabel1,
        root: {
          minWidth: 248 + 'px',
          height: buttonHeight1 + 'px'
        }
      }
    },
    newPassword: {
      button: {
        label: buttonLabel1,
        root: {
          minWidth: 340 + 'px',
          height: buttonHeight1 + 'px'
        }
      }
    }
  }
};

export const PdfPreviewStyle = {
  fill: colors.pdf,
  height: 2.5 + 'em',
  width: 'auto'
};
