import { VALIDATION_EMAIL_ALREADY_EXISTS } from '../constants/errors-constants';
import { checkEmailAvailability } from '../actions/all-actions';

const unifyAsyncValidators = validators => {
  return params => {
    let returnedPromise;
    const validatorsLength = validators.length;
    let activeValidators = [];

    // apply validators
    for (let i = 0; i < validatorsLength; i++) {
      let validatorPromise = validators[i](params);
      activeValidators.push(validatorPromise);
    }

    returnedPromise = Promise.race(activeValidators).then(
      data => {
        // we don't want anything to return in case of success
        return null;
      },
      err => {
        // we need to return instead of throwing or the calling Promise.all will fail-fast
        return err;
      }
    );

    return returnedPromise;
  };
};

export function createAsyncValidator(rules) {
  return (values = {}, dispatch, props) => {
    let returnedPromise;
    let fieldPromises = [];
    let fieldNames = [];

    Object.keys(rules).forEach(key => {
      // concat enables both functions and arrays of functions
      const unifiedValidator = unifyAsyncValidators([].concat(rules[key]));
      fieldPromises.push(unifiedValidator({ value: values[key], values, props, dispatch }));
      fieldNames.push(key);
    });

    returnedPromise = Promise.all(fieldPromises).then(data => {
      let errors;

      for (let i = 0, dataLength = data.length; i < dataLength; i++) {
        if (data[i]) {
          if (!errors) {
            errors = {};
          }
          errors[fieldNames[i]] = data[i];
        }
      }

      if (errors) {
        throw errors;
      }
    });

    return returnedPromise;
  };
}

// ------------------
// Validators
// ------------------

export function emailIsAvailable(params = {}) {
  return ({ value, dispatch }) => {
    return dispatch(checkEmailAvailability({ login: value })).then(function(data) {
      for (let i = 0; i < params.errorCases.length; i++) {
        if (params.errorCases[i] === 'front' && data.usedByMember && !data.usedByBackUser) {
          throw {
            type: VALIDATION_EMAIL_ALREADY_EXISTS
          };
        }
        if (params.errorCases[i] === 'front' && data.usedByBackUser && !data.usedByMember) {
          if (params.callBack) {
            return params.callBack({ login: value, usedByBackUser: true });
          }
        }
        if (params.errorCases[i] === 'front' && !data.usedByBackUser && !data.usedByMember) {
          if (params.callBack) {
            return params.callBack({ login: value, usedByBackUser: false });
          }
        }
        if (params.errorCases[i] === 'back' && data.usedByBackUser && !data.usedByMember) {
          throw {
            type: VALIDATION_EMAIL_ALREADY_EXISTS
          };
        }
        if (params.errorCases[i] === 'back' && !data.usedByBackUser && data.usedByMember) {
          if (params.callBack) {
            return params.callBack({ login: value, usedByMember: true });
          }
        }
        if (params.errorCases[i] === 'back' && !data.usedByBackUser && !data.usedByMember) {
          if (params.callBack) {
            return params.callBack({ login: value, usedByMember: false });
          }
        }
        if (params.errorCases[i] === 'both' && data.usedByMember && data.usedByBackUser) {
          throw {
            type: VALIDATION_EMAIL_ALREADY_EXISTS
          };
        }
      }
    });
  };
}
