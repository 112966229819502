import { BOOKING_START_DATE, SORT_DIRECTION_DESC } from './backend-constants';
import { bookingMaxItems } from '../reducers/booking-reducers';

export const apiParams = {
  existingBookings: {
    bookingPageSize: bookingMaxItems,
    payload: {
      sort: {
        direction: SORT_DIRECTION_DESC,
        property: BOOKING_START_DATE
      }
    }
  }
};
