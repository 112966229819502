import React, { Component, PropTypes } from 'react';

class LabeledCheckbox extends Component {
  render() {
    return (
      <div className={`labeledCheckbox ${this.props.customClass || ''}`}>
        <input type="checkbox" className="labeledCheckbox_input" id={this.props.id} {...this.props.field} />
        <label className={`labeledCheckbox_label ${this.props.customLabelClass || ''}`} htmlFor={this.props.id}>
          {this.props.children}
        </label>
      </div>
    );
  }
}

LabeledCheckbox.displayName = 'LabeledCheckbox';

LabeledCheckbox.propTypes = {
  children: PropTypes.element,
  id: PropTypes.string,
  customClass: PropTypes.string,
  customLabelClass: PropTypes.string
};

export default LabeledCheckbox;
