import { change } from 'redux-form';

import { objectToMomentFactory, addZeroForSelect } from '../utils/utils';
const changeType = change().type;
const pickupDateToMoment = objectToMomentFactory({
  getDate: o => o.pickupDate.value,
  getHour: o => o.pickupDateHour.value,
  getMinute: o => o.pickupDateMin.value
});

const returnDateToMoment = objectToMomentFactory({
  getDate: o => o.returnDate.value,
  getHour: o => o.returnDateHour.value,
  getMinute: o => o.returnDateMin.value
});

function updateObjectProperty(target, propertyName, value) {
  return {
    ...target,
    [propertyName]: value
  };
}

export const searchBooking = (state, action) => {
  if (
    action.type === changeType &&
    (action.field === 'pickupDateMin' || action.field === 'pickupDateHour' || action.field === 'pickupDate')
  ) {
    const nextHourMoment = pickupDateToMoment(state).add(1, 'hours');
    const returnMoment = returnDateToMoment(state);

    return nextHourMoment.isAfter(returnMoment)
      ? {
          ...state,
          returnDate: updateObjectProperty(state.returnDate, 'value', nextHourMoment.toDate()),
          returnDateHour: updateObjectProperty(state.returnDateHour, 'value', addZeroForSelect(nextHourMoment.hour())),
          returnDateMin: updateObjectProperty(state.returnDateMin, 'value', addZeroForSelect(nextHourMoment.minute()))
        }
      : state;
  }
  return state;
};
