import React from 'react';
import CustomField from '../../components/CustomField';

function CustomFields({ customFields, formFields, subscribe }) {
  return (
    <div className="subscribeFormFieldsWrapper">
      {customFields.map(fieldInfos => {
        return (
          <CustomField
            key={fieldInfos.id}
            fieldInfos={fieldInfos}
            field={formFields[fieldInfos.id]}
            subscribe={subscribe}
          />
        );
      })}
    </div>
  );
}

CustomFields.displayName = 'CustomFields';

export default CustomFields;
