import _get from 'lodash/object/get';
import _has from 'lodash/object/has';
import _isObject from 'lodash/lang/isObject';
import _isDate from 'lodash/lang/isDate';
import _isNumber from 'lodash/lang/isNumber';
import _isEmpty from 'lodash/lang/isEmpty';
import _isArray from 'lodash/lang/isArray';
import _forEachRight from 'lodash/collection/forEachRight';
import _includes from 'lodash/collection/includes';
import _isString from 'lodash/lang/isString';
import _forEach from 'lodash/collection/forEach';
import _set from 'lodash/object/set';

import { getValues } from 'redux-form';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import compose from 'recompose/compose';
import setDisplayName from 'recompose/setDisplayName';
import wrapDisplayName from 'recompose/wrapDisplayName';
import j from 'jquery';

import {
  CUSTOM_FIELD_TYPE_BOOLEAN,
  CUSTOM_FIELD_TYPE_FILE,
  CUSTOM_FIELD_TYPE_NUMERIC,
  CUSTOM_FIELD_TYPE_PHONE_NUMBER,
  CUSTOM_FIELD_TYPE_TEXT
} from '../constants/backend-constants';

import config from '../constants/config-constants';
import { addFlashMessage } from '../actions/all-actions';
import { FLASH_MESSAGE_TYPE_ERROR, MATERIAL_LOCALE_MAP } from '../constants/generic-constants';
import { errorCodes } from '../constants/options-constants';

// Number.isNaN not supported on IE 11
export function numIsNaN(n) {
  return typeof n === 'number' && isNaN(n);
}

// checks if passed value is empty
export function isEmpty(value) {
  return value === undefined || value === null || value === '' || numIsNaN(value);
}

export function getQueryParams() {
  const query = window.location.search.substr(1);
  let result = {};
  query.split('&').forEach(function(part) {
    let item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function createCustomFieldsValues(customFields, values) {
  return customFields.reduce((filtered, field) => {
    const newProps = {};

    if (field.id && _has(values, field.id)) {
      newProps.companyCustomFieldId = field.id;
      newProps.value = values[field.id];
      filtered.push(newProps);
    }

    return filtered;
  }, []);
}

export const customFieldTypes = [
  CUSTOM_FIELD_TYPE_TEXT,
  CUSTOM_FIELD_TYPE_NUMERIC,
  CUSTOM_FIELD_TYPE_BOOLEAN,
  CUSTOM_FIELD_TYPE_PHONE_NUMBER,
  CUSTOM_FIELD_TYPE_FILE
];

// noinspection JSUnusedGlobalSymbols
export function formatDate(date) {
  let day = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();
  return [year, month, day].join('-');
}

export function formatDateStringDayFirst(string = '') {
  let date = string.split('/');
  if (date.length === 3 && string) {
    let day = date[0];
    let month = date[1];
    let year = date[2];
    return [year, month, day].join('-');
  } else return string;
}

export function formatDateATC(string) {
  let chaine = string.split('+')[0].split('T');
  let date = chaine[0];
  let hour = chaine[1];
  // hour = hour + ':00';
  return [date, hour].join(' ');
}

export function formatDateToStringDayFirst(string = '') {
  let date = string.split('-');
  if (date.length === 3 && string) {
    let day = date[2];
    let month = date[1];
    let year = date[0];
    return [day, month, year].join('/');
  } else return string;
}

export function objectToMomentFactory({ getDate, getHour, getMinute }) {
  return function objectToMoment(obj) {
    const date = getDate(obj);
    const hour = +getHour(obj);
    const minute = +getMinute(obj);

    return (
      date &&
      hour > -1 &&
      minute > -1 &&
      moment(date)
        .hour(hour)
        .minute(minute)
    );
  };
}

export function getTypeOf(obj) {
  return Object.prototype.toString.call(obj).match(/^\[object (\w+)]$/)[1];
}

export function formatDateLong(date) {
  let dateMonth = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
  let dateDay = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
  let dateHour = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
  let dateMin = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
  let dateSec = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
  let dateMilliSec = '000';

  return (
    date.getFullYear() +
    '-' +
    dateMonth +
    '-' +
    dateDay +
    'T' +
    dateHour +
    ':' +
    dateMin +
    ':' +
    dateSec +
    '.' +
    dateMilliSec
  );
}

export function BookingId(id) {
  let explodeId = id.split('-');
  return explodeId[0];
}

// return passed value or object | will return undefined if 'any' is empty
export function nulify(any) {
  let checker = isEmpty;

  if (_isObject(any)) {
    if (_isDate(any)) checker = isNaN;
    else checker = _isEmpty;
  }

  return checker(any) ? undefined : any;
}

export function getSiteTimeUnitOfBooking(timeUnitOfBooking = 15) {
  const timeUnit = Math.min(timeUnitOfBooking, 60);
  const interval = Math.ceil(60 / timeUnit);

  return Array.apply(null, new Array(interval))
    .map((interval, index) => index * timeUnit)
    .map(interval => (String(interval).length === 1 ? '0' + interval : String(interval)));
}

export function dateFromString(dateString) {
  const [, year, month, day, hour, minute] = dateString.match(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})/);

  return new Date(year, month - 1, day, hour, minute);
}

export function splitPrice(priceNum) {
  priceNum = _isNumber(priceNum) ? priceNum : 0;
  const explodeNumber = priceNum.toFixed(2).split('.');
  return {
    int: explodeNumber[0],
    dec: explodeNumber[1]
  };
}

export const addZeroForSelect = value => (value < 10 ? '0' + value : value);

export const getCountryAndLang = () => {
  const lang = navigator.language;

  if (lang) {
    const langArr = lang.split('-');
    return {
      language: langArr[0],
      country: langArr[1] || langArr[0].toUpperCase()
    };
  } else {
    return {
      language: config.defaultLocale,
      country: config.defaultLocale.toUpperCase()
    };
  }
};

export function rideSharingDateValid(props, dispatch) {
  if (!_get(props, 'fields.openRideSharing')) return true;

  const { pickupDate, pickupDateHour, pickupDateMin } = trimValues(_get(props, 'fields'));
  let crossingDate = new Date(pickupDate);
  const message = (
    <span>
      <FormattedMessage id="booking_summary_section_ride_sharing" />
      <span>: </span>
      <FormattedMessage id="common_wrong_time_date" />
    </span>
  );

  crossingDate.setHours(pickupDateHour || 0);
  crossingDate.setMinutes(pickupDateMin || 0);

  if (
    crossingDate < moment(_get(props, 'currentBooking.start.date')).toDate() ||
    crossingDate > moment(_get(props, 'currentBooking.end.date')).toDate()
  ) {
    dispatch(
      addFlashMessage({
        content: message,
        type: FLASH_MESSAGE_TYPE_ERROR
      })
    );
    return false;
  }
  return true;
}

export function createDate(any) {
  let date = any;

  if (Object.prototype.toString.call(date) !== '[object Date]') date = new Date(any);
  return isNaN(date.getTime()) ? '' : date;
}

// add container to JSON property / if all container properties isEmpty return udnefined / delete empty properties
export function append(collection) {
  const isArray = _isArray(collection);
  let index = isArray ? collection.length - 1 : 0;

  _forEachRight(collection, (value, key) => {
    if (isEmpty(value)) isArray ? collection.splice(index, 1) : delete collection[key];
    --index;
  });
  return _isEmpty(collection) ? undefined : collection;
}

// works like lodash _set except it will not create path if value is empty
export function trySet(object, path, value, defaultValue) {
  const emptyValue = nulify(value) === undefined;
  if (emptyValue) {
    const emptyDefault = defaultValue === undefined;
    if (emptyDefault) return object;
    value = defaultValue;
  }
  return _set(object, path, value);
}

// get default app [date format]
export function getAppDateFormat(short = false) {
  return 'DD/MM/' + (short ? 'YY' : 'YYYY');
}

// get default app [time format]
export function getAppTimeFormat() {
  return 'HH:mm';
}

// get default app [day month format]
export function getAppDayMonthFormat() {
  return 'DD/MM';
}

// format passed date to display in local or original time zone
export function formatAppDate(date, format, local = false) {
  if (date) {
    const momentDate = moment(date);
    const parsedDate = local ? momentDate.local() : momentDate.parseZone();
    return parsedDate.format(format || getAppDateFormat());
  }
}

// get [time] in default format for display purposes
export function getAppFormattedTime(date, options = {}) {
  const local = options.local || false;
  return date && formatAppDate(date, getAppTimeFormat(), local);
}

// get [day month time] in default format for display purposes
export function getAppFormattedDayMonthTime(date, options = {}) {
  const local = options.local || false;
  return date && formatAppDate(date, getAppDayMonthFormat() + ' ' + getAppTimeFormat(), local);
}

// get [date] in default format for display purposes
export function getAppFormattedDate(date, options = {}) {
  const short = options.short || false;
  const local = options.local || false;
  return date && formatAppDate(date, getAppDateFormat(short), local);
}

//  get [date + time] in default format for display purposes
export function getAppFormattedDateTime(date, options = {}) {
  const short = options.short || false;
  const local = options.local || false;
  return date && formatAppDate(date, getAppDateFormat(short) + ' ' + getAppTimeFormat(), local);
}

export function parseOrderCode(worldPayReturnInfos) {
  let orderKey = _get(worldPayReturnInfos, 'orderKey');
  if (orderKey) {
    orderKey = _isArray(orderKey) ? orderKey[orderKey.length - 1] : orderKey;
    orderKey = orderKey.split('^');
    return _get(orderKey, '[2]');
  }
}

// compose serveral HOC | add display name to resulting component
// https://github.com/acdlite/recompose/issues/299#issuecomment-310881007

export const namedCompose = (...hocs) => BaseComponent =>
  setDisplayName(wrapDisplayName(BaseComponent, 'Compose'))(compose(...hocs)(BaseComponent));

export function scrollToFirstError() {
  try {
    setTimeout(() => {
      const el = j('.fieldErrorMsg');
      if (el.length > 0) {
        j('html, body').animate(
          {
            scrollTop: el.offset().top - 100
          },
          500
        );
      }
      return;
    }, 100);
  } catch (e) {
    console.warn('scrollToFirstError: failed', e); // eslint-disable-line no-console
  }
}

// trimFields - String (field name) or Array of Strings | function will mutate object
export function trimFields(fields, excludeFromTrim) {
  function notExcluded(field, key) {
    let toTrim = true;

    if (excludeFromTrim) {
      if (_isArray(excludeFromTrim)) toTrim = !_includes(excludeFromTrim, key);
      else toTrim = key !== excludeFromTrim;
    }

    if (_isString(field)) return toTrim;
    else if (toTrim) trimFields(field);
  }

  if (_isObject(fields)) {
    _forEach(fields, (field, key) => {
      if (notExcluded(field, key)) fields[key] = field.trim();
    });
  }

  return fields;
}

// excludeFromTrim - String (field name) or Array of Strings
export function trimValues(form, excludeFromTrim) {
  return trimFields(getValues(form), excludeFromTrim);
}

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript
export function parseJwt(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  } catch (e) {
    return false;
  }
}

function checkPartialError(partialError, fullMessage) {
  const { partialErrorMsg, translationKey } = partialError;
  if (_includes(fullMessage, partialErrorMsg)) {
    return translationKey;
  }
}

function checkPartialErrors(partialErrors, fullMessage) {
  let key = undefined;

  if (partialErrors && fullMessage) {
    _forEach(partialErrors, error => {
      const resp = checkPartialError(error, fullMessage);
      if (resp) {
        key = resp;
        return false; // exit loop manually
      }
    });
  }

  return key;
}

function checkValidationErrors(partialErrors, validationErrors) {
  let key = undefined;

  if (validationErrors) {
    _forEach(validationErrors, validationError => {
      const staticKey = errorCodes[validationError];

      if (staticKey) {
        key = staticKey;
        return false; // exit loop manually
      }

      const partialKey = checkPartialErrors(partialErrors, validationError);

      if (partialKey) {
        key = partialKey;
        return false; // exit loop manually
      }
    });
  }

  return key;
}

function getErrorFromBundle(bundle, code, errorCodePrefixes) {
  let msg = undefined;

  if (errorCodePrefixes) {
    _forEach(errorCodePrefixes, prefix => {
      const key = prefix + code;
      msg = bundle[key];
      if (msg) return false; // exit loop manually
    });
  }

  return msg || bundle[code];
}

/*
  error - error returned as responce
  bundle - translation bundle
  partialErrors - array of partial errors
  def - default error message
  errorCodePrefixes - array of errorCode prefixes
  */
export function getErrorMsg({ error, bundle, partialErrors, errorCodePrefixes, def = 'error_server_unknown' }) {
  if (error && bundle) {
    const { developerMessage, errorCode, validationErrors } = error;
    const firstValidationError = validationErrors ? validationErrors[0] : undefined;

    const code =
      errorCodes[errorCode] ||
      errorCode ||
      errorCodes[developerMessage] ||
      checkPartialErrors(partialErrors, developerMessage) ||
      checkValidationErrors(partialErrors, validationErrors);

    return (
      getErrorFromBundle(bundle, code, errorCodePrefixes) ||
      developerMessage ||
      firstValidationError ||
      errorCode ||
      code ||
      bundle[def] ||
      def
    );
  }
  return def;
}

/*
  read addErrorMessage message
  contentData - addFlashMessage contentData param
  */
export function addErrorMessage({ error, bundle, partialErrors, errorCodePrefixes, def, contentData }) {
  const params = {
    content: getErrorMsg({ error, bundle, partialErrors, errorCodePrefixes, def }),
    type: FLASH_MESSAGE_TYPE_ERROR
  };

  if (contentData) params.contentData = contentData;
  return addFlashMessage(params);
}

export function stripMimeType(content) {
  const result = content + '';
  return result.split(';base64,')[1] || content;
}

export function toBoolean(value) {
  if (typeof value === 'string') return value === 'true';
  return !!value;
}

// use only for component that receives redux field
// sets the field value to initialValue on load (if value is empty)
// or on change of initialValue
export function setInitialValue({ field }) {
  const { value, initialValue, dirty, onChange, touched } = field || {};

  if (!this.valueSet && !isEmpty(value)) this.valueSet = true;
  if (!this.valueSet && !this.initDone && (dirty || touched)) this.valueSet = true;
  if (this.valueSet && !this.initDone) this.prevInitialValue = initialValue;
  if (this.valueSet && initialValue !== this.prevInitialValue && !isEmpty(initialValue)) this.valueSet = false;

  if (!this.valueSet && !isEmpty(initialValue)) {
    onChange(initialValue);
    this.prevInitialValue = initialValue;
  }

  if (!this.initDone) this.initDone = true;
}

export function isInternetExplorer11() {
  return !!window.MSInputMethodContext && !!document.documentMode;
}

export function castDate(form, initValue, field) {
  return formatDateToStringDayFirst(form.hasOwnProperty(field) && form.field !== '' ? form.field : initValue);
}

export function callbackNot(func) {
  function callback() {
    return !func.apply(this, arguments);
  }
  return callback;
}

export function toUpperSafe(str) {
  return typeof str === 'string' ? str.toUpperCase() : '';
}

export function getMaterialLocale(locale) {
  return MATERIAL_LOCALE_MAP[locale] || locale;
}

export const conditionItalianIsFalse = {
  condition: props => {
    const { form } = props;
    const { italian } = form || {};
    const { value } = italian || {};

    return !toBoolean(value);
  }
};
