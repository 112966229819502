import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { autocompletePlaceholderMessages as inputPlaceholderMessages } from '../../constants/options-constants';

import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import EkButton from '../EkButton/EkButton';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import config from '../../constants/config-constants';
import { getSiteTimeUnitOfBooking } from '../../utils/utils';
import {
  createValidator,
  notEmpty,
  stopValidationIf,
  addressOrSite,
  dateBeforeField,
  length
} from '../../validation/all-validation';
import { toggleVoucherInput } from '../../actions/all-actions';

class SearchBookingForm extends Component {
  constructor(props) {
    super(props);
    this.activateTab = this.activateTab.bind(this);
    this.handleActivateOneWayTripTab = this.handleActivateOneWayTripTab.bind(this);
    this.handleActivateRoundTripTab = this.handleActivateRoundTripTab.bind(this);
    this.handleToggleVoucherInput = this.handleToggleVoucherInput.bind(this);
  }

  handleActivateOneWayTripTab() {
    const {
      userInfo: {
        company: { contract }
      }
    } = this.props;
    if (contract.interfaceConfig.oneWayDisplay) this.activateTab('oneWayTrip');
  }

  handleActivateRoundTripTab() {
    const {
      userInfo: {
        company: { contract }
      }
    } = this.props;
    if (contract.interfaceConfig.returnTripDisplay) this.activateTab('roundTrip');
  }

  activateTab(type) {
    const { fields } = this.props;
    if (type === 'oneWayTrip') {
      fields.returnDate.onChange(null);
      fields.oneWayTrip.onChange(true);
    } else {
      fields.returnDate.onChange(fields.pickupDate.value);
      fields.oneWayTrip.onChange(false);
    }
  }

  renderSuggestFiltered(list) {
    const { fields } = this.props;
    let suggestList;
    if (fields.from.value) {
      suggestList = list.filter(function(obj) {
        return obj.id !== fields.from.value.id;
      });
    }
    return suggestList;
  }

  handleToggleVoucherInput() {
    this.props.dispatch(toggleVoucherInput());
  }

  render() {
    const {
      fields: {
        from,
        to,
        pickupDate,
        pickupDateHour,
        pickupDateMin,
        returnDate,
        returnDateHour,
        returnDateMin,
        passengers,
        oneWayTrip,
        voucherCode
      },
      userInfo,
      userInfo: {
        company: { contract }
      },
      searchError,
      handleSubmit,
      disableTabs,
      disableVoucher,
      showVoucherInput,
      intl: { formatMessage }
    } = this.props;

    const availableSiteMinutes = from.value
      ? getSiteTimeUnitOfBooking(from.value.timeUnitOfBooking)
      : config.availableMinutes;

    const now = new Date();

    const minReturnDate = pickupDate && typeof pickupDate.value === 'object' ? pickupDate.value : now;

    const sites = userInfo.company ? userInfo.company.sites : [];

    if (window.addtocalendar !== undefined) window.addtocalendar = undefined;
    if (window.ifaddtocalendar === 1) window.ifaddtocalendar = undefined;

    const submitForm = handleSubmit(this.props.onCallback);

    return (
      <div className="searchBooking_container">
        {disableTabs ? (
          ''
        ) : (
          <div className="searchBooking_tabs">
            {contract.interfaceConfig.oneWayDisplay && (
              <button
                className={`searchBooking_tab searchBooking_tab ${oneWayTrip.value ? '_is_active' : ''}`}
                onClick={this.handleActivateOneWayTripTab}
              >
                <span className="searchBooking_tab_text searchBooking_tab_text--oneWay">
                  <FormattedMessage id="searchBooking_oneWay" />
                </span>
              </button>
            )}
            {contract.interfaceConfig.returnTripDisplay && (
              <button
                className={`searchBooking_tab ${oneWayTrip.value ? '' : '_is_active'}`}
                onClick={this.handleActivateRoundTripTab}
              >
                <span className="searchBooking_tab_text">
                  <FormattedMessage id="searchBooking_return" />
                </span>
              </button>
            )}
          </div>
        )}

        <form action="#" method="post" className="searchBooking" onSubmit={submitForm} noValidate>
          {searchError ? (
            <div className="searchBookingForm_mainErrorMsg">
              <FormattedMessage id="error_server_unknown" />
            </div>
          ) : null}

          <div className="searchBooking_col">
            <FormRow customWrapperClass="searchBooking_formRow searchBooking_formRow_address">
              <label formRowItemKey="searchBooking_from_label" className="boxedInput_label">
                <span className="boxedInput_labelText">
                  <FormattedMessage id="searchBooking_from" />
                </span>
                <AddressAutocomplete
                  field={from}
                  fixturesTitle={formatMessage(inputPlaceholderMessages.sitesTitle)}
                  placeholder={formatMessage(inputPlaceholderMessages.generic)}
                  className="searchBookingForm_AddressAutocomplete"
                  fixtures={userInfo.company && sites}
                />

                <FieldErrorMsg field={from} customClass="fieldErrorMsg--searchBookingForm" />
              </label>

              {!oneWayTrip.value && (
                <label className="boxedInput_label" formRowItemKey="searchBooking_to_label">
                  <span className="boxedInput_labelText">
                    <FormattedMessage id="searchBooking_to" />
                  </span>
                  {oneWayTrip.value ? (
                    <AddressAutocomplete
                      field={to}
                      fixturesTitle={formatMessage(inputPlaceholderMessages.sitesTitle)}
                      placeholder={formatMessage(inputPlaceholderMessages.generic)}
                      className="searchBookingForm_AddressAutocomplete"
                      fixtures={userInfo.company && this.renderSuggestFiltered(sites)}
                    />
                  ) : from.value && from.value._isSite ? (
                    <input
                      type="text"
                      value={from.value.label}
                      className="searchBooking_AdressAutoCompleteDisabled"
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      value={from.value && from.value.formattedAddress}
                      className="searchBooking_AdressAutoCompleteDisabled"
                      disabled
                    />
                  )}
                  <FieldErrorMsg field={to} customClass="fieldErrorMsg--searchBookingForm" />
                </label>
              )}

              <BoxedSelect
                formRowItemKey="searchBooking_passengers"
                labelKey="searchBooking_passenger"
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
                customClass="searchBooking_boxedSelectWrapper"
                field={passengers}
              />
            </FormRow>

            <FormRow customWrapperClass="searchBooking_formRow searchBooking_formRow_time">
              <BoxedInput
                formRowItemKey="searchBooking_from_pickupDate"
                skinType="date"
                type="date"
                customClass="boxedInputWrapper--label _is_year_hidden"
                labelKey="searchBooking_pickup"
                field={pickupDate}
                minDate={now}
                disableYearSelection
              >
                <FieldErrorMsg field={pickupDate} customClass="fieldErrorMsg--searchBookingForm" />
              </BoxedInput>

              <BoxedSelect
                formRowItemKey="searchBooking_from_pickupDateHour"
                labelKey="searchBooking_pickup_hour"
                options={config.availableHours}
                customClass="searchBooking_boxedSelectWrapper"
                hideLabel
                field={pickupDateHour}
              />

              <BoxedSelect
                formRowItemKey="searchBooking_from_pickupDateMin"
                labelKey="searchBooking_pickup_min"
                options={availableSiteMinutes}
                customClass="searchBooking_boxedSelectWrapper"
                hideLabel
                field={pickupDateMin}
              />
              {!oneWayTrip.value && (
                <BoxedInput
                  formRowItemKey="searchBooking_to_returnDate"
                  skinType="date"
                  type="date"
                  customClass="boxedInputWrapper--label"
                  labelKey="searchBooking_return_date"
                  field={returnDate}
                  minDate={minReturnDate}
                  disabled={oneWayTrip.value ? true : false}
                  mandatory={!oneWayTrip.value}
                  disableYearSelection
                >
                  <FieldErrorMsg field={returnDate} customClass="fieldErrorMsg--searchBookingForm" />
                </BoxedInput>
              )}
              {!oneWayTrip.value && (
                <BoxedSelect
                  formRowItemKey="searchBooking_to_returnDateHour"
                  labelKey="searchBooking_return_hour"
                  options={config.availableHours}
                  customClass="searchBooking_boxedSelectWrapper"
                  hideLabel
                  field={returnDateHour}
                  disabled={oneWayTrip.value ? true : false}
                />
              )}
              {!oneWayTrip.value && (
                <BoxedSelect
                  formRowItemKey="searchBooking_to_returnDateMin"
                  labelKey="searchBooking_return_min"
                  options={availableSiteMinutes}
                  customClass="searchBooking_boxedSelectWrapper"
                  hideLabel
                  field={returnDateMin}
                  disabled={oneWayTrip.value ? true : false}
                />
              )}

              <EkButton
                formRowItemKey="searchBooking_submitBtn"
                skinType="variant3"
                customClass="submit"
                onAction={submitForm}
                fullWidth
              >
                <FormattedMessage id="searchBooking_btn" />
              </EkButton>
            </FormRow>
          </div>
        </form>

        {!disableVoucher && contract.privateCarSharing && (
          <div className="searchBooking_voucherWrap">
            <div className="searchBooking_voucherClickable" onClick={this.handleToggleVoucherInput}>
              <FormattedMessage id="bookingSummary_voucherCode" />
            </div>
            {showVoucherInput && (
              <div className="searchBooking_voucherInput">
                <BoxedInput
                  formRowItemKey="bookingSummary_address_pickupDate"
                  type="text"
                  customClass="boxedInputWrapper--label"
                  field={voucherCode}
                >
                  <FieldErrorMsg field={voucherCode} customClass="fieldErrorMsg--bookingSummary" />
                </BoxedInput>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

SearchBookingForm.displayName = 'SearchBookingForm';

SearchBookingForm.propTypes = {
  action: PropTypes.func,
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func,
  disableTabs: PropTypes.bool,
  disableVoucher: PropTypes.bool
};

SearchBookingForm = reduxForm(
  {
    form: 'searchBooking',
    fields: [
      'from',
      'to',
      'pickupDate',
      'pickupDateHour',
      'pickupDateMin',
      'returnDate',
      'returnDateHour',
      'returnDateMin',
      'passengers',
      'oneWayTrip',
      'voucherCode'
    ],
    validate: createValidator({
      from: [notEmpty(), addressOrSite()],
      to: [
        stopValidationIf({
          condition: (props, value) => {
            return !value;
          }
        }),
        addressOrSite(false)
      ],
      pickupDate: [notEmpty()],
      voucherCode: [length({ max: 256 })],
      returnDate: [
        stopValidationIf({
          condition: props => {
            return props.form.oneWayTrip ? props.form.oneWayTrip.value : false;
          }
        }),
        notEmpty(),
        dateBeforeField('pickupDate', 'pickupDateHour', 'pickupDateMin', 'returnDateHour', 'returnDateMin')
      ]
    }),
    destroyOnUnmount: false
  },
  state => {
    // mapStateToProps
  }
)(SearchBookingForm);

export default connect(state => {
  const {
    booking: { searchError, showVoucherInput },
    user: { userInfo }
  } = state;
  return {
    searchError,
    userInfo,
    showVoucherInput
  };
})(injectIntl(SearchBookingForm));
