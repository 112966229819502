import React, { Component } from 'react';
import { Router } from 'react-router';
import 'intl';
import { IntlProvider, addLocaleData } from 'react-intl';
import { connect } from 'react-redux';
import theme from '../constants/theme-constants';
import countries from 'i18n-iso-countries';

import fr from 'react-intl/locale-data/fr';
import es from 'react-intl/locale-data/es';
import it from 'react-intl/locale-data/it';
import pt from 'react-intl/locale-data/pt';
import da from 'react-intl/locale-data/da';
import nl from 'react-intl/locale-data/nl';
import pl from 'react-intl/locale-data/pl';
import sv from 'react-intl/locale-data/sv';
import de from 'react-intl/locale-data/de';
import cs from 'react-intl/locale-data/cs';
import br from 'react-intl/locale-data/br';
import sk from 'react-intl/locale-data/sk';

import { getStore } from '../store/all-store';
import { history, getRoutesConfig } from '../routing/all-routing';
import { setLanguage } from '../actions/all-actions';
import { setToken } from '../actions/api-actions';
import { initTokenHandlers } from '../api/ApiCaller';

function setCountries() {
  const languages = theme.availableLanguages;
  if (languages) {
    languages.forEach(code => {
      if (code === 'en') countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
      if (code === 'fr') countries.registerLocale(require('i18n-iso-countries/langs/fr.json'));
      if (code === 'es') countries.registerLocale(require('i18n-iso-countries/langs/es.json'));
      if (code === 'it') countries.registerLocale(require('i18n-iso-countries/langs/it.json'));
      if (code === 'pt' || code === 'pt_BR') countries.registerLocale(require('i18n-iso-countries/langs/pt.json'));
      if (code === 'da') countries.registerLocale(require('i18n-iso-countries/langs/da.json'));
      if (code === 'nl') countries.registerLocale(require('i18n-iso-countries/langs/nl.json'));
      if (code === 'pl') countries.registerLocale(require('i18n-iso-countries/langs/pl.json'));
      if (code === 'sv') countries.registerLocale(require('i18n-iso-countries/langs/sv.json'));
      if (code === 'de') countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
      if (code === 'cs') countries.registerLocale(require('i18n-iso-countries/langs/cs.json'));
      if (code === 'sk') countries.registerLocale(require('i18n-iso-countries/langs/sk.json'));
    });
  }
}

initTokenHandlers(
  function(token) {
    getStore().dispatch(setToken(token));
  },
  function() {
    return getStore().getState().api.token;
  }
);

addLocaleData([...fr, ...es, ...it, ...pt, ...de, ...da, ...nl, ...pl, ...sv, ...cs, ...br, ...sk]);

setCountries();

const reactIntlMap = {
  pt_BR: 'br'
};

class Root extends Component {
  componentWillMount() {
    const { dispatch, bundle } = this.props;

    if (!bundle) {
      dispatch(setLanguage());
    }
  }

  render() {
    const { locale, bundle } = this.props;
    const reactIntlLocale = reactIntlMap[locale] || locale;

    let content;

    if (bundle) {
      content = (
        <IntlProvider key="intl" locale={reactIntlLocale} messages={bundle}>
          <Router history={history} routes={getRoutesConfig()} />
        </IntlProvider>
      );
    } else {
      content = <div>Loading translation bundle...</div>;
    }

    return content;
  }
}

Root.displayName = 'Root';

export default connect(state => {
  const {
    i18n: { locale, bundle }
  } = state;

  return {
    locale,
    bundle
  };
})(Root);
