import { UPDATE_LOCATION } from 'react-router-redux';
import * as types from '../constants/actionTypes-constants';
import { parseJwt, trySet } from '../utils/utils';
import _get from 'lodash/object/get';
import _find from 'lodash/collection/find';
import _forEach from 'lodash/collection/forEach';
import _includes from 'lodash/collection/includes';
import { DRIVER_LICENCE_BACK_ID, DRIVER_LICENCE_FRONT_ID } from '../constants/generic-constants';
import { FILE_TYPE_BACK, FILE_TYPE_FRONT } from '../constants/backend-constants';

const resetActivateDefault = {
  tokenExpired: false,
  taskSuccess: false,
  unknownError: false,
  alreadyActivated: false,
  requestSuccess: false
};

function setResetActivateProperty(state, property) {
  return {
    ...state,
    resetActivateCases: {
      ...resetActivateDefault,
      [property]: true
    }
  };
}

function handleEditLicenceSuccess(action, state) {
  const newFile = _get(action, 'fileInfos');
  const newId = _get(newFile, 'id');
  const compareId = value => _get(value, 'id') === newId;
  const file = _find(state.drivingLicenceFiles, compareId);
  const files = _get(state, 'drivingLicenceFiles', []);

  if (!file) {
    files.push(newFile);
    return {
      ...state,
      drivingLicenceFiles: [...files]
    };
  } else return state;
}

export default function user(
  state = {
    userInfo: null,
    pendingSubscription: null,
    showPassword: false,
    defaultLogin: null,
    forgotPasswordMode: false,
    accountActiveTabIndex: 0,
    geoLocationInfos: null,
    geoLocationInfosError: null,
    paymentInfos: null,
    validationToken: null,
    validationTokenError: null,
    worldPayReturnInfos: null,
    subscribeFormStoredValues: null,
    resetPasswordSuccess: null,
    resetPasswordToken: null,
    settings: null,
    status: null,
    usedByBackUser: false,
    initialLoginValue: null,
    paymentUrl: null,
    showCookieConsent: true,
    openedCreditCardModal: null,
    resetActivateCases: resetActivateDefault,
    tokenEmail: null,
    temporaryImpersonateToken: null,
    drivingLicenceFiles: []
  },
  action
) {
  switch (action.type) {
    case types.USER_LOGIN_REQUEST:
      return state;

    case types.USER_LOGIN_ERROR:
      return Object.assign({}, state, {
        loginServerError: action.error
      });

    case types.UPDATE_USER_INFO_FILES:
      const getNewInfoFiles = () => {
        const fileTypes = {
          [DRIVER_LICENCE_FRONT_ID]: FILE_TYPE_FRONT,
          [DRIVER_LICENCE_BACK_ID]: FILE_TYPE_BACK
        };

        const userInfo = state.userInfo || {};
        const drivingLicence = userInfo.drivingLicence || {};
        const lightFiles = drivingLicence.files || [];
        const heavyFiles = state.drivingLicenceFiles || [];
        const data = action.data || {};
        const dataType = fileTypes[data.id];

        let lightFile = {};
        let heavyFile = {};
        let heavyIndex = heavyFiles.length;
        let lightIndex = lightFiles.length;

        _forEach(lightFiles, (file, index) => {
          const safeFile = file || {};
          const fileType = safeFile.type;

          if (fileType && fileType === dataType) {
            lightFile = safeFile;
            lightIndex = index;
          }
        });

        const lightId = lightFile.fileId;

        trySet(lightFile, 'id', data.fileId);
        trySet(lightFile, 'fileId', data.fileId);
        trySet(lightFile, 'type', dataType);

        _forEach(heavyFiles, (file, index) => {
          const safeFile = file || {};
          const fileId = safeFile.id;

          if (fileId && fileId === lightId) {
            heavyFile = safeFile;
            heavyIndex = index;
          }
        });

        trySet(heavyFile, 'id', data.fileId);
        trySet(heavyFile, 'content', data.content);
        trySet(heavyFile, 'mimeType', data.mimeType);
        trySet(heavyFile, 'name', data.name);

        heavyFiles[heavyIndex] = heavyFile;
        lightFiles[lightIndex] = lightFile;

        return {
          ...state,
          drivingLicenceFiles: [...heavyFiles],
          userInfo: {
            ...userInfo,
            drivingLicence: {
              ...drivingLicence,
              files: [...lightFiles]
            }
          }
        };
      };

      return getNewInfoFiles();

    case types.USER_RESET_PASSWORD_ERROR:
      return Object.assign({}, state, {
        resetPasswordSuccess: false,
        loginServerError: action.error
      });

    case types.USER_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        userInfo: action.userInfo,
        loginServerError: null,
        pendingSubscription: action.pendingSubscription
      });

    case types.SET_TEMPORARY_IMPERSONATE_TOKEN:
      return { ...state, temporaryImpersonateToken: action.temporaryImpersonateToken };

    case types.CLEAR_TEMPORARY_IMPERSONATE_TOKEN:
      return { ...state, temporaryImpersonateToken: null };

    case types.USER_TOKEN_REFRESH_SUCCESS:
      return Object.assign({}, state, {
        userInfo: action.userInfo
      });

    case types.USER_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordSuccess: null,
        loginServerError: null
      };

    case types.CHANGE_PASSWORD_GET_URL_REQUEST:
      return state;

    case types.CHANGE_PASSWORD_GET_URL_ERROR:
      return setResetActivateProperty(state, 'unknownError');

    case types.CHANGE_PASSWORD_GET_URL_SUCCESS:
      return setResetActivateProperty(state, 'requestSuccess');

    case types.USER_RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        loginServerError: null,
        resetPasswordSuccess: true
      });

    case types.USER_SUBSCRIBE_REQUEST:
      return Object.assign({}, state, {
        subscribeFormStoredValues: null
      });

    case types.USER_SUBSCRIBE_SUCCESS:
      return Object.assign({}, state, {
        userInfo: action.userInfo
      });

    case types.USER_PRE_SUBSCRIBE_SUCCESS:
      return Object.assign({}, state, {
        userInfo: action.userInfo,
        pendingSubscription: false
      });

    case types.USER_LOGOUT:
      return Object.assign({}, state, {
        // we use an empty object instead of a null because we don't want an error to be thrown in current page and
        // prevent route change when we log out
        userInfo: {}
      });

    case types.USER_PASSWORD_VISIBILITY_TOGGLE:
      return Object.assign({}, state, {
        showPassword: !state.showPassword
      });

    case types.USER_FORGOT_PASSWORD_MODE_TOGGLE:
      return Object.assign({}, state, {
        forgotPasswordMode: !state.forgotPasswordMode
      });

    case types.USER_STORED_LOGIN_EXPOSE:
      return Object.assign({}, state, {
        defaultLogin: action.login
      });

    case types.USER_EDIT_PROFILE_REQUEST:
      return state;

    case types.USER_GET_PROFILE_SUCCESS:
      return {
        ...state,
        userInfo: action.data
      };

    case types.USER_EDIT_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        userInfo: action.userInfo,
        customFields: action.userInfo.memberCustomValues
      });

    case types.USER_EDIT_DRIVER_LICENCE_REQUEST:
      return state;

    case types.USER_EDIT_DRIVER_LICENCE_SUCCESS:
      return {
        ...state,
        userInfo: action.userInfo
      };

    case types.USER_PAYMENT_INFOS_SUCCESS:
      return Object.assign({}, state, {
        paymentInfos: action.params
      });

    case types.USER_ACCOUNT_ACTIVE_TAB_INDEX_UPDATE:
      return Object.assign({}, state, {
        accountActiveTabIndex: action.index
      });

    case types.USER_GEOLOCATION_REQUEST:
      return state;

    case types.SEND_ACTIVATION_LINK_REQUEST:
      return {
        ...state,
        resetActivateCases: resetActivateDefault
      };

    case types.CHECK_WHITE_BRAND_PASSWORD_REQUEST:
      return {
        ...state,
        tokenEmail: _get(parseJwt(action.token), 'sub'),
        resetActivateCases: resetActivateDefault
      };

    case types.CHECK_WHITE_BRAND_PASSWORD_ERROR:
      if (action.code === 410) return setResetActivateProperty(state, 'tokenExpired');
      else return setResetActivateProperty(state, 'unknownError');

    case types.SEND_ACTIVATION_LINK_SUCCESS:
      return setResetActivateProperty(state, 'requestSuccess');

    case types.SEND_ACTIVATION_LINK_ERROR:
      if (
        _get(action.error, 'code') === 422 &&
        _includes(_get(action.error, 'developerMessage'), 'member is already enabled')
      )
        return setResetActivateProperty(state, 'alreadyActivated');
      else return setResetActivateProperty(state, 'unknownError');

    case types.USER_GEOLOCATION_SUCCESS:
      return Object.assign({}, state, {
        geoLocationInfos: action.location,
        geoLocationInfosError: null
      });

    case types.USER_GEOLOCATION_ERROR:
      return Object.assign({}, state, {
        geoLocationInfosError: action.error
      });

    case types.USER_VALIDATE_ACCOUNT_REQUEST:
      return {
        ...state,
        tokenEmail: _get(parseJwt(action.token), 'sub')
      };

    case types.USER_VALIDATE_ACCOUNT_SUCCESS:
      return setResetActivateProperty(state, 'taskSuccess');

    case types.USER_VALIDATE_ACCOUNT_ERROR:
      if (action.error.code === 410) return setResetActivateProperty(state, 'tokenExpired');
      else return setResetActivateProperty(state, 'unknownError');

    case types.USER_PAYMENT_INFOS_STORE:
      return Object.assign({}, state, {
        worldPayReturnInfos: action.query
      });

    case types.USER_SUBSCRIBE_FORM_RESTORE:
      let drivingLicenceFiles = state.drivingLicenceFiles || [];
      action.driverLicence ? drivingLicenceFiles.push(action.driverLicence) : '';
      action.driverLicenceBack ? drivingLicenceFiles.push(action.driverLicenceBack) : '';
      return Object.assign({}, state, {
        subscribeFormStoredValues: action.values,
        status: action.status,
        userInfo: action.userInfo,
        drivingLicenceFiles
      });

    case types.USER_SUBSCRIBE_FORM_UNSTORE:
      return Object.assign({}, state, {
        subscribeFormStoredValues: null
      });

    case types.USER_RESET_PASSWORD_CHECK_TOKEN_REQUEST:
      return state;

    case types.USER_RESET_PASSWORD_STORE_TOKEN:
      return Object.assign({}, state, {
        resetPasswordToken: action.validToken
      });

    case types.USER_RESET_PASSWORD_APPLY_REQUEST:
      return state;

    case types.CHANGE_PASSWORD_APPLY_REQUEST:
      return state;

    case types.CHANGE_PASSWORD_APPLY_SUCCESS:
      return setResetActivateProperty(state, 'taskSuccess');

    case types.CHANGE_PASSWORD_APPLY_ERROR:
      return setResetActivateProperty(state, 'unknownError');

    case types.USER_GET_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        settings: action.params
      });

    case types.USER_EDIT_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        settings: action.formParams
      });

    case types.USER_SET_USED_BY_BACKUSER:
      return Object.assign({}, state, {
        usedByBackUser: action.bool
      });

    case types.USER_SET_INITIAL_LOGIN_VALUE:
      return Object.assign({}, state, {
        initialLoginValue: action.value
      });

    case types.USER_GET_PAYMENT_URL_SUCCESS:
      return Object.assign({}, state, {
        paymentUrl: action.paymentUrl
      });

    case types.CREDIT_CARD_MODAL_OPEN:
      return Object.assign({}, state, {
        openedCreditCardModal: true
      });

    case types.CREDIT_CARD_MODAL_CLOSE:
      return Object.assign({}, state, {
        openedCreditCardModal: null
      });

    case UPDATE_LOCATION:
      if (action.path === '/') {
        return Object.assign({}, state, {
          userInfo: null,
          openedCreditCardModal: null
        });
      }
      return state;

    case types.USER_EDIT_DRIVER_LICENCE_FILE_INFOS_SUCCESS:
      return handleEditLicenceSuccess(action, state);

    case types.USER_CLOSE_COOKIE_CONSENT:
      return Object.assign({}, state, {
        showCookieConsent: false
      });

    case types.USER_GET_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        customFields: action.customFields
      };

    default:
      return state;
  }
}
