// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

class Marker extends Component {
  constructor() {
    // noinspection JSCheckFunctionSignatures
    super();
  }

  render() {
    const { bookingLocation, locale, bundle } = this.props;

    // TOIMPROVE: we need to wrap the marker in the IntlProvider because it won't be a child of the Root element
    // cf https://github.com/istarkov/google-map-react/issues/64
    return (
      <IntlProvider key="intl" locale={locale} messages={bundle}>
        <div className="mapMarkerWrapper">
          <div className={'mapMarker'}>
            <span className="mapMarker_label">{this.props.text}</span>
          </div>
          {this.props.currentMarkerIsClicked && (
            <div className="mapMarkerTooltip">
              <div className={'mapMarkerTooltip_head'}>
                <span className="mapMarkerTooltip_title mapMarkerTooltip_title--parking">
                  {_.get(bookingLocation, 'name')}
                </span>
              </div>
              <div className="mapMarkerTooltip_content">
                <span className="mapMarkerTooltip_addressTitle">
                  <FormattedMessage id="bookingResult_mapMarker_address" />:
                </span>
                <span className="mapMarkerTooltip_address">
                  {_.get(bookingLocation, 'site.address.formattedAddress')}
                </span>
              </div>
            </div>
          )}
        </div>
      </IntlProvider>
    );
  }
}

Marker.displayName = 'Marker';

Marker.propTypes = {
  $hover: PropTypes.bool,
  currentMarkerIsClicked: PropTypes.bool,
  bookingLocation: PropTypes.object,
  type: PropTypes.string
};

export default connect(state => {
  const {
    i18n: { locale, bundle }
  } = state;

  return {
    locale,
    bundle
  };
})(Marker);
