export const UNHANDLED_SERVER_ERROR = 'UNHANDLED_SERVER_ERROR';
export const INVALID_TOKEN = 'INVALID_TOKEN';

export const I18N_LOADING_FAILED = 'I18N_LOADING_FAILED';

export const LOGIN_MISSING_ARGUMENT = 'LOGIN_MISSING_ARGUMENT';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const USER_RESET_PASSWORD_MISSING_ARGUMENT = 'USER_RESET_PASSWORD_MISSING_ARGUMENT';
export const USER_RESET_PASSWORD_FAILED = 'USER_RESET_PASSWORD_FAILED';
export const USER_GET_CUSTOM_FIELDS_FAILED = 'USER_GET_CUSTOM_FIELDS_FAILED';
export const USER_EDIT_PROFILE_FAILED = 'USER_EDIT_PROFILE_FAILED';
export const USER_EDIT_PAYMENT_FAILED = 'USER_EDIT_PAYMENT_FAILED';
export const USER_GET_PAYMENT_INFOS_FAILED = 'USER_GET_PAYMENT_INFOS_FAILED';
export const USER_VALIDATE_ACCOUNT_FAILED = 'USER_VALIDATE_ACCOUNT_FAILED';
export const USER_UPDATE_SETTINGS_FAILED = 'USER_UPDATE_SETTINGS_FAILED';
export const USER_GET_SETTINGS_FAILED = 'USER_GET_SETTINGS_FAILED';

export const SUBSCRIBE_MISSING_ARGUMENT = 'SUBSCRIBE_MISSING_ARGUMENT';
export const SUBSCRIBE_FAILED = 'SUBSCRIBE_FAILED';

export const VALIDATION_FIELD_MISSING = 'VALIDATION_FIELD_MISSING';
export const ADDRESS_AUTO_COMPLETE_MISSING = 'ADDRESS_AUTO_COMPLETE_MISSING';
export const VALIDATION_LENGTH_TOO_SHORT = 'VALIDATION_LENGTH_TOO_SHORT';
export const VALIDATION_LENGTH_TOO_LONG = 'VALIDATION_LENGTH_TOO_LONG';
export const VALIDATION_TOO_LOW = 'VALIDATION_TOO_LOW';
export const VALIDATION_TOO_HIGH = 'VALIDATION_TOO_HIGH';
export const VALIDATION_NOT_INTEGER = 'VALIDATION_NOT_INTEGER';
export const VALIDATION_NOT_NUMBER = 'VALIDATION_NOT_NUMBER';
export const VALIDATION_NOT_IN_ENUM = 'VALIDATION_NOT_IN_ENUM';
export const VALIDATION_MISMATCH = 'VALIDATION_MISMATCH';
export const VALIDATION_EMAIL_INVALID = 'VALIDATION_EMAIL_INVALID';
export const VALIDATION_EMAIL_ALREADY_EXISTS = 'VALIDATION_EMAIL_ALREADY_EXISTS';
export const VALIDATION_PASSWORD_INVALID = 'VALIDATION_PASSWORD_INVALID';
export const VALIDATION_ADDRESS_MISSING = 'VALIDATION_ADDRESS_MISSING';
export const VALIDATION_ADDRESS_INVALID = 'VALIDATION_ADDRESS_INVALID';
export const VALIDATION_DRIVER_LICENCE_MISSING = 'VALIDATION_DRIVER_LICENCE_MISSING';
export const VALIDATION_DRIVER_LICENCE_INVALID = 'VALIDATION_DRIVER_LICENCE_INVALID';
export const VALIDATION_DRIVER_LICENCE_INVALID_MIME_TYPE = 'VALIDATION_DRIVER_LICENCE_INVALID_MIME_TYPE';
export const VALIDATION_DRIVER_LICENCE_SIZE_TOO_LARGE = 'VALIDATION_DRIVER_LICENCE_SIZE_TOO_LARGE';
export const VALIDATION_DRIVER_BIRTH_DATE_MISSING = 'VALIDATION_DRIVER_BIRTH_DATE_MISSING';
export const VALIDATION_DRIVER_BIRTH_DATE_INVALID = 'VALIDATION_DRIVER_BIRTH_DATE_INVALID';
export const VALIDATION_FRENCH_PHONE_NUMBER_INVALID = 'VALIDATION_FRENCH_PHONE_NUMBER_INVALID';
export const VALIDATION_DATE_BEFORE_INVALID = 'VALIDATION_DATE_BEFORE_INVALID';
export const VALIDATION_DATE_INVALID = 'VALIDATION_DATE_INVALID';

export const BOOKING_SEARCH_FAILED = 'BOOKING_SEARCH_FAILED';
