import fetch from 'isomorphic-fetch';
import _omit from 'lodash/object/omit';
import _map from 'lodash/collection/map';
import _get from 'lodash/object/get';

import config from '../constants/config-constants';
import {
  UNHANDLED_SERVER_ERROR,
  LOGIN_FAILED,
  USER_RESET_PASSWORD_FAILED,
  USER_GET_PAYMENT_INFOS_FAILED,
  USER_VALIDATE_ACCOUNT_FAILED,
  USER_EDIT_PAYMENT_FAILED,
  USER_UPDATE_SETTINGS_FAILED,
  USER_GET_SETTINGS_FAILED,
  USER_GET_CUSTOM_FIELDS_FAILED
} from '../constants/errors-constants';
import {
  enhanceUserInfo,
  enhanceBookingsSearch,
  enhanceBooking,
  parseHeader,
  checkErrorAndConvert
} from './data-enhancer';
import { formatDateStringDayFirst, trySet } from '../utils/utils';

const JSON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

let _setToken;
let _getToken;

function serialize(data) {
  return Object.keys(data)
    .map(function(keyName) {
      return encodeURIComponent(keyName) + '=' + encodeURIComponent(data[keyName]);
    })
    .join('&');
}

function getTokenizedHeaders(headers) {
  headers = headers || {};
  headers['x-auth-token'] = _getToken();
  return headers;
}

function handleToken(res) {
  const token = res.headers.get('X-AUTH-TOKEN');
  if (config.debugMode) {
    console.log('Token received from refresh:', token); // eslint-disable-line
  }
  _setToken(token);
}

const Api = {
  user: {
    checkEmailAvailability({ login }) {
      const payload = {
        login: login
      };

      const queryParams = serialize(payload);

      return fetch(`${config.apiBaseUrl}/v2/users?${queryParams}`).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    login({ login, password }) {
      const payload = {
        login,
        password
      };

      return fetch(`${config.apiBaseUrl}/v2/users/authenticate`, {
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: LOGIN_FAILED,
            code: res.status
          };
        }

        handleToken(res);

        return res.json().then(enhanceUserInfo);
      });
    },

    refreshToken() {
      return fetch(`${config.apiBaseUrl}/v2/users/auth/refresh`, {
        method: 'POST',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }

        handleToken(res);

        return res.json().then(enhanceUserInfo);
      });
    },

    validateAccount(validationToken) {
      return fetch(`${config.apiBaseUrl}/v2/users/validate?subscriptionToken=${validationToken}`, {
        method: 'PUT'
      }).then(res =>
        parseHeader(res, data => {
          if (res.ok) return data;
          else
            return Promise.reject({
              type: USER_VALIDATE_ACCOUNT_FAILED,
              code: data.status,
              developerMessage: data.developerMessage
            });
        })
      );
    },

    sendActivationLink(login) {
      const queryParameter = encodeURIComponent(login);
      return fetch(`${config.apiBaseUrl}/v2/users/validate?login=${queryParameter}`, {
        method: 'POST'
      }).then(res =>
        parseHeader(res, data => {
          if (res.ok) return data;
          else
            return Promise.reject({
              type: UNHANDLED_SERVER_ERROR,
              code: data.status,
              developerMessage: data.developerMessage
            });
        })
      );
    },

    resetPassword({ login }) {
      const payload = {
        login: login
      };

      const params = serialize(payload);

      return fetch(`${config.apiBaseUrl}/v2/users/password/reset?${params}`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_RESET_PASSWORD_FAILED,
            code: res.status
          };
        }
      });
    },

    resetPasswordGET(token) {
      const payload = {
        token: token
      };

      const params = serialize(payload);

      return fetch(`${config.apiBaseUrl}/v2/users/password/reset?${params}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_RESET_PASSWORD_FAILED,
            code: res.status
          };
        }
      });
    },

    resetPasswordPUT(params) {
      const body = {
        confirmationPassword: params.confirmationPassword,
        password: params.password
      };

      let payload = {
        token: params.token
      };

      payload = serialize(payload);

      return fetch(`${config.apiBaseUrl}/v2/users/password/reset?${payload}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(body)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_RESET_PASSWORD_FAILED,
            code: res.status
          };
        }
      });
    },

    subscribe(params = {}) {
      let payload = {
        companyId: params.companyId,
        login: params.login,
        password: params.password,
        memberCustomValues: params.memberCustomValues,
        locale: params.locale,
        commercialOffers: params.commercialOffers
      };

      if (params.italianInvoicing) payload.italianInvoicing = params.italianInvoicing;

      trySet(payload, 'managerEmail', params.managerEmail);
      trySet(payload, 'birthDate', formatDateStringDayFirst(params.birthDate));

      if (!params.usedByBackUser) {
        payload.address = params.address;
        payload.firstName = params.firstName;
        payload.lastName = params.lastName;
        payload.phoneNumber = {
          countryCode: params.mobilePhonePrefix,
          nationalNumber: params.phoneNumber
        };
      }

      if (params.drivingLicenceRequired) {
        const drivingLicenceFiles = [];
        if (params.driverLicence) drivingLicenceFiles.push({ fileId: params.driverLicence, type: 'FRONT' });
        if (params.driverLicenceBack) drivingLicenceFiles.push({ fileId: params.driverLicenceBack, type: 'BACK' });
        const drivingLicence = {
          files: drivingLicenceFiles,
          cityDeliverance: params.cityDeliverance,
          licenceNumber: params.driverLicenceNumber
        };

        if (params.drivingLicenceRequired) {
          payload = {
            ...payload,
            drivingLicence
          };
        }
        if (params.expirationDate)
          payload.drivingLicence.expirationDate = formatDateStringDayFirst(params.expirationDate);
        if (params.deliveranceDate)
          payload.drivingLicence.deliveranceDate = formatDateStringDayFirst(params.deliveranceDate);
      }

      if (params.paymentOrderCode) {
        payload.paymentOrderCode = params.paymentOrderCode;
      }

      let _api = { headers: JSON_HEADERS };
      if (params.userId) {
        _api = {
          endpoint: `${config.apiBaseUrl}/v2/members/${params.userId}`,
          method: 'PUT',
          headers: { ..._api.headers, 'x-auth-token': localStorage.getItem('token') }
        };

        delete payload.login;
        delete payload.password;

        if (payload.paymentOrderCode) {
          let payloadPayment = { id: params.userId, orderCode: payload.paymentOrderCode };
          this.updatePaymentInfos(payloadPayment, _api.headers);
        }
      } else {
        _api = {
          ..._api,
          endpoint: `${config.apiBaseUrl}/v2/members${params.usedByBackUser ? '/existing-user' : ''}`,
          method: 'POST'
        };
      }

      return fetch(_api.endpoint, {
        method: _api.method,
        headers: _api.headers,
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    updateProfile(payload = {}) {
      if (payload.company.drivingLicenceRequired) {
        const drivingLicence = _omit(payload.drivingLicence, 'fileId');
        const files = _get(drivingLicence, 'files');
        const newFiles = _map(files, file => _omit(file, 'id'));
        trySet(payload, 'drivingLicence.files', newFiles);
        payload = { ...payload, drivingLicence: { ...drivingLicence, files: newFiles } };
      }

      return fetch(`${config.apiBaseUrl}/v2/members/${payload.id}`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => checkErrorAndConvert(true)(res).then(enhanceUserInfo));
    },

    getProfile(id) {
      return fetch(`${config.apiBaseUrl}/v2/members/${id}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        return checkErrorAndConvert(true)(res).then(enhanceUserInfo);
      });
    },

    getPaymentInfos(userId) {
      return fetch(`${config.apiBaseUrl}/v2/members/${userId}/payment`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_GET_PAYMENT_INFOS_FAILED,
            code: res.status
          };
        }
        if (res.status === 200) {
          return res.json();
        }
        if (res.status === 204) {
          return null;
        }
      });
    },

    updatePaymentInfos(payload, headers = null) {
      let params = {
        orderCode: payload.orderCode
      };
      headers = headers ? headers : getTokenizedHeaders(JSON_HEADERS);
      return fetch(`${config.apiBaseUrl}/v2/members/${payload.id}/payment`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(params)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_EDIT_PAYMENT_FAILED,
            code: res.status
          };
        }
      });
    },

    getPaymentUrl(companyId) {
      return fetch(`${config.apiBaseUrl}/v2/members/payment/url?companyId=${companyId}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_GET_PAYMENT_INFOS_FAILED,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getPaymentUrlWithMember(memberId) {
      return fetch(`${config.apiBaseUrl}/v2/members/payment/url?memberId=${memberId}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_GET_PAYMENT_INFOS_FAILED,
            code: res.status
          };
        }
        return res.json();
      });
    },

    updatePassword(params) {
      const body = {
        newPassword: params.newPassword,
        oldPassword: params.oldPassword
      };

      return fetch(`${config.apiBaseUrl}/v2/users/password`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(body)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_GET_CUSTOM_FIELDS_FAILED,
            code: res.status
          };
        }
      });
    },

    getSettings(memberId) {
      return fetch(`${config.apiBaseUrl}/v2/members/${memberId}/settings`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_GET_SETTINGS_FAILED,
            code: res.status
          };
        }
        if (res.status === 200) {
          return res.json();
        }
        if (res.status === 204) {
          return null;
        }
      });
    },

    updateSettings(params) {
      const body = {
        sendMailForCallCenterChange: params.sendMailForCallCenterChange,
        sendMailForPersonalChange: params.sendMailForPersonalChange,
        smsBeforeArrivalTime: params.smsBeforeArrivalTime,
        smsBeforeDepartureTime: params.smsBeforeDepartureTime
      };

      return fetch(`${config.apiBaseUrl}/v2/members/${params.memberId}/settings`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(body)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_UPDATE_SETTINGS_FAILED,
            code: res.status
          };
        }
      });
    },

    getCustomFields(memberId) {
      return fetch(`${config.apiBaseUrl}/v2/members/${memberId}/custom-fields`, {
        method: 'GET',
        headers: JSON_HEADERS
      }).then(res => {
        if (!res.ok) {
          throw {
            type: USER_GET_CUSTOM_FIELDS_FAILED,
            code: res.status
          };
        }
        return res.json();
      });
    }
  },

  booking: {
    search(payload) {
      return fetch(`${config.apiBaseUrl}/v2/bookings/search-filtered`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        return checkErrorAndConvert(false)(res).then(enhanceBookingsSearch);
      });
    },

    getUserList({ userId, payload }) {
      return fetch(`${config.apiBaseUrl}/v2/members/${userId}/bookings/page`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(checkErrorAndConvert(true));
    },

    create(payload) {
      return fetch(`${config.apiBaseUrl}/v2/bookings`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        return checkErrorAndConvert(false)(res).then(enhanceBooking);
      });
    },

    update(payload) {
      return fetch(`${config.apiBaseUrl}/v2/bookings/${payload.id}/update`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload.booking)
      }).then(res => {
        return checkErrorAndConvert(false)(res).then(enhanceBooking);
      });
    },

    cancel(id) {
      return fetch(`${config.apiBaseUrl}/v2/bookings/${id}/cancel`, {
        method: 'POST',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status,
            body: res
          };
        }
      });
    },

    getFeedBack(bookingId) {
      return fetch(`${config.apiBaseUrl}/v2/bookings/${bookingId}/feedback`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        if (res.status === 200) {
          return res.json();
        }
      });
    },

    createFeedBack({ params, bookingId }) {
      let payload = {
        internalCleanliness: params.cleanlinessInside,
        externalCleanliness: params.cleanlinessOutside
      };

      if (params.reportDamage) {
        payload.report = {
          comment: params.message,
          type: params.damageType
        };
      }

      return fetch(`${config.apiBaseUrl}/v2/bookings/${bookingId}/feedback`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    },

    updateFeedBack({ params, bookingId }) {
      let payload = {
        internalCleanliness: params.cleanlinessInside,
        externalCleanliness: params.cleanlinessOutside
      };

      if (params.reportDamage) {
        payload.report = {
          comment: params.message,
          type: params.damageType
        };
      }

      if (params.comment) {
        payload.comment = params.comment;
      }

      return fetch(`${config.apiBaseUrl}/v2/bookings/${bookingId}/feedback`, {
        method: 'PUT',
        headers: getTokenizedHeaders(JSON_HEADERS),
        body: JSON.stringify(payload)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    }
  },

  company: {
    getInfos(companyId) {
      return fetch(`${config.apiBaseUrl}/v2/companies/${companyId}`, {
        method: 'GET'
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getCustomizations(token) {
      const params = serialize({
        token,
        defaultTemplate: false
      });
      return fetch(`${config.apiBaseUrl}/v2/customizations/look-token?${params}`, {
        method: 'GET'
      }).then(res =>
        parseHeader(res, data => {
          if (res.ok) return data;
          else
            return Promise.reject({
              type: UNHANDLED_SERVER_ERROR,
              code: data.status,
              developerMessage: data.developerMessage
            });
        })
      );
    },

    // data should be either companyId string or object conaining companyId prop
    getCustomFields({ companyId, form = '' }) {
      let params = form ? '?' + serialize({ form: form }) : '';

      return fetch(`${config.apiBaseUrl}/v2/customizations/${companyId}/fields${params}`, {
        method: 'GET',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    }
  },

  notifications: {
    getList(memberId) {
      return fetch(`${config.apiBaseUrl}/v2/members/${memberId}/notifications`, {
        method: 'GET',
        headers: getTokenizedHeaders()
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    setNotificationRead({ notificationId, read }) {
      return fetch(`${config.apiBaseUrl}/v2/notifications/${notificationId}/read?read=${read}`, {
        method: 'POST',
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
      });
    }
  },

  files: {
    uploadFile(file) {
      return fetch(`${config.apiBaseUrl}/v2/files`, {
        method: 'POST',
        headers: JSON_HEADERS,
        body: JSON.stringify({
          content: file.content,
          mimeType: file.mimeType,
          name: file.name
        })
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    },

    getFileInfos(fileId) {
      return fetch(`${config.apiBaseUrl}/v2/files/${fileId}`, {
        headers: getTokenizedHeaders(JSON_HEADERS)
      }).then(res => {
        if (!res.ok) {
          throw {
            type: UNHANDLED_SERVER_ERROR,
            code: res.status
          };
        }
        return res.json();
      });
    }
  }
};

export default function ApiCaller(resource, method, params) {
  if (!Api[resource]) {
    throw new Error("Unkown resource: '" + resource + "'");
  }
  if (!Api[resource][method]) {
    throw new Error("Unkown method: '" + method + "' for resource '" + resource + "'");
  }

  return Api[resource][method](params);
}

export function initTokenHandlers(setToken, getToken) {
  _setToken = setToken;
  _getToken = getToken;
}

export function isAuthenticated() {
  return !!_getToken();
}
