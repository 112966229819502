import { UPDATE_LOCATION } from 'react-router-redux';
import _get from 'lodash/object/get';

import * as types from '../constants/actionTypes-constants';

export default function user(
  state = {
    companyId: null,
    customFields: null,
    companyInfos: null,
    customizations: null
  },
  action
) {
  switch (action.type) {
    case types.COMPANY_ID_FOR_SUBSCRIBE_STORE:
      return Object.assign({}, state, {
        companyId: action.companyId
      });

    case types.COMPANY_GET_COMPANY_INFOS_SUCCESS:
      return Object.assign({}, state, {
        companyInfos: action.data
      });

    case types.CUSTOM_FIELDS_SUBSCRIPTION_GET_SUCCESS:
      return {
        ...state,
        customFields: _get(action, 'fields', [])
      };
    case types.CUSTOM_FIELDS_BOOKINGS_GET_SUCCESS:
      return {
        ...state,
        customFields: _get(action, 'fields', [])
      };

    case types.COMPANY_GET_CUSTOMIZATIONS:
      return {
        ...state,
        customizations: null
      };

    case types.COMPANY_GET_CUSTOMIZATIONS_SUCCESS:
      return {
        ...state,
        customizations: action.data
      };

    case types.COMPANY_GET_CUSTOMIZATIONS_ERROR:
      return {
        ...state,
        customizations: null
      };

    case UPDATE_LOCATION:
      if (action.path === '/') {
        return Object.assign({}, state, {
          companyId: null,
          companyInfos: null
        });
      } else return state;

    default:
      return state;
  }
}
