import React, { Component } from 'react';
import PdfIcon from 'material-ui/lib/svg-icons/image/picture-as-pdf';
import { PdfPreviewStyle } from '../../constants/style-constants';

class PdfPreview extends Component {
  render() {
    return (
      <div className="pdf-preview-container">
        <PdfIcon style={PdfPreviewStyle} />
      </div>
    );
  }
}

export default PdfPreview;
