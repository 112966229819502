import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import _get from 'lodash/object/get';
import _find from 'lodash/collection/find';
import { DRIVER_LICENCE_BACK_ID, DRIVER_LICENCE_FRONT_ID, ERROR, SUCCESS } from '../../constants/generic-constants';

// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';

import { uploadFile } from '../UploadFile/actions';
import { namedCompose } from '../../utils/utils';
import PdfPreview from '../PdfPreview/PdfPreview';

class EkButtonUpload extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.derivedStateFromProps(props);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  addBase64(file) {
    const mimeType = _get(file, 'mimeType');
    const content = _get(file, 'content');
    return mimeType && content ? `data:${mimeType};base64,${content}` : '';
  }

  isDriverLicence(id) {
    return id === DRIVER_LICENCE_FRONT_ID || id === DRIVER_LICENCE_BACK_ID;
  }

  derivedStateFromProps(props) {
    const { files, id } = props;

    if (!this.isDriverLicence(id)) return;

    const successPredicate = file => {
      const thisId = _get(file, 'id') === id;
      const success = _get(file, 'status') === SUCCESS;
      return thisId && success;
    };

    this.successFile = _find(files, successPredicate);

    this.mimeType = _get(this.successFile, 'mimeType');
    this.imgData = this.addBase64(this.successFile);

    const errorPredicate = file => {
      const thisId = _get(file, 'id') === id;
      const success = _get(file, 'status') === ERROR;
      return thisId && success;
    };

    this.errorFile = _find(files, errorPredicate);
    this.errorMsg = _get(this.errorFile, 'errorMessage');
  }

  handleChange(e) {
    e.preventDefault();

    const inputFile = e.target.files[0];
    if (!inputFile) {
      this.props.field.onChange(null);
      return;
    }

    const reader = new FileReader();
    reader.onload = function(upload) {
      const fileData = {
        id: this.props.id,
        name: inputFile.name,
        mimeType: inputFile.type,
        size: inputFile.size,
        lastModifiedDate: inputFile.lastModifiedDate,
        content: upload.target.result
      };

      this.props.uploadFile(fileData, this.props.field);
    }.bind(this);

    reader.readAsDataURL(inputFile);
  }

  displayDriverLicenceFile() {
    const {
      intl: { formatMessage }
    } = this.props;
    const toUploadTitle = formatMessage({ id: 'image_preview_selected_for_upload' });

    if (this.successFile) {
      return (
        <div className="ekButtonUpload_preview" title={toUploadTitle}>
          {this.mimeType === 'application/pdf' ? <PdfPreview /> : <img alt="" src={this.imgData} />}
        </div>
      );
    }
  }

  displayError() {
    if (this.errorFile) {
      const errorMessage = this.errorMsg || 'error_local_unknown';
      return (
        <div className="ekButtonUpload_error">
          <FormattedMessage id={errorMessage} />
        </div>
      );
    }
  }

  displayChildren() {
    const { children } = this.props;
    if (!this.successFile) return children;
  }

  render() {
    const { label, field, mandatory, customClass, buttonLabel, labelKey, id } = this.props;

    const readyLabel = labelKey ? (
      <FormattedMessage id={labelKey} />
    ) : label ? (
      label
    ) : (
      <FormattedMessage id="common_unknown" />
    );

    return (
      <div>
        <label className="ekButtonUploadWrapper" htmlFor={id}></label>
        <span className="ekButtonUpload_label">
          {readyLabel}
          {mandatory && <span className="boxedInput_mandatory">*</span>}
        </span>
        <span className={`ekButtonUpload ${customClass || ''}`}>
          <input type="file" id={id} title="" className="ekButtonUpload_input" onChange={this.handleChange} />
          <span className="ekButtonUpload_labelButton">
            <FormattedMessage id={buttonLabel} />
          </span>
        </span>
        {_get(field, 'value.name') && <span className="ekButtonUpload_name">{field.value.name}</span>}

        {this.displayChildren()}
        {this.displayDriverLicenceFile()}
        {this.displayError()}
      </div>
    );
  }
}

EkButtonUpload.displayName = 'EkButtonUpload';

EkButtonUpload.propTypes = {
  label: PropTypes.string,
  buttonLabel: PropTypes.string,
  customClass: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  mandatory: PropTypes.bool
};

export default namedCompose(
  connect(
    state => state.fileUpload,
    { uploadFile }
  ),
  injectIntl
)(EkButtonUpload);
