import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';

import { addZeroForSelect } from '../../utils/utils';
import _get from 'lodash/object/get';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import ListNotifications from '../../components/ListNotifications/ListNotifications';
import SearchBookingForm from '../../components/SearchBookingForm/SearchBookingForm';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import routes from '../../constants/routes-constants';
import { bundleSelector } from '../../selectors/all-selectors';
import Paper from 'material-ui/lib/paper';
import RaisedButton from 'material-ui/lib/raised-button';
import ErrorIcon from 'material-ui/lib/svg-icons/alert/error-outline';
import { MEMBER_STATUS_TO_COMPLETE } from '../../constants/backend-constants';

class Home extends Component {
  constructor() {
    super();
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubscribeComplete = this.handleSubscribeComplete.bind(this);
  }

  componentDidMount() {
    let anchorName = location.hash;
    if (anchorName) {
      let anchors = anchorName.split('#');
      let anchorToFollow = anchors[anchors.length - 1];
      let anchorElement = document.getElementById(anchorToFollow);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    }
  }

  isVR() {
    const { userInfo } = this.props;
    return _get(userInfo.company, 'contract.interfaceConfig.replacementVehicleDisplay');
  }

  subscribePending() {
    const { userInfo } = this.props;
    const labelStyle = { height: '100%', margin: '12px!important', padding: '15px 8px 15px 0' };
    return _get(userInfo, 'status') === MEMBER_STATUS_TO_COMPLETE ? (
      <Paper className="pre-subscribe-message" zDepth={4}>
        <div className="pre-subscribe-title">
          <FormattedHTMLMessage id="pre_subscribe_warn_title" values={{ firstname: userInfo.firstName }} />
        </div>
        <RaisedButton
          primary
          style={{ width: 'fit-content', margin: '10px auto', display: 'block' }}
          labelStyle={labelStyle}
          className="pending-subscription"
          onClick={this.handleSubscribeComplete}
          label={<FormattedMessage id="pre_subccribe_warn_btn" />}
          icon={<ErrorIcon />}
        />
      </Paper>
    ) : (
      ''
    );
  }

  handleSubscribeComplete() {
    const { dispatch, userInfo } = this.props;
    dispatch(routeActions.push(routes.subscribe.path.replace(':companyId', userInfo.company.id)));
  }

  handleSearch(values) {
    const { dispatch } = this.props;

    dispatch(
      routeActions.push(routes.bookingResult.path.replace(':search', encodeURIComponent(JSON.stringify(values))))
    );
  }

  render() {
    const { notifications, searchContext, bundle, userInfo } = this.props;

    let now = new Date(),
      nowHour = addZeroForSelect(now.getHours()),
      returnHour = addZeroForSelect(nowHour + 1);

    let initialValues = {};

    if (searchContext === null) {
      initialValues = {
        pickupDate: now,
        pickupDateHour: nowHour,
        pickupDateMin: '00',
        returnDate: now,
        returnDateHour: returnHour,
        returnDateMin: '00',
        passengers: '1',
        oneWayTrip: _get(userInfo.company, 'contract.interfaceConfig.oneWayDisplay')
      };
    } else {
      initialValues = {
        from: searchContext.from,
        to: searchContext.to,
        pickupDate: new Date(searchContext.pickupDate),
        pickupDateHour: searchContext.pickupDateHour,
        pickupDateMin: searchContext.pickupDateMin,
        returnDateHour: searchContext.returnDateHour,
        returnDateMin: searchContext.returnDateMin,
        passengers: searchContext.passengers,
        oneWayTrip: searchContext.oneWayTrip
      };
      if (searchContext.returnDate) {
        initialValues.returnDate = new Date(searchContext.returnDate);
      }
    }

    return (
      <div className="HomePage">
        <div className="HomePage_poster">
          <Header customClass="header--home" />
          <FlashMessageDisplayer />

          <div className="HomePage_content">
            <h2 className="HomePage_title">
              <FormattedMessage id="homePage_title" values={{ productName: bundle.values_productName }} />
            </h2>
            <p className="HomePage_subtitle">
              <FormattedMessage id="homePage_subtitle" values={{ productName: bundle.values_productName }} />
            </p>
            {!this.isVR() && (
              <SearchBookingForm
                onCallback={this.handleSearch}
                serverError={this.props.searchError}
                initialValues={initialValues}
              />
            )}
          </div>
        </div>

        <div className="mainContent">
          <div className="mainContent_inner">
            {this.subscribePending()}
            {!notifications.list || !notifications.list.length ? '' : <ListNotifications />}

            <section className="tuto">
              <h2 className="tuto_title">
                <FormattedMessage
                  id={this.isVR() ? 'tuto_intro_vr' : 'tuto_intro'}
                  values={{ productName: bundle.values_productName }}
                />
              </h2>

              <ul className="tuto_narration">
                <li className="step tuto_narration_item">
                  <figure className="step_img">
                    <img src="/img/welcome.svg" alt="" width="130" height="142" />
                  </figure>
                  <div className="step_content">
                    <h4 className="step_title">
                      <FormattedMessage
                        id={this.isVR() ? 'tuto_step1_title_vr' : 'tuto_step1_title'}
                        values={{ productName: bundle.values_productName }}
                      />
                    </h4>
                    <FormattedMessage
                      id={this.isVR() ? 'tuto_step1_content_vr' : 'tuto_step1_content'}
                      values={{ productName: bundle.values_productName }}
                    />
                  </div>
                </li>

                <li className="step tuto_narration_item">
                  <figure className="step_img">
                    <img src="/img/map.svg" alt="" width="114" height="104" />
                  </figure>
                  <div className="step_content">
                    <h4 className="step_title">
                      <FormattedMessage
                        id={this.isVR() ? 'tuto_step2_title_vr' : 'tuto_step2_title'}
                        values={{ productName: bundle.values_productName }}
                      />
                    </h4>
                    <FormattedMessage
                      id={this.isVR() ? 'tuto_step2_content_vr' : 'tuto_step2_content'}
                      values={{ productName: bundle.values_productName }}
                    />
                  </div>
                </li>

                <li className="step tuto_narration_item">
                  <figure className="step_img">
                    <img src="/img/badge.svg" alt="" width="147" height="89" />
                  </figure>
                  <div className="step_content">
                    <h4 className="step_title">
                      <FormattedMessage
                        id={this.isVR() ? 'tuto_step3_title_vr' : 'tuto_step3_title'}
                        values={{ productName: bundle.values_productName }}
                      />
                    </h4>
                    <FormattedMessage
                      id={this.isVR() ? 'tuto_step3_content_vr' : 'tuto_step3_content'}
                      values={{ productName: bundle.values_productName }}
                    />
                  </div>
                </li>

                <li className="step tuto_narration_item">
                  <figure className="step_img">
                    <img src="/img/car.svg" alt="" width="114" height="127" />
                  </figure>
                  <div className="step_content">
                    <h4 className="step_title">
                      <FormattedMessage
                        id={this.isVR() ? 'tuto_step4_title_vr' : 'tuto_step4_title'}
                        values={{ productName: bundle.values_productName }}
                      />
                    </h4>
                    <FormattedMessage
                      id={this.isVR() ? 'tuto_step4_content_vr' : 'tuto_step4_content'}
                      values={{ productName: bundle.values_productName }}
                    />
                  </div>
                </li>

                <li className="step tuto_narration_item">
                  <figure className="step_img">
                    <img src="/img/pointer.svg" alt="" width="92" height="157" />
                  </figure>
                  <div className="step_content">
                    <h4 className="step_title">
                      <FormattedMessage
                        id={this.isVR() ? 'tuto_step5_title_vr' : 'tuto_step5_title'}
                        values={{ productName: bundle.values_productName }}
                      />
                    </h4>
                    <FormattedMessage
                      id={this.isVR() ? 'tuto_step5_content_vr' : 'tuto_step5_content'}
                      values={{ productName: bundle.values_productName }}
                    />
                  </div>
                </li>
              </ul>
            </section>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

Home.displayName = 'Home';

Home.propTypes = {};

export default connect(state => {
  const {
    user: { userInfo },
    booking: { searchContext },
    notifications
  } = state;

  return {
    userInfo,
    searchContext,
    notifications,
    bundle: bundleSelector(state)
  };
})(Home);
