import _get from 'lodash/object/get';
import _isEqual from 'lodash/lang/isEqual';
import moment from 'moment';

import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { formatDateLong, createCustomFieldsValues, trySet, append, trimValues, addErrorMessage } from '../utils/utils';
import { bookingCustomFieldsSelector, bundleSelector } from '../selectors/all-selectors';
import { apiParams } from '../constants/api-params-constants';
import { BOOKING_STATE_PAST, BOOKING_STATE_UPCOMING } from '../constants/backend-constants';
import { addFlashMessage } from './flashMessage-actions';
import { FLASH_MESSAGE_TYPE_ERROR } from '../constants/generic-constants';
import { enhanceBookingsUser } from '../api/data-enhancer';

//---------------------------------------------
// Search booking
//---------------------------------------------

export function requestBookingSearch(searchParams) {
  return (dispatch, getState) => {
    const state = getState();
    const bundle = bundleSelector(state);

    const {
      from,
      to,
      passengers,
      pickupDate,
      pickupDateHour,
      pickupDateMin,
      returnDate,
      returnDateHour,
      returnDateMin,
      oneWayTrip,
      originalBookingId,
      startParkingId,
      carOptions,
      fuelTypes,
      fuelLevel,
      usages,
      transmissionTypes,
      voucherCode,
      usageTypes
    } = searchParams;

    const roundTrip = oneWayTrip === false;
    let params = { passengers };

    trySet(
      params,
      'start.address',
      append({
        formattedAddress: _get(from, 'formattedAddress'),
        coordinates: _get(from, 'coordinates')
      })
    );
    trySet(params, 'usageTypes', usageTypes);
    trySet(params, 'start.siteId', _get(from, 'id'));
    trySet(
      params,
      'end.address',
      append({
        formattedAddress: roundTrip ? _get(params, 'start.address.formattedAddress') : _get(to, 'formattedAddress'),
        coordinates: roundTrip ? _get(params, 'start.address.coordinates') : _get(to, 'coordinates')
      })
    );
    trySet(params, 'end.siteId', roundTrip ? _get(params, 'start.siteId') : _get(to, 'id'));

    let startDate = new Date(pickupDate);

    startDate.setHours(pickupDateHour || 0);
    startDate.setMinutes(pickupDateMin || 0);
    trySet(params, 'start.date', formatDateLong(startDate));

    let endDate = new Date(returnDate);

    if (returnDate && roundTrip) {
      endDate.setHours(returnDateHour || 0);
      endDate.setMinutes(returnDateMin || 0);
      trySet(params, 'end.date', formatDateLong(endDate));
    }

    // BE tweaks
    if (!_get(params, 'end.date')) {
      if (
        _isEqual(_get(params, 'start.address.coordinates', 'sc'), _get(params, 'end.address.coordinates', 'ec')) ||
        _isEqual(_get(params, 'start.siteId', 'si'), _get(params, 'end.siteId', 'ei'))
      )
        delete params.end;
    }

    if (originalBookingId) {
      params.originalBookingId = originalBookingId;
    }

    if (carOptions && carOptions.length > 0) {
      params.accessories = carOptions;
    }

    if (fuelTypes && fuelTypes.length > 0) {
      params.fuelTypes = fuelTypes;
    }

    if (fuelLevel && fuelLevel.length > 0) {
      params.fuelLevel = fuelLevel;
    }

    if (usages && usages.length > 0) {
      params.usageTypes = usages;
    }

    if (startParkingId) {
      params.startParkingId = startParkingId;
    }

    if (transmissionTypes && transmissionTypes.length > 0) {
      params.transmissionTypes = transmissionTypes;
    }

    if (voucherCode) {
      params.voucherCode = voucherCode;
    }

    dispatch({
      type: types.BOOKING_SEARCH_REQUEST,
      params
    });

    dispatch({
      type: types.BOOKING_SEARCH_CONTEXT_SET,
      search: searchParams
    });

    return dispatch(callApi('booking', 'search', params)).catch(error => {
      dispatch(addErrorMessage({ error, bundle, errorCodePrefixes: ['bookingResult_'] }));
    });
  };
}

export function callBookingSearchSuccess(list) {
  return {
    type: types.BOOKING_SEARCH_SUCCESS,
    list
  };
}

export function toggleVoucherInput() {
  return {
    type: types.BOOKING_TOGGLE_VOUCHER_INPUT
  };
}

//---------------------------------------------
// Result booking
//---------------------------------------------

export function updateBookingResultActiveTabIndex(index) {
  return {
    type: types.BOOKING_RESULT_ACTIVE_TAB_INDEX_UPDATE,
    index
  };
}

export function increaseBookingResultCarSharingBusinessMaxItems() {
  return {
    type: types.BOOKING_RESULT_CAR_SHARING_BUSINESS_MAX_ITEMS_INCREASE
  };
}

export function increaseBookingResultCarSharingPrivateMaxItems() {
  return {
    type: types.BOOKING_RESULT_CAR_SHARING_PRIVATE_MAX_ITEMS_INCREASE
  };
}

export function increaseBookingResultRideSharingMaxItems() {
  return {
    type: types.BOOKING_RESULT_RIDE_SHARING_MAX_ITEMS_INCREASE
  };
}

export function updateBookingMemberActiveTabIndex(index) {
  return {
    type: types.BOOKING_MEMBER_ACTIVE_TAB_INDEX_UPDATE,
    index
  };
}

export function updateBookingPastList() {
  return updateBookingUserList('pastBookingPage', BOOKING_STATE_PAST, 'past', types.BOOKING_PAST_LIST_UPDATE);
}

export function updateBookingUpcomingList() {
  return updateBookingUserList(
    'upcomingBookingPage',
    BOOKING_STATE_UPCOMING,
    'upcoming',
    types.BOOKING_UPCOMING_LIST_UPDATE
  );
}

function updateBookingUserList(page, bookingState, bookingType, dispatchType) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = _get(state.user, 'userInfo.id');
    const defaultParams = apiParams.existingBookings;
    const nextPage = _get(state.booking, page) + 1 || 1;

    const payload = {
      ...defaultParams.payload,
      page: {
        number: nextPage,
        size: defaultParams.bookingPageSize
      },
      states: [bookingState]
    };

    return dispatch(callApi('booking', 'getUserList', { userId, payload })).then(
      data =>
        dispatch({
          type: dispatchType,
          params: enhanceBookingsUser(data, bookingType)
        }),
      () =>
        dispatch(
          addFlashMessage({
            contentKey: 'error_server_unknown',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        )
    );
  };
}

export function toggleBookingDetailsOpenedState(id) {
  return {
    type: types.BOOKING_DETAILS_OPENED_STATE_TOGGLE,
    id
  };
}

export function setCurrentBooking(booking) {
  return {
    type: types.BOOKING_CURRENT_BOOKING_SET,
    booking
  };
}

export function openCancelBookingModal(booking) {
  return {
    type: types.BOOKING_CANCEL_MODAL_OPEN,
    booking
  };
}

export function closeCancelBookingModal() {
  return {
    type: types.BOOKING_CANCEL_MODAL_CLOSE
  };
}

export function openPrivatePaymentBookingModal() {
  return {
    type: types.BOOKING_OPEN_PRIVATE_PAYMENT_MODAL
  };
}

export function closePrivatePaymentBookingModal() {
  return {
    type: types.BOOKING_CLOSE_PRIVATE_PAYMENT_MODAL
  };
}

export function requestBookingCancel(booking) {
  return dispatch => {
    let id = booking.id;

    dispatch({
      type: types.BOOKING_CANCEL_REQUEST,
      booking
    });

    return dispatch(callApi('booking', 'cancel', id));
  };
}

export function callCancelBookingSuccess(booking) {
  return {
    type: types.BOOKING_CANCEL_SUCCESS,
    booking
  };
}

export function requestConfirmBooking() {
  return (dispatch, getState) => {
    const state = getState();

    const {
      message,
      sendSMS,
      openRideSharing,
      availableSeats,
      crossingAddress,
      pickupDate,
      pickupDateHour,
      pickupDateMin
    } = trimValues(state.form.bookingDetails);

    const currentBooking = state.booking.currentBooking;
    const customFields = bookingCustomFieldsSelector(state);
    const voucherCode = _get(state.booking.currentBooking, 'carSharingInfo.cost.voucherCode', false);

    let params = {
      type: currentBooking.type,
      start: {
        date: currentBooking.start.date
      },
      end: {
        date: currentBooking.end.date
      },
      reservedSeats: currentBooking.reservedSeats
    };

    if (voucherCode) {
      params.voucherCode = voucherCode;
    }

    if (currentBooking.vehicle) {
      params.vehicle = {
        id: currentBooking.vehicle.id
      };
    }

    if (currentBooking.start.parking) {
      params.start.parking = {
        id: currentBooking.start.parking.id
      };
    }

    if (currentBooking.end.parking) {
      params.end.parking = {
        id: currentBooking.end.parking.id
      };
    }

    if (currentBooking.start.address) {
      params.start.address = currentBooking.start.address;
    }

    if (currentBooking.start.coordinates) {
      params.start.coordinates = currentBooking.start.coordinates;
    }

    if (currentBooking.end.address) {
      params.end.address = currentBooking.end.address;
    }

    if (currentBooking.end.coordinates) {
      params.end.coordinates = currentBooking.end.coordinates;
    }

    if (message) {
      params.comment = message;
      params.sendCommentBySms = sendSMS;
    }

    if (currentBooking.carSharingInfo) {
      params.carSharingInfo = {
        usageType: currentBooking.carSharingInfo.usageType,
        openToRideSharing: openRideSharing
      };
    }

    let crossingDate = new Date(pickupDate);
    crossingDate.setHours(pickupDateHour || 0);
    crossingDate.setMinutes(pickupDateMin || 0);

    const crossingDateFormat = moment(crossingDate).format('YYYY-MM-DDTHH:mmZ');

    if (openRideSharing) {
      params.carSharingInfo.availableSeats = availableSeats;
      if (crossingAddress) {
        params.carSharingInfo.pickUp = {
          address: crossingAddress,
          coordinates: crossingAddress.coordinates,
          date: crossingDateFormat
        };
      }
    }

    if (currentBooking.rideSharingInfo) {
      params.rideSharingInfo = currentBooking.rideSharingInfo;
    }

    if (_get(customFields, 'length')) {
      params.bookingCustomValues = createCustomFieldsValues(customFields, trimValues(state.form.bookingDetails));
    }

    dispatch({
      type: types.BOOKING_CONFIRM_REQUEST,
      params
    });
    return dispatch(callApi('booking', 'create', params));
  };
}

export function callConfirmBookingSuccess(confirmedBooking) {
  return {
    type: types.BOOKING_CONFIRM_SUCCESS,
    confirmedBooking
  };
}

export function toggleBookingResultSearchOpenedState() {
  return {
    type: types.BOOKING_RESULT_SEARCH_OPENED_STATE_TOGGLE
  };
}

export function resetBookingSearch() {
  return {
    type: types.BOOKING_RESET_SEARCH
  };
}

export function toggleBookingResultFiltersOpenedState() {
  return {
    type: types.BOOKING_RESULT_FILTERS_OPENED_STATE_TOGGLE
  };
}

//---------------------------------------------
// Edit booking
//---------------------------------------------
export function requestEditBooking(bookingId) {
  return (dispatch, getState) => {
    const state = getState();

    const {
      message,
      sendSMS,
      openRideSharing,
      availableSeats,
      crossingAddress,
      pickupDate,
      pickupDateHour,
      pickupDateMin
    } = trimValues(state.form.editBooking);

    const currentBooking = state.booking.currentBooking;

    let params = {};
    params.id = bookingId;
    params.booking = {
      type: currentBooking.type,
      start: {
        date: currentBooking.start.date,
        parking: {
          id: currentBooking.start.parking.id
        }
      },
      end: {
        date: currentBooking.end.date
      },
      vehicle: {
        id: currentBooking.vehicle.id
      },
      reservedSeats: currentBooking.reservedSeats
    };

    if (currentBooking.end.parking) {
      params.booking.end.parking = {
        id: currentBooking.end.parking.id
      };
    }

    if (message) {
      params.booking.comment = message;
      params.booking.sendCommentBySms = sendSMS;
    }

    if (currentBooking.carSharingInfo) {
      params.booking.carSharingInfo = {
        usageType: currentBooking.carSharingInfo.usageType
      };
    }

    let crossingDate = new Date(pickupDate);
    crossingDate.setHours(pickupDateHour || 0);
    crossingDate.setMinutes(pickupDateMin || 0);

    const crossingDateFormat = moment(crossingDate).format('YYYY-MM-DDTHH:mmZ');

    if (openRideSharing) {
      params.booking.carSharingInfo.availableSeats = availableSeats;
      params.booking.carSharingInfo.openToRideSharing = openRideSharing;
      params.booking.carSharingInfo.pickUp = {
        address: crossingAddress,
        coordinates: _get(crossingAddress, 'coordinates') || _get(currentBooking, 'carSharingInfo.pickUp.coordinates'),
        date: crossingDateFormat
      };
    }

    if (currentBooking.rideSharingInfo) {
      params.booking.rideSharingInfo = currentBooking.rideSharingInfo;
    }

    let sendParams = params.booking;

    dispatch({
      type: types.BOOKING_CONFIRM_REQUEST,
      sendParams
    });

    return dispatch(callApi('booking', 'update', params));
  };
}

export function callEditBookingSuccess(confirmedBooking) {
  return {
    type: types.BOOKING_CONFIRM_SUCCESS,
    confirmedBooking
  };
}

export function setBookingToCancel(bookingId) {
  return {
    type: types.BOOKING_SET_BOOKING_CANCEL,
    bookingId
  };
}

export function resetSearchContext() {
  return {
    type: types.BOOKING_SEARCH_CONTEXT_RESET
  };
}

export function setBookingAllFiltersSelected(allFiltersSelected) {
  return {
    type: types.BOOKING_SET_BOOKING_ALL_FILTERS_SELECTED,
    allFiltersSelected
  };
}

export function resetBookingAllFiltersSelected() {
  return {
    type: types.BOOKING_RESET_BOOKING_ALL_FILTERS_SELECTED
  };
}

export function setBookingFacets(facets) {
  return {
    type: types.BOOKING_SET_BOOKING_FACETS,
    facets
  };
}

export function openDamageReportingModal(booking) {
  return {
    type: types.BOOKING_DAMAGE_REPORTING_MODAL_OPEN,
    booking
  };
}

export function closeDamageReportingModal() {
  return {
    type: types.BOOKING_DAMAGE_REPORTING_MODAL_CLOSE
  };
}

export function getBookingFeedBack(bookingId) {
  return dispatch => {
    dispatch({
      type: types.BOOKING_GET_BOOKING_FEEDBACK_REQUEST,
      bookingId
    });

    return dispatch(callApi('booking', 'getFeedBack', bookingId)).then(function(data) {
      dispatch({
        type: types.BOOKING_GET_BOOKING_FEEDBACK_SUCCESS,
        params: data
      });
      return data;
    });
  };
}

export function requestCreateFeedBack(bookingId) {
  return (dispatch, getState) => {
    const state = getState();

    const { reportDamage, damageType, message, comment, cleanlinessInside, cleanlinessOutside } = trimValues(
      state.form.damageReportingForm
    );

    let params = {
      reportDamage,
      damageType,
      message,
      comment,
      cleanlinessInside,
      cleanlinessOutside
    };

    let feedBack = trimValues(state.booking.feedBack);

    dispatch({
      type: types.BOOKING_GET_BOOKING_FEEDBACK_REQUEST,
      params
    });

    if (!feedBack) {
      return dispatch(callApi('booking', 'createFeedBack', { params, bookingId }));
    } else {
      return dispatch(callApi('booking', 'updateFeedBack', { params, bookingId }));
    }
  };
}
