import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import { routeActions } from 'react-router-redux';
import Modal from 'react-modal';
import _ from 'lodash';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import BookingCar from '../../components/BookingCar/BookingCar';
import FormRow from '../../components/FormRow/FormRow';
import EkButton from '../../components/EkButton/EkButton';
import LabeledCheckbox from '../../components/LabeledCheckbox/LabeledCheckbox';
import BoxedInput from '../../components/BoxedInput/BoxedInput';
import BoxedSelect from '../../components/BoxedSelect/BoxedSelect';
import AddressAutocomplete from '../../components/AddressAutocomplete/AddressAutocomplete';
import BaseTitle from '../../components/BaseTitle/BaseTitle';
import FieldErrorMsg from '../../components/FieldErrorMsg/FieldErrorMsg';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import SearchBookingForm from '../../components/SearchBookingForm/SearchBookingForm';
import EkRadio from '../../components/EkRadio/EkRadio';
import config from '../../constants/config-constants';
import {
  addFlashMessage,
  resetBookingSearch,
  requestBookingSearch,
  callBookingSearchSuccess,
  requestEditBooking,
  callEditBookingSuccess,
  resetSearchContext
} from '../../actions/all-actions';
import { createValidator, notEmpty, stopValidationIf } from '../../validation/all-validation';

import {
  addErrorMessage,
  dateFromString,
  namedCompose,
  rideSharingDateValid,
  scrollToFirstError
} from '../../utils/utils';
import routes from '../../constants/routes-constants';
import TripDetails from '../../components/TripDetails/TripDetails';
import { addressOrSite } from '../../validation/sync-validation';
import { autocompletePlaceholderMessages } from '../../constants/options-constants';
import { bundleSelector } from '../../selectors/all-selectors';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class EditBooking extends Component {
  constructor() {
    super();
    this.handleEditBooking = this.handleEditBooking.bind(this);
    this.handleUpdateSearch = this.handleUpdateSearch.bind(this);
    this.handleResetSearch = this.handleResetSearch.bind(this);
    this.handleBackLink = this.handleBackLink.bind(this);
    this.handleCancelAction = this.handleCancelAction.bind(this);
    this.renderVehiclesList = this.renderVehiclesList.bind(this);
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(resetSearchContext());
    dispatch(resetBookingSearch());
  }

  handleEditBooking() {
    const { dispatch, currentBooking, bookingToCancel, bundle } = this.props;

    if (!rideSharingDateValid(this.props, dispatch)) return false;

    dispatch(requestEditBooking(bookingToCancel))
      .then(booking => {
        // check if all data is present for BookingSuccess.jsx
        if (!_.get(booking, 'start.date')) booking = _.set(booking, 'start.date', _.get(currentBooking, 'start.date'));
        if (!_.get(booking, 'end.date')) booking = _.set(booking, 'end.date', _.get(currentBooking, 'end.date'));
        if (!_.get(booking, 'id')) booking = _.set(booking, 'id', _.get(currentBooking, 'id'));

        dispatch(callEditBookingSuccess(booking));
        dispatch(routeActions.push(routes.bookingSuccess.path));
      })
      .catch(error => {
        dispatch(
          addErrorMessage({
            error,
            bundle,
            errorCodePrefixes: ['booking_', 'bookingResult_'],
            def: 'booking_confirm_failed'
          })
        );
      });
  }

  handleUpdateSearch(values) {
    const { dispatch, bookingToCancel } = this.props;
    values.originalBookingId = bookingToCancel;
    dispatch(requestBookingSearch(values)).then(list => {
      dispatch(callBookingSearchSuccess(list));
    });
  }

  handleResetSearch() {
    const { dispatch } = this.props;
    dispatch(resetBookingSearch());
  }

  handleBackLink() {
    const { dispatch } = this.props;
    dispatch(routeActions.goBack());
  }

  handleCancelAction() {
    const { dispatch } = this.props;
    dispatch(routeActions.push(routes.myBookings.path));
  }

  renderVehiclesList(vehicles) {
    const oneWayTrip = _.get(this.props, 'searchContext.oneWayTrip');

    let bookingResultItems;
    bookingResultItems = vehicles.map(function(item, index) {
      return (
        <li className="bookingResultItem" key={item._id}>
          <BookingCar item={item} index={index} isEdited oneWayTrip={oneWayTrip} />
        </li>
      );
    });

    return bookingResultItems;
  }

  render() {
    const {
      fields: {
        message,
        sendSMS,
        cgv,
        openRideSharing,
        availableSeats,
        crossingAddress,
        pickupDate,
        pickupDateHour,
        pickupDateMin
      },
      currentBooking,
      lists,
      searchContext,
      companyInfos,
      handleSubmit,
      intl: { formatMessage }
    } = this.props;

    // fix issue when box is not selected
    if (_.isNumber(_.get(availableSeats, 'value'))) availableSeats.value = `${_.get(availableSeats, 'value')}`;

    let initialValues;
    if (searchContext !== null) {
      initialValues = {
        from: searchContext.from,
        to: searchContext.to,
        pickupDate: new Date(searchContext.pickupDate),
        pickupDateHour: searchContext.pickupDateHour,
        pickupDateMin: searchContext.pickupDateMin,
        returnDateHour: searchContext.returnDateHour,
        returnDateMin: searchContext.returnDateMin,
        passengers: searchContext.passengers,
        oneWayTrip: searchContext.oneWayTrip
      };

      if (searchContext.returnDate) {
        initialValues.returnDate = new Date(searchContext.returnDate);
      }
    } else {
      let startAddress = {
        _isSite: true,
        address: _.get(currentBooking, 'start.parking.site.address'),
        id: _.get(currentBooking, 'start.parking.site.id'),
        name: _.get(currentBooking, 'start.parking.site.name'),
        label: _.get(currentBooking, 'start.parking.site.name'),
        placeId: _.get(currentBooking, 'start.parking.site.name')
      };

      let endAddress = {
        _isSite: true,
        address: _.get(currentBooking, 'end.parking.site.address'),
        id: _.get(currentBooking, 'end.parking.site.id'),
        name: _.get(currentBooking, 'end.parking.site.name'),
        label: _.get(currentBooking, 'end.parking.site.name'),
        placeId: _.get(currentBooking, 'end.parking.site.name')
      };

      const bookingStartDate = dateFromString(currentBooking.start.date);

      initialValues = {
        from: startAddress,
        to: endAddress,
        pickupDate: bookingStartDate,
        pickupDateHour:
          bookingStartDate.getHours() > 9 ? bookingStartDate.getHours() : '0' + bookingStartDate.getHours(),
        pickupDateMin: bookingStartDate.getMinutes() > 0 ? bookingStartDate.getMinutes() : '00',
        passengers: currentBooking.reservedSeats
      };

      if (currentBooking.end.date) {
        const bookingEndDate = dateFromString(currentBooking.end.date);

        initialValues.returnDate = bookingEndDate;
        initialValues.returnDateHour =
          bookingEndDate.getHours() > 9 ? bookingEndDate.getHours() : '0' + bookingEndDate.getHours();
        initialValues.returnDateMin = bookingEndDate.getMinutes() > 0 ? bookingEndDate.getMinutes() : '00';
        initialValues.oneWayTrip = false;
      } else {
        initialValues.oneWayTrip = true;
      }
    }

    const maxNumberOfSeats = currentBooking.vehicle.seats - currentBooking.reservedSeats;
    const availableSeatsOptions = [];
    for (let i = 0; i < maxNumberOfSeats; i++) {
      let number = (i + 1).toString();
      let seatOption = {
        label: number,
        value: number
      };
      availableSeatsOptions.push(seatOption);
    }

    const now = new Date();
    let maxDateDefault = new Date();
    maxDateDefault.setFullYear(now.getFullYear() + 100);

    const pickupDateStart = new Date(currentBooking.start.date);
    const pickupDateEnd = new Date(currentBooking.end.date);

    const submitForm = handleSubmit(this.handleEditBooking);

    return (
      <div className="EditBookingPage">
        <Header />
        <FlashMessageDisplayer />

        <div className="mainContent">
          <div className="mainContent_inner">
            <button className="BookingSummaryPage_backlink" onClick={this.handleBackLink}>
              <FormattedMessage id="booking_edit_backlink" />
            </button>

            <div className="editBooking">
              <div className="searchBooking_wrapper">
                <SearchBookingForm
                  onCallback={this.handleUpdateSearch}
                  serverError={this.props.searchError}
                  initialValues={initialValues}
                  disableVoucher
                />
              </div>

              <BaseTitle customClass="editBooking_subtitle" title="booking_summary_subtitle" />

              <form action="#" method="post" className="editBookingForm" onSubmit={submitForm} noValidate>
                <TripDetails item={currentBooking} />

                <section className="editBooking_section">
                  <div className="editBooking_details">
                    <h3 className="editBooking_section_title">
                      <FormattedMessage id="booking_summary_othersubtitle" />
                    </h3>

                    <BookingCar item={currentBooking} isCurrentBooking />
                  </div>
                </section>

                <section className="bookingSummary_section">
                  <LabeledCheckbox
                    formRowItemKey="bookingSummary_openRideSharing"
                    id="openRideSharing"
                    customClass="bookingSummary_openRideSharingOption"
                    field={openRideSharing}
                  >
                    <FormattedMessage id="booking_summary_propose_rideSharing" />
                  </LabeledCheckbox>

                  {_.get(openRideSharing, 'value') && (
                    <EkRadio
                      formRowItemKey="bookingSummary_availableSeats"
                      customContainerClass="bookingSummary_ekRadioContainer"
                      customWrapperClass="bookingSummary_ekRadioWrapper"
                      customTitleClass="bookingSummary_ekRadioTitle"
                      titleKey="bookingSummary_availableSeats"
                      items={availableSeatsOptions}
                      namespace="availableSeats"
                      field={availableSeats}
                      isEkRadioAlt
                    >
                      <FieldErrorMsg field={availableSeats} customClass="fieldErrorMsg--bookingSummary" />
                    </EkRadio>
                  )}

                  {_.get(openRideSharing, 'value') && (
                    <div className="bookingSummary_crossingPoint">
                      <h3 className="bookingSummary_section_title">
                        <FormattedMessage id="bookingSummary_crossingPoint" />
                      </h3>

                      <FormRow customWrapperClass="bookingSummary_row editBooking_row_rideSharing">
                        <label formRowItemKey="bookingSummary_address_label" className="boxedInput_label">
                          <AddressAutocomplete
                            field={crossingAddress}
                            placeholder={formatMessage(autocompletePlaceholderMessages.generic)}
                            className="bookingSummary_AddressAutocomplete"
                          />

                          <FieldErrorMsg field={crossingAddress} customClass="fieldErrorMsg--bookingSummary" />
                        </label>

                        <BoxedInput
                          formRowItemKey="bookingSummary_address_pickupDate"
                          skinType="date"
                          type="date"
                          customClass="boxedInputWrapper--label"
                          customLabelTextClass="bookingSummary_boxedInputLabel"
                          labelKey="bookingSummary_pickup"
                          minDate={pickupDateStart}
                          maxDate={pickupDateEnd}
                          field={pickupDate}
                          hideLabel
                        >
                          <FieldErrorMsg field={pickupDate} customClass="fieldErrorMsg--bookingSummary" />
                        </BoxedInput>

                        <BoxedSelect
                          formRowItemKey="bookingSummary_address_pickupDateHour"
                          labelKey="bookingSummary_pickup_hour"
                          options={config.availableHours}
                          customClass="bookingSummary_boxedSelectWrapper"
                          customLabelClass="bookingSummary_boxedSelectLabel"
                          hideLabel
                          field={pickupDateHour}
                        >
                          <FieldErrorMsg field={pickupDateHour} customClass="fieldErrorMsg--bookingSummary" />
                        </BoxedSelect>

                        <BoxedSelect
                          formRowItemKey="bookingSummary_address_pickupDateMin"
                          labelKey="bookingSummary_pickup_min"
                          options={config.availableMinutes}
                          customClass="bookingSummary_boxedSelectWrapper"
                          customLabelClass="bookingSummary_boxedSelectLabel"
                          field={pickupDateMin}
                          hideLabel
                        >
                          <FieldErrorMsg field={pickupDateMin} customClass="fieldErrorMsg--bookingSummary" />
                        </BoxedSelect>
                      </FormRow>
                    </div>
                  )}
                </section>

                <section className="editBooking_section">
                  <h3 className="editBooking_section_title">
                    <FormattedMessage id="booking_summary_form_title" />
                  </h3>

                  <FormRow customClass="editBooking_row">
                    <label formRowItemKey="editBooking_message_label" htmlFor="message">
                      <textarea
                        className="editBooking_ekTextarea"
                        name="message"
                        id="message"
                        rows="3"
                        placeholder="My message..."
                        {...message}
                      />
                    </label>
                  </FormRow>

                  <FormRow customClass="editBooking_row">
                    <LabeledCheckbox formRowItemKey="editBooking_sendSMS" id="sendSMS" field={sendSMS}>
                      <FormattedMessage id="booking_summary_send_note_sms" />
                    </LabeledCheckbox>
                  </FormRow>

                  <FormRow customClass="editBooking_row editBooking_cgv">
                    <LabeledCheckbox formRowItemKey="editBooking_cgv" id="cgv" field={cgv}>
                      <FormattedMessage
                        id="booking_summary_cgv"
                        values={{
                          cguLink: (
                            <a target="_blank" href={companyInfos.termsOfUseUrl}>
                              <FormattedMessage id="booking_summary_cgu_link" />
                            </a>
                          )
                        }}
                      />
                    </LabeledCheckbox>
                  </FormRow>

                  <FieldErrorMsg field={cgv} customClass="fieldErrorMsg--subscribeForm" />
                </section>

                <FormRow customClass="editBooking_row editBooking_row_actions">
                  <EkButton
                    formRowItemKey="editBooking_cancelBtn"
                    type="button"
                    customClass="editBooking_actionsButton"
                    skinType="reverse"
                    onAction={this.handleCancelAction}
                    fullWidth
                  >
                    <FormattedMessage id="bookingCar_cancel_button" />
                  </EkButton>

                  <EkButton
                    formRowItemKey="editBooking_submitBtn"
                    type="button"
                    customClass="editBooking_actionsButton submit"
                    onAction={submitForm}
                    fullWidth
                  >
                    <FormattedMessage id="booking_summary_confirm_button" />
                  </EkButton>
                </FormRow>
              </form>
            </div>
          </div>
        </div>

        <Footer />

        {lists === null ? (
          ''
        ) : (
          <Modal isOpen style={modalStyles}>
            <button className="EditBookingPage_closeModal" onClick={this.handleResetSearch} />
            {lists.all.length > 0 ? (
              <ul className="bookingResultItems EditBookingPage_bookingResultItems">
                {this.renderVehiclesList(lists.all)}
              </ul>
            ) : (
              <div>
                <FormattedMessage id="myBookings_no_bookings_found" />
              </div>
            )}
          </Modal>
        )}
      </div>
    );
  }
}

EditBooking.displayName = 'EditBooking';

EditBooking.propTypes = {
  handleSubmit: PropTypes.func
};

EditBooking = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'editBooking',
    fields: [
      'message',
      'sendSMS',
      'cgv',
      'openRideSharing',
      'availableSeats',
      'crossingAddress',
      'pickupDate',
      'pickupDateHour',
      'pickupDateMin'
    ],
    validate: createValidator({
      cgv: [notEmpty()],
      availableSeats: [
        stopValidationIf({
          condition: props => {
            return !_.get(props, 'form.openRideSharing.value');
          }
        }),
        notEmpty()
      ],
      crossingAddress: [
        stopValidationIf({
          condition: props => {
            return !_.get(props, 'form.openRideSharing.value');
          }
        }),
        addressOrSite()
      ],
      pickupDate: [
        stopValidationIf({
          condition: props => {
            return !_.get(props, 'form.openRideSharing.value');
          }
        }),
        notEmpty()
      ],
      pickupDateHour: [
        stopValidationIf({
          condition: props => {
            return !_.get(props, 'form.openRideSharing.value');
          }
        }),
        notEmpty()
      ],
      pickupDateMin: [
        stopValidationIf({
          condition: props => {
            return !_.get(props, 'form.openRideSharing.value');
          }
        }),
        notEmpty()
      ]
    })
    // asyncBlurFields is needed if we want an async validator on blur. Use onSubmit validation otherwise.
  },
  state => {
    // mapStateToProps
    let bookingInfos = state.booking.currentBooking;
    let pickUpMin = '';
    if (_.get(bookingInfos, 'carSharingInfo.pickUp')) {
      let minutes = new Date(bookingInfos.carSharingInfo.pickUp.date).getMinutes();
      pickUpMin = minutes === 0 ? '00' : minutes;
    }

    return {
      // Here to fix issue with form initialisation & validators
      initialValues: {
        message: !bookingInfos.comment ? '' : bookingInfos.comment,
        openRideSharing: !bookingInfos.carSharingInfo.openToRideSharing ? '' : true,
        availableSeats: !bookingInfos.carSharingInfo.availableSeats ? '' : bookingInfos.carSharingInfo.availableSeats,
        crossingAddress: !bookingInfos.carSharingInfo.pickUp ? '' : bookingInfos.carSharingInfo.pickUp.address,
        pickupDate: !bookingInfos.carSharingInfo.pickUp ? '' : dateFromString(bookingInfos.carSharingInfo.pickUp.date),
        pickupDateHour: !bookingInfos.carSharingInfo.pickUp
          ? ''
          : dateFromString(bookingInfos.carSharingInfo.pickUp.date).getHours(),
        pickupDateMin: pickUpMin
      }
    };
  }
)(EditBooking);

export default namedCompose(
  connect(state => {
    const {
      booking: { currentBooking, lists, bookingToCancel, searchContext },
      company: { companyInfos }
    } = state;

    return {
      currentBooking,
      lists,
      bookingToCancel,
      searchContext,
      companyInfos,
      bundle: bundleSelector(state)
    };
  }),
  injectIntl
)(EditBooking);
