import React, { Component, PropTypes } from 'react';
import { reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import BaseTitle from '../../components/BaseTitle/BaseTitle';
import FormRow from '../../components/FormRow/FormRow';
import BoxedInput from '../../components/BoxedInput/BoxedInput';
import EkButton from '../../components/EkButton/EkButton';
import FieldErrorMsg from '../../components/FieldErrorMsg/FieldErrorMsg';

import { createValidator, notEmpty, password, length, match } from '../../validation/all-validation';
import { togglePasswordVisibility } from '../../actions/all-actions';
import { PASSWORD_CONFIRM_FIELD_NAME, PASSWORD_FIELD_NAME } from '../../constants/generic-constants';
import autoBind from 'react-autobind';
import { whiteBrand } from '../../constants/style-constants';
import RaisedButton from 'material-ui/lib/raised-button';

class NewPasswordForm extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    if (this.props.whiteBrand) {
      const { colour1, colour2, colour3 } = this.props.customizations || {};
      const styles = whiteBrand.component.newPassword;
      this.buttonStyles = styles.button;
      this.mainColor = colour1 || colour2 || colour3 || whiteBrand.colors.default;
    }
  }

  handleTogglePasswordVisibility() {
    const { dispatch } = this.props;
    dispatch(togglePasswordVisibility());
  }

  whiteBrandButton(buttonTitle, onAction) {
    return (
      <RaisedButton
        onMouseUp={onAction}
        label={<FormattedMessage id={buttonTitle} />}
        labelStyle={this.buttonStyles.label}
        style={this.buttonStyles.root}
        backgroundColor={this.mainColor}
      />
    );
  }

  regularButton(buttonTitle, onAction) {
    return (
      <EkButton
        formRowItemKey="newPassword_submitBtn"
        type="submit"
        customClass="newPassword_actionsButton"
        onAction={onAction}
      >
        <FormattedMessage id={buttonTitle} />
      </EkButton>
    );
  }

  buttonContainer(buttonTitle, onAction) {
    const reactButton = this.props.whiteBrand ? this.whiteBrandButton : this.regularButton;
    return reactButton(buttonTitle, onAction);
  }

  render() {
    const {
      fields: { password, confirmationPassword },
      handleSubmit,
      showPassword,
      formSubmit
    } = this.props;
    const submitAction = handleSubmit(formSubmit);

    return (
      <form className="newPassword" action="#" onSubmit={submitAction} noValidate>
        <section className="newPassword_section">
          <BaseTitle skinType="variant1" title="resetPassword_new_title" />
          <div className="newPassword_container">
            <FormRow customClass="newPassword_row">
              <BoxedInput
                formRowItemKey="newPassword_password"
                type="password"
                id="password"
                placeholder="New password"
                labelKey="resetPassword_new_label_password"
                customClass="newPassword_boxedInput"
                actionText="loginForm_password_actionText"
                showPassword={showPassword}
                onAction={this.handleTogglePasswordVisibility}
                field={password}
                mandatory
              >
                <FieldErrorMsg field={password} customClass="error-msg" />
              </BoxedInput>
              <BoxedInput
                formRowItemKey="newPassword_confirmPassword"
                type="password"
                id="confirmPassword"
                placeholder="Confirm password"
                labelKey="resetPassword_new_label_confirm"
                customClass="newPassword_boxedInput"
                actionText="loginForm_password_actionText"
                field={confirmationPassword}
                mandatory
              >
                <FieldErrorMsg field={confirmationPassword} customClass="error-msg" />
              </BoxedInput>
            </FormRow>
            <FormRow customClass="newPassword_row newPassword_buttonContainer">
              {this.buttonContainer('ekButton_defaultSaveButton', submitAction)}
            </FormRow>
          </div>
        </section>
      </form>
    );
  }
}

NewPasswordForm.defaultProps = {
  formSubmit: () => false,
  whiteBrand: false
};

NewPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  whiteBrand: PropTypes.bool
};

const validation = {
  form: 'newPassword',
  fields: [PASSWORD_FIELD_NAME, PASSWORD_CONFIRM_FIELD_NAME],
  validate: createValidator({
    password: [notEmpty(), password(), length({ min: 8, max: 25 })],
    confirmationPassword: [notEmpty(), password(), length({ min: 8, max: 25 }), match('password')]
  })
};

const mapStateToProps = state => {
  const {
    user: { showPassword },
    company: { customizations }
  } = state;

  let initialValues = {
    password: '',
    confirmationPassword: ''
  };

  return {
    initialValues,
    showPassword,
    customizations
  };
};

NewPasswordForm.displayName = 'NewPasswordForm';
export default reduxForm(validation, mapStateToProps)(NewPasswordForm);
