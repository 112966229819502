import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SubscribeForm from '../../components/SubscribeForm/SubscribeForm';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import { requestSubscribe, callSubscribeSuccess, callPreSubscribeSuccess, setToken } from '../../actions/all-actions';
import routes from '../../constants/routes-constants';
import _get from 'lodash/object/get';

import { addErrorMessage, namedCompose } from '../../utils/utils';
import { bundleSelector } from '../../selectors/all-selectors';
import { MEMBER_STATUS_TO_COMPLETE } from '../../constants/backend-constants';

class Subscribe extends Component {
  constructor(props) {
    super(props);

    const { userInfo, status: rawStatus } = this.props;
    let { status } = userInfo || {};
    status = status || rawStatus;

    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.onboardingMember = status === MEMBER_STATUS_TO_COMPLETE;
    const isToken = localStorage.getItem('token');
    if (!isToken) props.dispatch(setToken(isToken));
    this.showCarSharingForm = !!(props.businessCarSharing || props.privateCarSharing);
    this.initialValues = {
      login: props.initialLoginValue || '',
      carSharingEnabled: this.showCarSharingForm
    };
    if (props.userInfo) {
      this.initialValues = {
        ...this.initialValues,
        firstName: props.userInfo.firstName || '',
        lastName: props.userInfo.lastName || '',
        phoneNumber: _get(props.userInfo, 'phoneNumber.nationalNumber', ''),
        mobilePhonePrefix: _get(props.userInfo, 'phoneNumber.countryCode', '')
      };
    }
    if (props.subscribeFormStoredValues) {
      this.initialValues = { ...this.initialValues, ...props.subscribeFormStoredValues };
    }

    if (props.locale) this.initialValues.lang = props.locale;
    if (props.status === MEMBER_STATUS_TO_COMPLETE) this.edit = true;
  }

  handleSubscribe() {
    const { dispatch, usedByBackUser, bundle, userInfo, status } = this.props;
    const _status = _get(userInfo, 'status') || status || {};
    const isToCompleteStatus = _status === MEMBER_STATUS_TO_COMPLETE;

    dispatch(requestSubscribe(usedByBackUser, isToCompleteStatus ? userInfo : undefined)).then(
      respUserInfo => {
        const _promises = [];
        isToCompleteStatus
          ? _promises.push(dispatch(callSubscribeSuccess(respUserInfo)))
          : _promises.push(dispatch(callPreSubscribeSuccess(respUserInfo, isToCompleteStatus)));

        _promises.push(dispatch(routeActions.push(routes.subscribeSuccess.path)));
        Promise.all(_promises);
      },
      error => dispatch(addErrorMessage({ error, bundle, def: 'subscribeForm_subscribe_error' }))
    );
  }

  render() {
    return (
      <div className="subscribePage">
        <Header />
        <FlashMessageDisplayer />
        <div className="mainContent">
          <div className="mainContent_innerAlt">
            <div className="subscribeBox">
              <SubscribeForm
                onCallback={this.handleSubscribe}
                subscribeError={this.props.subscribeError}
                usedByBackUser={this.props.usedByBackUser}
                initialValues={this.initialValues}
                edit={this.onboardingMember || this.edit}
                showCarSharingForm={this.showCarSharingForm}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default namedCompose(
  connect(state => {
    const {
      user: { subscribeError, usedByBackUser, initialLoginValue, subscribeFormStoredValues, userInfo, status },
      company: {
        companyInfos: {
          contract: { businessCarSharing, privateCarSharing }
        }
      },
      i18n: { locale }
    } = state;

    return {
      userInfo,
      subscribeError,
      usedByBackUser,
      businessCarSharing,
      status,
      privateCarSharing,
      initialLoginValue,
      subscribeFormStoredValues,
      locale,
      bundle: bundleSelector(state)
    };
  })
)(Subscribe);
