import * as types from '../../constants/actionTypes-constants';
import callApi from '../../actions/api-actions';
import _get from 'lodash/object/get';
import {
  VALIDATION_DRIVER_LICENCE_INVALID_MIME_TYPE,
  VALIDATION_DRIVER_LICENCE_SIZE_TOO_LARGE
} from '../../constants/errors-constants';
import { ERROR, UPLOADING, SUCCESS } from '../../constants/generic-constants';
import config from '../../constants/config-constants';
import { stripMimeType } from '../../utils/utils';

export function addUploadFile(data) {
  return {
    type: types.UPLOAD_FILE_REQUEST,
    file: data
  };
}

export function successUploadFile(data) {
  return {
    type: types.UPLOAD_FILE_SUCCESS,
    file: data
  };
}

export function errorUploadFile(data) {
  return {
    type: types.UPLOAD_FILE_ERROR,
    file: data
  };
}

export function hideUpload() {
  return {
    type: types.UPLOAD_FILE_CLOSE
  };
}

export function uploadFile(params, field) {
  return dispatch => {
    const id = _get(params, 'id');
    const content = _get(params, 'content');

    const simplifiedParams = {
      id,
      name: params.name,
      mimeType: params.mimeType,
      content: stripMimeType(content),
      status: UPLOADING
    };

    if (params.mimeType !== 'image/jpeg' && params.mimeType !== 'image/png' && params.mimeType !== 'application/pdf') {
      simplifiedParams.status = ERROR;
      simplifiedParams.errorMessage = VALIDATION_DRIVER_LICENCE_INVALID_MIME_TYPE;
      return dispatch(errorUploadFile(simplifiedParams));
    }

    if (params.size > config.maxDriverLicenceSize) {
      simplifiedParams.status = ERROR;
      simplifiedParams.errorMessage = VALIDATION_DRIVER_LICENCE_SIZE_TOO_LARGE;
      return dispatch(errorUploadFile(simplifiedParams));
    }

    dispatch(addUploadFile(simplifiedParams));

    dispatch(callApi('files', 'uploadFile', params)).then(uploadedFile => {
      const respId = _get(uploadedFile, 'id');

      const params = {
        ...simplifiedParams,
        fileId: respId,
        status: SUCCESS
      };

      field.value = respId;
      field.onChange(field);

      dispatch(successUploadFile(params));
    });
  };
}
