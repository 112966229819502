import * as types from '../constants/actionTypes-constants';

//---------------------------------------------
// Result booking map
//---------------------------------------------

export function toggleMapOpenedState() {
  return {
    type: types.GOOGLE_MAP_OPENED_STATE_TOGGLE
  };
}

export function getActiveMarkerIndex(index) {
  return {
    type: types.GOOGLE_MAP_ACTIVE_MARKER_INDEX_UPDATE,
    index
  };
}

export function setMapPosition({ center, zoom }) {
  return {
    type: types.GOOGLE_MAP_POSITION_MARKER_SET,
    center,
    zoom
  };
}

export function setMapResetFlag(flag) {
  return {
    type: types.GOOGLE_MAP_RESET_FLAG_SET,
    flag
  };
}

export function unsetMapResetFlag(flag) {
  return {
    type: types.GOOGLE_MAP_RESET_FLAG_UNSET,
    flag
  };
}
