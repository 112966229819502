import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';
import classNames from 'classnames';
import _get from 'lodash/object/get';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import BookingCar from '../../components/BookingCar/BookingCar';
import EkButton from '../../components/EkButton/EkButton';
import BaseTitle from '../../components/BaseTitle/BaseTitle';
import TabsItems from '../../components/TabsItems/TabsItems';
import BookingsMap from '../../components/BookingsMap/BookingsMap';
import SearchBookingForm from '../../components/SearchBookingForm/SearchBookingForm';
import FiltersForm from '../../components/FiltersForm/FiltersForm';
import {
  updateBookingResultActiveTabIndex,
  increaseBookingResultCarSharingBusinessMaxItems,
  increaseBookingResultCarSharingPrivateMaxItems,
  increaseBookingResultRideSharingMaxItems,
  toggleMapOpenedState,
  toggleBookingResultSearchOpenedState
} from '../../actions/all-actions';
import routes from '../../constants/routes-constants';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import { bookingListUniqueSelector } from '../../selectors/all-selectors';

class BookingResult extends Component {
  constructor() {
    super();
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleDisplayCarSharingBusinessMoreResults = this.handleDisplayCarSharingBusinessMoreResults.bind(this);
    this.handleDisplayCarSharingPrivateMoreResults = this.handleDisplayCarSharingPrivateMoreResults.bind(this);
    this.handleDisplayRideSharingMoreResults = this.handleDisplayRideSharingMoreResults.bind(this);
    this.handleDisplayBookingResultsMap = this.handleDisplayBookingResultsMap.bind(this);
    this.handleDisplayBookingResultsSearch = this.handleDisplayBookingResultsSearch.bind(this);
    this.handleUpdateSearch = this.handleUpdateSearch.bind(this);
    this.handleFilteredSearch = this.handleFilteredSearch.bind(this);
    this.handleFilteredResetSearch = this.handleFilteredResetSearch.bind(this);
    this.renderVehiclesList = this.renderVehiclesList.bind(this);
    this.hasRideSharing = this.hasRideSharing.bind(this);
    this.hasBusinessCarSharing = this.hasBusinessCarSharing.bind(this);
    this.hasPrivateCarSharing = this.hasPrivateCarSharing.bind(this);
    this.hasResults = this.hasResults.bind(this);
  }

  renderVehiclesList(vehicles, maxItems) {
    const oneWayTrip = _get(this.props, 'searchContext.oneWayTrip');

    let bookingResultItems;
    let vehiclesRestrictedList = vehicles.slice(0, maxItems);

    if (vehiclesRestrictedList) {
      bookingResultItems = vehiclesRestrictedList.map(function(item, index) {
        return (
          <li className="bookingResultItem" key={item._id}>
            <BookingCar item={item} index={index} oneWayTrip={oneWayTrip} />
          </li>
        );
      });
    }

    return bookingResultItems;
  }

  hasRideSharing() {
    const {
      userInfo: {
        company: { contract }
      }
    } = this.props;
    return _get(contract, 'rideSharing');
  }

  hasBusinessCarSharing() {
    const {
      userInfo: {
        company: { contract }
      }
    } = this.props;
    return _get(contract, 'businessCarSharing');
  }

  hasPrivateCarSharing() {
    const {
      userInfo: {
        company: { contract }
      }
    } = this.props;
    return _get(contract, 'privateCarSharing');
  }

  handleChangeTab(tab) {
    const { dispatch } = this.props;
    dispatch(updateBookingResultActiveTabIndex(tab.id));
  }

  handleDisplayCarSharingBusinessMoreResults() {
    const { dispatch } = this.props;
    dispatch(increaseBookingResultCarSharingBusinessMaxItems());
  }

  handleDisplayCarSharingPrivateMoreResults() {
    const { dispatch } = this.props;
    dispatch(increaseBookingResultCarSharingPrivateMaxItems());
  }

  handleDisplayRideSharingMoreResults() {
    const { dispatch } = this.props;
    dispatch(increaseBookingResultRideSharingMaxItems());
  }

  handleDisplayBookingResultsMap() {
    const { dispatch } = this.props;
    dispatch(toggleMapOpenedState());
  }

  handleDisplayBookingResultsSearch() {
    const { dispatch } = this.props;
    dispatch(toggleBookingResultSearchOpenedState());
  }

  handleUpdateSearch(values) {
    const { dispatch } = this.props;

    dispatch(
      routeActions.push(routes.bookingResult.path.replace(':search', encodeURIComponent(JSON.stringify(values))))
    );
  }

  handleFilteredSearch(values) {
    const { dispatch, searchContext } = this.props;

    var filteredQuery = Object.assign({}, searchContext, values);
    dispatch(
      routeActions.push(routes.bookingResult.path.replace(':search', encodeURIComponent(JSON.stringify(filteredQuery))))
    );
  }

  hasResults() {
    const { lists } = this.props;
    if (lists.carSharings.private.length > 0 || lists.carSharings.business.length > 0 || lists.rideSharings.length > 0)
      return true;
    else return false;
  }

  handleFilteredResetSearch() {
    const { dispatch, searchContext } = this.props;

    const searchContextNoFilters = {
      from: searchContext.from,
      oneWayTrip: searchContext.oneWayTrip,
      passengers: searchContext.passengers,
      to: searchContext.to,
      pickupDate: searchContext.pickupDate,
      pickupDateHour: searchContext.pickupDateHour,
      pickupDateMin: searchContext.pickupDateMin,
      returnDate: searchContext.returnDate,
      returnDateHour: searchContext.returnDateHour,
      returnDateMin: searchContext.returnDateMin
    };

    dispatch(
      routeActions.push(
        routes.bookingResult.path.replace(':search', encodeURIComponent(JSON.stringify(searchContextNoFilters)))
      )
    );
  }

  render() {
    const {
      lists,
      bookingResultActiveTabIndex,
      bookingResultCarSharingBusinessMaxItems,
      bookingResultCarSharingPrivateMaxItems,
      bookingResultRideSharingMaxItems,
      bookingResultDisplaySearch,
      searchContext,
      displayMap
    } = this.props;

    const carSharingItemsLength = lists.carSharings.business.length + lists.carSharings.private.length;

    const tabList = [
      { id: 0, name: 'bookingResult_tab_all', numberItems: lists.all.length },
      { id: 1, name: 'bookingResult_tab_carSharing', numberItems: carSharingItemsLength },
      { id: 2, name: 'bookingResult_tab_rideSharing', numberItems: lists.rideSharings.length }
    ];
    let initialValues = {
      from: searchContext.from,
      to: searchContext.to,
      pickupDate: new Date(searchContext.pickupDate),
      pickupDateHour: searchContext.pickupDateHour,
      pickupDateMin: searchContext.pickupDateMin,
      returnDateHour: searchContext.returnDateHour,
      returnDateMin: searchContext.returnDateMin,
      passengers: searchContext.passengers,
      oneWayTrip: searchContext.oneWayTrip,
      voucherCode: searchContext.voucherCode
    };

    if (searchContext.returnDate) {
      initialValues.returnDate = new Date(searchContext.returnDate);
    }

    return (
      <div className="BookingResultPage">
        <Header />

        <div className={classNames('mainContent_searchBooking', { _is_opened: bookingResultDisplaySearch })}>
          <div className="mainContent_searchBookingInner">
            <div className="searchBooking_wrapper">
              <SearchBookingForm
                onCallback={this.handleUpdateSearch}
                serverError={this.props.searchError}
                initialValues={initialValues}
              />
            </div>
          </div>
        </div>

        <div className="searchBooking_buttonWrapper">
          <EkButton
            customClass={classNames('searchBooking_button', 'searchBooking_buttonToggle', {
              _is_opened: bookingResultDisplaySearch
            })}
            onAction={this.handleDisplayBookingResultsSearch}
          >
            <FormattedMessage id="searchBooking_modifyResearch_button" />
          </EkButton>
        </div>

        <div className="mainContent search-result">
          <div className="mainContent_inner">
            <div className="bookingResult">
              {this.hasResults() && (
                <FiltersForm
                  formName="bookingFilters"
                  onCallback={this.handleFilteredSearch}
                  onResetCallBack={this.handleFilteredResetSearch}
                />
              )}

              <div className="bookingResult_container">
                <div className="bookingResult_nav">
                  {this.hasRideSharing() && (this.hasBusinessCarSharing() || this.hasPrivateCarSharing()) && (
                    <div className="bookingResult_navInner">
                      <TabsItems
                        tabList={tabList}
                        onChangeTab={this.handleChangeTab}
                        currentTab={bookingResultActiveTabIndex}
                        actionButtons
                      />
                    </div>
                  )}
                  {this.hasResults() && (
                    <EkButton
                      customClass={`
                    bookingResult_mapButton
                    ekButton--${!displayMap ? 'viewOpen' : 'viewClose'}
                    `}
                      skinType="variant1"
                      onAction={this.handleDisplayBookingResultsMap}
                    >
                      <FormattedMessage
                        id={`${!displayMap ? 'bookingResult_mapOpen_button' : 'bookingResult_mapClose_button'}`}
                      />
                    </EkButton>
                  )}
                </div>

                <div
                  className={`
                bookingResult_content
                ${!displayMap ? 'bookingResult_content--full' : ''}
                `}
                >
                  <div className="bookingResult_listing">
                    {bookingResultActiveTabIndex === tabList[0].id || bookingResultActiveTabIndex === tabList[1].id ? (
                      <section className="bookingResult_section">
                        <BaseTitle customClass="bookingResult_sectionTitle" title="bookingResult_carSharing_title">
                          <span className="bookingResult_number">{carSharingItemsLength}</span>
                        </BaseTitle>

                        {this.hasBusinessCarSharing() && (
                          <div className="bookingResult_sectionBusiness">
                            <BaseTitle
                              customClass="bookingResult_sectionSubtitle"
                              title="bookingResult_carSharing_business_title"
                            />

                            {!lists.carSharings.business.length ? (
                              <FormattedMessage id="bookingResult_noCarSharingBusiness" />
                            ) : (
                              <div>
                                <ul className="bookingResultItems">
                                  {this.renderVehiclesList(
                                    lists.carSharings.business,
                                    bookingResultCarSharingBusinessMaxItems
                                  )}
                                </ul>

                                {lists.carSharings.business.length <= bookingResultCarSharingBusinessMaxItems ? (
                                  ''
                                ) : (
                                  <EkButton
                                    type="button"
                                    customClass="bookingResult_resultsButton"
                                    skinType="variant1"
                                    onAction={this.handleDisplayCarSharingBusinessMoreResults}
                                    fullWidth
                                  >
                                    <FormattedMessage id="bookingResult_moreResult_button" />
                                  </EkButton>
                                )}
                              </div>
                            )}
                          </div>
                        )}

                        {this.hasPrivateCarSharing() && (
                          <div className="bookingResult_sectionPrivate">
                            <BaseTitle
                              customClass="bookingResult_sectionSubtitle"
                              title="bookingResult_carSharing_private_title"
                            />
                            {!lists.carSharings.private.length ? (
                              <FormattedMessage id="bookingResult_noCarSharingPrivate" />
                            ) : (
                              <div>
                                <ul className="bookingResultItems">
                                  {this.renderVehiclesList(
                                    lists.carSharings.private,
                                    bookingResultCarSharingPrivateMaxItems
                                  )}
                                </ul>

                                {lists.carSharings.private.length <= bookingResultCarSharingPrivateMaxItems ? (
                                  ''
                                ) : (
                                  <EkButton
                                    type="button"
                                    customClass="bookingResult_resultsButton"
                                    skinType="variant1"
                                    onAction={this.handleDisplayCarSharingPrivateMoreResults}
                                    fullWidth
                                  >
                                    <FormattedMessage id="bookingResult_moreResult_button" />
                                  </EkButton>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </section>
                    ) : (
                      ''
                    )}

                    {(bookingResultActiveTabIndex === tabList[0].id || bookingResultActiveTabIndex === tabList[2].id) &&
                    this.hasRideSharing() ? (
                      <section className="bookingResult_section">
                        <BaseTitle
                          customClass="bookingResult_sectionTitle"
                          title="bookingResult_rideSharing_title"
                          skinType="variant2"
                        >
                          <span className="bookingResult_number bookingResult_number--variant2">
                            {lists.rideSharings.length}
                          </span>
                        </BaseTitle>

                        {!lists.rideSharings.length && this.hasRideSharing() ? (
                          <FormattedMessage id="bookingResult_noRideSharing" />
                        ) : (
                          <ul className="bookingResultItems">
                            {this.renderVehiclesList(lists.rideSharings, bookingResultRideSharingMaxItems)}
                          </ul>
                        )}

                        {lists.rideSharings.length <= bookingResultRideSharingMaxItems && this.hasRideSharing() ? (
                          ''
                        ) : (
                          <EkButton
                            type="button"
                            customClass="bookingResult_resultsButton"
                            skinType="variant1"
                            onAction={this.handleDisplayRideSharingMoreResults}
                            fullWidth
                          >
                            <FormattedMessage id="bookingResult_moreResult_button" />
                          </EkButton>
                        )}
                      </section>
                    ) : (
                      ''
                    )}
                  </div>

                  {!displayMap || !this.hasResults() ? (
                    ''
                  ) : (
                    <div className="bookingResult_map">
                      <div className="bookingResult_mapInner">
                        <div id="map" className="bookingResult_gmapElement">
                          <BookingsMap bookings={lists.all} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <FlashMessageDisplayer />
        <Footer />
      </div>
    );
  }
}

BookingResult.displayName = 'BookingResult';

BookingResult.propTypes = {
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func
};

export default connect(state => {
  const {
    user: { userInfo },
    booking: {
      bookingResultActiveTabIndex,
      bookingResultCarSharingBusinessMaxItems,
      bookingResultCarSharingPrivateMaxItems,
      bookingResultRideSharingMaxItems,
      bookingResultDisplaySearch,
      searchContext
    },
    googleMap: { displayMap }
  } = state;

  return {
    userInfo,
    lists: bookingListUniqueSelector(state),
    bookingResultActiveTabIndex,
    bookingResultCarSharingBusinessMaxItems,
    bookingResultCarSharingPrivateMaxItems,
    bookingResultRideSharingMaxItems,
    bookingResultDisplaySearch,
    searchContext,
    displayMap
  };
})(BookingResult);
