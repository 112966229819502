import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';

import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator';
import CookieConsent from '../components/CookieConsent/CookieConsent';
import { closeHeaderProfileMenu, closeHeaderLangMenu, closeCookieConsent } from '../actions/all-actions';
import FileUpload from '../components/UploadFile/UploadFile';

class Layout extends Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
    this.handleCookieConsent = this.handleCookieConsent.bind(this);
  }

  handleClick() {
    const { dispatch, profileMenuIsOpen, langMenuIsOpen } = this.props;
    if (profileMenuIsOpen) {
      dispatch(closeHeaderProfileMenu());
    }
    if (langMenuIsOpen) {
      dispatch(closeHeaderLangMenu());
    }
  }

  handleCookieConsent() {
    const { dispatch } = this.props;
    dispatch(closeCookieConsent());
  }

  render() {
    // Check if cookie consent has not already been hidden
    const showCookieConsent =
      localStorage.getItem('hideCookieConsent') === null
        ? this.props.showCookieConsent
        : !localStorage.getItem('hideCookieConsent');

    const {
      page,
      // latestLanguageChangeDate is used to force an update when the language bundle changes
      latestLanguageChangeDate
    } = this.props;

    return (
      <div onClick={this.handleClick} key={latestLanguageChangeDate} className="layout-wrapper">
        <LoadingIndicator />

        {showCookieConsent && <CookieConsent onCallback={this.handleCookieConsent} />}

        {page}
        <FileUpload />
      </div>
    );
  }
}

Layout.displayName = 'Layout';

Layout.propTypes = {
  page: PropTypes.element
};

export default connect(state => {
  const {
    header: { profileMenuIsOpen, langMenuIsOpen },
    i18n: { latestLanguageChangeDate },
    user: { showCookieConsent }
  } = state;

  return {
    profileMenuIsOpen,
    langMenuIsOpen,
    latestLanguageChangeDate,
    showCookieConsent
  };
})(Layout);
