import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormattedTime } from 'react-intl';
import { routeActions } from 'react-router-redux';
import Modal from 'react-modal';
import _get from 'lodash/object/get';
import _map from 'lodash/collection/map';
import autoBind from 'react-autobind';
import classNames from 'classnames';

import BaseTitle from '../BaseTitle/BaseTitle';
import TextIcon from '../TextIcon/TextIcon';
import EkButton from '../EkButton/EkButton';
import DamageReportingModal from '../DamageReportingModal/DamageReportingModal';
import {
  BOOKING_TYPE_RIDE_SHARING,
  BOOKING_STATUS_COMPLETED,
  BOOKING_STATE_PAST,
  BOOKING_STATE_UPCOMING,
  BOOKING_USAGE_TYPE_PRIVATE,
  BOOKING_TYPE_SHUTTLE,
  BOOKING_TYPE_CAR_SHARING
} from '../../constants/backend-constants';
import { refreshPage } from '../../routing/all-routing';
import { FLASH_MESSAGE_TYPE_ERROR } from '../../constants/generic-constants';
import {
  toggleBookingDetailsOpenedState,
  setCurrentBooking,
  openCancelBookingModal,
  closeCancelBookingModal,
  requestBookingCancel,
  callCancelBookingSuccess,
  addFlashMessage,
  resetBookingSearch,
  openPrivatePaymentBookingModal,
  closePrivatePaymentBookingModal,
  openDamageReportingModal,
  getBookingFeedBack
} from '../../actions/all-actions';
import { dateFromString, isEmpty, splitPrice, getAppFormattedDate } from '../../utils/utils';
import routes from '../../constants/routes-constants';
import ReactSVG from 'react-svg';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const modalPrivatePaymentStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '80%',
    transform: 'translate(-50%, -50%)'
  }
};

function displayEndPlace(item, oneWayTrip) {
  const type = _get(item, 'type');
  const startParking = _get(item, 'start.parking.name');
  const endParking = _get(item, 'end.parking.name');
  const startAddress = _get(item, 'start.address.formattedAddress');
  const endAddress = _get(item, 'end.address.formattedAddress');
  const roundTrip = oneWayTrip === false;

  if (type === BOOKING_TYPE_CAR_SHARING && roundTrip) return startParking || startAddress;
  return endParking || endAddress;
}

class BookingCar extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.derivedStateFromProps(props);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    this.setPreBookedVar(props); // 1
    this.setPreviewClassName(); // 2
  }

  setPreviewClassName() {
    this.previewClassName = classNames('bookingCar_preview', { preBooked: this.preBooked });
  }

  setPreBookedVar(props) {
    let { item, isBooked } = props;
    if (!isBooked) return (this.preBooked = false);

    const { carSharingInfo, rideSharingInfo, groupSharingInfo, rrsSharingInfo } = item || {};
    const checkObj = [carSharingInfo, rideSharingInfo, groupSharingInfo, rrsSharingInfo];
    this.preBooked = checkObj.some((info = {}) => info.preBooked);
  }

  hourOfBookingStart(item) {
    return <FormattedTime value={dateFromString(_get(item, 'start.date'))} hour="numeric" minute="numeric" />;
  }

  dayOfBookingEnd(item) {
    return <span>{getAppFormattedDate(_get(item, 'end.date'), { short: true })}</span>;
  }

  hourOfBookingEnd(item) {
    return <FormattedTime value={dateFromString(_get(item, 'end.date'))} hour="numeric" minute="numeric" />;
  }

  dayOfBookingStart(item) {
    return <span>{getAppFormattedDate(_get(item, 'start.date'), { short: true })}</span>;
  }

  handleMoreDetails() {
    const { dispatch, item } = this.props;
    if (item.id) dispatch(toggleBookingDetailsOpenedState(item.id));
    else if (item._id) dispatch(toggleBookingDetailsOpenedState(item._id));
  }

  handleBookButton() {
    const { dispatch, item, paymentInfos } = this.props;
    if (
      item.carSharingInfo &&
      (item.carSharingInfo.usageType === BOOKING_USAGE_TYPE_PRIVATE && paymentInfos === null)
    ) {
      dispatch(openPrivatePaymentBookingModal());
    } else {
      dispatch(setCurrentBooking(item));
      dispatch(routeActions.push(routes.bookingSummary.path));
    }
  }

  displayCarItems() {
    const { userHasReservationsList, item, isCurrentBooking } = this.props;

    let itemList = [];

    const fuelLevel = _get(item, 'vehicle.fuelLevel.percentage');
    const startDate = _get(item, 'start.date');
    const endDate = _get(item, 'end.date');
    const gearBox = _get(item, 'vehicle.transmissionType');
    const avSits = _get(item, 'carSharingInfo.availableSeats');
    const plateNumber = _get(item, 'vehicle.registrationNumber');
    const fuelType = _get(item, 'vehicle.fuelType');
    const carVer = _get(item, 'vehicle.version');

    if (!this.preBooked) {
      if (fuelLevel)
        itemList.push(
          <div>
            <TextIcon iconName="fuel" label={fuelLevel} /> %
          </div>
        );
      if (isCurrentBooking && plateNumber) itemList.push(<TextIcon iconName="licencePlate" label={plateNumber} />);
      if (isCurrentBooking && typeof fuelType === 'string')
        itemList.push(<TextIcon iconName="fuel" labelKey={`bookingResult_fuelType_${fuelType.toLowerCase()}`} />);
      if (isCurrentBooking && carVer) itemList.push(<TextIcon iconName="private" label={carVer} />);
    }

    if (!isCurrentBooking && !userHasReservationsList && startDate) {
      itemList.push(<TextIcon iconName="calendar" label={this.dayOfBookingStart(item)} />);
      itemList.push(<TextIcon iconName="time" label={this.hourOfBookingStart(item)} />);
    }

    if (!isCurrentBooking && userHasReservationsList && startDate) {
      itemList.push(
        <div>
          <TextIcon iconName="calendar" label={this.dayOfBookingStart(item)} />
          <span className="bookingCar_item_separator">
            <FormattedMessage id="bookingCar_item_separator" />
          </span>
          <TextIcon iconName="time" label={this.hourOfBookingStart(item)} />
        </div>
      );
    }

    if (!isCurrentBooking && userHasReservationsList && endDate) {
      itemList.push(
        <div>
          <TextIcon iconName="calendar" label={this.dayOfBookingEnd(item)} />
          <span className="bookingCar_item_separator">
            <FormattedMessage id="bookingCar_item_separator" />
          </span>
          <TextIcon iconName="time" label={this.hourOfBookingEnd(item)} />
        </div>
      );
    }

    if (!this.preBooked) {
      if (gearBox)
        itemList.push(<TextIcon iconName="transmission" labelKey={`bookingResult_transmissionType_${gearBox}`} />);
      if (!isCurrentBooking && avSits) itemList.push(<TextIcon iconName="carSeat" label={avSits} />);
    }

    return (
      <div className="bookingCar_items">
        {_map(itemList, (item, index) => {
          return (
            <div key={index} className="bookingCar_item">
              {item}
            </div>
          );
        })}
      </div>
    );
  }

  handleAbortPrivatePaymentBooking() {
    const { dispatch } = this.props;

    dispatch(closePrivatePaymentBookingModal());
  }

  handleChangeBooking() {
    const { dispatch, item } = this.props;
    dispatch(setCurrentBooking(item));
    dispatch(resetBookingSearch());
    dispatch(routeActions.push(routes.editBooking.path));
  }

  handleBookEditButton() {
    const { dispatch, item } = this.props;
    dispatch(setCurrentBooking(item));
    dispatch(resetBookingSearch());
  }

  handleCancelBooking() {
    const { dispatch, item } = this.props;

    dispatch(openCancelBookingModal(item));
  }

  handleConfirmCancelBooking() {
    const { dispatch, item } = this.props;

    dispatch(closeCancelBookingModal());

    dispatch(requestBookingCancel(item))
      .then(() => {
        dispatch(callCancelBookingSuccess(item));
        refreshPage();
      })
      .catch(error => {
        if (!error.type) {
          // make sure native errors not swallowed
          console.error(error.stack); // eslint-disable-line
        }
        if (error.code === 422) {
          error.body.json().then(data => {
            dispatch(
              addFlashMessage({
                contentKey: `booking_${data.errorCode}`,
                type: FLASH_MESSAGE_TYPE_ERROR
              })
            );
          });
        } else {
          dispatch(
            addFlashMessage({
              contentKey: 'booking_cancel_failed',
              type: FLASH_MESSAGE_TYPE_ERROR
            })
          );
        }
      });
  }

  handleAbortCancelBooking() {
    const { dispatch } = this.props;

    dispatch(closeCancelBookingModal());
  }

  renderVehicleAccessories(vehicle) {
    let vehicleAccessories;
    vehicleAccessories = vehicle.accessories.map(function(item, index) {
      return (
        <li className="bookingCar_accessories_item" key={item}>
          {index === 0 ? '' : ', '}
          <FormattedMessage id={`vehicle_accessories_${item}`} />
        </li>
      );
    });

    return vehicleAccessories;
  }

  handleOpenDamageReportingModal() {
    const { dispatch, item } = this.props;
    dispatch(getBookingFeedBack(item.id)).then(() => {
      dispatch(openDamageReportingModal(item));
    });
  }

  displayMainPriceBlock() {
    const { item, isBooked } = this.props;
    const isPrivateBooking = _get(item, 'carSharingInfo.usageType') === BOOKING_USAGE_TYPE_PRIVATE;
    const costObj = _get(item, 'carSharingInfo.cost');
    const currency = _get(costObj, 'currency', 'EUR');
    const priceBeforeVoucher = _get(costObj, 'estimatedPriceWithoutVoucher');
    const estimatedPrice = _get(costObj, 'estimatedPriceForDuration');
    const pricePerKm = _get(costObj, 'pricePerKm');
    const freeKmPerDay = _get(costObj, 'freeKmPerDay');
    const totalPrice = _get(costObj, 'totalPriceIncludingTaxes');
    const pricePerKmSplit = splitPrice(pricePerKm);
    const priceBeforeVoucherSplit = splitPrice(priceBeforeVoucher);
    const estimatedPriceSplit = splitPrice(estimatedPrice);
    const totalPriceSplit = splitPrice(totalPrice);
    const currencySymbol = <FormattedMessage id={`unit_${currency}`} />;

    if (isBooked)
      return (
        <div>
          {!isEmpty(totalPrice) && isPrivateBooking && (
            <div className="bookingCar_details_pricingTotal">
              <span className="bookingCar_details_price">
                {currencySymbol}
                {totalPriceSplit.int},{totalPriceSplit.dec}
              </span>
            </div>
          )}
        </div>
      );
    else
      return (
        <div>
          <div>
            {priceBeforeVoucher > 0 && (
              <div className="bookingCar_details_pricingTotal">
                <span className="bookingCar_details_priceWithoutVoucher">
                  <sup>{currencySymbol}</sup>
                  {priceBeforeVoucherSplit.int},{priceBeforeVoucherSplit.dec}
                </span>
              </div>
            )}
            {(priceBeforeVoucher > 0 || estimatedPrice > 0) && (
              <div className="bookingCar_details_pricingTotal">
                <span className="bookingCar_details_price">
                  <sup>{currencySymbol}</sup>
                  {estimatedPriceSplit.int},{estimatedPriceSplit.dec}
                </span>
              </div>
            )}
          </div>
          {pricePerKm > 0 && (
            <div className="bookingCar_details_pricingPerKm">
              <span>+ </span>
              <span className="bookingCar_details_price">
                {currencySymbol}
                {pricePerKmSplit.int},{pricePerKmSplit.dec}{' '}
              </span>
              <FormattedMessage id="bookingCar_perKm" />
              {freeKmPerDay > 0 && <span> *</span>}
            </div>
          )}
        </div>
      );
  }

  getCarImage() {
    const { item } = this.props;
    const { vehicle } = item || {};
    const { pictureUrl } = vehicle || {};

    if (this.preBooked) {
      return <ReactSVG src="../img/car-flat.svg" svgClassName="r-svg" />;
    } else {
      return (
        <img
          src={pictureUrl || '../img/navette.svg'}
          className="bookingCar_media_img"
          alt=""
          width="253"
          height="154"
        />
      );
    }
  }

  getCarName() {
    if (!this.preBooked) {
      const { item } = this.props;
      const { vehicle } = item || {};
      const { model, brand } = vehicle || {};

      return (
        <h2 className="bookingCar_details_title">
          <span className="bookingCar_details_titleLabel">
            {brand} {model}
          </span>
        </h2>
      );
    }
  }

  getActions() {
    const { item, isBooked, isCurrentBooking, isEdited, moreDetailsOpenedList } = this.props;
    if (this.preBooked) return;
    if ((isBooked && item.status === 'CANCELED') || isCurrentBooking) return;

    return (
      <div className="bookingCar_actions">
        {isBooked || item.type === BOOKING_TYPE_SHUTTLE
          ? ''
          : [
              moreDetailsOpenedList.indexOf(item._id) !== -1 ? (
                <EkButton
                  type="button"
                  customClass="bookingCar_actionsButton ekButtonToggle _is_opened"
                  skinType={'reverse'}
                  key="button1"
                  onAction={this.handleMoreDetails}
                >
                  <FormattedMessage id="bookingCar_lessDetails_button" />
                </EkButton>
              ) : (
                <EkButton
                  type="button"
                  customClass="bookingCar_actionsButton ekButtonToggle"
                  skinType={'reverse'}
                  key="button1"
                  onAction={this.handleMoreDetails}
                >
                  <FormattedMessage id="bookingCar_moreDetails_button" />
                </EkButton>
              ),
              <EkButton
                type="button"
                customClass="bookingCar_actionsButton"
                key="button2"
                onAction={!isEdited ? this.handleBookButton : this.handleBookEditButton}
              >
                {item.type === BOOKING_TYPE_RIDE_SHARING ? (
                  <FormattedMessage id="bookingCar_bookCar_ride_sharing_button" />
                ) : (
                  <FormattedMessage id="bookingCar_bookCar_car_sharing_button" />
                )}
              </EkButton>
            ]}
        {isBooked &&
        item.state === BOOKING_STATE_UPCOMING &&
        (item.type === BOOKING_TYPE_CAR_SHARING || item.type === BOOKING_TYPE_RIDE_SHARING)
          ? [
              moreDetailsOpenedList.indexOf(item.id) !== -1 ? (
                <EkButton
                  type="button"
                  customClass="bookingCar_actionsButton ekButtonToggle _is_opened"
                  skinType={'reverse'}
                  key="button1"
                  onAction={this.handleMoreDetails}
                >
                  <FormattedMessage id="bookingCar_lessDetails_button" />
                </EkButton>
              ) : (
                <EkButton
                  type="button"
                  customClass="bookingCar_actionsButton ekButtonToggle"
                  skinType={'reverse'}
                  key="button1"
                  onAction={this.handleMoreDetails}
                >
                  <FormattedMessage id="bookingCar_moreDetails_button" />
                </EkButton>
              ),
              !item.allowedActions.cancel ? (
                ''
              ) : (
                <EkButton
                  type="button"
                  customClass="bookingCar_actionsButton"
                  skinType="variant1"
                  key="button2"
                  onAction={this.handleCancelBooking}
                >
                  <FormattedMessage id="bookingCar_cancel_button" />
                </EkButton>
              ),
              !item.allowedActions.update ? (
                ''
              ) : (
                <EkButton
                  type="button"
                  customClass="bookingCar_actionsButton"
                  key="button3"
                  onAction={this.handleChangeBooking}
                >
                  <FormattedMessage id="bookingCar_change_button" />
                </EkButton>
              )
            ]
          : ''}
        {isBooked && item.allowedActions.report
          ? [
              <EkButton
                type="button"
                customClass={`bookingCar_actionsButton bookingCar_actions--variant
                ${item.status === BOOKING_STATUS_COMPLETED ? '_is_alone' : ''} `}
                key="button4"
                onAction={this.handleOpenDamageReportingModal}
              >
                <FormattedMessage id="bookingCar_report_button" />
              </EkButton>
            ]
          : ''}
        {item.type === BOOKING_TYPE_SHUTTLE
          ? [
              !isBooked ? (
                <EkButton
                  type="button"
                  customClass="bookingCar_actionsButton"
                  key="button5"
                  onAction={this.handleBookButton}
                >
                  <FormattedMessage id="bookingCar_bookCar_shuttle_button" />
                </EkButton>
              ) : !item.allowedActions.cancel ? (
                ''
              ) : (
                <EkButton
                  type="button"
                  customClass="bookingCar_actionsButton"
                  skinType="variant1"
                  key="button6"
                  onAction={this.handleCancelBooking}
                >
                  <FormattedMessage id="bookingCar_cancel_button" />
                </EkButton>
              )
            ]
          : ''}
      </div>
    );
  }

  getMoreDetails() {
    if (this.preBooked) return;
    const { item, isCurrentBooking, moreDetailsOpenedList } = this.props;

    if (moreDetailsOpenedList.indexOf(item.id) !== -1 || moreDetailsOpenedList.indexOf(item._id) !== -1) {
      return (
        <div className="bookingCar_moreDetails">
          <div>
            <ul className="bookingCar_items">
              {!item.vehicle.fuelType ? null : (
                <li className="bookingCar_item">
                  <TextIcon
                    iconName="fuel"
                    labelKey={`bookingResult_fuelType_${item.vehicle.fuelType.toLowerCase()}`}
                  />
                </li>
              )}
              {_get(item, 'vehicle.registrationNumber') && (
                <li className="bookingCar_item">
                  <TextIcon iconName="licencePlate" label={item.vehicle.registrationNumber} />
                </li>
              )}
              {item.vehicle.version && (
                <li className="bookingCar_item">
                  <TextIcon iconName="private" label={item.vehicle.version} />
                </li>
              )}
            </ul>
          </div>
          {item.vehicle.accessories.length === 0 ? (
            ''
          ) : (
            <div className={`bookingCar_accessories ${isCurrentBooking ? 'bookingCar_accessories--alt' : ''}`}>
              <span className="bookingCar_accessories_title">
                <FormattedMessage id="bookingCar_accessories_title" /> :
              </span>
              <ul className="bookingCar_accessories_items">{this.renderVehicleAccessories(item.vehicle)}</ul>
            </div>
          )}
        </div>
      );
    }
  }

  render() {
    const {
      item,
      isBooked,
      isCurrentBooking,
      openedCancelModalBookingId,
      privatePaymentModal,
      paymentUrl,
      openedDamageReportingModalBooking
    } = this.props;

    const costObj = _get(item, 'carSharingInfo.cost');
    const freeKmPerDay = _get(costObj, 'freeKmPerDay');
    const currency = _get(costObj, 'currency', 'EUR');

    return (
      <div className="bookingCar">
        <div className={this.previewClassName}>
          <div className="bookingCar_media">
            {this.getCarImage()}
            {!isBooked || item.state === BOOKING_STATE_PAST ? (
              ''
            ) : (
              <span className="bookingCar_media_icon bookingCar_media_icon--leaf">Green</span>
            )}
          </div>

          <div className="bookingCar_details">
            {!item.state || isCurrentBooking ? (
              ''
            ) : (
              <BaseTitle
                customClass="booking_sectionTitle"
                skinType={`${item.type === BOOKING_TYPE_RIDE_SHARING ? 'variant2' : ''}`}
                title={`myBookings_bookingType_${item.type}`}
              />
            )}
            {item.state === BOOKING_STATE_PAST && item.status ? (
              <span
                className={`bookingCar_details_status ${item.type === BOOKING_TYPE_RIDE_SHARING ? 'variant2' : ''}
                          ${item.status === BOOKING_STATUS_COMPLETED ? 'isDone' : ''}`}
              >
                <FormattedMessage id={`myBookings_status_${item.status}`} />
              </span>
            ) : (
              ''
            )}
            {!item.vehicle ? (
              ''
            ) : (
              <div className="bookingCar_details_head">
                {this.getCarName()}
                <div className="bookingCar_row">
                  <div className="bookingCar_place">
                    <span
                      className={`bookingCar_placeStart ${
                        item.type === BOOKING_TYPE_SHUTTLE ? 'bookingCar_placeStart--shuttle' : ''
                      }`}
                    >
                      {_get(item, 'start.parking.name') || _get(item, 'start.address.formattedAddress')}
                    </span>
                    {!isCurrentBooking && (
                      <span className={'bookingCar_placeDistance'}>{displayEndPlace(item, this.props.oneWayTrip)}</span>
                    )}
                  </div>
                  {this.displayMainPriceBlock()}
                </div>
                {!isBooked && freeKmPerDay > 0 && (
                  <div className="bookingCar_details_pricingNotice">
                    <span>* </span>
                    {freeKmPerDay}
                    <span> </span>
                    <FormattedMessage id="searchBooking_included" />
                  </div>
                )}
              </div>
            )}

            {!item.vehicle && item.shuttleInfo ? (
              <div className="bookingCar_details_head">
                <span className="bookingCar_details_price">
                  {item.shuttleInfo.price}
                  <sup>
                    <FormattedMessage id={`unit_${currency}`} />
                  </sup>
                </span>
              </div>
            ) : (
              ''
            )}

            {this.displayCarItems()}
          </div>
        </div>

        {this.getMoreDetails()}
        {this.getActions()}

        <Modal isOpen={openedCancelModalBookingId === item.id} style={modalStyles}>
          <p>
            <FormattedMessage id="bookingCar_cancelBooking_text" />
            {!item.vehicle ? (
              ''
            ) : (
              <span className="bookingCar_modal_infos">
                {item.vehicle.brand} {item.vehicle.model}, {this.dayOfBookingStart(item)}
              </span>
            )}
          </p>

          <EkButton
            type="button"
            skinType="reverse"
            customClass="bookingCar_cancelBooking_abort_button"
            onAction={this.handleAbortCancelBooking}
          >
            <FormattedMessage id="bookingCar_cancelBooking_abort_button" />
          </EkButton>

          <EkButton
            type="button"
            customClass="bookingCar_cancelBooking_confirm_button"
            onAction={this.handleConfirmCancelBooking}
          >
            <FormattedMessage id="bookingCar_cancelBooking_confirm_button" />
          </EkButton>
        </Modal>

        {!privatePaymentModal ? (
          ''
        ) : (
          <Modal isOpen style={modalPrivatePaymentStyles}>
            <p>
              <FormattedMessage id="bookingCar_modal_private_booking_text" />
            </p>

            <EkButton
              type="button"
              skinType="reverse"
              customClass="bookingCar_cancelBooking_abort_button"
              onAction={this.handleAbortPrivatePaymentBooking}
            >
              <FormattedMessage id="bookingCar_cancelBooking_abort_button" />
            </EkButton>

            <a
              className="subscribeForm_ekButton subscribeForm_ekButton ekButton bookingCar_cancelBooking_confirm_button"
              href={paymentUrl}
            >
              <FormattedMessage id="subscribeForm_savePaymentBtn" />
            </a>
          </Modal>
        )}

        {!openedDamageReportingModalBooking ? '' : <DamageReportingModal booking={item} />}
      </div>
    );
  }
}

BookingCar.displayName = 'BookingCar';

BookingCar.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  isBooked: PropTypes.bool,
  isCurrentBooking: PropTypes.bool,
  isEdited: PropTypes.bool,
  oneWayTrip: PropTypes.any
};

export default connect(state => {
  const {
    user: { userInfo, paymentInfos, paymentUrl },
    booking: {
      moreDetailsOpenedList,
      openedCancelModalBookingId,
      privatePaymentModal,
      openedDamageReportingModalBooking,
      userHasReservationsList
    },
    company: { companyId }
  } = state;

  return {
    userInfo,
    paymentInfos,
    paymentUrl,
    moreDetailsOpenedList,
    openedCancelModalBookingId,
    privatePaymentModal,
    companyId,
    openedDamageReportingModalBooking,
    userHasReservationsList
  };
})(BookingCar);
