import React, { Component, PropTypes } from 'react';

import Header from '../../components/Header/Header';

class Redirect extends Component {
  render() {
    return (
      <div className="MyBookingsPage">
        <Header />

        <div className="mainContent">
          <div className="mainContent_inner"></div>
        </div>
      </div>
    );
  }
}

Redirect.displayName = 'Redirect';

Redirect.propTypes = {};

export default Redirect;
