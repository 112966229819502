import { UPDATE_LOCATION } from 'react-router-redux';

import * as types from '../constants/actionTypes-constants';

export default function notifications(
  state = {
    list: null,
    notificationsMaxItems: 4,
    showMoreDetailsList: [],
    notificationsRestrictedList: null
  },
  action
) {
  switch (action.type) {
    case types.NOTIFICATIONS_NOTIFICATIONS_LIST_SUCCESS:
      return Object.assign({}, state, {
        list: action.params,
        notificationsRestrictedList: action.params.slice(0, state.notificationsMaxItems)
      });

    case types.NOTIFICATIONS_MAX_ITEMS_SET:
      return Object.assign({}, state, {
        notificationsMaxItems: action.maxItems
      });

    case types.NOTIFICATIONS_MORE_DETAILS_STATE_TOGGLE:
      let newShowMoreDetailsList = state.showMoreDetailsList.slice(0);
      let index = null;
      for (let i = 0, length = newShowMoreDetailsList.length; i < length; i++) {
        if (newShowMoreDetailsList[i] === action.id) {
          index = i;
          break;
        }
      }
      if (index === null) {
        newShowMoreDetailsList.push(action.id);
      } else {
        newShowMoreDetailsList.splice(index, 1);
      }
      return Object.assign({}, state, {
        showMoreDetailsList: newShowMoreDetailsList
      });

    case types.NOTIFICATIONS_MAX_ITEMS_INCREASE:
      return Object.assign({}, state, {
        notificationsMaxItems: state.notificationsMaxItems + 4
      });

    default:
      return state;
  }
}
