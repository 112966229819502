import { routeActions } from 'react-router-redux';

import * as types from '../constants/actionTypes-constants';
import ApiCaller from '../api/ApiCaller';
import routes from '../constants/routes-constants';
import { INVALID_TOKEN } from '../constants/errors-constants';

import { getStore } from '../store/all-store';

export default function callApi(resource, method, params, loader = true) {
  return dispatch => {
    if (loader) {
      dispatch({
        type: types.API_CALL_START,
        resource,
        method,
        params
      });
    }

    return (
      ApiCaller(resource, method, params)
        // native promises don't have 'finally' yet
        .then(
          result => {
            if (loader) endCall(dispatch, resource, method, params);
            return result;
          },
          error => {
            console.log(error); // eslint-disable-line
            if (loader) endCall(dispatch, resource, method, params);

            if (error.code === 401 && !(resource === 'user' && method === 'login')) {
              // for some reason, we need to use the dispatch function directly from the store or
              // the redirection will never happen.
              getStore().dispatch(routeActions.push(routes.login.path));
              error = {
                type: INVALID_TOKEN,
                code: error.code
              };
            }
            throw error;
          }
        )
    );
  };
}

function endCall(dispatch, resource, method, params) {
  dispatch({
    type: types.API_CALL_END,
    resource,
    method,
    params
  });
}

export function setToken(token) {
  return dispatch => {
    dispatch({
      type: types.API_SET_TOKEN_REQUEST,
      token
    });
    window.localStorage.setItem('token', token);
  };
}

export function unsetToken() {
  return dispatch => {
    dispatch({
      type: types.API_UNSET_TOKEN_REQUEST
    });
    window.localStorage.removeItem('token');
  };
}
