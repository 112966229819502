// noinspection ES6CheckImport
import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm, addArrayValue } from 'redux-form';
import { connect } from 'react-redux';
import { getTypeOf, scrollToFirstError } from '../../utils/utils';
import _isEmpty from 'lodash/lang/isEmpty';
import _get from 'lodash/object/get';
import _isString from 'lodash/lang/isString';

import FormRow from '../FormRow/FormRow';
import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox';
import EkButton from '../EkButton/EkButton';
import { toggleBookingResultFiltersOpenedState } from '../../actions/all-actions';
import config from '../../constants/config-constants';
import { BOOKING_USAGE_TYPE_BUSINESS, BOOKING_USAGE_TYPE_PRIVATE } from '../../constants/backend-constants';

class FiltersForm extends Component {
  constructor() {
    // noinspection JSCheckFunctionSignatures
    super();
    this.handleDisplayBookingResultsFilters = this.handleDisplayBookingResultsFilters.bind(this);
    this.handleResetFilters = this.handleResetFilters.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.renderFilterTransmission = this.renderFilterTransmission.bind(this);
    this.addFilters = this.addFilters.bind(this);
    this.renderFilterUsage = this.renderFilterUsage.bind(this);
    this.renderFilterType = this.renderFilterType.bind(this);
    this.renderFilterAccessories = this.renderFilterAccessories.bind(this);
    this.renderFilterTransmission = this.renderFilterTransmission.bind(this);
    this.renderAllFiltersSelected = this.renderAllFiltersSelected.bind(this);

    this.facets = config.bookingFilters;
  }

  componentWillMount() {
    this.addFilters();
  }

  handleDisplayBookingResultsFilters() {
    const { dispatch } = this.props;
    dispatch(toggleBookingResultFiltersOpenedState());
  }

  handleResetFilters() {
    if (this.props.onResetCallBack) {
      this.props.resetForm();
      this.props.onResetCallBack();
    }
  }

  addFilters() {
    const { allFiltersSelected, userInfo } = this.props;
    const hasPrivateUsage = _get(userInfo.company, 'contract.privateCarSharing');
    const hasBusinessUsage = _get(userInfo.company, 'contract.businessCarSharing');
    this.usageTypesAvailableValues = [];
    this.fuelTypesAvailableValues = [];
    this.accessoriesAvailableValues = [];
    this.transmissionsAvailableValues = [];

    this.facets.usageTypes.forEach(item => {
      if (hasPrivateUsage && item === BOOKING_USAGE_TYPE_PRIVATE) this.usageTypesAvailableValues.push(item);
      if (hasBusinessUsage && item === BOOKING_USAGE_TYPE_BUSINESS) this.usageTypesAvailableValues.push(item);
      if (allFiltersSelected && allFiltersSelected.usages && allFiltersSelected.usages.indexOf(item) !== -1) {
        this.props.fields.usages.addField(true);
      } else {
        this.props.fields.usages.addField();
      }
    });

    this.facets.fuelTypes.forEach(item => {
      this.fuelTypesAvailableValues.push(item);
      if (allFiltersSelected && allFiltersSelected.types && allFiltersSelected.types.indexOf(item) !== -1) {
        this.props.fields.fuelTypes.addField(true);
      } else {
        this.props.fields.fuelTypes.addField();
      }
    });

    this.facets.accessories.forEach(item => {
      this.accessoriesAvailableValues.push(item);
      if (allFiltersSelected && allFiltersSelected.carOptions && allFiltersSelected.carOptions.indexOf(item) !== -1) {
        this.props.fields.carOptions.addField(true);
      } else {
        this.props.fields.carOptions.addField();
      }
    });

    this.facets.transmissionTypes.forEach(item => {
      this.transmissionsAvailableValues.push(item);
      if (
        allFiltersSelected &&
        allFiltersSelected.carOptions &&
        allFiltersSelected.transmissionTypes.indexOf(item) !== -1
      ) {
        this.props.fields.transmissionTypes.addField(true);
      } else {
        this.props.fields.transmissionTypes.addField();
      }
    });
  }

  renderFilterUsage() {
    let filterUsages = [];

    this.facets.usageTypes.forEach(value => {
      if (_isString(value) && this.usageTypesAvailableValues.length === 2) {
        const indexInList = this.usageTypesAvailableValues.indexOf(value);
        filterUsages.push(
          <li className="filtersForm_rowItem" key={`usageTypes_${value}`}>
            <LabeledCheckbox
              customLabelClass="filtersForm_labeledCheckboxLabel"
              id={`${_get(this, 'props.formName', FiltersForm.displayName)}_usageType_${value.toLowerCase()}`}
              field={this.props.fields.usages[indexInList]}
            >
              <FormattedMessage id={`filtersForm_label_${value}`} />
            </LabeledCheckbox>
          </li>
        );
      }
    });

    return filterUsages;
  }

  renderFilterType() {
    let filterTypes = [];

    this.facets.fuelTypes.forEach(value => {
      if (_isString(value)) {
        const indexInList = this.fuelTypesAvailableValues.indexOf(value);

        filterTypes.push(
          <li className="filtersForm_rowItem" key={`fuelTypes_${value}`}>
            <LabeledCheckbox
              customLabelClass="filtersForm_labeledCheckboxLabel"
              id={`${_get(this, 'props.formName', FiltersForm.displayName)}_fuelType_${value.toLowerCase()}`}
              field={this.props.fields.fuelTypes[indexInList]}
            >
              <FormattedMessage id={`filtersForm_label_${value}`} />
            </LabeledCheckbox>
          </li>
        );
      }
    });

    return filterTypes;
  }

  renderFilterAccessories() {
    let filterAccessories = [];

    this.facets.accessories.forEach((value, index) => {
      if (_isString(value)) {
        const indexInList = this.accessoriesAvailableValues.indexOf(value);

        filterAccessories.push(
          <li className="filtersForm_rowItem" key={`accessories_${value}`}>
            <LabeledCheckbox
              customLabelClass="filtersForm_labeledCheckboxLabel"
              id={`${_get(this, 'props.formName', FiltersForm.displayName)}_carOption_${value.toLowerCase()}`}
              field={this.props.fields.carOptions[indexInList]}
            >
              <FormattedMessage id={`filtersForm_label_${this.facets.accessories[index]}`} />
            </LabeledCheckbox>
          </li>
        );
      }
    });

    return filterAccessories;
  }

  renderFilterTransmission() {
    let filterTransmissions = [];

    this.facets.transmissionTypes.forEach((value, index) => {
      if (_isString(value)) {
        const indexInList = this.transmissionsAvailableValues.indexOf(value);

        filterTransmissions.push(
          <li className="filtersForm_rowItem" key={`transmissions_${value}`}>
            <LabeledCheckbox
              customLabelClass="filtersForm_labeledCheckboxLabel"
              id={`${_get(this, 'props.formName', FiltersForm.displayName)}_gearbox_${value.toLowerCase()}`}
              field={this.props.fields.transmissionTypes[indexInList]}
            >
              <FormattedMessage id={`filtersForm_label_${this.facets.transmissionTypes[index]}`} />
            </LabeledCheckbox>
          </li>
        );
      }
    });

    return filterTransmissions;
  }

  renderAllFiltersSelected() {
    const { allFiltersSelected } = this.props;
    let selectedFiltersList = [];
    let filtersList;

    for (let key in allFiltersSelected) {
      // noinspection JSUnfilteredForInLoop
      let selectedFiltersItems = allFiltersSelected[key];

      if (allFiltersSelected.hasOwnProperty(key) && selectedFiltersItems && selectedFiltersItems.length) {
        if (getTypeOf(selectedFiltersItems) === 'Array') {
          let filters = [];

          selectedFiltersItems.forEach((item, index, array) => {
            let formattedFilter = <FormattedMessage key={`${key}_${item}`} id={`filtersForm_label_${item}`} />;
            filters.push(formattedFilter);

            if (index < array.length - 1) {
              filters.push(', ');
            }
          });

          selectedFiltersList = Object.assign(selectedFiltersList, { [key]: filters });
        } else {
          selectedFiltersList = Object.assign(selectedFiltersList, { [key]: selectedFiltersItems });
        }

        filtersList = (
          <ul className="filtersForm_filtersList_items">
            {selectedFiltersList.usages && (
              <li className="filtersForm_filtersList_item">
                <FormattedMessage id={`filtersForm_label_usage`} /> : {selectedFiltersList.usages}
              </li>
            )}
            {selectedFiltersList.types && (
              <li className="filtersForm_filtersList_item">
                <FormattedMessage id={`filtersForm_label_type`} /> : {selectedFiltersList.types}
              </li>
            )}
            {selectedFiltersList.transmissionTypes && (
              <li className="filtersForm_filtersList_item">
                <FormattedMessage id={`filtersForm_label_gearbox`} /> : {selectedFiltersList.transmissionTypes}
              </li>
            )}
            {selectedFiltersList.carOptions && (
              <li className="filtersForm_filtersList_item">
                <FormattedMessage id={`filtersForm_label_carOptions`} /> : {selectedFiltersList.carOptions}
              </li>
            )}
          </ul>
        );
      }
    }

    return filtersList;
  }

  handleFormSubmit(values) {
    const { usages, fuelTypes, carOptions, transmissionTypes } = values;

    let usagesTypes = [];
    usages.forEach((item, index) => {
      if (item) {
        usagesTypes.push(this.usageTypesAvailableValues[index]);
      }
    });

    let types = [];
    fuelTypes.forEach((item, index) => {
      if (item) {
        types.push(this.fuelTypesAvailableValues[index]);
      }
    });

    let vehicleAccessories = [];
    carOptions.forEach((item, index) => {
      if (item) {
        vehicleAccessories.push(this.accessoriesAvailableValues[index]);
      }
    });

    let carGearbox = [];
    transmissionTypes.forEach((item, index) => {
      if (item) {
        carGearbox.push(this.transmissionsAvailableValues[index]);
      }
    });

    let returnedValues = {
      usages: usagesTypes,
      fuelTypes: types,
      carOptions: vehicleAccessories,
      transmissionTypes: carGearbox
    };

    this.props.onCallback(returnedValues);
  }

  render() {
    const { bookingResultDisplayFilters, allFiltersSelected, handleSubmit, userInfo } = this.props;

    return (
      <div className="filtersFormPage_form">
        <div className="filtersFormPage_filter">
          <EkButton
            id={`${_get(this, 'props.formName', FiltersForm.displayName)}_${
              bookingResultDisplayFilters ? 'close' : 'open'
            }_button`}
            type="button"
            customClass={`filterButton filtersForm_buttonToggle ${!bookingResultDisplayFilters ? '' : '_is_opened'}`}
            skinType="variant1"
            onAction={this.handleDisplayBookingResultsFilters}
          >
            <FormattedMessage id="bookingResult_filters_button" />
          </EkButton>
        </div>
        {bookingResultDisplayFilters && (
          <form
            className="filtersForm"
            method="post"
            action="#"
            onSubmit={handleSubmit(this.handleFormSubmit)}
            noValidate
          >
            <section className="filtersForm_section">
              <div className="filtersForm_container">
                {_get(userInfo.company, 'contract.businessCarSharing') &&
                  _get(userInfo.company, 'contract.privateCarSharing') && (
                    <FormRow customClass="filtersForm_row">
                      <div>
                        <span className="filtersForm_rowTitle">
                          <FormattedMessage id="filtersForm_label_usage" />
                        </span>
                        <ul
                          className="filtersForm_rowItems"
                          id={`${_get(this, 'props.formName', FiltersForm.displayName)}_usageType_filters`}
                        >
                          {this.renderFilterUsage()}
                        </ul>
                      </div>
                    </FormRow>
                  )}
                <FormRow customClass="filtersForm_row">
                  <div>
                    <span className="filtersForm_rowTitle">
                      <FormattedMessage id="filtersForm_label_type" />
                    </span>
                    <ul
                      className="filtersForm_rowItems"
                      id={`${_get(this, 'props.formName', FiltersForm.displayName)}_fuelType_filters`}
                    >
                      {this.renderFilterType()}
                    </ul>
                  </div>
                </FormRow>
                <FormRow customClass="filtersForm_row">
                  <div>
                    <span className="filtersForm_rowTitle">
                      <FormattedMessage id="filtersForm_label_gearbox" />
                    </span>
                    <ul
                      className="filtersForm_rowItems"
                      id={`${_get(this, 'props.formName', FiltersForm.displayName)}_gearbox_filters`}
                    >
                      {this.renderFilterTransmission()}
                    </ul>
                  </div>
                </FormRow>
                <FormRow customClass="filtersForm_row">
                  <div>
                    <span className="filtersForm_rowTitle">
                      <FormattedMessage id="filtersForm_label_carOptions" />
                    </span>
                    <ul
                      className="filtersForm_rowItems"
                      id={`${_get(this, 'props.formName', FiltersForm.displayName)}_carOptions_filters`}
                    >
                      {this.renderFilterAccessories()}
                    </ul>
                  </div>
                </FormRow>
              </div>
            </section>
            <FormRow customWrapperClass="filtersForm_actions" customClass="filtersForm_row">
              <EkButton
                id={`${_get(this, 'props.formName', FiltersForm.displayName)}_reset_button`}
                formRowItemKey="filtersForm_resetBtn"
                type="submit"
                customClass="filtersForm_actionsButton filtersForm_actionsButton_left"
                skinType="reverse"
                onAction={this.handleResetFilters}
              >
                <FormattedMessage id="filtersForm_reset_filters" />
              </EkButton>
              <EkButton
                id={`${_get(this, 'props.formName', FiltersForm.displayName)}_apply_button`}
                formRowItemKey="filtersForm_submitBtn"
                type="submit"
                customClass="filtersForm_actionsButton"
                onAction={handleSubmit(this.handleFormSubmit)}
              >
                <FormattedMessage id="filtersForm_apply_filters" />
              </EkButton>
            </FormRow>
          </form>
        )}
        {!_isEmpty(allFiltersSelected) && (
          <div className="filtersForm_filtersList">
            <span className="filtersForm_filtersList_label">
              <FormattedMessage id="filtersForm_show_filters" />
            </span>
            {this.renderAllFiltersSelected()}
          </div>
        )}
      </div>
    );
  }
}

FiltersForm.displayName = 'FiltersForm';

FiltersForm.propTypes = {
  action: PropTypes.func,
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  onResetCallBack: PropTypes.func,
  handleSubmit: PropTypes.func,
  formName: PropTypes.string
};

FiltersForm = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'filtersForm',
    fields: ['transmissionTypes[]', 'usages[]', 'fuelTypes[]', 'carOptions[]']
  },
  undefined,
  {
    // mapDispatchToProps (will bind action creator to dispatch)
    addValue: addArrayValue
  }
)(FiltersForm);

export default connect(state => {
  const {
    booking: { lists, bookingResultDisplayFilters, allFiltersSelected },
    user: { userInfo }
  } = state;

  return {
    lists,
    bookingResultDisplayFilters,
    allFiltersSelected,
    userInfo
  };
})(FiltersForm);
