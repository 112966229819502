import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm, change } from 'redux-form';
import pure from 'recompose/pure';
import _get from 'lodash/object/get';
import _find from 'lodash/collection/find';
import {
  customFieldsValidatorsSelector,
  localeSelector,
  selectCustomFieldNames,
  visibleSubscriptionCustomFieldsSelector
} from '../../selectors/all-selectors';
import { getStore } from '../../store/all-store';
import config from '../../constants/config-constants';
import Modal from 'react-modal';
import ConfirmationForm from '../ConfirmationForm/ConfirmationForm';
import { CreditCardModalStyles } from '../../constants/style-constants';
import FormRow from '../FormRow/FormRow';
import EkButton from '../EkButton/EkButton';
import EkButtonUpload from '../EkButtonUpload/EkButtonUpload';
import BaseTitle from '../BaseTitle/BaseTitle';
import BoxedInput from '../BoxedInput/BoxedInput';
import CustomFields from '../CustomFields';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import {
  createValidator,
  createAsyncValidator,
  stopValidationIf,
  match,
  notEmpty,
  driverLicence,
  emailIsAvailable,
  email,
  password,
  length,
  integer,
  validDate,
  validBirthDate
} from '../../validation/all-validation';
import {
  storeSubscribeFormState,
  setUsedByBackUser,
  setInitialLoginValue,
  openCreditCardModal,
  closeCreditCardModal
} from '../../actions/all-actions';
import { conditionItalianIsFalse, namedCompose, scrollToFirstError, toBoolean, toUpperSafe } from '../../utils/utils';
import { notEmptyExternal, truthy, condition } from '../../validation/sync-validation';
import {
  DATE_PLACEHOLDER,
  DRIVER_LICENCE_BACK_ID,
  DRIVER_LICENCE_FRONT_ID,
  PASSWORD_CONFIRM_FIELD_NAME,
  PASSWORD_FIELD_NAME
} from '../../constants/generic-constants';
import classNames from 'classnames';
import _values from 'lodash/object/values';
import { autocompletePlaceholderMessages } from '../../constants/options-constants';
import { callbackNot as not } from '../../utils/utils';
import { ADDRESS_FIELDS as ADDR } from '../../constants/field-constants';
import { ADDRESS_AUTO_COMPLETE_MISSING } from '../../constants/errors-constants';
import PdfPreview from '../PdfPreview/PdfPreview';
import RciToggle from '../RciToggle/RciToggle';
import { EXTERNAL_INVOICE_ITALIAN } from '../../constants/backend-constants';

class SubscribeForm extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.setStaticData(props);
    this.derivedStateFromProps(props);
  }

  // noinspection JSCheckFunctionSignatures
  componentDidUpdate(prevProps) {
    this.updatePhonePrefix(prevProps);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    const { customFields, useExternalInvoiceSystem } = props;

    this.customFieldsPresent = customFields && customFields.length > 0;
    this.externalInvoiceItalian = useExternalInvoiceSystem === EXTERNAL_INVOICE_ITALIAN;
  }

  setStaticData(props) {
    this.edit = props.edit;
  }

  bindFunctions() {
    this.handleToDownloadTos = this.handleToDownloadTos.bind(this);
    this.handleToDownloadPrivacyPolicy = this.handleToDownloadPrivacyPolicy.bind(this);
    this.handleGotoPayment = this.handleGotoPayment.bind(this);
    this.handleAbortCreditCardRedirect = this.handleAbortCreditCardRedirect.bind(this);
    this.handleConfirmCreditCardRedirect = this.handleConfirmCreditCardRedirect.bind(this);
  }

  updatePhonePrefix(prevProps) {
    const newIsoCode = this.props.fields[ADDR.COUNTRY_ISO];
    const prevIsoCode = prevProps.fields[ADDR.COUNTRY_ISO];
    const phoneNumberNotSet = _get(prevProps.fields.phoneNumber, 'value') === '';

    if (newIsoCode !== prevIsoCode && phoneNumberNotSet) this.setPhonePrefix();
  }

  handleToDownloadTos() {
    const { termsOfUseUrl } = this.props.companyInfos;
    window.open(termsOfUseUrl, '_blank');
  }

  handleToDownloadPrivacyPolicy() {
    const { privacyPolicyUrl } = this.props.companyInfos;
    window.open(privacyPolicyUrl, '_blank');
  }

  handleGotoPayment() {
    const { dispatch } = this.props;
    dispatch(openCreditCardModal());
  }

  handleAbortCreditCardRedirect() {
    const { dispatch } = this.props;
    dispatch(closeCreditCardModal());
  }

  handleConfirmCreditCardRedirect() {
    const { dispatch, paymentUrl } = this.props;
    dispatch(storeSubscribeFormState());
    window.location = paymentUrl;
  }

  displayPreview(fileProps) {
    const {
      intl: { formatMessage },
      drivingLicenceFiles
    } = this.props;
    const uploadedTitle = formatMessage({ id: 'image_preview_uploaded_title' });
    let img = _find(drivingLicenceFiles, { id: fileProps });
    let attr;
    if (drivingLicenceFiles.length && img) {
      if (_get(img, 'mimeType') !== 'application/pdf') {
        attr = {
          src: 'data:' + img.mimeType + ';base64,' + img.content
        };
      }
      return (
        <div className="ekButtonUpload_preview" title={uploadedTitle}>
          {_get(img, 'mimeType') === 'application/pdf' ? <PdfPreview /> : <img alt="" {...attr} />}
        </div>
      );
    }
  }

  getBirthdayField() {
    const {
      fields: { birthDate },
      companyInfos
    } = this.props;

    return (
      <FormRow customClass="subscribeForm_row">
        <BoxedInput
          formRowItemKey="subscribeForm_maxBirthDate"
          id="birthDate"
          customClass="subscribeForm_boxedInputWrapper--date"
          labelKey="subscribeForm_birthDate_label"
          type="text"
          placeholder={DATE_PLACEHOLDER}
          field={birthDate}
          maxlength="10"
          mandatory={companyInfos.drivingLicenceRequired}
        >
          {companyInfos.drivingLicenceRequired && (
            <FieldErrorMsg field={birthDate} customClass="fieldErrorMsg--subscribeForm" />
          )}
        </BoxedInput>
      </FormRow>
    );
  }

  setPhonePrefix() {
    const mapPrefixCountryCode = config.prefixPhoneCountryCode;
    const countryIsoCode = toUpperSafe(this.props.values[ADDR.COUNTRY_ISO]);

    function search(objects, toSearch) {
      let toRet = '';
      for (let key in objects) {
        if (objects[key].indexOf(toSearch) !== -1) {
          toRet = '+' + key;
          return toRet;
        }
      }
    }

    if (countryIsoCode) {
      let phonePrefix = search(mapPrefixCountryCode, countryIsoCode);
      this.props.dispatch(change('subscribe', 'mobilePhonePrefix', phonePrefix));
    }
  }

  showAdditionalInfo() {
    const { fields, customFields } = this.props;
    const { italian, fiscalCode } = fields;
    const { value } = italian || {};
    const italianValue = toBoolean(value);

    if (this.customFieldsPresent || this.externalInvoiceItalian) {
      return (
        <section className="subscribeForm_section">
          <BaseTitle customClass="subscribeForm_title" title="subscribeForm_additionnalInfos_title" />
          {this.externalInvoiceItalian && (
            <div className="italianInvoice">
              <RciToggle
                field={italian}
                titleKey="register.italy.resident"
                id="italianInvoice"
                customTitleClass="boxedInput_labelText"
              />
              {italianValue && (
                <BoxedInput
                  formRowItemKey="subscribeForm_formatMessage"
                  type="text"
                  id="fiscalCode"
                  labelKey="register.italy.fiscal.code"
                  customClass="subscribeForm_boxedInput"
                  field={fiscalCode}
                  mandatory
                >
                  <FieldErrorMsg field={fiscalCode} customClass="fieldErrorMsg--subscribeForm" />
                </BoxedInput>
              )}
            </div>
          )}
          {this.customFieldsPresent && <CustomFields customFields={customFields} formFields={fields} subscribe />}
        </section>
      );
    }
  }

  render() {
    const {
      fields: {
        login,
        loginConfirm,
        password,
        confirmationPassword,
        firstName,
        lastName,
        mobilePhonePrefix,
        phoneNumber,
        driverLicence,
        driverLicenceBack,
        driverLicenceNumber,
        expirationDate,
        optionalExpirationDate,
        deliveranceDate,
        cityDeliverance,
        tosDownload,
        privacePolicyAccepted,
        lang,
        commercialOffers,
        managerEmail,
        [ADDR.STREET]: street,
        [ADDR.CITY]: city,
        [ADDR.COUNTRY]: country,
        [ADDR.POSTAL_CODE]: postalCode,
        [ADDR.COUNTRY_ISO]: countryIso
      },
      handleSubmit,
      resetForm,
      worldPayReturnInfos,
      companyInfos,
      openedCreditCardModal,
      usedByBackUser,
      showCarSharingForm,
      edit,
      intl: { formatMessage }
    } = this.props;

    let displaySetPaymentInfosBlock =
      _get(companyInfos, 'contract.privateCarSharing') && (_get(login, 'error') || !worldPayReturnInfos);
    let displayPaymentInfosBlock = _get(companyInfos, 'contract.privateCarSharing') && worldPayReturnInfos;
    const mobilePhonePrefixes = config.phoneCountryPrefixes;
    const submitForm = handleSubmit(this.props.onCallback);
    const currencySymbol = <FormattedMessage id={`unit_${companyInfos.currency}`} />;

    return (
      <div className="subscribeForm_page">
        <form action="#" className="subscribeForm" onSubmit={submitForm} noValidate>
          <BoxedInput formRowItemKey="subscribeForm_lang" type="hidden" id="lang" field={lang} />
          <section className="subscribeForm_section">
            <BaseTitle
              customClass="subscribeForm_title"
              title="subscribeForm_account_title"
              values={{ company: _get(companyInfos, 'name') }}
            />
            <div className="subscribeForm_container">
              {!edit && (
                <FormRow customClass="subscribeForm_row">
                  <BoxedInput
                    formRowItemKey="subscribeForm_login"
                    type="email"
                    id="email"
                    labelKey="subscribeForm_email_label"
                    placeholder={formatMessage({ id: 'subscribeForm_email_label' })}
                    field={login}
                    mandatory
                  >
                    <FieldErrorMsg field={login} customClass="fieldErrorMsg--subscribeForm" />
                  </BoxedInput>

                  {!usedByBackUser && (
                    <BoxedInput
                      formRowItemKey="subscribeForm_login_confirmation"
                      type="email"
                      id="confirm_email"
                      labelKey="subscribeForm_email_confirmation_label"
                      placeholder={formatMessage({ id: 'subscribeForm_email_label' })}
                      field={loginConfirm}
                      mandatory
                    >
                      <FieldErrorMsg field={loginConfirm} customClass="fieldErrorMsg--subscribeForm" />
                    </BoxedInput>
                  )}
                </FormRow>
              )}
              {!edit && (
                <FormRow customClass="subscribeForm_row">
                  <BoxedInput
                    formRowItemKey="subscribeForm_password_confirmation"
                    type="password"
                    id="password"
                    labelKey="subscribeForm_password_label"
                    placeholder={formatMessage({ id: 'subscribeForm_password_label' })}
                    field={password}
                    mandatory
                  >
                    <span className="subscribeForm_helpText">
                      <FormattedMessage id="subscribeForm_password_help" />
                    </span>
                    <FieldErrorMsg field={password} customClass="fieldErrorMsg--subscribeForm" />
                  </BoxedInput>
                  <BoxedInput
                    formRowItemKey="subscribeForm_password"
                    type="password"
                    id="confirm_password"
                    placeholder={formatMessage({ id: 'subscribeForm_password_confirmation_label' })}
                    labelKey="subscribeForm_password_confirmation_label"
                    field={confirmationPassword}
                    mandatory
                  >
                    <FieldErrorMsg field={confirmationPassword} customClass="fieldErrorMsg--subscribeForm" />
                  </BoxedInput>
                </FormRow>
              )}
              {_get(companyInfos, 'sendEmailsToManager') && (
                <FormRow customClass="subscribeForm_row">
                  <BoxedInput
                    formRowItemKey="subscribeForm_managerEmail"
                    type="email"
                    id="managerEmail"
                    labelKey="account_manager_email"
                    placeholder={formatMessage({ id: 'account_manager_email' })}
                    field={managerEmail}
                    mandatory
                  >
                    <FieldErrorMsg field={managerEmail} customClass="fieldErrorMsg--subscribeForm" />
                  </BoxedInput>
                </FormRow>
              )}
            </div>
          </section>

          <section className="subscribeForm_section">
            <BaseTitle customClass="subscribeForm_title" title="subscribeForm_personalInfos_title" />

            <div className="subscribeForm_container">
              {usedByBackUser && (
                <p className="subscribeForm_existAsBackUser">
                  <FormattedMessage id="subscribeForm_exist_as_backUser" />
                </p>
              )}

              {!usedByBackUser && (
                <FormRow customClass="subscribeForm_row">
                  <BoxedInput
                    formRowItemKey="subscribeForm_firstName"
                    type="text"
                    id="firstName"
                    labelKey="subscribeForm_firstName_label"
                    customClass="subscribeForm_boxedInput"
                    placeholder={formatMessage({ id: 'subscribeForm_firstName_label' })}
                    field={firstName}
                    mandatory
                  >
                    <FieldErrorMsg field={firstName} customClass="fieldErrorMsg--subscribeForm" />
                  </BoxedInput>

                  <BoxedInput
                    formRowItemKey="subscribeForm_lastName"
                    type="text"
                    id="lastName"
                    labelKey="subscribeForm_lastName_label"
                    customClass="subscribeForm_boxedInput"
                    placeholder={formatMessage({ id: 'subscribeForm_lastName_label' })}
                    field={lastName}
                    mandatory
                  >
                    <FieldErrorMsg field={lastName} customClass="fieldErrorMsg--subscribeForm" />
                  </BoxedInput>
                </FormRow>
              )}

              {!usedByBackUser && (
                <FormRow customClass="subscribeForm_row">
                  <label formRowItemKey="subscribeForm_address_label" className="boxedInput_label">
                    <span className="boxedInput_labelText">
                      <FormattedMessage id="subscribeForm_address_label" />
                      <span className="boxedInput_mandatory">*</span>
                    </span>
                    <AddressAutocomplete
                      className="subscribeForm_AddressAutocomplete"
                      placeholder={formatMessage(autocompletePlaceholderMessages.generic)}
                      field={street}
                      spreadExternalFields={{
                        cityField: city,
                        countryField: country,
                        countryIsoField: countryIso,
                        postalCodeField: postalCode
                      }}
                    />
                    <FieldErrorMsg field={street} customClass="fieldErrorMsg--subscribeForm" />
                  </label>
                </FormRow>
              )}

              {!usedByBackUser && (
                <FormRow customClass="subscribeForm_row">
                  <BoxedInput
                    formRowItemKey="subscribeForm_city"
                    type="text"
                    id="city"
                    labelKey="profileForm_city_label"
                    customClass="subscribeForm_boxedInput"
                    field={city}
                    mandatory
                  >
                    <FieldErrorMsg field={city} customClass="fieldErrorMsg--subscribeForm" />
                  </BoxedInput>
                  <label className="boxedInput_label">
                    <span className="boxedInput_labelText">
                      <FormattedMessage id="profileForm_country_label" />
                      <span className="boxedInput_mandatory">*</span>
                    </span>
                    <AddressAutocomplete
                      className="subscribeForm_AddressAutocomplete"
                      placeholder={formatMessage(autocompletePlaceholderMessages.generic)}
                      updateCountryIsoField={countryIso}
                      field={country}
                    />
                    <FieldErrorMsg field={countryIso} customClass="fieldErrorMsg--subscribeForm" />
                  </label>
                  <BoxedInput
                    formRowItemKey="subscribeForm_postalCode"
                    type="text"
                    id="postalCode"
                    labelKey="profileForm_postal_code_label"
                    customClass="subscribeForm_boxedInput"
                    field={postalCode}
                    mandatory
                  >
                    <FieldErrorMsg field={postalCode} customClass="fieldErrorMsg--subscribeForm" />
                  </BoxedInput>
                </FormRow>
              )}

              {!usedByBackUser && (
                <FormRow customClass="subscribeForm_row">
                  <BoxedSelect
                    formRowItemKey="subscribeForm_mobilePhonePrefix"
                    id="mobilePhonePrefix"
                    customClass="subscribeForm_boxedSelectWrapper"
                    labelKey="subscribeForm_mobilePhone_label"
                    options={mobilePhonePrefixes}
                    field={mobilePhonePrefix}
                    mandatory
                  >
                    <BoxedInput
                      type="tel"
                      id="phoneNumber"
                      customClass="boxedInputWrapper--phoneNumber"
                      placeholder={formatMessage({ id: 'subscribeForm_mobilePhone_label' })}
                      field={phoneNumber}
                    />
                    <FieldErrorMsg field={phoneNumber} customClass="fieldErrorMsg--subscribeForm" />
                  </BoxedSelect>
                </FormRow>
              )}

              {this.getBirthdayField()}
            </div>

            {showCarSharingForm && (
              <div className={classNames('subscribeForm_container', 'subscribeForm_container--carSharing')}>
                {companyInfos.drivingLicenceRequired && (
                  <div>
                    <FormRow customClass="subscribeForm_row">
                      <EkButtonUpload
                        formRowItemKey="subscribeForm_driverLicence"
                        id={DRIVER_LICENCE_FRONT_ID}
                        customClass="subscribeForm_ekButton"
                        labelKey="subscribeForm_driverLicence_label"
                        buttonLabel="subscribeForm_uploadLicence_label"
                        field={driverLicence}
                        mandatory
                        preview
                      >
                        {this.displayPreview(DRIVER_LICENCE_FRONT_ID)}
                        <span className="subscribeForm_helpText">
                          <FormattedMessage id="subscribeForm_uploadLicence_help" />
                        </span>
                        <FieldErrorMsg field={driverLicence} customClass="fieldErrorMsg--subscribeForm" />
                      </EkButtonUpload>
                    </FormRow>
                    <FormRow customClass="subscribeForm_row">
                      <EkButtonUpload
                        formRowItemKey="subscribeForm_driverLicenceBack"
                        id={DRIVER_LICENCE_BACK_ID}
                        customClass="subscribeForm_ekButton"
                        labelKey="subscribeForm_driverLicenceBack_label"
                        buttonLabel="subscribeForm_uploadLicence_label"
                        field={driverLicenceBack}
                        mandatory
                        preview
                      >
                        {this.displayPreview(DRIVER_LICENCE_BACK_ID)}
                        <span className="subscribeForm_helpText">
                          <FormattedMessage id="subscribeForm_uploadLicence_help" />
                        </span>
                        <FieldErrorMsg field={driverLicenceBack} customClass="fieldErrorMsg--subscribeForm" />
                      </EkButtonUpload>
                    </FormRow>
                    <FormRow customClass="subscribeForm_row subscribeForm_row--alt">
                      <BoxedInput
                        formRowItemKey="subscribeForm_driverLicenceNumber"
                        type="text"
                        id="driverLicenceNumber"
                        customClass="subscribeForm_boxedInput"
                        labelKey="subscribeForm_driverLicenceNumber_label"
                        maxlength="25"
                        field={driverLicenceNumber}
                        placeholder={formatMessage({ id: 'subscribeForm_driverLicenceNumber_label' })}
                        mandatory
                      >
                        <FieldErrorMsg field={driverLicenceNumber} customClass="fieldErrorMsg--subscribeForm" />
                      </BoxedInput>
                      <BoxedInput
                        formRowItemKey="subscribeForm_expirationDate"
                        id="expirationDate"
                        customClass="subscribeForm_boxedInputWrapper--date"
                        labelKey="subscribeForm_expirationDate_label"
                        type="text"
                        placeholder={DATE_PLACEHOLDER}
                        maxlength="10"
                        field={expirationDate}
                        mandatory={optionalExpirationDate && !optionalExpirationDate.checked}
                      >
                        {optionalExpirationDate && !optionalExpirationDate.checked && (
                          <FieldErrorMsg field={expirationDate} customClass="fieldErrorMsg--subscribeForm" />
                        )}
                      </BoxedInput>
                    </FormRow>
                    <FormRow customClass="subscribeForm_row">
                      <LabeledCheckbox
                        formRowItemKey="subscribeForm_optionalExpirationDate"
                        id="optionalExpirationDate"
                        field={optionalExpirationDate}
                      >
                        <FormattedMessage id="subscribeForm_optionalExpirationDate_label" />
                      </LabeledCheckbox>
                    </FormRow>
                    <FormRow customClass="subscribeForm_row subscribeForm_row--alt">
                      <BoxedInput
                        formRowItemKey="subscribeForm_cityDeliverance"
                        type="text"
                        id="cityDeliverance"
                        customClass="boxedSelect--medium subscribeForm_boxedSelectWrapper"
                        labelKey="subscribeForm_deliveranceCountry_label"
                        placeholder={formatMessage({ id: 'subscribeForm_deliveranceCountry_label' })}
                        field={cityDeliverance}
                        mandatory
                      >
                        <FieldErrorMsg field={cityDeliverance} customClass="fieldErrorMsg--subscribeForm" />
                      </BoxedInput>
                      <BoxedInput
                        formRowItemKey="subscribeForm_deliveranceDate"
                        id="deliveranceDate"
                        customClass="subscribeForm_boxedInputWrapper--date"
                        labelKey="common_deliveranceDate"
                        type="text"
                        placeholder={DATE_PLACEHOLDER}
                        maxlength="10"
                        field={deliveranceDate}
                        mandatory
                      >
                        <FieldErrorMsg field={deliveranceDate} customClass="fieldErrorMsg--subscribeForm" />
                      </BoxedInput>
                    </FormRow>
                  </div>
                )}

                {(displaySetPaymentInfosBlock || displayPaymentInfosBlock) && (
                  <div className="subscribeForm_row subscribeForm_payment">
                    <span className="subscribeForm_row_text">
                      <FormattedMessage
                        id={
                          displayPaymentInfosBlock
                            ? 'subscribeForm_payzen_infos'
                            : 'subscribeForm_payment_informations_label'
                        }
                      />
                    </span>
                    <span>
                      <a
                        className="subscribeForm_ekButton subscribeForm_ekButton ekButton ekButton--variant1Reverse"
                        onClick={this.handleGotoPayment}
                      >
                        <FormattedMessage
                          id={
                            displayPaymentInfosBlock ? 'subscribeForm_modifyPaymentBtn' : 'subscribeForm_savePaymentBtn'
                          }
                        />
                      </a>
                    </span>
                  </div>
                )}
                {openedCreditCardModal && (
                  <Modal isOpen style={CreditCardModalStyles}>
                    <ConfirmationForm
                      confirmationMessage={`confirmationForm_creditCard_text`}
                      confirmationMessageValues={{ amountToTake: config.amountToTake, currency: currencySymbol }}
                      confirmationText={`confirmationForm_save_button`}
                      abortText={`confirmationForm_abort_button`}
                      onCallback={this.handleConfirmCreditCardRedirect}
                      onAbort={this.handleAbortCreditCardRedirect}
                    />
                  </Modal>
                )}
              </div>
            )}
          </section>
          {this.showAdditionalInfo()}
          <section className="subscribeForm_section">
            <FormRow>
              <label className="subscribeForm_commercialOffers" htmlFor="commercialOffers">
                <LabeledCheckbox
                  id="commercialOffers"
                  customLabelClass="subscribeForm_commercialOffersLabel"
                  field={commercialOffers}
                >
                  <span>
                    <FormattedMessage id="subscribeForm_commercialOffers_label" />
                  </span>
                </LabeledCheckbox>
              </label>
            </FormRow>

            {privacyPolicyUrlPresent(this.props) && (
              <FormRow customClass="subscribeForm_row subscribeForm_row--tos">
                <div formRowItemKey="subscribeForm_privacyPolicyWrapper">
                  <label className="subscribeForm_tos" htmlFor="privacePolicyAccepted">
                    <LabeledCheckbox id="privacePolicyAccepted" field={privacePolicyAccepted}>
                      <span>
                        <FormattedMessage id="subscribeForm_privacyPolicy_label" />
                        <span className="boxedInput_mandatory">*</span>
                      </span>
                    </LabeledCheckbox>
                    <EkButton
                      type="button"
                      id="download_button"
                      customClass="ekButton--download subscribeForm_ekButton"
                      skinType="variant1Reverse"
                      onAction={this.handleToDownloadPrivacyPolicy}
                    >
                      <FormattedMessage id="subscribeForm_cguBtn" />
                    </EkButton>
                  </label>
                  <FieldErrorMsg field={privacePolicyAccepted} customClass="fieldErrorMsg--subscribeForm" />
                </div>
              </FormRow>
            )}

            <FormRow customClass="subscribeForm_row subscribeForm_row--tos">
              <div formRowItemKey="subscribeForm_tosWrapper">
                <label className="subscribeForm_tos" htmlFor="tosDownload">
                  <LabeledCheckbox id="tosDownload" customLabelClass="subscribeForm_tosLabel" field={tosDownload}>
                    <span>
                      <FormattedMessage id="subscribeForm_tos_label" />
                      <span className="boxedInput_mandatory">*</span>
                    </span>
                  </LabeledCheckbox>
                  <EkButton
                    type="button"
                    id="download_privacy_policy_button"
                    customClass="ekButton--download subscribeForm_ekButton"
                    skinType="variant1Reverse"
                    onAction={this.handleToDownloadTos}
                  >
                    <FormattedMessage id="subscribeForm_cguBtn" />
                  </EkButton>
                </label>
                <FieldErrorMsg field={tosDownload} customClass="fieldErrorMsg--subscribeForm" />
              </div>
            </FormRow>
          </section>
          <FormRow customWrapperClass="subscribeForm_actions" customClass="subscribeForm_row">
            <EkButton
              formRowItemKey="subscribeForm_resetBtn"
              type="button"
              id="cancel_button"
              customClass="subscribeForm_cancelBtn"
              skinType="reverse"
              onAction={resetForm}
              fullWidth
            >
              <FormattedMessage id="subscribeForm_cancelBtn" />
            </EkButton>
            <EkButton
              formRowItemKey="subscribeForm_submitBtn"
              type="submit"
              id="submit_button"
              customClass="subscribeForm_submitBtn"
              onAction={submitForm}
              fullWidth
            >
              <FormattedMessage id="subscribeForm_submitBtn" />
            </EkButton>
          </FormRow>
        </form>
      </div>
    );
  }
}

SubscribeForm.defaultProps = {
  usedByBackUser: false,
  edit: false
};

const isEditingMember = {
  condition: props => props.edit
};

const isDriverLicenceMandatory = {
  condition: props => !_get(props.companyInfos, 'drivingLicenceRequired')
};

const isBackUserOrEditingMember = {
  condition: (props = {}) => props.edit || props.usedByBackUser
};

SubscribeForm.propTypes = {
  action: PropTypes.func,
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func,
  subscribeError: PropTypes.object,
  customFields: PropTypes.array,
  edit: PropTypes.bool,
  showCarSharingForm: PropTypes.bool,
  resetForm: PropTypes.func.isRequired,
  usedByBackUser: PropTypes.bool
};

const nonCustomFields = [
  'italian',
  'fiscalCode',
  'login',
  'loginConfirm',
  PASSWORD_FIELD_NAME,
  PASSWORD_CONFIRM_FIELD_NAME,
  'firstName',
  'lastName',
  'mobilePhonePrefix',
  'phoneNumber',
  'birthDate',
  'driverLicence',
  'driverLicenceBack',
  'driverLicenceNumber',
  'expirationDate',
  'optionalExpirationDate',
  'deliveranceDate',
  'cityDeliverance',
  'tosDownload',
  'privacePolicyAccepted',
  'lang',
  'commercialOffers',
  'managerEmail',
  ..._values(ADDR)
];

function privacyPolicyUrlPresent(props) {
  const { companyInfos } = props || {};
  const { privacyPolicyUrl } = companyInfos || {};
  return !!privacyPolicyUrl;
}

const emailCheck = props => {
  const { edit } = props || {};

  if (!edit) {
    return {
      // asyncBlurFields is needed if we want an async validator on blur. Use onSubmit validation otherwise.
      asyncBlurFields: ['login'],
      asyncValidate: createAsyncValidator({
        login: [
          emailIsAvailable({
            errorCases: ['both', 'front'],
            callBack: function(obj) {
              const store = getStore();
              store.dispatch(setUsedByBackUser(obj.usedByBackUser));
              store.dispatch(setInitialLoginValue(obj.login));
            }
          })
        ]
      })
    };
  }
};

const addressValidator = {
  [ADDR.STREET]: [notEmpty(), length({ max: 200 })],
  [ADDR.CITY]: [notEmpty(), length({ max: 200 })],
  [ADDR.COUNTRY]: [length({ max: 200 })],
  [ADDR.COUNTRY_ISO]: [notEmpty(ADDRESS_AUTO_COMPLETE_MISSING)],
  [ADDR.POSTAL_CODE]: [notEmpty(), length({ max: 200 })]
};

export default namedCompose(
  reduxForm(
    {
      onSubmitFail: scrollToFirstError,
      form: 'subscribe',
      ...emailCheck()
    },
    state => {
      const {
        company: { companyInfos, companyId },
        user: { worldPayReturnInfos, paymentUrl, openedCreditCardModal, drivingLicenceFiles }
      } = state;

      const { useExternalInvoiceSystem } = companyInfos || {};
      const customFields = visibleSubscriptionCustomFieldsSelector(state);

      const expirationDateDisabled = {
        condition: props => {
          const {
            form: { optionalExpirationDate }
          } = props;
          return _get(optionalExpirationDate, 'value');
        }
      };
      const sendManagerEmailDisabled = {
        condition: () => {
          return !_get(companyInfos, 'sendEmailsToManager');
        }
      };

      const nonCustomFieldsValidation = {
        login: [stopValidationIf(isEditingMember), notEmpty(), email(), length({ max: 80 })],
        fiscalCode: [stopValidationIf(conditionItalianIsFalse), notEmpty()],
        loginConfirm: [
          stopValidationIf(isBackUserOrEditingMember),
          notEmpty(),
          email(),
          length({ max: 80 }),
          match('login')
        ],
        password: [stopValidationIf(isEditingMember), notEmpty(), password(), length({ min: 8, max: 25 })],
        confirmationPassword: [
          stopValidationIf(isEditingMember),
          notEmpty(),
          password(),
          length({ min: 8, max: 25 }),
          match(PASSWORD_FIELD_NAME)
        ],
        firstName: [
          stopValidationIf({
            condition: props => {
              return props.usedByBackUser;
            }
          }),
          notEmpty(),
          length({ min: 2, max: 25 })
        ],
        lastName: [
          stopValidationIf({
            condition: props => {
              return props.usedByBackUser;
            }
          }),
          notEmpty(),
          length({ min: 2, max: 25 })
        ],
        phoneNumber: [
          stopValidationIf({
            condition: props => {
              return props.usedByBackUser;
            }
          }),
          notEmptyExternal('mobilePhonePrefix'),
          notEmpty(),
          integer()
        ],
        birthDate: [stopValidationIf(isDriverLicenceMandatory), validBirthDate(), notEmpty()],
        driverLicence: [stopValidationIf(isDriverLicenceMandatory), notEmpty(), driverLicence()],
        driverLicenceBack: [stopValidationIf(isDriverLicenceMandatory), notEmpty(), driverLicence()],
        driverLicenceNumber: [stopValidationIf(isDriverLicenceMandatory), notEmpty(), length({ max: 25 })],
        expirationDate: [
          stopValidationIf(isDriverLicenceMandatory),
          stopValidationIf(expirationDateDisabled),
          notEmpty(),
          validDate()
        ],
        deliveranceDate: [stopValidationIf(isDriverLicenceMandatory), notEmpty(), validDate()],
        cityDeliverance: [stopValidationIf(isDriverLicenceMandatory), notEmpty()],
        tosDownload: [truthy()],
        privacePolicyAccepted: [stopValidationIf(condition(not(privacyPolicyUrlPresent))), truthy()],
        managerEmail: [stopValidationIf(sendManagerEmailDisabled), notEmpty()],
        ...addressValidator
      };

      return {
        locale: localeSelector(state),
        companyInfos,
        useExternalInvoiceSystem,
        worldPayReturnInfos,
        paymentUrl,
        openedCreditCardModal,
        drivingLicenceFiles,
        companyId,
        customFields,
        // redux-form props
        validate: createValidator({
          ...nonCustomFieldsValidation,
          ...customFieldsValidatorsSelector(customFields, state.form.subscribe)
        }),
        fields: [...nonCustomFields, ...selectCustomFieldNames(customFields)]
      };
    }
  ),
  injectIntl,
  pure
)(SubscribeForm);
