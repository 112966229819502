import * as types from '../constants/actionTypes-constants';
import { I18N_LOADING_FAILED } from '../constants/errors-constants';
import config from '../constants/config-constants';
import { getStore } from '../store/all-store';
import { getQueryParams } from '../utils/utils';

export function setLanguage(locale) {
  const queryParams = getQueryParams();

  if (!locale && queryParams.locale) {
    locale = queryParams.locale;
  }
  if (!locale) {
    locale = window.localStorage.getItem('locale');
  }
  if (!locale) {
    locale = config.browserLocale;
  }
  locale = config.theme.availableLanguages.indexOf(locale) !== -1 ? locale : config.defaultLocale;

  return (dispatch, getState) => {
    dispatch({
      type: types.I18N_BUNDLE_REQUEST,
      locale
    });

    const fileName = config.i18nRevManifest[locale + '-i18n.json'];
    return fetch(`i18n/${fileName}`, {
      method: 'GET'
    })
      .then(res => {
        if (res.status !== 200) {
          throw {
            name: I18N_LOADING_FAILED,
            code: res.status
          };
        }
        return res;
      })
      .then(res => res.json())
      .then(bundle => {
        // for some reason, dispatch is not logged by useLogger if we don't use the dispatch straight from the store
        return getStore().dispatch({
          type: types.I18N_BUNDLE_SUCCESS,
          locale,
          bundle
        });
      });
  };
}
