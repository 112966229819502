import React, { Component } from 'react';
import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import BaseTitle from '../../components/BaseTitle/BaseTitle';
import TabsItems from '../../components/TabsItems/TabsItems';
import ProfileForm from '../../components/ProfileForm/ProfileForm';
import PasswordForm from '../../components/PasswordForm/PasswordForm';
import DriverForm from '../../components/DriverForm/DriverForm';
import PaymentSection from '../../components/PaymentSection/PaymentSection';
import ParametersForm from '../../components/ParametersForm/ParametersForm';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import _get from 'lodash/object/get';
import {
  requestEditProfile,
  callEditProfileSuccess,
  requestEditDriverLicence,
  callEditDriverLicenceSuccess,
  updateUserAccountActiveTabIndex,
  addFlashMessage,
  scrollToTop,
  requestEditPassword,
  callEditPasswordSuccess,
  requestEditSettings,
  callEditSettingsSuccess,
  hideUpload
} from '../../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS, FLASH_MESSAGE_TYPE_ERROR } from '../../constants/generic-constants';
import { bundleSelector, drivingLicenceRequiredSelector } from '../../selectors/all-selectors';
import { addErrorMessage } from '../../utils/utils';
import { partialErrorCodes } from '../../constants/options-constants';
import {
  DRIVER_TAB_FORM_NAME,
  PROFILE_TAB_FORM_NAME,
  PARAMETERS_TAB_FORM_NAME
} from '../../constants/generic-constants';

class Account extends Component {
  constructor(props) {
    super(props);
    this.handleSaveProfile = this.handleSaveProfile.bind(this);
    this.handleSaveDriverLicence = this.handleSaveDriverLicence.bind(this);
    this.handleSavePassword = this.handleSavePassword.bind(this);
    this.handleSaveParameters = this.handleSaveParameters.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.needPaymentTab = this.needPaymentTab.bind(this);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(destroy(PROFILE_TAB_FORM_NAME));
    dispatch(destroy(DRIVER_TAB_FORM_NAME));
    dispatch(destroy(PARAMETERS_TAB_FORM_NAME));
  }

  handleSaveProfile() {
    const { dispatch, bundle } = this.props;
    dispatch(requestEditProfile()).then(
      userInfo =>
        Promise.all([
          dispatch(callEditProfileSuccess(userInfo)),
          dispatch(
            addFlashMessage({
              contentKey: 'profileForm_update_member_succeeded',
              type: FLASH_MESSAGE_TYPE_SUCCESS
            })
          )
        ]),
      error => {
        const { drivingLicenseMissing, phoneNumberInvalid } = partialErrorCodes;
        const partialErrors = [drivingLicenseMissing, phoneNumberInvalid];
        dispatch(addErrorMessage({ error, bundle, partialErrors, def: 'profileForm_update_member_error' }));
      }
    );
  }

  handleSaveDriverLicence() {
    const { dispatch, bundle } = this.props;
    dispatch(requestEditDriverLicence()).then(
      userInfo =>
        Promise.all([
          dispatch(callEditDriverLicenceSuccess(userInfo)),
          dispatch(hideUpload()),
          dispatch(
            addFlashMessage({
              contentKey: 'driverForm_update_driver_licence_success',
              type: FLASH_MESSAGE_TYPE_SUCCESS
            })
          )
        ]),
      error => dispatch(addErrorMessage({ error, bundle, def: 'driverForm_update_driver_licence_error' }))
    );
  }

  handleSavePassword() {
    const { dispatch } = this.props;
    dispatch(requestEditPassword())
      .then(() => {
        dispatch(callEditPasswordSuccess());
        dispatch(
          addFlashMessage({
            contentKey: 'passwordForm_password_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
        dispatch(scrollToTop);
      })
      .catch(error => {
        if (!error.type) {
          // make sure native errors not swallowed
          console.error(error.stack); // eslint-disable-line
        }
        dispatch(
          addFlashMessage({
            contentKey: 'passwordForm_password_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
        dispatch(scrollToTop);
      });
  }

  handleSaveParameters() {
    const { dispatch, bundle } = this.props;
    dispatch(requestEditSettings()).then(
      () =>
        Promise.all([
          dispatch(callEditSettingsSuccess()),
          dispatch(
            addFlashMessage({
              contentKey: 'parametersForm_update_success',
              type: FLASH_MESSAGE_TYPE_SUCCESS
            })
          )
        ]),
      error => dispatch(addErrorMessage({ error, bundle, def: 'parametersForm_update_failed' }))
    );
  }

  handleChangeTab(tab) {
    const { dispatch } = this.props;
    dispatch(updateUserAccountActiveTabIndex(tab.id));
  }

  computeHideIndex() {
    let hideArray = [];
    if (!this.props.drivingLicenceRequired) {
      hideArray.push(3);
    }
    if (!this.needPaymentTab()) {
      hideArray.push(2);
    }
    return hideArray;
  }

  needPaymentTab() {
    const { userInfo } = this.props;
    return _get(userInfo.company, 'contract.privateCarSharing');
  }

  render() {
    const { accountActiveTabIndex, settings, userInfo } = this.props;

    const tabList = [
      { id: 0, name: 'account_stepper_profile' },
      { id: 1, name: 'account_stepper_password' },
      { id: 2, name: 'account_stepper_payment' },
      { id: 3, name: 'account_stepper_driver' },
      { id: 4, name: 'account_stepper_settings' }
    ];

    let favoriteSiteName = '';
    if (userInfo.company && userInfo.company.sites && userInfo.defaultSite) {
      favoriteSiteName = userInfo.company.sites[0].name;
    }

    let initialSettings = {};
    if (settings) {
      initialSettings = settings;
      initialSettings.defaultSite = favoriteSiteName;
    }

    return (
      <div className="AccountPage">
        <Header />
        <FlashMessageDisplayer />

        <div className="mainContent">
          <div className="mainContent_inner">
            <BaseTitle customClass="baseTitle--variant1 AccountPage_title" title="account_title" />

            <TabsItems
              tabList={tabList}
              onChangeTab={this.handleChangeTab}
              currentTab={accountActiveTabIndex}
              actionButtons
              hideIndex={this.computeHideIndex()}
            />

            {accountActiveTabIndex === tabList[0].id ? <ProfileForm onCallback={this.handleSaveProfile} /> : ''}

            {accountActiveTabIndex === tabList[1].id ? <PasswordForm onCallback={this.handleSavePassword} /> : ''}

            {accountActiveTabIndex === tabList[2].id ? <PaymentSection /> : ''}

            {accountActiveTabIndex === tabList[3].id ? <DriverForm onCallback={this.handleSaveDriverLicence} /> : ''}

            {accountActiveTabIndex === tabList[4].id ? (
              <ParametersForm
                onCallback={this.handleSaveParameters}
                initialValues={{
                  ...initialSettings,
                  commercialOffers: userInfo.commercialOffers
                }}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

Account.displayName = 'Account';

Account.propTypes = {};

export default connect(state => {
  const {
    user: { editProfileError, accountActiveTabIndex, settings, userInfo }
  } = state;

  return {
    editProfileError,
    accountActiveTabIndex,
    settings,
    userInfo,
    drivingLicenceRequired: drivingLicenceRequiredSelector(state),
    bundle: bundleSelector(state)
  };
})(Account);
