export const FLASH_MESSAGE_TYPE_INFO = 'FLASH_MESSAGE_TYPE_INFO';
export const FLASH_MESSAGE_TYPE_WARNING = 'FLASH_MESSAGE_TYPE_WARNING';
export const FLASH_MESSAGE_TYPE_ERROR = 'FLASH_MESSAGE_TYPE_ERROR';
export const FLASH_MESSAGE_TYPE_SUCCESS = 'FLASH_MESSAGE_TYPE_SUCCESS';

export const FILE_UPLOADING = 'FILE_UPLOADING';
export const FILE_UPLOADING_SUCCESS = 'FILE_UPLOADING_SUCCESS';
export const FILE_UPLOADING_ERROR = 'FILE_UPLOADING_ERROR';

export const ERROR = 'ERROR';
export const UPLOADING = 'UPLOADING';
export const SUCCESS = 'SUCCESS';
export const FILE = 'FILE';
export const FRONT = 'FRONT';
export const GEO_MISSING = 'GEO_MISSING';

export const PASSWORD_FIELD_NAME = 'password';
export const PASSWORD_CONFIRM_FIELD_NAME = 'confirmationPassword';

export const DATE_PLACEHOLDER = 'dd/mm/yyyy';

export const DRIVER_LICENCE_FRONT_ID = 'driverLicence';
export const DRIVER_LICENCE_BACK_ID = 'driverLicenceBack';

export const PROFILE_TAB_FORM_NAME = 'editProfile';
export const DRIVER_TAB_FORM_NAME = 'editDriverLicence';
export const PARAMETERS_TAB_FORM_NAME = 'parametersForm';

export const MATERIAL_LOCALE_MAP = {
  pt_BR: 'pt-br'
};
