import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';

class FieldErrorMsg extends Component {
  render() {
    const { field, customClass } = this.props;

    if (!field || !field.touched || !field.error || field.__focused) {
      return null;
    }

    return (
      <div className={`fieldErrorMsg ${customClass || ''}`}>
        <FormattedMessage id={field.error.type.toLowerCase()} values={field.error.data} />
      </div>
    );
  }
}

FieldErrorMsg.displayName = 'FieldErrorMsg';

FieldErrorMsg.propTypes = {
  field: PropTypes.object,
  customClass: PropTypes.string
};

export default FieldErrorMsg;
