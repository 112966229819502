import { createSelector } from 'reselect';
import _get from 'lodash/object/get';
import _filter from 'lodash/collection/filter';
import _reduce from 'lodash/collection/reduce';
import _find from 'lodash/collection/find';
import _uniq from 'lodash/array/uniq';
import _memoize from 'lodash/function/memoize';
import _includes from 'lodash/collection/includes';
import _clone from 'lodash/lang/clone';
import _has from 'lodash/object/has';
import countries from 'i18n-iso-countries';

import {
  CUSTOM_FIELD_FORM_SUBSCRIPTION,
  BOOKING_TYPE_RIDE_SHARING,
  BOOKING_USAGE_TYPE_ALL,
  CUSTOM_FIELD_FORM_BOOKING,
  CUSTOM_FIELD_TYPE_CONDITIONED,
  CUSTOM_FIELD_TYPE_YES,
  CUSTOM_FIELD_TYPE_NUMERIC,
  CUSTOM_FIELD_TYPE_PHONE_NUMBER,
  CUSTOM_FIELD_TYPE_TEXT,
  CUSTOM_FIELD_TYPE_BOOLEAN,
  BE_ADDRESS_PROPS
} from '../constants/backend-constants';
import { customFieldTypes, formatDateToStringDayFirst, isEmpty, toUpperSafe, trySet } from '../utils/utils';
import { integer, length, maximum, minimum, notEmpty, number } from '../validation/sync-validation';
import { PROFILE_TAB_FORM_NAME } from '../constants/generic-constants';
import { addAddressPart } from '../api/data-enhancer';
import { ADDRESS_FIELDS } from '../constants/field-constants';

const customFieldsSelector = state => _get(state, 'company.customFields');

const companySubscriptionCustomFieldsSelector = createSelector(
  customFieldsSelector,
  customFields => _get(customFields, CUSTOM_FIELD_FORM_SUBSCRIPTION) || []
);

const carSharingCustomFieldsSelector = createSelector(
  customFieldsSelector,
  customFields => _get(customFields, CUSTOM_FIELD_FORM_BOOKING) || []
);

export const memberRawCustomFieldsSelector = state => _get(state, 'user.customFields');
export const editProfileForm = state => state.form[PROFILE_TAB_FORM_NAME];

export const memberCustomFieldsSelector = createSelector(
  companySubscriptionCustomFieldsSelector,
  memberRawCustomFieldsSelector,
  editProfileForm,
  (companyFields = [], memberFields = [], profileForm) => {
    const visibleCompanyFields = companyFields.filter(field => {
      const visibility = _get(field, 'visible');

      if (visibility === CUSTOM_FIELD_TYPE_CONDITIONED)
        return fieldMatchParentCondition(field, companyFields, profileForm, 'visibleCondition', memberFields);
      else return visibility === CUSTOM_FIELD_TYPE_YES;
    });

    const flatCopyMemberFields = _clone(memberFields);

    const selectedCustomFields = visibleCompanyFields.map(companyField => {
      const newField = { companyCustomField: companyField };

      flatCopyMemberFields.forEach((memberField, index, object) => {
        if (_get(companyField, 'id') === _get(memberField, 'companyCustomField.id')) {
          trySet(newField, 'value', _get(memberField, 'value'));
          object.splice(index, 1); // delete item when found
        }
      });
      return newField;
    });

    const getFieldPosition = field => _get(field, 'companyCustomField.position', 0);
    return selectedCustomFields.sort((a, b) => getFieldPosition(a) - getFieldPosition(b));
  }
);

function getProfileAddressInitialValues(userAddress = {}, state) {
  const userCountryIso = userAddress[BE_ADDRESS_PROPS.COUNTRY_ISO];
  const userSteetNumber = userAddress[BE_ADDRESS_PROPS.STREET_NUMBER];
  const userSteetName = userAddress[BE_ADDRESS_PROPS.STREET_NAME] || '';

  const locale = localeSelector(state);
  const street = addAddressPart(userSteetNumber, ' ') + userSteetName;

  return {
    [ADDRESS_FIELDS.STREET]: street,
    [ADDRESS_FIELDS.COUNTRY_ISO]: userCountryIso,
    [ADDRESS_FIELDS.CITY]: userAddress[BE_ADDRESS_PROPS.CITY],
    [ADDRESS_FIELDS.POSTAL_CODE]: userAddress[BE_ADDRESS_PROPS.POSTAL_CODE],
    [ADDRESS_FIELDS.COUNTRY]: countries.getName(userCountryIso, locale)
  };
}

export const initialValuesProfileForm = state => {
  let {
    secondaryPhoneNumber,
    firstName,
    lastName,
    phoneNumber,
    login,
    address,
    company,
    birthDate,
    managerEmail,
    italianInvoicing
  } = state.user.userInfo;

  const { italian, fiscalCode } = italianInvoicing || {};

  let initialValues = {
    mobilePhonePrefix: phoneNumber ? phoneNumber.countryCode : '',
    secondMobilePhonePrefix: secondaryPhoneNumber ? secondaryPhoneNumber.countryCode : '',
    firstName,
    lastName,
    phoneNumber: phoneNumber ? phoneNumber.nationalNumber : '',
    secondPhoneNumber: secondaryPhoneNumber ? secondaryPhoneNumber.nationalNumber : '',
    email: login,
    company: company ? company.name : '',
    italian,
    fiscalCode
  };

  trySet(initialValues, 'managerEmail', managerEmail);

  initialValues = { ...initialValues, ...getProfileAddressInitialValues(address, state) };

  if (!drivingLicenceRequiredSelector(state)) {
    initialValues.birthDate = birthDate ? formatDateToStringDayFirst(birthDate) : '';
  }

  const customFields = state.user.customFields;

  if (customFields) {
    customFields.map((field = {}) => {
      const id = _get(field, 'companyCustomField.id');
      initialValues[id] = field.value;
    });
  }

  return initialValues;
};

const filterVisible = field => _get(field, 'visible') === CUSTOM_FIELD_TYPE_YES;
const currentBookingSelector = state => _get(state, 'booking.currentBooking');

export const bundleSelector = state => state.i18n.bundle || {};
export const localeSelector = state => state.i18n.locale || 'en';

export const hotlineSelector = state => _get(state.user.userInfo, 'company.computedConfiguration.hotline');

export const subscribeFormSelector = state => state.form.subscribe;

// get only visible fields
export const visibleSubscriptionCustomFieldsSelector = createSelector(
  companySubscriptionCustomFieldsSelector,
  subscribeFormSelector,
  (companyFields = [], subscribeForm) =>
    companyFields.reduce((fields, field) => {
      const visibility = _get(field, 'visible');

      if (visibility === CUSTOM_FIELD_TYPE_CONDITIONED) {
        const status = fieldMatchParentCondition(field, companyFields, subscribeForm, 'visibleCondition');
        if (status) fields.push(field);
      } else if (visibility === CUSTOM_FIELD_TYPE_YES) {
        fields.push(field);
      }
      return fields;
    }, [])
);

export const bookingCustomFieldsSelector = createSelector(
  carSharingCustomFieldsSelector,
  currentBookingSelector,
  (fields, booking) => {
    const visibleFields = _filter(fields, filterVisible);
    const bookingUsageType = _get(booking, 'carSharingInfo.usageType');
    const bookingType = _get(booking, 'type');

    if (bookingType === BOOKING_TYPE_RIDE_SHARING || !bookingUsageType) return [];

    return _filter(visibleFields, field => {
      const fieldUsageType =
        _get(field, 'usageType') === BOOKING_USAGE_TYPE_ALL ? bookingUsageType : _get(field, 'usageType');
      return fieldUsageType === bookingUsageType;
    });
  }
);

export const bookingListSelector = state => _get(state, 'booking.lists');

export const bookingListUniqueSelector = createSelector(
  bookingListSelector,
  lists => {
    let carSharings = [];
    let all = _uniq(_get(lists, 'all'), item => item._id) || [];
    let rideSharings = _uniq(_get(lists, 'rideSharings'), item => item._id) || [];

    carSharings.private = _uniq(_get(lists, 'carSharings.private'), item => item._id) || [];
    carSharings.business = _uniq(_get(lists, 'carSharings.business'), item => item._id) || [];

    return {
      ...lists,
      all,
      rideSharings,
      carSharings
    };
  }
);

export const customFieldsSubscribeIdsValues = (state, useFormData = false) => {
  return _reduce(
    memberCustomFieldsSelector(state),
    (filtered, field) => {
      const newProps = {};

      const id = _get(field, 'companyCustomField.id');
      const target = useFormData ? `form.${PROFILE_TAB_FORM_NAME}[${id}].value` : 'value';
      const source = useFormData ? state : field;
      const value = _get(source, target);

      if (!isEmpty(value) && id) {
        newProps.companyCustomFieldId = id;
        newProps.value = value;
        filtered.push(newProps);
      }

      return filtered;
    },
    []
  );
};

const memberDrivingDetails = state => _get(state.user.userInfo, 'drivingLicence');

export const driverLicenceSelector = createSelector(
  memberDrivingDetails,
  (member = []) => {
    const { cityDeliverance, deliveranceDate, expirationDate, files, licenceNumber, status, validated } = member;
    let initialValues = {
      cityDeliverance,
      deliveranceDate,
      expirationDate,
      files,
      licenceNumber,
      status,
      validated
    };
    return initialValues;
  }
);

export const drivingLicenceSelector = state => {
  const {
    cityDeliverance,
    deliveranceDate,
    expirationDate,
    files,
    licenceNumber,
    status,
    validated
  } = state.user.userInfo.drivingLicence;

  let initialValues = {
    cityDeliverance,
    deliveranceDate,
    expirationDate,
    files,
    licenceNumber,
    status,
    validated
  };
  return initialValues;
};
export const drivingLicenceFilesSelector = state => _get(state.user, 'drivingLicenceFiles');

export const fileTypeSelector = createSelector(
  (state, type) => [memberDrivingDetails(state), type],
  ([licence, type]) => {
    const files = _get(licence, 'files');
    return _find(files, 'type', type);
  }
);

export const driverFileSelector = createSelector(
  (state, id) => [drivingLicenceFilesSelector(state), id],
  ([files, id]) => {
    const idEqual = file => _get(file, 'id') === id;
    const file = _find(files, idEqual);
    const mimeType = _get(file, 'mimeType');
    const content = _get(file, 'content');
    return {
      content: mimeType && content ? `data:${mimeType};base64,${content}` : '',
      mimeType: mimeType,
      name: _get(file, 'name')
    };
  }
);

export const companyInfosSelector = createSelector(
  state => state.user.userInfo,
  state => state.company.companyInfos,
  (userInfo, companyInfos) => _get(userInfo, 'company') || companyInfos
);

export const drivingLicenceRequiredSelector = createSelector(
  state => state.company.companyInfos,
  companyInfos => !(_get(companyInfos, 'drivingLicenceRequired') === false)
);

export const parentFieldIdSelector = _memoize((childField, customFields = [], type) => {
  const parentPosition = _get(childField, type + '.dependsOnPosition');
  const predicate = item => _get(item, 'position') === parentPosition;
  return _get(customFields.filter(predicate), '[0].id');
});

export const fieldMatchParentCondition = (field, customFields, formState, type, initialFields = []) => {
  const idField = parentFieldIdSelector(field, customFields, type);
  let parentField = _get(formState, idField);

  if (!_has(parentField, 'value')) {
    parentField = initialFields.filter(item => _get(item, 'companyCustomField.id') === idField);
    if (parentField) parentField = parentField[0];
  }

  const formFieldValue = _get(parentField, 'value') === 'true';
  const expectedValue = _get(field, type + '.expectedValueForPosition') === 'true';
  return formFieldValue === expectedValue;
};

export function customFieldsValidatorsSelector(customFields = [], formState, customFieldsMember = []) {
  return _reduce(
    customFields,
    (computedValidation, field) => {
      const fieldType = _get(field, 'fieldType');

      if (!_includes(customFieldTypes, fieldType)) return computedValidation;

      const validators = [];
      const fieldMin = _get(field, 'min');
      const fieldMax = _get(field, 'max');
      const mandatoryType = _get(field, 'mandatory');
      const fieldId = _get(field, 'id');

      if (fieldType !== CUSTOM_FIELD_TYPE_BOOLEAN) {
        if (mandatoryType === CUSTOM_FIELD_TYPE_CONDITIONED) {
          if (fieldMatchParentCondition(field, customFields, formState, 'mandatoryCondition', customFieldsMember))
            validators.push(notEmpty());
        }
        if (mandatoryType === CUSTOM_FIELD_TYPE_YES) validators.push(notEmpty());
      }

      if (fieldType === CUSTOM_FIELD_TYPE_NUMERIC) {
        validators.push(number());
        typeof fieldMin === 'number' && validators.push(minimum(fieldMin));
        typeof fieldMax === 'number' && validators.push(maximum(fieldMax));
      }

      if (fieldType === CUSTOM_FIELD_TYPE_PHONE_NUMBER) {
        validators.push(integer());
      }

      if (fieldType === CUSTOM_FIELD_TYPE_TEXT) {
        const lengthValidator = length({ min: fieldMin || 0, max: fieldMax || 255 });
        validators.push(lengthValidator);
      }

      if (fieldId) computedValidation[fieldId] = validators;

      return computedValidation;
    },
    {}
  );
}

export const selectCustomFieldNames = (customFields = []) => customFields.map((field = {}) => field.id);
