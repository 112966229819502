import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import Modal from 'react-modal';

import EkButton from '../EkButton/EkButton';
import BaseTitle from '../../components/BaseTitle/BaseTitle';
import LabeledCheckbox from '../../components/LabeledCheckbox/LabeledCheckbox';
import BoxedSelect from '../../components/BoxedSelect/BoxedSelect';
import Rating from '../../components/Rating/Rating';
import FieldErrorMsg from '../../components/FieldErrorMsg/FieldErrorMsg';
import { closeDamageReportingModal, requestCreateFeedBack, addFlashMessage } from '../../actions/all-actions';
import { createValidator, notEmpty, stopValidationIf } from '../../validation/all-validation';
import { FLASH_MESSAGE_TYPE_SUCCESS, FLASH_MESSAGE_TYPE_ERROR } from '../../constants/generic-constants';
import { scrollToFirstError } from '../../utils/utils';

const modalWidth = 680;

const modalStyles = {
  content: {
    maxHeight: '90%',
    width: modalWidth,
    position: 'absolute',
    right: 'auto',
    bottom: 'auto',
    top: '50%',
    left: `calc(50% - ${modalWidth >> 1}px)`,
    transform: 'translateY(-50%)'
  }
};

class DamageReportingModal extends Component {
  constructor() {
    super();
    this.handleCancelReporting = this.handleCancelReporting.bind(this);
    this.handleSendReporting = this.handleSendReporting.bind(this);
  }

  componentDidMount() {
    document.querySelector('body').style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.querySelector('body').style.overflow = '';
  }

  handleCancelReporting() {
    const { dispatch, booking } = this.props;
    dispatch(closeDamageReportingModal(booking));
  }

  handleSendReporting() {
    const { dispatch, booking } = this.props;
    dispatch(requestCreateFeedBack(booking.id))
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'damageReportingForm_sendReporting_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS
          })
        );
      })
      .catch(error => {
        if (!error.type) {
          // make sure native errors not swallowed
          console.error(error.stack); // eslint-disable-line
        }
        dispatch(
          addFlashMessage({
            contentKey: 'damageReportingForm_sendReporting_failed',
            type: FLASH_MESSAGE_TYPE_ERROR
          })
        );
      });
    dispatch(closeDamageReportingModal(booking));
  }

  render() {
    const {
      fields: { reportDamage, damageType, message, cleanlinessInside, cleanlinessOutside, comment },
      booking,
      openedDamageReportingModalBooking,
      handleSubmit
    } = this.props;

    let damageTypes = [
      {
        labelKey: 'damageReportingForm_damageType_DAMAGE',
        value: 'DAMAGE'
      },
      {
        labelKey: 'damageReportingForm_damageType_DYSFUNCTION',
        value: 'DYSFUNCTION'
      },
      {
        labelKey: 'damageReportingForm_damageType_MINOR_SCRAPES',
        value: 'MINOR_SCRAPES'
      },
      {
        labelKey: 'damageReportingForm_damageType_OTHER',
        value: 'OTHER'
      }
    ];

    return (
      <Modal isOpen={openedDamageReportingModalBooking.id === booking.id} style={modalStyles}>
        <div className="damageReportingModal">
          <form
            action="#"
            method="post"
            className="damageReportingForm"
            onSubmit={handleSubmit(this.handleSendReporting)}
            noValidate
          >
            <BaseTitle customClass="damageReportingForm_title" title="damageReportingForm_title" skinType="variant1" />

            <section className="damageReportingForm_section">
              <Rating
                titleKey="damageReportingForm_cleanliness_inside"
                namespace="cleanlinessInside_rating"
                field={cleanlinessInside}
              />
              <FieldErrorMsg field={cleanlinessInside} customClass="fieldErrorMsg--damageReportingForm" />

              <Rating
                titleKey="damageReportingForm_cleanliness_outside"
                namespace="cleanlinessOutside_rating"
                field={cleanlinessOutside}
              />
              <FieldErrorMsg field={cleanlinessOutside} customClass="fieldErrorMsg--damageReportingForm" />

              {cleanlinessInside.value < 3 || cleanlinessOutside.value < 3 ? (
                <label formRowItemKey="damageReportingForm_message_label" htmlFor="description">
                  <textarea
                    className="damageReportingForm_ekTextarea"
                    name="description"
                    id="description"
                    rows="3"
                    placeholder="Write your message, describe the uncleanliness..."
                    field={comment}
                    {...comment}
                  ></textarea>

                  <FieldErrorMsg field={comment} customClass="fieldErrorMsg--damageReportingForm" />
                </label>
              ) : (
                ''
              )}

              <LabeledCheckbox
                formRowItemKey="damageReportingForm_reportDamage"
                id="reportDamage"
                customClass="damageReportingForm_reportDamageOption"
                customLabelClass="damageReportingForm_reportDamageOptionLabel"
                field={reportDamage}
              >
                <FormattedMessage id="damageReportingForm_report_damage" />
              </LabeledCheckbox>
            </section>

            {reportDamage.checked ? (
              <section className="damageReportingForm_section">
                <h3 className="damageReportingForm_section_title">
                  <FormattedMessage id="damageReportingForm_damage_title" />
                </h3>

                <BoxedSelect
                  formRowItemKey="damageReportingForm_damageType"
                  labelKey="damageReportingForm_damage_title"
                  options={damageTypes}
                  customClass="damageReportingForm_boxedSelectWrapper"
                  customLabelClass="damageReportingForm_boxedSelectLabel"
                  hideLabel
                  field={damageType}
                >
                  <FieldErrorMsg field={damageType} customClass="fieldErrorMsg--damageReportingForm" />
                </BoxedSelect>

                <label formRowItemKey="damageReportingForm_message_label" htmlFor="message">
                  <textarea
                    className="damageReportingForm_ekTextarea"
                    name="message"
                    id="message"
                    rows="3"
                    placeholder="Write your message, describe the damage..."
                    field={message}
                    {...message}
                  ></textarea>

                  <FieldErrorMsg field={message} customClass="fieldErrorMsg--damageReportingForm" />
                </label>
              </section>
            ) : (
              ''
            )}

            <div className="damageReportingForm_actionsButton">
              <EkButton
                type="button"
                skinType="reverse"
                customClass="damageReportingForm_button"
                onAction={this.handleCancelReporting}
              >
                <FormattedMessage id="damageReportingForm_cancel_button" />
              </EkButton>

              <EkButton
                type="button"
                customClass="damageReportingForm_button"
                onAction={handleSubmit(this.handleSendReporting)}
              >
                <FormattedMessage id="damageReportingForm_sendReporting_button" />
              </EkButton>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

DamageReportingModal.displayName = 'DamageReportingModal';

DamageReportingModal.propTypes = {
  booking: PropTypes.object,
  handleSubmit: PropTypes.func,
  reportDamage: PropTypes.bool
};

DamageReportingModal = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'damageReportingForm',
    fields: ['reportDamage', 'damageType', 'message', 'cleanlinessInside', 'cleanlinessOutside', 'comment'],
    validate: createValidator({
      cleanlinessInside: [notEmpty()],
      cleanlinessOutside: [notEmpty()],
      damageType: [
        stopValidationIf({
          condition: props => {
            return props.form.reportDamage ? !props.form.reportDamage.value : true;
          }
        }),
        notEmpty()
      ],
      message: [
        stopValidationIf({
          condition: props => {
            return props.form.reportDamage ? !props.form.reportDamage.value : true;
          }
        }),
        notEmpty()
      ],
      comment: [
        stopValidationIf({
          condition: props => {
            if (props.form.cleanlinessOutside && props.form.cleanlinessInside) {
              return props.form.cleanlinessOutside.value >= 3 && props.form.cleanlinessInside.value >= 3 ? true : false;
            }
          }
        }),
        notEmpty()
      ]
    })
    // asyncBlurFields is needed if we want an async validator on blur. Use onSubmit validation otherwise.
  },
  state => {
    // mapStateToProps
    return {
      // Here to fix issue with form initialisation & validators
      initialValues: {
        damageType:
          state.booking.feedBack && state.booking.feedBack.report ? state.booking.feedBack.report.type : 'DAMAGE',
        message: state.booking.feedBack && state.booking.feedBack.report ? state.booking.feedBack.report.comment : '',
        comment: state.booking.feedBack && state.booking.feedBack.comment ? state.booking.feedBack.comment : '',
        reportDamage: state.booking.feedBack && state.booking.feedBack.report ? true : false,
        cleanlinessInside: state.booking.feedBack ? state.booking.feedBack.internalCleanliness : '',
        cleanlinessOutside: state.booking.feedBack ? state.booking.feedBack.externalCleanliness : ''
      }
    };
  }
)(DamageReportingModal);

export default connect(state => {
  const {
    booking: { openedDamageReportingModalBooking }
  } = state;

  return {
    openedDamageReportingModalBooking
  };
})(DamageReportingModal);
