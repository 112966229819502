export const NOTIFICATION_TYPE_FINE = 'FINE';

export const BOOKING_USAGE_TYPE_DUAL = 'DUAL';
export const BOOKING_USAGE_TYPE_ALL = 'ALL';

export const BOOKING_TYPE_CAR_SHARING = 'CAR_SHARING';
export const BOOKING_TYPE_RIDE_SHARING = 'RIDE_SHARING';
export const BOOKING_TYPE_SHUTTLE = 'SHUTTLE';

export const BOOKING_START_DATE = 'START_DATE';
export const BOOKING_STATUS_PAYMENT_UNPAID = 'UNPAID';
export const BOOKING_STATUS_COMPLETED = 'COMPLETED';
export const BOOKING_STATE_UPCOMING = 'UPCOMING';
export const BOOKING_STATE_PAST = 'PAST';

export const MEMBER_STATUS_APPROVED = 'APPROVED';
export const MEMBER_STATUS_TO_COMPLETE = 'TO_COMPLETE';

export const CUSTOM_FIELD_TYPE_TEXT = 'TEXT';
export const CUSTOM_FIELD_TYPE_NUMERIC = 'NUMERIC';
export const CUSTOM_FIELD_TYPE_BOOLEAN = 'BOOLEAN';

export const CUSTOM_FIELD_TYPE_CONDITIONED = 'CONDITIONED';
export const CUSTOM_FIELD_TYPE_YES = 'YES';

export const CUSTOM_FIELD_TYPE_PHONE_NUMBER = 'PHONE_NUMBER';
export const CUSTOM_FIELD_TYPE_FILE = 'FILE';
export const CUSTOM_FIELD_FORM_SUBSCRIPTION = 'SUBSCRIPTION';
export const CUSTOM_FIELD_FORM_BOOKING = 'CAR_SHARING_BOOKING';

export const BOOKING_USAGE_TYPE_BUSINESS = 'BUSINESS';
export const BOOKING_USAGE_TYPE_PRIVATE = 'PRIVATE';
export const CAR_TRANSMISSION_TYPE_AUTOMATIC = 'AUTOMATIC';
export const CAR_TRANSMISSION_TYPE_MANUAL = 'MANUAL';
export const CAR_FUEL_TYPE_ELECTRIC = 'ELECTRIC';
export const CAR_FUEL_TYPE_PETROL = 'PETROL';
export const CAR_FUEL_TYPE_DIESEL = 'DIESEL';
export const CAR_FUEL_TYPE_HYBRID = 'HYBRID';
export const CAR_FUEL_TYPE_LPG = 'LPG';
export const CAR_ACCESSORY_TYPE_AIR_CONDITIONING = 'AIR_CONDITIONING';
export const CAR_ACCESSORY_TYPE_LARGE_TRUCK = 'LARGE_TRUCK';
export const CAR_ACCESSORY_TYPE_FOLDING_SEATS = 'FOLDING_SEATS';
export const CAR_ACCESSORY_TYPE_RADIO_CD = 'RADIO_CD';
export const CAR_ACCESSORY_TYPE_BLUETOOTH = 'BLUETOOTH';
export const CAR_ACCESSORY_TYPE_GPS = 'GPS';

export const FILE_TYPE_FRONT = 'FRONT';
export const FILE_TYPE_BACK = 'BACK';

export const SORT_DIRECTION_ASC = 'ASC';
export const SORT_DIRECTION_DESC = 'DESC';

export const PROFILE_EDIT_MEMBER_APPROVED =
  'Name, birth info and marital status cannot be changed once the member is APPROVED';

export const BE_ADDRESS_PROPS = {
  POSTAL_CODE: 'postalCode',
  STREET_NAME: 'streetName',
  CITY: 'city',
  COUNTRY_ISO: 'country',
  STREET_NUMBER: 'streetNumber',
  FORMATTED_ADDRESS: 'formattedAddress'
};

export const EXTERNAL_INVOICE_ITALIAN = 'ITALIAN';
