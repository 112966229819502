// This component has been made to handle a customField
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import _get from 'lodash/object/get';
import _find from 'lodash/collection/find';

import {
  localeSelector,
  visibleSubscriptionCustomFieldsSelector,
  subscribeFormSelector,
  fieldMatchParentCondition,
  editProfileForm,
  memberCustomFieldsSelector,
  memberRawCustomFieldsSelector
} from '../../selectors/all-selectors';

import FormField from '../FormField';
import { injectIntl } from 'react-intl';

import {
  CUSTOM_FIELD_TYPE_TEXT,
  CUSTOM_FIELD_TYPE_NUMERIC,
  CUSTOM_FIELD_TYPE_BOOLEAN,
  CUSTOM_FIELD_TYPE_PHONE_NUMBER,
  CUSTOM_FIELD_TYPE_FILE,
  CUSTOM_FIELD_TYPE_CONDITIONED,
  CUSTOM_FIELD_TYPE_YES
} from '../../constants/backend-constants';

import { booleanOptions } from '../../constants/options-constants';

const typeMap = {
  [CUSTOM_FIELD_TYPE_TEXT]: 'text',
  [CUSTOM_FIELD_TYPE_NUMERIC]: 'number',
  [CUSTOM_FIELD_TYPE_BOOLEAN]: 'radio',
  [CUSTOM_FIELD_TYPE_PHONE_NUMBER]: 'text',
  [CUSTOM_FIELD_TYPE_FILE]: 'file'
};

const CustomField = compose(
  connect((state, props) => {
    const ret = {
      locale: localeSelector(state)
    };

    if (props.profile) {
      ret.customFields = memberCustomFieldsSelector(state);
      ret.profileForm = editProfileForm(state);
      ret.initialFields = memberRawCustomFieldsSelector(state);
    }

    if (props.subscribe) {
      ret.customFields = visibleSubscriptionCustomFieldsSelector(state);
      ret.subscribeForm = subscribeFormSelector(state);
    }

    return ret;
  }),
  mapProps(({ fieldInfos, locale, customFields, ...props }) => {
    const type = _get(typeMap, _get(fieldInfos, 'fieldType'), 'unknown');
    const additionalProps = {};
    let label = _get(
      _find(_get(fieldInfos, 'companyCustomFieldLabels'), label => _get(label, 'language') === locale),
      'label'
    );

    if (!label)
      label = props.intl.formatMessage({
        id: _get(fieldInfos, 'fieldType') ? `customFieldType_${fieldInfos.fieldType}` : 'common_unknown'
      });

    if (type === 'radio') {
      additionalProps.id = _get(fieldInfos, 'id');
    }

    if (type === 'file') {
      additionalProps.id = _get(fieldInfos, 'id', null);
      additionalProps.buttonLabel = _get(props, 'value') ? 'common_update' : 'subscribeForm_uploadLicence_label';
    }

    const mandatoryType = _get(fieldInfos, 'mandatory');

    let isMandatory = false;

    if (type !== 'radio') {
      if (mandatoryType === CUSTOM_FIELD_TYPE_YES) isMandatory = true;
      if (mandatoryType === CUSTOM_FIELD_TYPE_CONDITIONED) {
        const form = props.profile ? props.profileForm : props.subscribeForm;
        isMandatory = fieldMatchParentCondition(
          fieldInfos,
          customFields,
          form,
          'mandatoryCondition',
          props.initialFields
        );
      }
    }

    return {
      label,
      type,
      mandatory: isMandatory,
      customLabelClass: 'boxedInput_labelText',
      ...additionalProps,
      ...props
    };
  })
)(FormField);

CustomField.displayName = 'CustomField';

export default injectIntl(CustomField);
