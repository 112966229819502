import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import RaisedButton from 'material-ui/lib/raised-button';
import ReactSVG from 'react-svg';
import { FormattedMessage } from 'react-intl';
import { whiteBrand } from '../../constants/style-constants';
import NewPasswordForm from '../../components/NewPasswordForm/NewPasswordForm';
import { connect } from 'react-redux';
import { changePasswordApply, changePasswordGetUrl } from '../../actions/user-actions';
import autoBind from 'react-autobind';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    const { colour1, colour2, colour3, logo } = this.props.customizations || {};
    const styles = whiteBrand.component.activateAccount;

    this.buttonStyles = styles.button;
    this.mainColor = colour1 || colour2 || colour3 || whiteBrand.colors.default;
    this.logoUrl = logo;
    this.svgStyle = { fill: this.mainColor };
    this.textStyle = { color: this.mainColor };
    this.successIcon = '/img/success.svg';
    this.errorIcon = '/img/error.svg';
  }

  handleSubmit() {
    const { dispatch } = this.props;
    dispatch(changePasswordApply());
  }

  handleResetPassword() {
    const { dispatch } = this.props;
    dispatch(changePasswordGetUrl());
  }

  oneLine(iconSrc, titleKey) {
    return (
      <div className="one-line-container msg-container">
        <ReactSVG src={iconSrc} svgClassName="r-svg" svgStyle={this.svgStyle} />
        <div className="single-line-msg">
          <FormattedMessage id={titleKey} />
        </div>
      </div>
    );
  }

  twoLines(iconSrc, titleKey, contentKey, buttonKey, actionCallback) {
    return (
      <div className="two-line-container msg-container">
        <div className="inner-container">
          <ReactSVG src={iconSrc} svgClassName="r-svg" svgStyle={this.svgStyle} />
          <div className="second-inner-container">
            <h1 className="message-title" style={this.textStyle}>
              <FormattedMessage id={titleKey} />
            </h1>
            <p className="message-content">
              <FormattedMessage id={contentKey} />
            </p>
          </div>
        </div>
        <div className="button-container">
          <RaisedButton
            onMouseUp={actionCallback}
            label={<FormattedMessage id={buttonKey} />}
            labelStyle={this.buttonStyles.label}
            style={this.buttonStyles.root}
            backgroundColor={this.mainColor}
          />
        </div>
      </div>
    );
  }

  displayPage() {
    if (this.props.tokenExpired) {
      return this.twoLines(
        this.errorIcon,
        'validateAccount_expired_title',
        'changePassword_expired_content',
        'validateAccount_request_button',
        this.handleResetPassword
      );
    } else if (this.props.requestSuccess) {
      return this.oneLine(this.successIcon, 'resetPasswordForm_successMsg');
    } else if (this.props.unknownError) {
      return this.oneLine(this.errorIcon, 'error_local_unknown');
    } else if (this.props.taskSuccess) {
      return this.oneLine(this.successIcon, 'resetPassword_update_success');
    } else {
      return <NewPasswordForm formSubmit={this.handleSubmit} whiteBrand={true} />;
    }
  }

  render() {
    return (
      <div className="change-password-page">
        <Header whiteBrand={true} logoUrl={this.logoUrl} />
        <FlashMessageDisplayer />
        <div className="change-password-body">
          <div className="second-container">{this.displayPage()}</div>
        </div>
        <Footer whiteBrand={true} />
      </div>
    );
  }
}

ChangePassword.displayName = 'ChangePassword';

export default connect(state => {
  const {
    user: {
      resetActivateCases: { tokenExpired, taskSuccess, unknownError, requestSuccess }
    },
    company: { customizations }
  } = state;

  return {
    tokenExpired,
    taskSuccess,
    unknownError,
    requestSuccess,
    customizations
  };
})(ChangePassword);
