import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import areIntlLocalesSupported from 'intl-locales-supported';
import DatePicker from 'material-ui/lib/date-picker/date-picker';
import ThemeManager from 'material-ui/lib/styles/theme-manager';
// injectTapEventPlugin needs to be called for datepicker to work!
import injectTapEventPlugin from 'react-tap-event-plugin';
import classNames from 'classnames';

injectTapEventPlugin();
import currentTheme from '../../constants/theme-constants.js';
import MaterialUiTheme from '../../constants/material-ui-theme-constants';
import { getMaterialLocale, setInitialValue } from '../../utils/utils';

let DateTimeFormat;

// Use the native Intl if available
if (areIntlLocalesSupported('fr')) {
  DateTimeFormat = global.Intl.DateTimeFormat;
} else {
  const IntlPolyfill = require('intl');
  require('intl/locale-data/jsonp/fr');

  DateTimeFormat = IntlPolyfill.DateTimeFormat;
}

class BoxedInput extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  getChildContext() {
    if (currentTheme.currentTheme === 'renaultB2B') {
      MaterialUiTheme.palette.primary1Color = '#333';
      MaterialUiTheme.palette.primary2Color = '#ffce00';
    }
    return {
      muiTheme: ThemeManager.getMuiTheme(MaterialUiTheme)
    };
  }

  componentWillMount() {
    this.setInitialValue(this.props);
  }

  componentWillUpdate(props) {
    this.setInitialValue(props);
  }

  bindFunctions() {
    this.setInitialValue = setInitialValue.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateFocus = this.handleDateFocus.bind(this);
    this.handleDateDismiss = this.handleDateDismiss.bind(this);
    this.handleDateBlurFix = this.handleDateBlurFix.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onAction();
  }

  handleDateChange(e, value) {
    // for some reason, event is not received even though the doc says it should.
    if (e) {
      e.preventDefault();
    }

    let date = value;
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    this.props.field.onChange(date);
  }

  handleDateFocus() {
    this.props.field.__focused = true;
  }

  handleDateDismiss() {
    this.props.field.__focused = false;
  }

  handleDateBlurFix() {
    // when we focus on a date input, blur occurred while material-ui widget opens. We need this hack so that the
    // date doesn't get converted to a string at this time.
    this.props.field.onChange(this.props.field.value);
  }

  formatDate(date) {
    let day = ('0' + date.getDate()).slice(-2);
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  render() {
    const { locale } = this.props;

    let actionButton = !this.props.onAction ? null : (
      <button type="button" className="boxedInput_button" onClick={this.handleClick}>
        <FormattedMessage id={this.props.actionText || 'boxedInput_defaultActionMsg'} />
      </button>
    );

    let boxedInputLabel =
      !this.props.label && !this.props.labelKey ? (
        ''
      ) : (
        <span
          className={classNames('boxedInput_labelText', this.props.customLabelTextClass, {
            _is_hidden: this.props.hideLabel
          })}
        >
          {this.props.labelKey ? <FormattedMessage id={this.props.labelKey} /> : this.props.label}
          {!this.props.mandatory ? '' : <span className="boxedInput_mandatory">*</span>}
        </span>
      );

    let inputContent;
    if (this.props.type === 'date') {
      // Specify value attribute to fix initial value bug
      inputContent = !this.props.disabled ? (
        <DatePicker
          hintText={this.props.placeholder}
          className={classNames(
            `boxedInput ${!this.props.type ? '' : `boxedInput--${this.props.type}`}`,
            this.props.customInputClass
          )}
          id={this.props.id}
          {...this.props.field}
          value={this.props.field.value}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          onChange={this.handleDateChange}
          onFocus={this.handleDateFocus}
          onDismiss={this.handleDateDismiss}
          onBlur={this.handleDateBlurFix}
          locale={getMaterialLocale(locale)}
          wordings={{
            ok: <FormattedMessage id="boxedInput_okBtn" />,
            cancel: <FormattedMessage id="boxedInput_cancelBtn" />
          }}
          DateTimeFormat={DateTimeFormat}
          formatDate={this.formatDate}
          firstDayOfWeek={1}
          autoOk
          disableYearSelection={this.props.disableYearSelection}
        />
      ) : (
        <input
          type="text"
          className={classNames(
            `boxedInput ${!this.props.type ? '' : `boxedInput--${this.props.type}`}`,
            this.props.customInputClass
          )}
          id={this.props.id}
          disabled={this.props.disabled}
          value={this.props.field.value ? this.props.field.value.toLocaleDateString() : ''}
        />
      );
    } else {
      inputContent = (
        <input
          type={this.props.showPassword ? 'text' : this.props.type}
          placeholder={this.props.placeholder}
          className={classNames(
            `boxedInput ${!this.props.type ? '' : `boxedInput--${this.props.type}`} ${
              !this.props.readOnly ? '' : '_isReadOnly'
            }`,
            this.props.customInputClass
          )}
          id={this.props.id}
          maxLength={this.props.maxlength}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled}
          value={this.props.field.value}
          onBlur={this.props.field.onBlur}
          onChange={this.props.field.onChange}
        />
      );
    }

    return (
      <div
        className={`boxedInputWrapper
          ${!this.props.skinType ? '' : `boxedInputWrapper--${this.props.skinType}`}
          ${this.props.customClass || ''}`}
      >
        {this.props.type !== 'date' ? (
          <label className="boxedInput_label" htmlFor={this.props.id}>
            {boxedInputLabel}
            <span className="boxedInput_inputText">
              {inputContent}
              {actionButton}
            </span>
          </label>
        ) : (
          <div className="boxedInput_label">
            {boxedInputLabel}
            {inputContent}
            {actionButton}
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

BoxedInput.displayName = 'BoxedInput';

BoxedInput.propTypes = {
  onAction: PropTypes.func,
  actionText: PropTypes.string,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  skinType: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  maxlength: PropTypes.string,
  hideLabel: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  mandatory: PropTypes.bool,
  customLabelTextClass: PropTypes.string,
  customInputClass: PropTypes.string,
  disableYearSelection: PropTypes.bool
};

//the key passed through context must be called "muiTheme"
BoxedInput.childContextTypes = {
  muiTheme: React.PropTypes.object
};

export default connect(state => {
  const {
    i18n: { locale }
  } = state;

  return {
    locale
  };
})(BoxedInput);
