import React, { Component, PropTypes } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import _get from 'lodash/object/get';
import _map from 'lodash/collection/map';
import _values from 'lodash/object/values';

import config from '../../constants/config-constants';
import BaseTitle from '../BaseTitle/BaseTitle';
import FormRow from '../FormRow/FormRow';
import BoxedInput from '../BoxedInput/BoxedInput';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import EkButton from '../EkButton/EkButton';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import CustomField from '../CustomField';
import {
  customFieldsValidatorsSelector,
  drivingLicenceRequiredSelector,
  initialValuesProfileForm,
  localeSelector,
  memberCustomFieldsSelector
} from '../../selectors/all-selectors';
import {
  createValidator,
  notEmpty,
  length,
  integer,
  stopValidationIf,
  validBirthDate
} from '../../validation/all-validation';
import { EXTERNAL_INVOICE_ITALIAN, MEMBER_STATUS_APPROVED } from '../../constants/backend-constants';
import { conditionItalianIsFalse, namedCompose, scrollToFirstError, toBoolean } from '../../utils/utils';
import { notEmptyExternal } from '../../validation/sync-validation';
import { DATE_PLACEHOLDER, PROFILE_TAB_FORM_NAME } from '../../constants/generic-constants';
import { autocompletePlaceholderMessages } from '../../constants/options-constants';
import { ADDRESS_FIELDS as ADDR } from '../../constants/field-constants';
import { ADDRESS_AUTO_COMPLETE_MISSING } from '../../constants/errors-constants';
import RciToggle from '../RciToggle/RciToggle';

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.derivedStateFromProps(props);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    const { useExternalInvoiceSystem } = props;

    this.externalInvoiceItalian = useExternalInvoiceSystem === EXTERNAL_INVOICE_ITALIAN;
    this.isMemberValidated = _get(props.userInfo, 'status') === MEMBER_STATUS_APPROVED;
  }

  getBirthdayField() {
    const {
      fields: { birthDate },
      drivingLicenceRequired
    } = this.props;

    if (!drivingLicenceRequired) {
      return (
        <BoxedInput
          formRowItemKey="driverForm_maxBirthDate"
          id="birthDate"
          customClass="driverForm_boxedInputWrapper--date"
          labelKey="subscribeForm_birthDate_label"
          type="text"
          placeholder={DATE_PLACEHOLDER}
          field={birthDate}
          maxlength="10"
          readOnly={this.isMemberValidated}
        >
          <FieldErrorMsg field={birthDate} customClass="fieldErrorMsg--subscribeForm" />
        </BoxedInput>
      );
    }
  }

  showItalianRow() {
    const { fields } = this.props;
    const { italian, fiscalCode } = fields;
    const { value } = italian || {};
    const italianValue = toBoolean(value);

    if (this.externalInvoiceItalian) {
      return (
        <FormRow customClass="profileForm_row">
          <div className="italianInvoice" formRowItemKey="italianInvoice">
            <RciToggle
              field={italian}
              titleKey="register.italy.resident"
              id="italianInvoice"
              customTitleClass="boxedInput_labelText"
            />
            {italianValue && (
              <BoxedInput
                formRowItemKey="subscribeForm_formatMessage"
                type="text"
                id="fiscalCode"
                labelKey="register.italy.fiscal.code"
                customClass="subscribeForm_boxedInput"
                field={fiscalCode}
                mandatory
              >
                <FieldErrorMsg field={fiscalCode} customClass="fieldErrorMsg--subscribeForm" />
              </BoxedInput>
            )}
          </div>
        </FormRow>
      );
    }
  }

  render() {
    const {
      fields: {
        firstName,
        lastName,
        mobilePhonePrefix,
        phoneNumber,
        secondMobilePhonePrefix,
        secondPhoneNumber,
        email,
        company,
        managerEmail,
        [ADDR.STREET]: street,
        [ADDR.CITY]: city,
        [ADDR.COUNTRY]: country,
        [ADDR.POSTAL_CODE]: postalCode,
        [ADDR.COUNTRY_ISO]: countryIso
      },
      companyInfos,
      fields,
      handleSubmit,
      customFields,
      intl: { formatMessage }
    } = this.props;

    const mobilePhonePrefixes = config.phoneCountryPrefixes;
    const submitForm = handleSubmit(this.props.onCallback);

    return (
      <div className="profileFormPage_form">
        <form className="profileForm" method="post" action="#" onSubmit={submitForm} noValidate>
          <section className="profileForm_section">
            <BaseTitle title="profileForm_profile_title" />

            <div className="profileForm_container">
              <FormRow customClass="profileForm_row">
                <BoxedInput
                  formRowItemKey="profileForm_firstName"
                  type="text"
                  id="firstName"
                  labelKey="profileForm_firstName_label"
                  customClass="profileForm_boxedInput"
                  field={firstName}
                  readOnly={this.isMemberValidated}
                  mandatory
                >
                  <FieldErrorMsg field={firstName} customClass="fieldErrorMsg--profileForm" />
                </BoxedInput>

                <BoxedInput
                  formRowItemKey="profileForm_lastName"
                  type="text"
                  id="lastName"
                  labelKey="profileForm_lastName_label"
                  customClass="profileForm_boxedInput"
                  field={lastName}
                  readOnly={this.isMemberValidated}
                  mandatory
                >
                  <FieldErrorMsg field={lastName} customClass="fieldErrorMsg--profileForm" />
                </BoxedInput>
              </FormRow>

              <FormRow customClass="profileForm_row">
                <BoxedInput
                  formRowItemKey="profileForm_email"
                  type="text"
                  id="email"
                  labelKey="profileForm_email_label"
                  customClass="profileForm_boxedInput"
                  field={email}
                  readOnly
                  mandatory
                />

                <BoxedInput
                  formRowItemKey="profileForm_company"
                  type="text"
                  id="company"
                  labelKey="profileForm_company_label"
                  customClass="profileForm_boxedInput"
                  field={company}
                  readOnly
                  mandatory
                />
              </FormRow>

              {_get(companyInfos, 'sendEmailsToManager') && (
                <FormRow customClass="profileForm_row">
                  <BoxedInput
                    formRowItemKey="profileForm_managerEmail"
                    type="email"
                    id="managerEmail"
                    labelKey="account_manager_email"
                    field={managerEmail}
                    mandatory
                  >
                    <FieldErrorMsg field={managerEmail} customClass="fieldErrorMsg--profileForm" />
                  </BoxedInput>
                </FormRow>
              )}

              <FormRow customClass="profileForm_row">
                <label className="boxedInput_label">
                  <span className="boxedInput_labelText">
                    <FormattedMessage id="profileForm_address_label" />
                    <span className="boxedInput_mandatory">*</span>
                  </span>
                  <AddressAutocomplete
                    className="profileForm_AddressAutocomplete"
                    placeholder={formatMessage(autocompletePlaceholderMessages.generic)}
                    field={street}
                    spreadExternalFields={{
                      cityField: city,
                      countryField: country,
                      countryIsoField: countryIso,
                      postalCodeField: postalCode
                    }}
                  />
                  <FieldErrorMsg field={street} customClass="fieldErrorMsg--profileForm" />
                </label>

                {this.getBirthdayField()}
              </FormRow>

              <FormRow customClass="profileForm_row">
                <BoxedInput
                  formRowItemKey="profileForm_city"
                  type="text"
                  id="city"
                  labelKey="profileForm_city_label"
                  customClass="profileForm_boxedInput"
                  field={city}
                  mandatory
                >
                  <FieldErrorMsg field={city} customClass="fieldErrorMsg--profileForm" />
                </BoxedInput>
                <label className="boxedInput_label">
                  <span className="boxedInput_labelText">
                    <FormattedMessage id="profileForm_country_label" />
                    <span className="boxedInput_mandatory">*</span>
                  </span>
                  <AddressAutocomplete
                    className="profileForm_AddressAutocomplete"
                    placeholder={formatMessage(autocompletePlaceholderMessages.generic)}
                    updateCountryIsoField={countryIso}
                    field={country}
                  />
                  <FieldErrorMsg field={countryIso} customClass="fieldErrorMsg--profileForm" />
                </label>
                <BoxedInput
                  formRowItemKey="profileForm_postalCode"
                  type="text"
                  id="postalCode"
                  labelKey="profileForm_postal_code_label"
                  customClass="profileForm_boxedInput"
                  field={postalCode}
                  mandatory
                >
                  <FieldErrorMsg field={postalCode} customClass="fieldErrorMsg--profileForm" />
                </BoxedInput>
              </FormRow>

              <FormRow customClass="profileForm_row">
                <BoxedSelect
                  formRowItemKey="profileForm_mobilePhonePrefix"
                  id="mobilePhonePrefix"
                  customClass="profileForm_boxedSelectWrapper"
                  labelKey="profileForm_mobilePhone_label"
                  options={mobilePhonePrefixes}
                  field={mobilePhonePrefix}
                  mandatory
                >
                  <BoxedInput
                    type="tel"
                    id="phoneNumber"
                    customClass="boxedInputWrapper--phoneNumber profileForm_phoneNumber"
                    field={phoneNumber}
                  />

                  <FieldErrorMsg field={phoneNumber} customClass="fieldErrorMsg--profileForm" />
                </BoxedSelect>

                <BoxedSelect
                  formRowItemKey="profileForm_secondMobilePhonePrefix"
                  id="mobilePhonePrefix"
                  customClass="profileForm_boxedSelectWrapper"
                  labelKey="profileForm_phone_label"
                  options={mobilePhonePrefixes}
                  field={secondMobilePhonePrefix}
                >
                  <BoxedInput
                    type="tel"
                    id="secondPhoneNumber"
                    customClass="boxedInputWrapper--phoneNumber profileForm_phoneNumber"
                    field={secondPhoneNumber}
                  />
                  <FieldErrorMsg field={secondPhoneNumber} customClass="fieldErrorMsg--profileForm" />
                </BoxedSelect>
              </FormRow>

              {this.showItalianRow()}

              <div className="profileForm_customFields">
                {_map(customFields, (fieldInfos, index) => {
                  const customField = _get(fieldInfos, 'companyCustomField');
                  const id = _get(customField, 'id');
                  const field = _get(fields, id);
                  const value = _get(fieldInfos, 'value');

                  return (
                    <CustomField
                      id={id}
                      key={id || index}
                      fieldInfos={customField}
                      field={field}
                      value={value}
                      profile
                    />
                  );
                })}
              </div>
            </div>
          </section>

          <FormRow customClass="profileForm_row">
            <EkButton
              formRowItemKey="profileForm_submitBtn"
              type="submit"
              customClass="profileForm_actionsButton _is_alone"
              onAction={submitForm}
            >
              <FormattedMessage id="ekButton_defaultSaveButton" />
            </EkButton>
          </FormRow>
        </form>
      </div>
    );
  }
}

ProfileForm.propTypes = {
  onCallback: PropTypes.func
};

const nonCustomFields = [
  'italian',
  'fiscalCode',
  'firstName',
  'lastName',
  'mobilePhonePrefix',
  'phoneNumber',
  'secondMobilePhonePrefix',
  'secondPhoneNumber',
  'email',
  'company',
  'birthDate',
  'managerEmail',
  ..._values(ADDR)
];

const sendManagerEmailDisabled = {
  condition: props => {
    return !_get(props.companyInfos, 'sendEmailsToManager');
  }
};

const addressValidator = {
  [ADDR.STREET]: [notEmpty(), length({ max: 200 })],
  [ADDR.CITY]: [notEmpty(), length({ max: 200 })],
  [ADDR.COUNTRY]: [length({ max: 200 })],
  [ADDR.COUNTRY_ISO]: [notEmpty(ADDRESS_AUTO_COMPLETE_MISSING)],
  [ADDR.POSTAL_CODE]: [notEmpty(), length({ max: 200 })]
};

export default namedCompose(
  reduxForm(
    {
      onSubmitFail: scrollToFirstError,
      form: PROFILE_TAB_FORM_NAME,
      destroyOnUnmount: false
    },
    state => {
      // mapStateToProps

      const {
        company: { companyInfos },
        user: { userInfo }
      } = state;

      const customFields = memberCustomFieldsSelector(state);
      const initialValues = initialValuesProfileForm(state);
      const { useExternalInvoiceSystem } = companyInfos || {};

      const nonCustomFieldsValidation = {
        fiscalCode: [stopValidationIf(conditionItalianIsFalse), notEmpty()],
        firstName: [notEmpty(), length({ min: 2, max: 25 })],
        birthDate: [validBirthDate()],
        lastName: [notEmpty(), length({ min: 2, max: 25 })],
        phoneNumber: [notEmptyExternal('mobilePhonePrefix'), notEmpty(), integer()],
        secondPhoneNumber: [
          stopValidationIf({
            condition: props => {
              return !!(props.form.secondMobilePhonePrefix && !props.form.secondPhoneNumber.value);
            }
          }),
          notEmpty(),
          integer()
        ],
        managerEmail: [stopValidationIf(sendManagerEmailDisabled), notEmpty()],
        ...addressValidator
      };

      return {
        userInfo,
        initialValues,
        customFields,
        companyInfos,
        useExternalInvoiceSystem,
        drivingLicenceRequired: drivingLicenceRequiredSelector(state),
        locale: localeSelector(state),
        validate: createValidator({
          ...nonCustomFieldsValidation,
          ...customFieldsValidatorsSelector(
            _map(customFields, field => field.companyCustomField),
            state.form.profileForm,
            state.user.customFields
          )
        }),
        fields: [...nonCustomFields, ..._map(customFields, field => field.companyCustomField.id)]
      };
    }
  ),
  injectIntl
)(ProfileForm);
