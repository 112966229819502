import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import BookingCar from '../../components/BookingCar/BookingCar';
import EkButton from '../../components/EkButton/EkButton';
import BaseTitle from '../../components/BaseTitle/BaseTitle';
import TabsItems from '../../components/TabsItems/TabsItems';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import {
  updateBookingMemberActiveTabIndex,
  updateBookingPastList,
  updateBookingUpcomingList
} from '../../actions/all-actions';
import {
  BOOKING_USAGE_TYPE_BUSINESS,
  BOOKING_USAGE_TYPE_PRIVATE,
  BOOKING_USAGE_TYPE_DUAL,
  BOOKING_TYPE_CAR_SHARING,
  BOOKING_STATUS_PAYMENT_UNPAID
} from '../../constants/backend-constants';

const tabList = [
  { id: 0, name: 'myBookings_tab_all' },
  { id: 1, name: 'myBookings_tab_business' },
  { id: 2, name: 'myBookings_tab_private' }
];

class MyBookings extends Component {
  constructor() {
    super();
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.displayTabs = this.displayTabs.bind(this);
    this.handleDisplayPastMoreResults = this.handleDisplayPastMoreResults.bind(this);
    this.handleDisplayUpcomingMoreResults = this.handleDisplayUpcomingMoreResults.bind(this);
  }

  renderVehiclesList(vehicles) {
    let bookingResultItems = [];

    const { bookingMemberActiveTabIndex } = this.props;

    vehicles.forEach(function(item, index) {
      if (
        bookingMemberActiveTabIndex === tabList[0].id ||
        (bookingMemberActiveTabIndex === tabList[1].id &&
          (item.carSharingInfo &&
            (item.carSharingInfo.usageType === BOOKING_USAGE_TYPE_BUSINESS ||
              item.carSharingInfo.usageType === BOOKING_USAGE_TYPE_DUAL))) ||
        (bookingMemberActiveTabIndex === tabList[2].id &&
          (item.carSharingInfo &&
            (item.carSharingInfo.usageType === BOOKING_USAGE_TYPE_PRIVATE ||
              item.carSharingInfo.usageType === BOOKING_USAGE_TYPE_DUAL)))
      ) {
        bookingResultItems.push(
          <li
            className={`bookingResultItem ${item.upcomingBooking === false ? 'bookingResultItem--bgColor' : ''}`}
            key={item.id}
          >
            <BookingCar item={item} index={index} isBooked />
          </li>
        );
      }
    });

    if (!bookingResultItems.length) {
      return <FormattedMessage id="myBookings_usage_vehicle_empty" />;
    }

    return bookingResultItems;
  }

  displayTabs() {
    const {
      contract: {
        interfaceConfig: { replacementVehicleDisplay },
        privateCarSharing,
        businessCarSharing
      }
    } = this.props;

    if (replacementVehicleDisplay) {
      return !replacementVehicleDisplay;
    } else if (privateCarSharing && businessCarSharing) {
      return privateCarSharing && businessCarSharing;
    } else return false;
  }

  handleChangeTab(tab) {
    const { dispatch } = this.props;
    dispatch(updateBookingMemberActiveTabIndex(tab.id));
  }

  handleDisplayPastMoreResults() {
    const { dispatch } = this.props;
    dispatch(updateBookingPastList());
  }

  handleDisplayUpcomingMoreResults() {
    const { dispatch } = this.props;
    dispatch(updateBookingUpcomingList());
  }

  render() {
    const {
      listBookingsUser,
      bookingMemberActiveTabIndex,
      upcomingBookingPage,
      upcomingBookingTotalPages,
      pastBookingPage,
      pastBookingTotalPages
    } = this.props;

    let unpaidBooking = false;
    for (let i = 0; i < listBookingsUser.past.length; i++) {
      if (
        listBookingsUser.past[i].type === BOOKING_TYPE_CAR_SHARING &&
        listBookingsUser.past[i].carSharingInfo.statusPayment === BOOKING_STATUS_PAYMENT_UNPAID
      ) {
        unpaidBooking = true;
        break;
      }
    }

    return (
      <div className="MyBookingsPage">
        <Header />
        <FlashMessageDisplayer />

        <div className="mainContent">
          <div className="mainContent_inner">
            <div className="bookingResult myBookings">
              <div className="bookingResult_container">
                {this.displayTabs() && (
                  <div className="bookingResult_nav">
                    <div className="bookingResult_navInner">
                      <BaseTitle
                        customClass="bookingResult_sectionTitle baseTitle--variant1"
                        title="myBookings_title"
                      />

                      <TabsItems
                        tabList={tabList}
                        onChangeTab={this.handleChangeTab}
                        currentTab={bookingMemberActiveTabIndex}
                        actionButtons
                      />
                    </div>
                  </div>
                )}

                <div className="bookingResult_content bookingResult_content--full">
                  <div className="bookingResult_listing">
                    <section className="bookingResult_section">
                      <BaseTitle
                        customClass="bookingResult_sectionTitle baseTitle--variant1"
                        title="myBookings_upcoming_title"
                      />

                      <ul className="bookingResultItems">{this.renderVehiclesList(listBookingsUser.upcoming)}</ul>

                      {upcomingBookingPage === upcomingBookingTotalPages ? (
                        ''
                      ) : (
                        <EkButton
                          type="button"
                          customClass="bookingResult_resultsButton"
                          skinType="variant1"
                          onAction={this.handleDisplayUpcomingMoreResults}
                          fullWidth
                        >
                          <FormattedMessage id="bookingResult_moreResult_button" />
                        </EkButton>
                      )}
                    </section>

                    <section className="booking_section">
                      <BaseTitle
                        customClass="bookingResult_sectionTitle baseTitle--variant1"
                        title="myBookings_past_title"
                      />

                      {!unpaidBooking ? (
                        ''
                      ) : (
                        <div className="myBookings_pay">
                          <span className="myBookings_pay_text">
                            <span className="myBookings_pay_icon">!</span>
                            <FormattedMessage id="myBookings_pay_notice" />
                          </span>
                          <EkButton type="button" customClass="myBookings_pay_button">
                            <FormattedMessage id="myBookings_pay_button" />
                          </EkButton>
                        </div>
                      )}

                      <ul className="bookingResultItems">{this.renderVehiclesList(listBookingsUser.past)}</ul>

                      {pastBookingPage === pastBookingTotalPages ? (
                        ''
                      ) : (
                        <EkButton
                          type="button"
                          customClass="bookingResult_resultsButton"
                          skinType="variant1"
                          onAction={this.handleDisplayPastMoreResults}
                          fullWidth
                        >
                          <FormattedMessage id="bookingResult_moreResult_button" />
                        </EkButton>
                      )}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

MyBookings.displayName = 'MyBookings';

MyBookings.propTypes = {
  handleSubmit: PropTypes.func,
  onCallback: PropTypes.func
};

export default connect(state => {
  const {
    booking: {
      listBookingsUser,
      bookingMemberActiveTabIndex,
      pastBookingPage,
      pastBookingTotalPages,
      upcomingBookingPage,
      upcomingBookingTotalPages
    }
  } = state;

  const { userInfo } = state.user;
  const { company } = userInfo || {};
  const { contract } = company || {};

  return {
    listBookingsUser,
    bookingMemberActiveTabIndex,
    pastBookingPage,
    pastBookingTotalPages,
    upcomingBookingPage,
    upcomingBookingTotalPages,
    contract
  };
})(MyBookings);
