import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

class BoxedSelect extends Component {
  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    // trigger default behaviour
    this.props.field.onChange(e);

    // call eventual additional callback
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  }

  render() {
    const wrapOptionSelect = (label, value, key) => {
      return (
        <option key={key} value={value}>
          {label}
        </option>
      );
    };

    let options = [];
    this.props.options.forEach(function(optionItem, index) {
      let label, value;

      if (typeof optionItem === 'object') {
        value = optionItem.value;
        label = optionItem.labelKey ? optionItem.labelKey : optionItem.label;

        if (optionItem.labelKey) {
          options.push(
            <FormattedMessage key={optionItem.value} id={label}>
              {id => wrapOptionSelect(id, value, id)}
            </FormattedMessage>
          );
        } else {
          options.push(wrapOptionSelect(label, value, value));
        }
      } else {
        label = optionItem;
        value = optionItem;
        options.push(wrapOptionSelect(label, value, value));
      }
    });

    var boxedSelectLabel =
      !this.props.label && !this.props.labelKey ? (
        ''
      ) : (
        <label
          className={classNames('boxedSelect_label', this.props.customLabelClass, {
            _is_hidden: this.props.hideLabel
          })}
        >
          {this.props.labelKey ? <FormattedMessage id={this.props.labelKey} /> : this.props.label}
          {!this.props.mandatory ? '' : <span className="boxedInput_mandatory">*</span>}
        </label>
      );

    return (
      <div className="boxedSelectBlock">
        {boxedSelectLabel}
        <span
          className={classNames('boxedSelectWrapper', this.props.customClass, {
            _is_disabled: this.props.disabled,
            'boxedSelectWrapper--fullWidth': this.props.fullWidth
          })}
        >
          <select
            className="boxedSelect"
            name={this.props.name}
            disabled={this.props.disabled}
            {...this.props.field}
            value={this.props.field.value || ''}
            onChange={this.handleChange}
          >
            {options}
          </select>
        </span>
        {this.props.children}
      </div>
    );
  }
}

BoxedSelect.displayName = 'BoxedSelect';

BoxedSelect.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.array,
  customClass: PropTypes.string,
  name: PropTypes.string,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  mandatory: PropTypes.bool,
  customLabelClass: PropTypes.string,
  fullWidth: PropTypes.bool
};

export default BoxedSelect;
