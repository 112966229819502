import _get from 'lodash/object/get';
import _isArray from 'lodash/lang/isArray';
import _forEach from 'lodash/collection/forEach';
import _isString from 'lodash/lang/isString';
import _isObject from 'lodash/lang/isObject';
import _cloneDeep from 'lodash/lang/cloneDeep';

import {
  VALIDATION_FIELD_MISSING,
  VALIDATION_LENGTH_TOO_SHORT,
  VALIDATION_LENGTH_TOO_LONG,
  VALIDATION_NOT_INTEGER,
  VALIDATION_NOT_IN_ENUM,
  VALIDATION_MISMATCH,
  VALIDATION_EMAIL_INVALID,
  VALIDATION_PASSWORD_INVALID,
  VALIDATION_DRIVER_LICENCE_MISSING,
  VALIDATION_DRIVER_BIRTH_DATE_MISSING,
  VALIDATION_DRIVER_BIRTH_DATE_INVALID,
  VALIDATION_FRENCH_PHONE_NUMBER_INVALID,
  VALIDATION_DATE_BEFORE_INVALID,
  VALIDATION_TOO_LOW,
  VALIDATION_TOO_HIGH,
  VALIDATION_DATE_INVALID,
  VALIDATION_NOT_NUMBER,
  ADDRESS_AUTO_COMPLETE_MISSING
} from '../constants/errors-constants';
import { isEmpty, trimFields } from '../utils/utils';
import { GEO_MISSING } from '../constants/generic-constants';

const STOP_VALIDATION = 'STOP_VALIDATION';

const unifyValidators = validators => {
  return params => {
    const validatorsLength = validators.length;
    let errors = [];

    // apply validators
    for (let i = 0; i < validatorsLength; i++) {
      let validatorResult = validators[i](params);
      if (validatorResult === STOP_VALIDATION) {
        break;
      }
      if (validatorResult) {
        errors.push(validatorResult);
      }
    }

    // only return the first error encountered
    return errors[0];
  };
};

export function createValidator(rules) {
  return (values = {}, props) => {
    const errors = {};

    Object.keys(rules).forEach(key => {
      // concat enables both functions and arrays of functions
      let value = values[key];
      const rawValue = value;
      if (_isString(value)) value = value.trim();
      else value = trimFields(_cloneDeep(value));
      const unifiedValidator = unifyValidators([].concat(rules[key]));
      const error = unifiedValidator({ value, values, props, rawValue, key });

      if (error) {
        errors[key] = error;
      }
    });

    return errors;
  };
}

// ------------------
// Validators
// ------------------
export const condition = func => ({ condition: func });

export function stopValidationIf(params) {
  return ({ value, values, props }) => {
    if (params && params.condition(props, value, values)) {
      return STOP_VALIDATION;
    }
  };
}

export function notEmpty(errorMsg = VALIDATION_FIELD_MISSING) {
  return ({ value }) => {
    if (isEmpty(value)) {
      return {
        value,
        type: errorMsg
      };
    }
  };
}

export function truthy() {
  return ({ value }) => {
    if (!value) {
      return {
        value,
        type: VALIDATION_FIELD_MISSING
      };
    }
  };
}

// fieldName - String or Array of Strings
export function notEmptyExternal(fieldName, errorMsg = VALIDATION_FIELD_MISSING) {
  return ({ props }) => {
    let status = false;

    function fieldIsEmpty(field) {
      return isEmpty(_get(props, 'form.' + field + '.value'));
    }

    if (typeof fieldName === 'string') if (fieldIsEmpty(fieldName)) status = true;

    if (_isArray(fieldName)) {
      _forEach(fieldName, v => {
        if (fieldIsEmpty(v)) {
          status = true;
          return false;
        }
      });
    }
    if (status) return { type: errorMsg };
  };
}

export function minimum(min) {
  return ({ value }) => {
    if (value && value < min) {
      return {
        data: { value: String(min) },
        type: VALIDATION_TOO_LOW
      };
    }
  };
}

export function maximum(max) {
  return ({ value }) => {
    if (value && value > max) {
      return {
        data: { value: String(max) },
        type: VALIDATION_TOO_HIGH
      };
    }
  };
}

export function email() {
  return ({ value, values, props }) => {
    if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      return {
        value,
        type: VALIDATION_EMAIL_INVALID
      };
    }
  };
}

export function length(params) {
  return ({ value }) => {
    if (value && _get(params, 'min') && _get(value, 'length') < _get(params, 'min')) {
      return {
        value,
        type: VALIDATION_LENGTH_TOO_SHORT,
        data: {
          count: params.min
        }
      };
    }

    if (!isEmpty(value) && !isEmpty(params.max) && value.length > params.max) {
      return {
        value,
        type: VALIDATION_LENGTH_TOO_LONG,
        data: {
          count: params.max
        }
      };
    }
  };
}

export function integer() {
  return ({ value }) => {
    if (value && !(Number(value) % 1 === 0)) return { type: VALIDATION_NOT_INTEGER };
  };
}

export function number() {
  return ({ value }) => {
    if (value && isNaN(Number(value))) {
      return { type: VALIDATION_NOT_NUMBER };
    }
  };
}

export function oneOf(params) {
  return ({ value, values, props }) => {
    if (params.enumeration.indexOf(value) === -1) {
      return {
        value,
        type: VALIDATION_NOT_IN_ENUM,
        data: {
          enumeration: params.enumeration.join(', ')
        }
      };
    }
  };
}

export function match(field) {
  return ({ rawValue, values }) => {
    if (values) {
      if (rawValue !== values[field]) {
        return {
          rawValue,
          type: VALIDATION_MISMATCH,
          data: {
            matchedValue: values
          }
        };
      }
    }
  };
}

export function password() {
  return ({ rawValue }) => {
    var regexContainsLetter = /^.*[a-z]+.*/;
    var regexContainsCapitalizedLetter = /^.*[A-Z]+.*/;
    var regexContainsNumber = /^.*\d+.*/;

    if (
      !(
        regexContainsLetter.test(rawValue) &&
        regexContainsCapitalizedLetter.test(rawValue) &&
        regexContainsNumber.test(rawValue)
      )
    ) {
      return {
        rawValue,
        type: VALIDATION_PASSWORD_INVALID
      };
    }
  };
}

export function notEmptyAddress() {
  return ({ value, key }) => {
    let address = null;

    if (_isObject(value)) {
      address = _get(value, 'formattedAddress');
      if (_isString(address)) address = address.trim();
    }
    if (_isString(value)) address = value.trim();

    if (isEmpty(address)) {
      return {
        key,
        type: VALIDATION_FIELD_MISSING
      };
    }
  };
}

export function addressOrSite(notEmpty = true) {
  return params => {
    const { value, key } = params;

    if (value) {
      if (!value._isSite && notEmpty) {
        let result = notEmptyAddress()(params);
        if (result) return result;
      }

      if (value[GEO_MISSING]) {
        let address = _get(value, 'formattedAddress');
        if (_isString(address)) address = address.trim();
        if (address)
          return {
            key,
            type: ADDRESS_AUTO_COMPLETE_MISSING
          };
      }
    }
  };
}

export function driverLicence() {
  return ({ value }) => {
    if (isEmpty(value)) {
      return {
        value,
        type: VALIDATION_DRIVER_LICENCE_MISSING
      };
    }

    /*if (
      value.mimeType !== 'image/jpeg' &&
      value.mimeType !== 'image/png' &&
      value.mimeType !== 'application/pdf'
    ) {
      return {
        value,
        type: VALIDATION_DRIVER_LICENCE_INVALID_MIME_TYPE
      };
    }

    if (value.size > config.maxDriverLicenceSize) {
      return {
        value,
        type: VALIDATION_DRIVER_LICENCE_SIZE_TOO_LARGE,
        data: {
          max: config.maxDriverLicenceSize
        }
      };
    }*/
  };
}

export function driverBirthDate() {
  return ({ value, values, props }) => {
    if (isEmpty(value)) {
      return {
        value,
        type: VALIDATION_DRIVER_BIRTH_DATE_MISSING
      };
    }

    const now = new Date();
    let maxBirthdayDate = new Date();
    maxBirthdayDate.setFullYear(now.getFullYear() - 18);
    maxBirthdayDate.setHours(0);
    maxBirthdayDate.setMinutes(0);
    maxBirthdayDate.setSeconds(0);

    const maxBirthdayDateTime = maxBirthdayDate.getTime();
    const valueTime = value.getTime();

    if (valueTime > maxBirthdayDateTime) {
      return {
        value,
        type: VALIDATION_DRIVER_BIRTH_DATE_INVALID
      };
    }
  };
}

export function dateBeforeField(startDay, startHour, startMin, endHour, endMin) {
  return ({ value, values, props }) => {
    if (values[startDay] && values[startHour] && values[startMin] && values[endHour] && values[endMin] && value) {
      let endDate = value;
      endDate.setHours(values[endHour]);
      endDate.setMinutes(values[endMin]);

      let startDate = values[startDay];
      startDate.setHours(values[startHour]);
      startDate.setMinutes(values[startMin]);

      if (endDate.getTime() < startDate.getTime()) {
        return {
          value,
          type: VALIDATION_DATE_BEFORE_INVALID
        };
      }
    }
  };
}

export function frenchPhoneNumber() {
  return ({ value, values, props }) => {
    if (isEmpty(value)) {
      return {
        value,
        type: VALIDATION_FRENCH_PHONE_NUMBER_INVALID
      };
    }

    const regex = /(^(6|7)\d{8}$)|(^(06|07)\d{8}$)/;

    if (!regex.test(value)) {
      return {
        value,
        type: VALIDATION_FRENCH_PHONE_NUMBER_INVALID
      };
    }
  };
}

export function validBirthDate() {
  return ({ value, values, props, i18n }) => {
    if (!isEmpty(value)) {
      if (!isValidBirthDateFormat(value, values.lang)) {
        return {
          value,
          type: VALIDATION_DATE_INVALID
        };
      }

      let now = new Date();
      let actualYear = now.getFullYear();
      let explode = value.split('/');
      let year = explode[2];

      if (actualYear - year < 18) {
        return {
          value,
          type: VALIDATION_DATE_INVALID
        };
      }
    }
  };
}

export function validDate() {
  return ({ value, values, props, i18n }) => {
    if (!isEmpty(value)) {
      let regex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(?:19|20)\d{2}$/;
      if (!regex.test(value)) {
        return {
          value,
          type: VALIDATION_DATE_INVALID
        };
      }
    }
  };
}

export function isValidBirthDateFormat(value, lang) {
  let regex, formatRegex;
  /*eslint-disable max-len */
  regex = /(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)(?:0?2)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  /*eslint-enable max-len */
  formatRegex = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(?:19|20)\d{2}$/;
  return regex.test(value) && formatRegex.test(value);
}
