import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedTime } from 'react-intl';
import { FormattedMessage } from 'react-intl';

import config from '../../constants/config-constants';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';

class SubscribeSuccess extends Component {
  render() {
    const { userInfo, pendingSubscription } = this.props;

    return (
      <div className="subscribeSuccessPage">
        <Header />
        <FlashMessageDisplayer />

        <div className="mainContent">
          <div className="mainContent_inner subscribeSuccess">
            <div className="subscribeSuccess_message">
              <div className="subscribeSuccess_message_inner">
                <h1 className="subscribeSuccess_message_title">
                  <FormattedMessage
                    id={pendingSubscription ? 'preSubscribeSuccess_message_title' : 'subscribeSuccess_message_title'}
                    values={{ firstName: userInfo.firstName }}
                  />
                </h1>
                <p className="subscribeSuccess_message_content">
                  <FormattedMessage
                    id={
                      pendingSubscription ? 'preSubscribeSuccess_message_content' : 'subscribeSuccess_message_content'
                    }
                    values={{ login: userInfo.login }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

SubscribeSuccess.displayName = 'SubscribeSuccess';

SubscribeSuccess.propTypes = {};

export default connect(state => {
  const {
    user: { userInfo, pendingSubscription }
  } = state;

  return {
    userInfo,
    pendingSubscription
  };
})(SubscribeSuccess);
