import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';
import { CUSTOM_FIELD_FORM_BOOKING, CUSTOM_FIELD_FORM_SUBSCRIPTION } from '../constants/backend-constants';
import { CUSTOM_FIELDS_SUBSCRIPTION_GET_SUCCESS } from '../constants/actionTypes-constants';
import { CUSTOM_FIELDS_BOOKINGS_GET_SUCCESS } from '../constants/actionTypes-constants';

export function storeCompanyId(companyId) {
  return {
    type: types.COMPANY_ID_FOR_SUBSCRIBE_STORE,
    companyId
  };
}

export function getCompanyInfo(companyId) {
  return dispatch => {
    dispatch({
      type: types.COMPANY_GET_COMPANY_INFOS_REQUEST
    });

    return dispatch(callApi('company', 'getInfos', companyId)).then(function(data) {
      dispatch({
        type: types.COMPANY_GET_COMPANY_INFOS_SUCCESS,
        data: data
      });
      return data;
    });
  };
}

export const getSubscriptionCustomFields = companyId => dispatch => {
  return dispatch(callApi('company', 'getCustomFields', { companyId, form: CUSTOM_FIELD_FORM_SUBSCRIPTION })).then(
    fields => {
      dispatch({
        type: CUSTOM_FIELDS_SUBSCRIPTION_GET_SUCCESS,
        fields
      });
      return fields;
    }
  );
};

export const getBookingsCustomFields = companyId => dispatch => {
  return dispatch(callApi('company', 'getCustomFields', { companyId, form: CUSTOM_FIELD_FORM_BOOKING })).then(
    fields => {
      dispatch({
        type: CUSTOM_FIELDS_BOOKINGS_GET_SUCCESS,
        fields
      });
      return fields;
    }
  );
};

export function getCompanyCustomizations(token) {
  return dispatch => {
    dispatch({
      type: types.COMPANY_GET_CUSTOMIZATIONS,
      token
    });

    return dispatch(callApi('company', 'getCustomizations', token)).then(
      data =>
        dispatch({
          type: types.COMPANY_GET_CUSTOMIZATIONS_SUCCESS,
          data
        }),
      error =>
        dispatch({
          type: types.COMPANY_GET_CUSTOMIZATIONS_ERROR,
          error
        })
    );
  };
}
