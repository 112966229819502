import * as types from '../constants/actionTypes-constants';

export default function api(
  state = {
    token: null,
    callsCount: 0
  },
  action
) {
  switch (action.type) {
    case types.API_CALL_START:
      return Object.assign({}, state, {
        callsCount: state.callsCount + 1
      });

    case types.API_CALL_END:
      return Object.assign({}, state, {
        callsCount: state.callsCount - 1
      });

    case types.API_SET_TOKEN_REQUEST:
      return Object.assign({}, state, {
        token: action.token
      });

    case types.API_UNSET_TOKEN_REQUEST:
      return Object.assign({}, state, {
        token: null
      });

    default:
      return state;
  }
}
