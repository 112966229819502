import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';

class LoadingIndicator extends Component {
  render() {
    if (!this.props.isLoading) {
      return null;
    }

    return (
      <div className="loadingIndicator">
        <div className="loader_wrap">
          <div className="sk-circle">
            <div className="sk-circle1 sk-child"></div>
            <div className="sk-circle2 sk-child"></div>
            <div className="sk-circle3 sk-child"></div>
            <div className="sk-circle4 sk-child"></div>
            <div className="sk-circle5 sk-child"></div>
            <div className="sk-circle6 sk-child"></div>
            <div className="sk-circle7 sk-child"></div>
            <div className="sk-circle8 sk-child"></div>
            <div className="sk-circle9 sk-child"></div>
            <div className="sk-circle10 sk-child"></div>
            <div className="sk-circle11 sk-child"></div>
            <div className="sk-circle12 sk-child"></div>
          </div>
        </div>
      </div>
    );
  }
}

LoadingIndicator.displayName = 'LoadingIndicator';

LoadingIndicator.propTypes = {};

export default connect(state => {
  const {
    api: { callsCount: apiCallsCount },
    i18n: { callsCount: i18nCallsCount }
  } = state;

  return {
    isLoading: apiCallsCount + i18nCallsCount > 0
  };
})(LoadingIndicator);
