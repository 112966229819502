import React, { PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';

import EkButton from '../EkButton/EkButton';
import FormRow from '../FormRow/FormRow';

const ConfirmationForm = ({
  title,
  confirmationText,
  confirmationMessageValues,
  abortText,
  confirmationMessage,
  onCallback,
  onAbort
}) => {
  return (
    <div className="confirmationForm">
      {title && (
        <h4>
          <FormattedMessage id={title} />
        </h4>
      )}

      <FormRow customWrapperClass="confirmationForm_message" customClass="confirmationForm_row" customElements>
        <span className="confirmationForm_message_label">
          <FormattedMessage id={confirmationMessage} values={confirmationMessageValues} />
        </span>
      </FormRow>

      <FormRow customClass="confirmationForm_row confirmationForm_button_mt">
        <EkButton
          type="button"
          skinType="reverse"
          formRowItemKey="confirmationForm_abortBtn"
          customClass="confirmationForm_button float-right"
          onAction={onAbort}
        >
          <FormattedMessage id={abortText} />
        </EkButton>

        <EkButton
          type="button"
          formRowItemKey="confirmationForm_submitBtn"
          customClass="confirmationForm_button float-left"
          onAction={onCallback}
        >
          <FormattedMessage id={confirmationText} />
        </EkButton>
      </FormRow>
    </div>
  );
};

ConfirmationForm.displayName = 'ConfirmationForm';

ConfirmationForm.defaultProps = {
  title: '',
  confirmationMessageValues: {}
};

ConfirmationForm.propTypes = {
  title: PropTypes.string,
  confirmationText: PropTypes.string.isRequired,
  confirmationMessageValues: PropTypes.object,
  abortText: PropTypes.string.isRequired,
  confirmationMessage: PropTypes.string.isRequired,
  onCallback: PropTypes.func.isRequired,
  onAbort: PropTypes.func.isRequired
};

export default ConfirmationForm;
