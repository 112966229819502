import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';

export function getNotificationsList() {
  return (dispatch, getState) => {
    let memberId = getState().user.userInfo.id;
    let filterReadNotifs = [];

    dispatch({
      type: types.NOTIFICATIONS_NOTIFICATIONS_LIST_REQUEST,
      memberId
    });

    return dispatch(callApi('notifications', 'getList', memberId)).then(function(data) {
      data.forEach(function(item, index) {
        if (!item.read) {
          filterReadNotifs.push(item);
        }
      });

      dispatch({
        type: types.NOTIFICATIONS_NOTIFICATIONS_LIST_SUCCESS,
        params: filterReadNotifs
      });
      return data;
    });
  };
}

export function setNotificationsList(list) {
  return {
    type: types.NOTIFICATIONS_NOTIFICATIONS_LIST_SUCCESS,
    params: list
  };
}

export function toggleNotificationsMoreDetails(id) {
  return {
    type: types.NOTIFICATIONS_MORE_DETAILS_STATE_TOGGLE,
    id
  };
}

export function increaseNotificationsMaxItems() {
  return {
    type: types.NOTIFICATIONS_MAX_ITEMS_INCREASE
  };
}

export function setNotificationRead(notificationId, read) {
  return (dispatch, getState) => {
    dispatch({
      type: types.NOTIFICATIONS_IS_READ_REQUEST
    });

    return dispatch(callApi('notifications', 'setNotificationRead', { notificationId, read })).then(function(data) {
      dispatch({
        type: types.NOTIFICATIONS_IS_READ_SUCCESS,
        params: data
      });
      return data;
    });
  };
}
