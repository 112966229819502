import React, { Component, PropTypes } from 'react';
import AlgoliaPlaces from '../AlgoliaPlaces/AlgoliaPlaces';
import autoBind from 'react-autobind';
import { FLASH_MESSAGE_TYPE_ERROR, GEO_MISSING } from '../../constants/generic-constants';
import { getStore } from '../../store/all-store';
import { addFlashMessage } from '../../actions/flashMessage-actions';
import { connect } from 'react-redux';
import { localeSelector } from '../../selectors/all-selectors';
import { namedCompose } from '../../utils/utils';
import { injectIntl } from 'react-intl';
import countries from 'i18n-iso-countries';

class AddressAutocomplete extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.dispatch = getStore().dispatch;
    this.setApiKeys();
    this.derivedStateFromProps(props);
  }

  componentWillReceiveProps(props) {
    this.derivedStateFromProps(props);
  }

  derivedStateFromProps(props) {
    const { updateCountryIsoField, searchType } = props;
    if (updateCountryIsoField) this.searchType = 'country';
    else this.searchType = searchType;
  }

  updateField(field, text) {
    if (field) field.onChange(text);
  }

  formatStreet(suggestion = {}) {
    const { name } = suggestion;
    return name ? name : '';
  }

  updateExternalFields(suggestion = {}) {
    const { spreadExternalFields, locale } = this.props;
    const { cityField, countryField, countryIsoField, postalCodeField } = spreadExternalFields;
    const { city, countryCode, postcode } = suggestion;

    this.updateField(cityField, city);
    this.updateField(countryField, countries.getName(countryCode, locale));
    this.updateField(countryIsoField, countryCode);
    this.updateField(postalCodeField, postcode);

    return this.formatStreet(suggestion);
  }

  setApiKeys() {
    const { settings } = window.GLIDE || {};
    const { algoliaAppId, algoliaApiKey } = settings || {};

    this.algoliaAppId = algoliaAppId;
    this.algoliaApiKey = algoliaApiKey;
  }

  formatAddress(suggestion = {}) {
    const { latlng, city, countryCode, value, postcode, name } = suggestion;
    const ret = { formattedAddress: value };

    if (latlng) {
      ret.coordinates = {
        latitude: latlng.lat,
        longitude: latlng.lng
      };
    } else ret.GEO_MISSING = true;

    if (city) ret.city = city;
    if (name) ret.streetName = name;
    if (postcode) ret.postalCode = postcode;
    if (countryCode) ret.country = countryCode.toUpperCase();

    return ret;
  }

  updateCountry(suggestion = {}) {
    const { updateCountryIsoField } = this.props;
    const { value, countryCode } = suggestion;
    this.updateField(updateCountryIsoField, countryCode);
    return value;
  }

  handleSuggestSelected(suggestion = {}) {
    const { field, spreadExternalFields, updateCountryIsoField } = this.props;
    let value = '';

    if (spreadExternalFields) value = this.updateExternalFields(suggestion);
    else if (updateCountryIsoField) value = this.updateCountry(suggestion);
    else value = suggestion._isSite ? suggestion : this.formatAddress(suggestion);

    field.onChange(value);
  }

  handleChange(text) {
    const { field, spreadExternalFields, updateCountryIsoField } = this.props;

    if (updateCountryIsoField) this.updateField(updateCountryIsoField, null);
    if (spreadExternalFields || updateCountryIsoField) return text;

    field.onChange({
      [GEO_MISSING]: !!text,
      formattedAddress: text
    });
  }

  renderInitialValue() {
    const { field, spreadExternalFields, updateCountryIsoField } = this.props;
    let { value } = field || {};
    if (spreadExternalFields || updateCountryIsoField) return value;
    const { _isSite, formattedAddress, name } = value;
    return _isSite ? name : formattedAddress;
  }

  dispatchTimedError(error) {
    if (!this.limitError) {
      this.dispatch(error);
      this.limitError = setTimeout(() => (this.limitError = false), 2000);
    }
  }

  handleError() {
    this.dispatchTimedError(
      addFlashMessage({
        contentKey: 'error_during_address_search',
        type: FLASH_MESSAGE_TYPE_ERROR
      })
    );
  }

  handleLimit() {
    this.dispatchTimedError(
      addFlashMessage({
        contentKey: 'autocomplete_api_limit_reached',
        type: FLASH_MESSAGE_TYPE_ERROR
      })
    );
  }

  getAddressesTranslated() {
    const {
      intl: { formatMessage }
    } = this.props;
    return formatMessage({ id: 'autocomplete_company_addresses_title' });
  }

  render() {
    return (
      <AlgoliaPlaces
        placeholder={this.props.placeholder}
        initialValue={this.renderInitialValue()}
        fixtures={this.props.fixtures}
        fixturesTitle={this.props.fixturesTitle}
        addressesTitle={this.getAddressesTranslated()}
        language={this.props.locale}
        customFormatOnSuggestionSelect={this.props.spreadExternalFields && this.formatStreet}
        placesOptions={{
          appId: this.algoliaAppId,
          apiKey: this.algoliaApiKey,
          type: this.searchType
        }}
        fixturesSearchKeys={['name']}
        fixturesDisplayKey="name"
        fixturesInfoKey="address.formattedAddress"
        onSelect={this.handleSuggestSelected}
        onInputChange={this.handleChange}
        onError={this.handleError}
        onLimit={this.handleLimit}
      />
    );
  }
}

// noinspection JSUnusedGlobalSymbols
AddressAutocomplete.defaultProps = {
  locale: 'en',
  searchType: ['address', 'city', 'airport']
};

AddressAutocomplete.propTypes = {
  field: PropTypes.object,
  /** if true will spread data to external fields, replacing field with 'street' data */
  spreadExternalFields: PropTypes.shape({
    cityField: PropTypes.object.isRequired,
    countryField: PropTypes.object.isRequired,
    countryIsoField: PropTypes.object.isRequired,
    postalCodeField: PropTypes.object.isRequired
  }),
  /** Do not format suggestion, return raw string instead */
  fixtures: PropTypes.array,
  fixturesTitle: PropTypes.string,
  addressesTitle: PropTypes.string,
  placeholder: PropTypes.string,
  /** If true, field is treated like a country search */
  /** This will update countryIso when country is selected */
  /** This will clear countryIso if input is modified */
  updateCountryIsoField: PropTypes.object,
  /** https://community.algolia.com/places/documentation.html#api-options-type */
  searchType: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default namedCompose(
  connect(state => ({
    locale: localeSelector(state)
  })),
  injectIntl
)(AddressAutocomplete);
