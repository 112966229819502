import React, { PropTypes } from 'react';
import Fuse from 'fuse.js';
import _find from 'lodash/collection/find';
import _get from 'lodash/object/get';
import _cloneDeep from 'lodash/lang/cloneDeep';
import _isEqual from 'lodash/lang/isEqual';
import { siteIcon } from './siteIcon';
import { isInternetExplorer11 } from '../../utils/utils';
import classNames from 'classnames';
import shortid from 'shortid';

class AlgoliaPlaces extends React.Component {
  constructor(props) {
    super(props);
    this.setStaticVariables();
  }

  componentWillMount() {
    this.bindFunctions();
  }

  componentDidMount() {
    this.initEverything(this.props);
  }

  // noinspection JSCheckFunctionSignatures
  shouldComponentUpdate(props) {
    this.setDynamicVariables(props);
    this.handleIndependentProps(props);
    const heavyPropsUpdated = this.handleHeavyProps(props);
    return this.lightPropsUpdated(props) || heavyPropsUpdated;
  }

  componentWillUnmount() {
    this.destroyInstance();
  }

  setDynamicVariables(props) {
    const { fixtures } = props;
    this.fixturesPresent = fixtures && fixtures.length > 0;
  }

  bindFunctions() {
    this.setInputRef = this.setInputRef.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchForFixtures = this.searchForFixtures.bind(this);
    this.getFixturesSuggestion = this.getFixturesSuggestion.bind(this);
    this.safeSetVal = this.safeSetVal.bind(this);
    this.setRef = this.setRef.bind(this);
  }

  setStaticVariables() {
    this.instanceId = shortid.generate();
    this.containerClass = 'react-algolia-places';
  }

  setVariables(props) {
    const { aaOptions, fixtures } = props;
    const { cssClasses, includeMatches, minLength } = aaOptions || {};
    const { prefix, root } = cssClasses || {};
    const innerClass = 'algolia-main';

    this.prefix = prefix || 'ap';
    this.rootClass = root ? classNames(root, innerClass) : innerClass;
    this.maxPatternLength = 32; // fixtures query maximum length
    this.includeMatches = true; // default value
    this.visiblePlacesClass = 'visible'; // default mod class
    this.isInternetExplorer11 = isInternetExplorer11();
    this.fixturesPresent = fixtures && fixtures.length > 0;

    this.minLength = this.fixturesPresent ? 0 : minLength || 1;
    this.includeMatches = includeMatches === undefined ? this.includeMatches : !!includeMatches;
  }

  setFuseOptions({ fuseOptions, fixturesSearchKeys }) {
    this.fuseOptions = {
      shouldSort: true,
      threshold: 0.5,
      location: 0,
      distance: 100,
      maxPatternLength: this.maxPatternLength,
      keys: fixturesSearchKeys,
      minMatchCharLength: 1,
      ...fuseOptions,
      includeMatches: this.includeMatches
    };
  }

  setInputRef(ref) {
    this.autocompleteElem = ref;
  }

  initLibraries() {
    if (window.autocomplete && window.placesAutocompleteDataset && window.algoliasearch) {
      this.autocomplete = window.autocomplete;
      this.placesAutocompleteDataset = window.placesAutocompleteDataset;
      this.algoliasearch = window.algoliasearch;
      return true;
    }
  }

  getAddressTemplates(props) {
    if (this.fixturesPresent) {
      return {
        header: `<div class="${this.prefix}-header">${props.addressesTitle}</div>`
      };
    }
  }

  // fix search with 0 results issue
  replacePlacesSource(placesDataSet) {
    return (query, cb) => {
      query = query.trim();
      if (query) placesDataSet.source(query, cb);
      else cb([]);
    };
  }

  getAutocompleteDataset(props) {
    const placesDataSet = this.placesAutocompleteDataset({
      algoliasearch: this.algoliasearch,
      style: false,
      templates: this.getAddressTemplates(props),
      onError: props.onError,
      onRateLimitReached: props.onLimit,
      language: props.language,
      ...props.placesOptions
    });

    return {
      ...placesDataSet,
      source: this.replacePlacesSource(placesDataSet)
    };
  }

  getHighlightedItem(suggestion = {}, key) {
    if (this.includeMatches) {
      const { _matches: matches } = suggestion;

      const match = _find(matches, (match = {}) => match.key === key);
      if (match) {
        const strArray = [];
        // noinspection JSUnresolvedVariable
        const indices = match.indices || [];
        let value = match.value || '';
        let prevIndex = 0;
        const { length } = value;

        indices.forEach((item = []) => {
          const itemStart = item[0];
          const itemEnd = item[1] + 1;

          if (itemStart) strArray.push(value.substring(prevIndex, itemStart));
          strArray.push('<em>');
          strArray.push(value.substring(itemStart, itemEnd));
          strArray.push('</em>');
          prevIndex = itemEnd;
        });

        if (length !== prevIndex) strArray.push(value.substring(prevIndex, length));

        return strArray.join('');
      }
    }

    return _get(suggestion, key);
  }

  getFixturesSuggestion(suggestion) {
    const { fixturesDisplayKey, fixturesInfoKey } = this.props;
    const nameItem = this.getHighlightedItem(suggestion, fixturesDisplayKey);
    const infoItem = this.getHighlightedItem(suggestion, fixturesInfoKey);

    const icon = `<span class="${this.prefix}-fixture-icon">${siteIcon}</span>`;
    const name = `<span class="${this.prefix}-name">${nameItem}</span>`;
    const info = infoItem ? `<span class="${this.prefix}-address">${infoItem}</span>` : '';

    return icon + name + info;
  }

  getFixturesDataset(props) {
    return {
      source: this.searchForFixtures,
      name: props.fixturesName,
      displayKey: props.fixturesDisplayKey,
      templates: {
        header: `<div class="${this.prefix}-header">${props.fixturesTitle}</div>`,
        suggestion: this.getFixturesSuggestion
      }
    };
  }

  getAutocompleteInstance(props, datasets) {
    const { aaOptions } = props;
    const { cssClasses } = aaOptions || {};

    return this.autocomplete(
      this.autocompleteElem,
      {
        hint: false,
        openOnFocus: true,
        autoselect: false,
        tabAutocomplete: false,
        ...props.aaOptions,
        cssClasses: { ...cssClasses, prefix: this.prefix, root: this.rootClass },
        minLength: this.minLength
      },
      datasets
    );
  }

  forwardInstance(props, instance) {
    if (props.aaInstance) props.aaInstance(instance);
  }

  forwardSetter(props) {
    if (props.getValueSetter) props.getValueSetter(this.safeSetVal);
  }

  // handle 'includeMatches' and 'maxFixtures' props
  handleFuseResults(results = []) {
    const { maxFixtures } = this.props;
    return results.reduce((sum, data = {}, index) => {
      if (this.includeMatches) data = { ...data.item, _matches: data.matches };
      if (!maxFixtures || index < maxFixtures) sum.push(data);
      return sum;
    }, []);
  }

  searchForFixtures(query, callback) {
    if (this.fuse) {
      query = query.substring(0, this.maxPatternLength);
      query = query.trim();
      if (query) {
        const results = this.fuse.search(query);
        callback(this.handleFuseResults(results));
      } else {
        let { fixtures, initialNumberOfFixtures } = this.props;
        if (initialNumberOfFixtures) fixtures = fixtures.slice(0, initialNumberOfFixtures);
        callback(fixtures);
      }
    }
  }

  initFuse({ fixtures }) {
    if (this.fixturesPresent) {
      this.fuse = new Fuse(fixtures, this.fuseOptions);
      this.prevFixtures = _cloneDeep(fixtures);
    } else this.fuse = null;
  }

  initEverything(props) {
    if (!this.initLibraries()) return;

    this.setVariables(props);
    this.setFuseOptions(props);
    this.initFuse(props);

    const placesDataset = this.getAutocompleteDataset(props);
    const fixturesDataset = this.getFixturesDataset(props);

    this.aaInstance = this.getAutocompleteInstance(props, [fixturesDataset, placesDataset]);

    this.forwardInstance(props, this.aaInstance);
    this.forwardSetter(props);
    this.setInitialValue(props.initialValue);
    this.connectEvents(props);
  }

  connectEvents(props) {
    // can be string or function
    const autocompleteListeners = {
      'autocomplete:cursorchanged': 'onCursorChanged',
      'autocomplete:selected': this.handleChange
    };

    for (let event in autocompleteListeners) {
      const listener = autocompleteListeners[event];
      const func = typeof listener === 'string' ? props[listener] : listener;
      if (typeof func === 'function') this.aaInstance.on(event, func);
    }
  }

  setInitialValue(value) {
    this.safeSetVal(value);
  }

  fireSelectCallback(suggestion) {
    const { onSelect } = this.props;
    if (typeof onSelect === 'function') onSelect(suggestion);
  }

  excludeMatches(suggestion = {}) {
    const { _matches, ...noMatches } = suggestion;
    return this.includeMatches ? noMatches : suggestion;
  }

  handleChange(event, suggestion = {}, dataset) {
    const { fixturesName, customFormatOnSuggestionSelect } = this.props;
    const { value, name } = suggestion;
    const fixtures = dataset === fixturesName;

    if (fixtures) {
      this.safeSetVal(name);
      suggestion = this.excludeMatches(suggestion);
    } else {
      if (customFormatOnSuggestionSelect) this.safeSetVal(customFormatOnSuggestionSelect(suggestion));
      else this.currentValue = value;
    }

    this.fireSelectCallback(suggestion);
  }

  executeTimedAction(action, time) {
    if (time) setTimeout(action, time);
    else action();
  }

  setClass(value, newClass, setTimeout, restoreTimeout) {
    if (!this.containerRef) return;
    const isSet = this.containerRef.classList.contains(newClass);

    if (value) {
      if (!isSet) {
        this.executeTimedAction(() => {
          this.containerRef.classList.add(newClass);
        }, setTimeout);
      }
    } else {
      if (isSet) {
        this.executeTimedAction(() => {
          this.containerRef.classList.remove(newClass);
        }, restoreTimeout);
      }
    }
  }

  setPlacesVisibility(value, setTimeout, restoreTimeout) {
    this.setClass(value, this.visiblePlacesClass, setTimeout, restoreTimeout);
  }

  setStaticClasses(setTimeout, restoreTimeout) {
    if (!this.fixturesPresent) {
      if (!this.staticClassesSet) {
        this.setClass(true, this.visiblePlacesClass, setTimeout, restoreTimeout);
        this.staticClassesSet = true;
      }
      return true; // prevent updates
    }
    if (this.fixturesPresent && this.staticClassesSet) this.staticClassesSet = false;
  }

  handleClassNames(value) {
    const setTimeout = this.isInternetExplorer11 ? 100 : 0;
    value = value.trim();

    if (this.setStaticClasses(setTimeout)) return;
    this.setPlacesVisibility(value, setTimeout);
  }

  handleInputChange(event) {
    const { onInputChange } = this.props;

    this.currentValue = event.target.value;
    this.handleClassNames(this.currentValue);

    if (typeof onInputChange === 'function') onInputChange(event.target.value);
  }

  safeSetVal(value) {
    if (this.aaInstance && typeof value === 'string') {
      this.currentValue = value;
      this.handleClassNames(value);
      // noinspection JSUnresolvedFunction
      this.aaInstance.autocomplete.setVal(value);
    }
  }

  updateInitialValue(props) {
    const { initialValue } = props;
    const { initialValue: prevValue } = this.props;
    if (initialValue && initialValue !== prevValue && initialValue !== this.currentValue) {
      this.safeSetVal(initialValue);
    }
  }

  fuseOptionsUpdated(props) {
    const { fuseOptions } = props;
    const { fuseOptions: prevValue } = this.props;
    return fuseOptions !== prevValue;
  }

  fixturesUpdatedShallow(props) {
    const { fixtures } = props;
    const { fixtures: prevValue } = this.props;
    return fixtures !== prevValue;
  }

  fixturesUpdatedDeep(props) {
    const { fixtures } = props;
    if (this.fixturesUpdatedShallow(props)) return !_isEqual(fixtures, this.prevFixtures);
  }

  fixturesUpdated(props) {
    return this.fixturesUpdatedDeep(props);
  }

  updateFuse(props) {
    if (this.fuseOptionsUpdated(props)) {
      this.setFuseOptions(props);
      this.initFuse(props);
    }
  }

  placeholderUpdated(props) {
    const { placeholder } = props;
    const { placeholder: prevValue } = this.props;
    return placeholder !== prevValue;
  }

  languageUpdated(props) {
    const { language } = props;
    const { language: prevValue } = this.props;
    return language !== prevValue;
  }

  fixturesTitleUpdated(props) {
    const { fixturesTitle } = props;
    const { fixturesTitle: prevValue } = this.props;
    return fixturesTitle !== prevValue;
  }

  // checks if any prop that require re-init updated
  // execute re-init if needed
  handleHeavyProps(props) {
    if (this.languageUpdated(props) || this.fixturesTitleUpdated(props) || this.fixturesUpdated(props)) {
      this.destroyInstance();
      this.initEverything(props);
      return true;
    }
    return false;
  }

  lightPropsUpdated(props) {
    return !!this.placeholderUpdated(props);
  }

  handleIndependentProps(props) {
    this.updateInitialValue(props);
    this.updateFuse(props);
  }

  destroyInstance() {
    if (this.aaInstance) this.aaInstance.autocomplete.destroy();
  }

  setRef(el) {
    this.containerRef = el;
  }

  render() {
    return (
      <div className={this.containerClass} id={this.instanceId} ref={this.setRef}>
        <input
          type="text"
          aria-label={this.props.placeholder}
          placeholder={this.props.placeholder}
          onChange={this.handleInputChange}
          ref={this.setInputRef}
        />
      </div>
    );
  }
}

AlgoliaPlaces.defaultProps = {
  fixturesSearchKeys: ['name', 'address.formattedAddress'],
  fixturesInfoKey: 'address.formattedAddress',
  fixturesDisplayKey: 'name',
  fixturesTitle: 'Sites',
  addressesTitle: 'Addresses',
  maxFixtures: 5,
  initialNumberOfFixtures: 0,
  fixturesName: 'fixtures',
  language: 'en',
  onError: () => undefined,
  onLimit: () => undefined
};

/*
  This props can be changed dynamically after initialization:

  - initialValue
  - fixtures
  - fixturesTitle
  - fuseOptions
  - language
  - maxFixtures

*/

AlgoliaPlaces.propTypes = {
  /** Will set the input value as the result of this function (if present) */
  customFormatOnSuggestionSelect: PropTypes.func,
  /** Get function to set input value (have to be used instead of native autocomplete.setVal */
  getValueSetter: PropTypes.func,
  /** https://community.algolia.com/places/documentation.html#options */
  placesOptions: PropTypes.object,
  /** https://github.com/algolia/autocomplete.js/blob/master/README.md#global-options */
  aaOptions: PropTypes.object,
  /** Initial value for input field */
  initialValue: PropTypes.string,
  /** https://community.algolia.com/places/documentation.html#api-options-language */
  language: PropTypes.string,
  /** Custom data to search for */
  fixtures: PropTypes.array,
  /** Maximum number of fixtures to display (0 - unlimited) */
  maxFixtures: PropTypes.number,
  /** Initial number of fixtures to display (0 - unlimited) */
  initialNumberOfFixtures: PropTypes.number,
  /** Title for provided fixtures */
  fixturesTitle: PropTypes.string,
  /** Title for algolia address results */
  addressesTitle: PropTypes.string,
  /** Keys used to filter fixtures (can be path) */
  fixturesSearchKeys: PropTypes.array,
  /** Key to retreive display name of suggestion (can be path) */
  fixturesDisplayKey: PropTypes.string,
  /** Key to display additional info (can be path)  */
  fixturesInfoKey: PropTypes.string,
  /** Input placeholder */
  placeholder: PropTypes.string,
  /** Used for fixtures: https://fusejs.io/ */
  fuseOptions: PropTypes.object,
  /** Autocomplete instance callback */
  aaInstance: PropTypes.func,
  /** Fired when input changes */
  onInputChange: PropTypes.func,
  /** Fired when you use arrow keys or cursor to navigate */
  onCursorChanged: PropTypes.func,
  /** Fired when you select the suggestion */
  onSelect: PropTypes.func,
  /** Fired when API limit reached */
  onLimit: PropTypes.func,
  /** Fired if (any other) error (other then API limit) has occured */
  onError: PropTypes.func,
  /** This will be appended to [aa]-dataset-[fixturesName] class */
  fixturesName: PropTypes.string
};

export default AlgoliaPlaces;
