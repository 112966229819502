import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Link } from 'react-router';
import _partial from 'lodash/function/partial';
import { isAuthenticated } from '../../api/ApiCaller';
import config from '../../constants/config-constants';
import {
  toggleHeaderProfileMenu,
  toggleHeaderLangMenu,
  closeHeaderProfileMenu,
  closeHeaderLangMenu,
  setLanguage
} from '../../actions/all-actions';
import routes from '../../constants/routes-constants';

const headerLocaleMap = {
  pt_BR: 'BR'
};

class Header extends Component {
  constructor() {
    super();
    this.handleAccountMenu = this.handleAccountMenu.bind(this);
    this.handleSwitchLang = this.handleSwitchLang.bind(this);
    this.handleLangMenu = this.handleLangMenu.bind(this);
  }

  handleAccountMenu(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(closeHeaderLangMenu());
    dispatch(toggleHeaderProfileMenu());
  }

  handleLangMenu(e) {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(closeHeaderProfileMenu());
    dispatch(toggleHeaderLangMenu());
  }

  handleSwitchLang(lang) {
    const { dispatch } = this.props;
    dispatch(setLanguage(lang));
  }

  inlineLogo() {
    const { logoUrl } = this.props;
    if (logoUrl)
      return {
        background: `url("${logoUrl}") no-repeat left top`,
        backgroundSize: 'contain'
      };
  }

  whiteBrandLogo() {
    return <div className="inline-logo" style={this.inlineLogo()} />;
  }

  headerLogo() {
    if (this.props.currentPage === 'subscribe') return <div className="header_logo" />;
    return (
      <a href={`#${routes.home.path}`} className="header_logo">
        Home
      </a>
    );
  }

  getLogo() {
    return this.props.whiteBrand ? this.whiteBrandLogo() : this.headerLogo();
  }

  render() {
    const { userInfo, profileMenuIsOpen, langMenuIsOpen, list, locale, currentPage } = this.props;
    const headerLocaleText = headerLocaleMap[locale] || locale;

    let availableLanguages;

    if (config.theme.availableLanguages) {
      availableLanguages = config.theme.availableLanguages.map((lang, index) => (
        <li key={index} className="header_subItem">
          <a
            className={`header_subItemLink ${locale === lang ? 'header_subItemLink--active' : ''}`}
            onClick={_partial(this.handleSwitchLang, lang)}
          >
            <FormattedMessage id={`trad_full_${lang}`} />
          </a>
        </li>
      ));
      availableLanguages = <ul className="header_subItems header_subItems--lang">{availableLanguages}</ul>;
    }

    return (
      <header className={`header ${this.props.customClass || ''}`}>
        {this.getLogo()}
        <ul className="header_items">
          {!isAuthenticated() ? (
            ''
          ) : (
            <li className="header_item">
              <a
                href={`#${routes.home.path}`}
                className={classNames('header_itemLink', {
                  _is_active: currentPage === 'home'
                })}
              >
                <FormattedMessage id="header_nav_home" />
              </a>
            </li>
          )}

          {!isAuthenticated() ? (
            ''
          ) : (
            <li className="header_item">
              <a
                href={`#${routes.myBookings.path}`}
                className={classNames('header_itemLink', {
                  _is_active: currentPage === 'myBookings'
                })}
              >
                <FormattedMessage id="header_nav_reservation" />
              </a>
            </li>
          )}

          {!isAuthenticated() ? (
            ''
          ) : (
            <li className="header_item">
              <a
                href={`#${routes.help.path}`}
                className={classNames('header_itemLink', {
                  _is_active: currentPage === 'help'
                })}
              >
                <FormattedMessage id="header_nav_help" />
              </a>
            </li>
          )}

          {!isAuthenticated() ? (
            ''
          ) : (
            <li className="header_item">
              <Link
                to={`${routes.home.path}#notifications`}
                className={classNames('header_itemLink', 'header_itemLink--notification')}
              >
                <FormattedMessage id="header_nav_notif" />
                {!list || list.length === 0 ? '' : <span className="header_itemLink_number">{list.length}</span>}
              </Link>
            </li>
          )}

          {!isAuthenticated() ? (
            ''
          ) : (
            <li className="header_item">
              <button
                className={classNames('header_item_name', 'header_itemLink', 'header_itemLinkToggle', {
                  _is_opened: profileMenuIsOpen,
                  _is_active: currentPage === 'account'
                })}
                onClick={this.handleAccountMenu}
              >
                {userInfo.firstName} {userInfo.lastName}
              </button>
              {!profileMenuIsOpen ? (
                ''
              ) : (
                <ul className="header_subItems">
                  <li className="header_subItem">
                    <a href={`#${routes.account.path}`} className="header_subItemLink header_subItemLink--account">
                      <FormattedMessage id="header_nav_account" />
                    </a>
                  </li>
                  <li className="header_subItem">
                    <a href={`#${routes.login.path}`} className="header_subItemLink header_subItemLink--signOut">
                      <FormattedMessage id="header_nav_signOut" />
                    </a>
                  </li>
                </ul>
              )}
            </li>
          )}
          <li className="header_item">
            <button
              className={classNames('header_itemLink', 'header_itemLinkToggle', 'locale', {
                _is_opened: langMenuIsOpen
              })}
              onClick={this.handleLangMenu}
            >
              {headerLocaleText}
            </button>
            {!langMenuIsOpen ? '' : availableLanguages}
          </li>
        </ul>
      </header>
    );
  }
}

Header.defaultProps = {
  whiteBrand: false
};

Header.propTypes = {
  customClass: PropTypes.string,
  whiteBrand: PropTypes.bool,
  logoUrl: PropTypes.string
};

Header.displayName = 'Header';

export default connect(state => {
  const {
    user: { userInfo },
    header: { profileMenuIsOpen, langMenuIsOpen, currentPage },
    notifications: { list },
    i18n: { locale }
  } = state;

  return {
    userInfo,
    profileMenuIsOpen,
    langMenuIsOpen,
    list,
    locale,
    currentPage
  };
})(Header);
