export const siteIcon = `<svg version="1.1"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:cc="http://creativecommons.org/ns#"
                            xmlns:dc="http://purl.org/dc/elements/1.1/"
                            xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
                          <metadata>
                            <rdf:RDF>
                              <cc:Work rdf:about="">
                                <dc:format>image/svg+xml</dc:format>
                                <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"/>
                                <dc:title/>
                              </cc:Work>
                            </rdf:RDF>
                          </metadata>
                          <path d="M0 0h24v24H0V0z" fill="none"/>
                          <path d="M18.92 5.01L18.57 4H5.43L3 11v9h3v-2h12v2h3v-9l-2.08-5.99zM6.5
                                  15c-.83 0-1.5-.67-1.5-1.5S5.67 12 6.5 12s1.5.67 1.5 1.5S7.33 15 6.5 15zm11
                                  0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5
                                  1.5zM5 10l1.5-4.5h11L19 10H5z"/>
                        </svg>`;
