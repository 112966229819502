import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import EkButton from '../../components/EkButton/EkButton';

class Notification extends Component {
  constructor() {
    super();
    this.handleNoReportButton = this.handleNoReportButton.bind(this);
  }

  handleNoReportButton(e) {
    e.preventDefault();

    if (this.props.onNoReportAction) {
      this.props.onNoReportAction();
    }
  }

  render() {
    var notificationMessage = this.props.messageKey ? (
      <FormattedMessage id={this.props.messageKey} />
    ) : (
      this.props.message
    );

    var notificationReportLabel = !this.props.reportLabelKey ? (
      ''
    ) : (
      <div className="boxNotif_link">
        <a href="#" className="boxNotif_report" onClick={this.handleNoReportButton}>
          <FormattedMessage id={this.props.reportLabelKey} />
        </a>
      </div>
    );

    var notificationMoreDetails = !this.props.moreDetails ? (
      ''
    ) : (
      <div className="boxNotif_moreDetails">{this.props.moreDetails}</div>
    );

    return (
      <div
        className={`boxNotif ${!this.props.skinNotifType ? '' : `boxNotif--${this.props.skinNotifType}`} ${this.props
          .customClass || ''}`}
      >
        <div className="boxNotif_inner">
          <div className="boxNotif_content">
            <div className="boxNotif_contentWrapper">
              <span className="boxNotif_type">
                <FormattedMessage id={`notification_type_${this.props.type}`} />
              </span>

              <div className="boxNotif_message">
                <span className="boxNotif_date">{this.props.date}</span>

                <span className="boxNotif_description">{notificationMessage}</span>
              </div>
              {notificationReportLabel}
            </div>
          </div>

          {this.props.reportable && this.props.reportable === true ? (
            <EkButton
              customClass={`boxNotif_button ${this.props.customButtonClass || ''}`}
              skinType={this.props.buttonSkinType}
              onAction={this.props.onAction}
              fullWidth
            >
              <FormattedMessage id={this.props.buttonLabelKey} />
            </EkButton>
          ) : (
            ''
          )}
        </div>

        {notificationMoreDetails}
      </div>
    );
  }
}

Notification.displayName = 'Notification';

Notification.propTypes = {
  customClass: PropTypes.string,
  customButtonClass: PropTypes.string,
  skinNotifType: PropTypes.string,
  type: PropTypes.string,
  date: PropTypes.object,
  message: PropTypes.object,
  messageKey: PropTypes.string,
  reportLabelKey: PropTypes.string,
  buttonLabelKey: PropTypes.string,
  buttonSkinType: PropTypes.string,
  onAction: PropTypes.func,
  onNoReportAction: PropTypes.func,
  reportable: PropTypes.bool
};

export default Notification;
