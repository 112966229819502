import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { requestActivationLink } from '../../actions/all-actions';
import EkButton from '../../components/EkButton/EkButton';
import ReactSVG from 'react-svg';
import autoBind from 'react-autobind';
import { routeActions } from 'react-router-redux';
import routes from '../../constants/routes-constants';
import RaisedButton from 'material-ui/lib/raised-button';
import { whiteBrand } from '../../constants/style-constants';

class ValidateAccountTemplate extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.pageClass = 'validate-account-page';
    if (this.props.whiteBrand) {
      const { colour1, colour2, colour3, logo } = this.props.customizations || {};
      const styles = whiteBrand.component.activateAccount;

      this.buttonStyles = styles.button;
      this.mainColor = colour1 || colour2 || colour3 || whiteBrand.colors.default;
      this.logoUrl = logo;
      this.pageClass = 'white-brand-activate';
      this.svgStyle = { fill: this.mainColor };
      this.textStyle = { color: this.mainColor };
    }

    this.successIcon = '/img/success.svg';
    this.errorIcon = '/img/error.svg';
    this.infoIcon = '/img/info.svg';
  }

  handleSendNewEmail() {
    const { dispatch } = this.props;
    dispatch(requestActivationLink());
  }

  whiteBrandButton(button, action) {
    return (
      <RaisedButton
        onMouseUp={action}
        label={<FormattedMessage id={button} />}
        labelStyle={this.buttonStyles.label}
        style={this.buttonStyles.root}
        backgroundColor={this.mainColor}
      />
    );
  }

  regularButton(button, action) {
    return (
      <EkButton type="button" customClass="validate-account-message-button" onAction={action}>
        <FormattedMessage id={button} />
      </EkButton>
    );
  }

  buttonContainer(button, action) {
    const reactButton = this.props.whiteBrand ? this.whiteBrandButton : this.regularButton;

    return <div className="button-container">{reactButton(button, action)}</div>;
  }

  twoLines(iconSrc, titleKey, contentKey, buttonKey, actionCallback) {
    return (
      <div className="validate-account-message-inner msg-container">
        <div className="icon-msg-container">
          <ReactSVG src={iconSrc} svgClassName="r-svg" svgStyle={this.svgStyle} />
          <div className="inner-msg-container">
            <h1 className="validate-account-message-title" style={this.textStyle}>
              <FormattedMessage id={titleKey} />
            </h1>
            <p className="validate-account-message-content">
              <FormattedMessage id={contentKey} />
            </p>
          </div>
        </div>
        {this.buttonContainer(buttonKey, actionCallback)}
      </div>
    );
  }

  displayLoginPage() {
    this.props.dispatch(routeActions.push(routes.login.path));
  }

  oneLine(iconSrc, titleKey) {
    return (
      <div className="one-line-container msg-container">
        <ReactSVG src={iconSrc} svgClassName="r-svg" svgStyle={this.svgStyle} />
        <div className="single-line-msg">
          <FormattedMessage id={titleKey} />
        </div>
      </div>
    );
  }

  showRequestSuccess() {
    if (this.props.requestSuccess) return this.oneLine(this.successIcon, 'validateAccount_request_success');
  }

  showError() {
    if (this.props.unknownError) return this.oneLine(this.errorIcon, 'error_server_unknown');
  }

  showExpired() {
    const { tokenExpired } = this.props;
    if (tokenExpired)
      return this.twoLines(
        this.errorIcon,
        'validateAccount_expired_title',
        'validateAccount_expired_content',
        'validateAccount_request_button',
        this.handleSendNewEmail
      );
  }

  showActivationSuccess() {
    const { activationSuccess, whiteBrand } = this.props;
    const titleKey = 'validateAccount_success_title';

    if (activationSuccess) {
      if (whiteBrand) {
        return this.oneLine(this.successIcon, 'validateAccount_success_title');
      } else {
        return this.twoLines(
          this.successIcon,
          titleKey,
          'validateAccount_success_content',
          'validateAccount_redirect_button',
          this.displayLoginPage
        );
      }
    }
  }

  showAlreadyActivated() {
    const { alreadyActivated, whiteBrand } = this.props;
    const titleKey = 'validateAccount_already_activated';

    if (alreadyActivated) {
      if (whiteBrand) {
        return this.oneLine(this.infoIcon, titleKey);
      } else {
        return this.twoLines(
          this.infoIcon,
          titleKey,
          'validateAccount_success_content',
          'validateAccount_redirect_button',
          this.displayLoginPage
        );
      }
    }
  }

  render() {
    return (
      <div className={this.pageClass}>
        <Header whiteBrand={this.props.whiteBrand} logoUrl={this.logoUrl} />
        <div className="validate-account">
          {this.showExpired()}
          {this.showError()}
          {this.showRequestSuccess()}
          {this.showActivationSuccess()}
          {this.showAlreadyActivated()}
        </div>
        <Footer whiteBrand={this.props.whiteBrand} />
      </div>
    );
  }
}

ValidateAccountTemplate.displayName = 'ValidateAccountTemplate';

ValidateAccountTemplate.defaultProps = {
  whiteBrand: false
};

ValidateAccountTemplate.propTypes = {
  whiteBrand: PropTypes.bool
};

export default connect(state => {
  const {
    user: {
      resetActivateCases: { tokenExpired, taskSuccess, unknownError, alreadyActivated, requestSuccess }
    },
    company: { customizations }
  } = state;

  return {
    tokenExpired,
    activationSuccess: taskSuccess,
    unknownError,
    alreadyActivated,
    requestSuccess,
    customizations
  };
})(ValidateAccountTemplate);
