import Layout from '../containers/Layout';
import Version from '../containers/Version/Version';
import Redirect from '../containers/Redirect/Redirect';
import Login from '../containers/Login/Login';
import Subscribe from '../containers/Subscribe/Subscribe';
import SubscribeSuccess from '../containers/SubscribeSuccess/SubscribeSuccess';
import ValidateAccount from '../containers/ValidateAccount/ValidateAccount';
import ResetPassword from '../containers/ResetPassword/ResetPassword';
import Home from '../containers/Home/Home';
import BookingResult from '../containers/BookingResult/BookingResult';
import Account from '../containers/Account/Account';
import MyBookings from '../containers/MyBookings/MyBookings';
import BookingSummary from '../containers/BookingSummary/BookingSummary';
import EditBooking from '../containers/EditBooking/EditBooking';
import BookingSuccess from '../containers/BookingSuccess/BookingSuccess';
import WhiteBrandActivate from '../containers/WhiteBrandActivate/WhiteBrandActivate';
import ChangePassword from '../containers/ChangePassword/ChangePassword';
import Help from '../containers/Help/Help';
import Error from '../containers/Error/Error';
import routes from '../constants/routes-constants';

import { onEnterApp, onEnterNoRoute, onEnterRoute } from './hooks-routing';

const routesConfig = [
  {
    path: '/',
    component: Layout,
    onEnter: onEnterApp(),
    indexRoute: {
      onEnter: onEnterNoRoute()
    },
    childRoutes: [
      {
        path: routes.version.path,
        component: { page: Version }
      },
      {
        path: routes.redirect.path,
        component: { page: Redirect },
        onEnter: onEnterRoute('redirect')
      },
      {
        path: routes.login.path,
        component: { page: Login },
        onEnter: onEnterRoute('login')
      },
      {
        path: routes.subscribe.path,
        component: { page: Subscribe },
        onEnter: onEnterRoute('subscribe')
      },
      {
        path: routes.subscribeSuccess.path,
        component: { page: SubscribeSuccess },
        onEnter: onEnterRoute('subscribeSuccess')
      },
      {
        path: routes.validateAccount.path,
        component: { page: ValidateAccount },
        onEnter: onEnterRoute('validateAccount')
      },
      {
        path: routes.whiteBrandActivate.path,
        component: { page: WhiteBrandActivate },
        onEnter: onEnterRoute('whiteBrandActivate')
      },
      {
        path: routes.resetPassword.path,
        component: { page: ResetPassword },
        onEnter: onEnterRoute('resetPassword')
      },
      {
        path: routes.changePassword.path,
        component: { page: ChangePassword },
        onEnter: onEnterRoute('changePassword')
      },
      {
        path: routes.home.path,
        component: { page: Home },
        onEnter: onEnterRoute('home')
      },
      {
        path: routes.bookingResult.path,
        component: { page: BookingResult },
        onEnter: onEnterRoute('bookingResult')
      },
      {
        path: routes.account.path,
        component: { page: Account },
        onEnter: onEnterRoute('account')
      },
      {
        path: routes.myBookings.path,
        component: { page: MyBookings },
        onEnter: onEnterRoute('myBookings')
      },
      {
        path: routes.bookingSummary.path,
        component: { page: BookingSummary },
        onEnter: onEnterRoute('bookingSummary')
      },
      {
        path: routes.bookingSuccess.path,
        component: { page: BookingSuccess },
        onEnter: onEnterRoute('bookingSuccess')
      },
      {
        path: routes.editBooking.path,
        component: { page: EditBooking },
        onEnter: onEnterRoute('editBooking')
      },
      {
        path: routes.help.path,
        component: { page: Help },
        onEnter: onEnterRoute('help')
      },
      {
        path: routes.error.path,
        component: { page: Error }
      },
      {
        path: '*',
        onEnter: onEnterNoRoute()
      }
    ]
  }
];

export function getRoutesConfig() {
  return routesConfig;
}
