export default {
  version: {
    path: '/version',
    needsAuthentication: false
  },
  redirect: {
    path: '/redirect/:path',
    needsAuthentication: false
  },
  subscribe: {
    path: '/subscribe/:companyId',
    needsAuthentication: false
  },
  subscribeSuccess: {
    path: '/subscribe-success',
    needsAuthentication: false
  },
  validateAccount: {
    path: '/validate-account/:token',
    needsAuthentication: false
  },
  whiteBrandActivate: {
    path: '/activate-account/:token',
    needsAuthentication: false
  },
  login: {
    path: '/login',
    needsAuthentication: false
  },
  resetPassword: {
    path: '/reset-password/:resetPasswordToken',
    needsAuthentication: false
  },
  changePassword: {
    path: '/change-password/:resetPasswordToken',
    needsAuthentication: false
  },
  home: {
    path: '/home',
    needsAuthentication: true
  },
  bookingResult: {
    path: '/booking-result/:search',
    needsAuthentication: true
  },
  account: {
    path: '/account',
    needsAuthentication: true
  },
  myBookings: {
    path: '/my-bookings',
    needsAuthentication: true
  },
  bookingSummary: {
    path: '/booking-summary',
    needsAuthentication: true
  },
  bookingSuccess: {
    path: '/booking-success',
    needsAuthentication: false
  },
  editBooking: {
    path: '/edit-booking',
    needsAuthentication: true
  },
  help: {
    path: '/help',
    needsAuthentication: true
  },
  error: {
    path: '/error',
    needsAuthentication: false
  }
};
