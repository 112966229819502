import { UPDATE_LOCATION } from 'react-router-redux';

import * as types from '../constants/actionTypes-constants';

export default function header(
  state = {
    profileMenuIsOpen: false,
    langMenuIsOpen: false,
    currentPage: null
  },
  action
) {
  switch (action.type) {
    case types.HEADER_PROFILE_MENU_OPEN:
      return Object.assign({}, state, {
        profileMenuIsOpen: true
      });

    case types.HEADER_PROFILE_MENU_CLOSE:
      return Object.assign({}, state, {
        profileMenuIsOpen: false
      });

    case types.HEADER_PROFILE_MENU_TOGGLE:
      return Object.assign({}, state, {
        profileMenuIsOpen: !state.profileMenuIsOpen
      });

    case types.HEADER_LANG_MENU_OPEN:
      return Object.assign({}, state, {
        langMenuIsOpen: true
      });

    case types.HEADER_LANG_MENU_CLOSE:
      return Object.assign({}, state, {
        langMenuIsOpen: false
      });

    case types.HEADER_LANG_MENU_TOGGLE:
      return Object.assign({}, state, {
        langMenuIsOpen: !state.langMenuIsOpen
      });

    case types.HEADER_CURRENT_PAGE_UPDATE:
      return Object.assign({}, state, {
        currentPage: action.routeName
      });

    default:
      return state;
  }
}
