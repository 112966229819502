import React, { Component, PropTypes } from 'react';

class FormRow extends Component {
  constructor() {
    super();
  }

  render() {
    let options;

    const wrapOption = (item, key) => {
      return (
        <div className={`formRow_item ${this.props.customClass || ''}`} key={key}>
          {item}
        </div>
      );
    };

    if (Array.isArray(this.props.children)) {
      options = this.props.children.reduce(function(total, optionItem, index) {
        if (optionItem) {
          total.push(wrapOption(optionItem, optionItem.props.formRowItemKey || index));
        }
        return total;
      }, []);
    } else {
      options = wrapOption(this.props.children, 1);
    }

    return <div className={`formRow ${this.props.customWrapperClass || ''}`}>{options}</div>;
  }
}

FormRow.displayName = 'FormRow';

FormRow.propTypes = {
  customWrapperClass: PropTypes.string,
  customClass: PropTypes.string
};

export default FormRow;
