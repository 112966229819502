import * as types from '../constants/actionTypes-constants';

export function updateHelpActiveTabIndex(index) {
  return {
    type: types.HELP_ACTIVE_TAB_INDEX_UPDATE,
    index
  };
}

export function toggleAnswerIssueOpenedState(id) {
  return {
    type: types.HELP_ANSWER_ISSUE_OPENED_STATE_TOGGLE,
    id
  };
}

export function setMobileViewStatus(isMobile) {
  return {
    type: types.HELP_STATUS_MOBILE_VIEW_SET,
    isMobile
  };
}
