import { UPDATE_LOCATION } from 'react-router-redux';
import shortid from 'shortid';
import _ from 'lodash';

import * as types from '../constants/actionTypes-constants';
import { FLASH_MESSAGE_TYPE_INFO } from '../constants/generic-constants';

const defaultMessage = {
  contentKey: null,
  contentData: {},
  type: FLASH_MESSAGE_TYPE_INFO,
  params: {},
  content: null,
  delayToNextRoute: false
};

export default function flashMessage(
  state = {
    messages: {},
    nextRouteMessages: {}
  },
  action
) {
  switch (action.type) {
    case types.FLASH_MESSAGE_ADD:
      if (!_.get(action, 'message.contentKey') && !_.get(action, 'message.content')) {
        throw new Error("FlashMessage requires a 'contentKey' or 'content' to display a message.");
      }

      const message = Object.assign({}, defaultMessage, { _uid: shortid.generate() }, action.message);

      if (message.delayToNextRoute) {
        return Object.assign({}, state, {
          nextRouteMessages: { ...state.nextRouteMessages, [message._uid]: message }
        });
      } else {
        return Object.assign({}, state, {
          messages: { ...state.messages, [message._uid]: message }
        });
      }

    case types.FLASH_MESSAGE_REMOVE:
      if (!state.messages[action.id]) return state;

      const newList = { ...state.messages };
      delete newList[action.id];

      return Object.assign({}, state, {
        messages: { ...newList }
      });

    case UPDATE_LOCATION:
      return Object.assign({}, state, {
        messages: state.nextRouteMessages,
        nextRouteMessages: {}
      });

    default:
      return state;
  }
}
