import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';

class TextIcon extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <span
        className={`textIcon
          ${!this.props.iconName ? '' : `textIcon--${this.props.iconName}`}
          ${this.props.customClass || ''}`}
      >
        <span className="textIcon_label">
          {this.props.labelKey ? <FormattedMessage id={this.props.labelKey} /> : this.props.label}
        </span>
      </span>
    );
  }
}

TextIcon.displayName = 'TextIcon';

TextIcon.propTypes = {
  customClass: PropTypes.string,
  iconName: PropTypes.string,
  labelKey: PropTypes.string
};

export default TextIcon;
