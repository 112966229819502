import React, { Component, PropTypes as T } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import _ from 'lodash';

class EkRadio extends Component {
  render() {
    const {
      namespace,
      title,
      titleKey,
      items,
      isEkRadioAlt,
      field,
      customLabelClass,
      customTitleClass,
      customContainerClass,
      customWrapperClass,
      children,
      mandatory
    } = this.props;

    if (!namespace) {
      throw new Error('Component EkRadio requires a namespace to operate with');
    }

    const options =
      items &&
      items.map(item => {
        const id = namespace + '_' + item.value;
        const itemLabel = item.labelKey ? (
          <FormattedMessage id={item.labelKey} />
        ) : item.label ? (
          item.label
        ) : (
          <FormattedMessage id="common_unknown" />
        );

        return (
          <div
            key={item.value}
            className={classNames('ekRadio_wrapper', customWrapperClass || '', { ekRadio_wrapperAlt: !!isEkRadioAlt })}
          >
            <input
              type="radio"
              className={classNames('ekRadio', { 'ekRadio--alt': !!isEkRadioAlt })}
              name={namespace}
              {...field}
              value={item.value}
              checked={field.value === item.value}
              id={id}
            />
            <label className={`ekRadio_label ${customLabelClass || ''}`} htmlFor={id}>
              {itemLabel}
            </label>
          </div>
        );
      });

    const mainTitle = titleKey ? (
      <span className={`ekRadio_title ${customTitleClass || ''}`}>
        <FormattedMessage id={titleKey} />
      </span>
    ) : title ? (
      <span className={`ekRadio_title ${customTitleClass || ''}`}>{title}</span>
    ) : (
      <FormattedMessage id="common_unknown" />
    );

    return (
      <div className="ekRadio_block">
        {mainTitle}
        {mandatory && <span className="boxedInput_mandatory">*</span>}
        <div className={`ekRadio_container ${customContainerClass || ''}`}>{options}</div>
        {children}
      </div>
    );
  }
}

EkRadio.displayName = 'EkRadio';

EkRadio.propTypes = {
  namespace: T.string.isRequired,
  onChange: T.func,
  items: T.array.isRequired,
  title: T.string,
  customContainerClass: T.string,
  customWrapperClass: T.string,
  customTitleClass: T.string,
  customLabelClass: T.string,
  isEkRadioAlt: T.bool
};

export default EkRadio;
