import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import GoogleMap from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';

import { getStore } from '../../store/all-store';
import BookingsMapMarker from '../../components/BookingsMapMarker/BookingsMapMarker';
import { getLocationInfos, getActiveMarkerIndex, setMapPosition, unsetMapResetFlag } from '../../actions/all-actions';
import config from '../../constants/config-constants';

class BookingsMap extends Component {
  constructor() {
    super();
    this.handleBoundsChange = this.handleBoundsChange.bind(this);
    this.handleChildClick = this.handleChildClick.bind(this);
  }

  componentWillMount() {
    this.setBookingMapPositionIfNeeded();
  }

  componentWillUpdate() {
    this.setBookingMapPositionIfNeeded();
  }

  setBookingMapPositionIfNeeded() {
    if (!this.props.resetFlag) {
      return;
    }

    const { dispatch, bookings } = this.props;
    let center;
    let zoom;

    // prepare mapPolicy
    // google maps bounds.extend(latLng) crashes when trying to extend with already used latLng.
    // this mapPolicy is here to avoid this issue.
    let differentLatitudes = new Set();
    let differentLongitudes = new Set();

    bookings.map(item => {
      if (item.start.parking) {
        differentLatitudes.add(item.start.parking.coordinates.latitude);
        differentLongitudes.add(item.start.parking.coordinates.longitude);
      } else {
        differentLatitudes.add(item.start.coordinates.latitude);
        differentLongitudes.add(item.start.coordinates.longitude);
      }
    });

    let mapPolicy = 'noParking';
    if (differentLatitudes.size === 1 && differentLongitudes.size === 1) {
      mapPolicy = 'oneParking';
    }
    if (differentLatitudes.size > 1 || differentLongitudes.size > 1) {
      mapPolicy = 'severalParking';
    }

    switch (mapPolicy) {
      case 'noParking':
        dispatch(getLocationInfos()).then(data => {
          if (data) {
            center = {
              lat: data.latitude,
              lng: data.longitude
            };
            dispatch(setMapPosition({ center, zoom: 11 }));
          }
        });
        break;

      case 'oneParking':
        center = {
          lat: bookings[0].start.parking
            ? bookings[0].start.parking.coordinates.latitude
            : bookings[0].start.coordinates.latitude,
          lng: bookings[0].start.parking
            ? bookings[0].start.parking.coordinates.longitude
            : bookings[0].start.coordinates.longitude
        };
        zoom = 11;
        dispatch(setMapPosition({ center, zoom }));
        break;

      case 'severalParking':
        let bounds = new window.google.maps.LatLngBounds();

        bookings.forEach(item => {
          let latLng;
          if (item.start.parking) {
            latLng = new window.google.maps.LatLng(
              item.start.parking.coordinates.latitude,
              item.start.parking.coordinates.longitude
            );
          } else {
            latLng = new window.google.maps.LatLng(item.start.coordinates.latitude, item.start.coordinates.longitude);
          }
          bounds.extend(latLng);
        });

        let jsonBounds = bounds.toJSON();
        const boundsToFit = {
          nw: {
            lat: jsonBounds.north,
            lng: jsonBounds.west
          },
          se: {
            lat: jsonBounds.south,
            lng: jsonBounds.east
          }
        };
        const size = {
          width: 882,
          height: 476
        };

        let fitBoundsMap = fitBounds(boundsToFit, size);
        center = fitBoundsMap.center;
        zoom = fitBoundsMap.zoom;

        dispatch(setMapPosition({ center, zoom: zoom - 1 }));
        break;
    }

    dispatch(unsetMapResetFlag());
  }

  handleBoundsChange(data) {
    const { dispatch } = this.props;
    dispatch(setMapPosition(data));
  }

  handleChildClick(key, childProps) {
    const { dispatch, activeMarkerIndex } = this.props;

    if (childProps.id === activeMarkerIndex) {
      dispatch(getActiveMarkerIndex(null));
    } else {
      dispatch(getActiveMarkerIndex(childProps.id));
    }
  }

  render() {
    const { activeMarkerIndex, bookings, center, zoom } = this.props;

    let hoverDistance = 50;

    const markers = bookings.map(function(booking, index) {
      let markerId = `BookingsMapMarker-${index}`;
      return (
        <BookingsMapMarker
          key={booking._id}
          id={markerId}
          lat={booking.start.parking ? booking.start.parking.coordinates.latitude : booking.start.coordinates.latitude}
          lng={
            booking.start.parking ? booking.start.parking.coordinates.longitude : booking.start.coordinates.longitude
          }
          text={index + 1}
          currentMarkerIsClicked={activeMarkerIndex === markerId}
          type={booking.type}
          bookingLocation={booking.start.parking}
          store={getStore()}
        />
      );
    });

    return (
      <GoogleMap
        bootstrapURLKeys={{
          key: config.gMapsApiKey,
          language: config.defaultLocale
        }}
        ref="googleMap"
        center={center}
        zoom={zoom}
        onChange={this.handleBoundsChange}
        onChildClick={this.handleChildClick}
        hoverDistance={hoverDistance}
      >
        {markers}
      </GoogleMap>
    );
  }
}

BookingsMap.displayName = 'BookingsMap';

BookingsMap.propTypes = {
  bookings: PropTypes.array
};

export default connect(state => {
  const {
    googleMap: { activeMarkerIndex, center, zoom, resetFlag }
  } = state;

  return {
    activeMarkerIndex,
    center,
    zoom,
    resetFlag
  };
})(BookingsMap);
