import * as types from '../constants/actionTypes-constants';

export function openHeaderProfileMenu() {
  return {
    type: types.HEADER_PROFILE_MENU_OPEN
  };
}

export function closeHeaderProfileMenu() {
  return {
    type: types.HEADER_PROFILE_MENU_CLOSE
  };
}

export function toggleHeaderProfileMenu() {
  return {
    type: types.HEADER_PROFILE_MENU_TOGGLE
  };
}

export function openHeaderLangMenu() {
  return {
    type: types.HEADER_LANG_MENU_OPEN
  };
}

export function closeHeaderLangMenu() {
  return {
    type: types.HEADER_LANG_MENU_CLOSE
  };
}

export function toggleHeaderLangMenu() {
  return {
    type: types.HEADER_LANG_MENU_TOGGLE
  };
}

export function updateCurrentRouteName(routeName) {
  return {
    type: types.HEADER_CURRENT_PAGE_UPDATE,
    routeName
  };
}
