import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';
import { reducer as formReducer } from 'redux-form';
import { syncHistory, routeReducer } from 'react-router-redux';

import { history } from '../routing/all-routing';
import { searchBooking } from '../reducers/form-plugin';
import appReducers from '../reducers/all-reducers';
import config from '../constants/config-constants';

import { composeWithDevTools } from 'redux-devtools-extension';

const reducer = combineReducers(
  Object.assign({}, appReducers, {
    routing: routeReducer,
    form: formReducer.plugin({ searchBooking })
  })
);

if (config.devMode) {
  var createStoreWithMiddleware = composeWithDevTools(applyMiddleware(thunkMiddleware))(createStore);
} else {
  var createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore);
}

if (config.useLogger) {
  createStoreWithMiddleware = applyMiddleware(createLogger())(createStoreWithMiddleware);
}

var store;

export default function initializeStore(initialState) {
  // Sync dispatched route actions to the history
  const reduxRouterMiddleware = syncHistory(history);
  createStoreWithMiddleware = applyMiddleware(reduxRouterMiddleware)(createStoreWithMiddleware);
  store = createStoreWithMiddleware(reducer);

  // Required for replaying actions from devtools to work
  reduxRouterMiddleware.listenForReplays(store);

  return store;
}

export function getStore() {
  return store;
}
