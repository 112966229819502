import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import Toggle from 'material-ui/lib/toggle';
import { setInitialValue, toBoolean } from '../../utils/utils';
import ThemeManager from 'material-ui/lib/styles/theme-manager';
import MaterialUiTheme from '../../constants/material-ui-theme-constants';
import classNames from 'classnames';

class RciToggle extends Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
    this.setInitialValue = setInitialValue.bind(this);
  }

  getChildContext() {
    return {
      muiTheme: ThemeManager.getMuiTheme(MaterialUiTheme)
    };
  }

  componentWillMount() {
    this.setInitialValue(this.props);
  }

  // noinspection JSCheckFunctionSignatures
  componentWillUpdate(props) {
    this.setInitialValue(props);
  }

  handleToggle(event, value) {
    this.props.field.onChange(String(value));
  }

  render() {
    const { id, title, titleKey, customTitleClass, customContainerClass, customRootClass, children } = this.props;

    const mainTitle = (
      <div className={classNames('rci-toggle_title', customTitleClass)}>
        {titleKey ? <FormattedMessage id={titleKey} /> : title ? title : <FormattedMessage id="common_unknown" />}
      </div>
    );

    return (
      <div className={classNames('rci-toggle_block', customRootClass)}>
        {mainTitle}
        <div className={`rci-toggle_container ${customContainerClass || ''}`}>
          <Toggle
            labelPosition="right"
            className="rci-toggle"
            onToggle={this.handleToggle}
            id={id}
            toggled={toBoolean(this.props.field.value)}
          />
        </div>
        {children}
      </div>
    );
  }
}

RciToggle.childContextTypes = {
  muiTheme: React.PropTypes.object
};

RciToggle.defaultProps = {
  field: {
    onChange: () => ''
  }
};

RciToggle.propTypes = {
  onChange: PropTypes.func,
  title: PropTypes.string,
  titleKey: PropTypes.string,
  customContainerClass: PropTypes.string,
  customWrapperClass: PropTypes.string,
  customRootClass: PropTypes.string,
  customTitleClass: PropTypes.string,
  customLabelClass: PropTypes.string
};

export default RciToggle;
