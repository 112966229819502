import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import theme from '../../constants/theme-constants';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AddToCalendar from 'react-add-to-calendar';
import { BookingId, namedCompose } from '../../utils/utils';

class BookingSuccess extends Component {
  render() {
    const { userInfo, latestBooking, intl } = this.props;
    const vehicle = latestBooking.vehicle;
    let startDate = latestBooking.start.date;
    let endDate = latestBooking.end.date;
    let id = BookingId(latestBooking.id);
    let title = `${theme.calendarEventTitle} : ${id}`;
    let note = '';

    if (vehicle) note = `${vehicle.brand} ${vehicle.model}, ${vehicle.registrationNumber}`;

    let event = {
      title,
      description: note,
      location: latestBooking.start.address.formattedAddress,
      startTime: startDate,
      endTime: endDate
    };

    return (
      <div className="BookingSuccessPage">
        <Header />

        <div className="mainContent">
          <div className="mainContent_inner bookingSuccess">
            <div className="bookingSuccess_message">
              <div className="bookingSuccess_message_inner">
                <h1 className="bookingSuccess_message_title">
                  <FormattedMessage id="bookingSuccess_congrats" /> {userInfo.firstName},{' '}
                  <FormattedMessage id="bookingSuccess_confirmed" />.
                </h1>
                <p className="bookingSuccess_message_content">
                  <FormattedMessage id="bookingSuccess_wait_email" /> {userInfo.login}.
                </p>
              </div>
            </div>

            <AddToCalendar
              rootClass="react-add-to-calendar"
              buttonLabel={intl.messages.bookingSummary_atc_link}
              event={event}
              buttonClassClosed="ekButton"
              displayItemIcons={false}
            />
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default namedCompose(
  connect(state => {
    const {
      user: { userInfo },
      booking: { latestBooking }
    } = state;

    return {
      userInfo,
      latestBooking
    };
  }),
  injectIntl
)(BookingSuccess);
