import { UPDATE_LOCATION } from 'react-router-redux';

import * as types from '../constants/actionTypes-constants';

import { enhanceBookingsUser } from '../api/data-enhancer';

export default function googleMap(
  state = {
    displayMap: true,
    activeMarkerIndex: null,
    center: {},
    zoom: null,
    resetFlag: false
  },
  action
) {
  switch (action.type) {
    case types.GOOGLE_MAP_OPENED_STATE_TOGGLE:
      return Object.assign({}, state, {
        displayMap: !state.displayMap
      });

    case types.GOOGLE_MAP_ACTIVE_MARKER_INDEX_UPDATE:
      return Object.assign({}, state, {
        activeMarkerIndex: action.index
      });

    case types.GOOGLE_MAP_POSITION_MARKER_SET:
      return Object.assign({}, state, {
        center: action.center,
        zoom: action.zoom
      });

    case types.GOOGLE_MAP_RESET_FLAG_SET:
      return Object.assign({}, state, {
        resetFlag: true
      });

    case types.GOOGLE_MAP_RESET_FLAG_UNSET:
      return Object.assign({}, state, {
        resetFlag: false
      });

    default:
      return state;
  }
}
