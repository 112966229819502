import * as types from '../constants/actionTypes-constants';
import callApi from './api-actions';

export function checkEmailAvailability({ login }) {
  return dispatch => {
    dispatch({
      type: types.VALIDATION_CHECK_EMAIL_AVAILABILITY_REQUEST,
      params: {
        login
      }
    });

    return dispatch(callApi('user', 'checkEmailAvailability', { login }, false)).then(function(data) {
      dispatch({
        type: types.VALIDATION_CHECK_EMAIL_AVAILABILITY_SUCCESS,
        params: data
      });
      return data;
    });
  };
}
