import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';

import EkRadio from '../../components/EkRadio/EkRadio';

class Rating extends Component {
  render() {
    const maxNote = 5;
    const notes = [];
    for (let i = 0; i < maxNote; i++) {
      let number = (i + 1).toString();
      let note = {
        label: number,
        value: number
      };
      notes.push(note);
    }
    return (
      <div className="rating" data-stars-rating={this.props.field.value}>
        <EkRadio
          formRowItemKey="stars_rating"
          customContainerClass="rating_ekRadioContainer"
          customWrapperClass="rating_ekRadioWrapper"
          customTitleClass="rating_ekRadioTitle"
          customLabelClass="rating_ekRadioLabel"
          titleKey={this.props.titleKey}
          items={notes}
          namespace={this.props.namespace}
          field={this.props.field}
        >
          {this.props.children}
        </EkRadio>
      </div>
    );
  }
}

Rating.displayName = 'Rating';

Rating.propTypes = {
  field: PropTypes.object,
  titleKey: PropTypes.string,
  namespace: PropTypes.string
};

export default Rating;
