import { BOOKING_TYPE_CAR_SHARING, BOOKING_USAGE_TYPE_PRIVATE } from '../constants/backend-constants';
import { BE_ADDRESS_PROPS } from '../constants/backend-constants';

import _get from 'lodash/object/get';
import _includes from 'lodash/collection/includes';
import { getProfileData } from '../actions/user-actions';

export function enhanceUserInfo(userInfo) {
  let favoriteSiteIndex;
  // format sites for AddressAutocomplete
  if (userInfo.company && userInfo.company.sites) {
    userInfo.company.sites.forEach((item, index) => {
      item.label = item.name;
      item._isSite = true;
      if (userInfo.defaultSite === item.id) {
        favoriteSiteIndex = index;
        item.className = '_is_favorite';
      }
    });
    // Put the default site on top of the sites list
    if (favoriteSiteIndex !== undefined) {
      const [favoriteItem] = userInfo.company.sites.splice(favoriteSiteIndex, 1);
      userInfo.company.sites.unshift(favoriteItem);
    }
  }

  // provide formattedAddress if not present
  if (userInfo.hasOwnProperty('address') && !userInfo.address.hasOwnProperty('formattedAddress')) {
    userInfo.address.formattedAddress = assembleBeFormattedAddress(userInfo.address);
  }

  return userInfo;
}

export function parseHeader(res, json, text) {
  const contentType = res.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return res.json().then(data => {
      return json(data);
    });
  } else {
    return res.text().then(data => {
      if (text) return text(data);
      else return json(data);
    });
  }
}

const checkResponse = (res, callback) => {
  const contentType = res.headers.get('content-type');
  const jsonType = _includes(contentType, 'application/json');

  if (jsonType) return res.json().then(callback);
  else return callback(res);
};

const parseError = (error, reject) => {
  if (reject) return Promise.reject(error);
  else throw error;
};

export const checkErrorAndConvert = (reject = false) => res => {
  return checkResponse(res, data => {
    if (res.ok) return data;
    else return parseError(data, reject);
  });
};

export function enhanceBookingsUser(data, type) {
  const list = _get(data, 'results') || [];
  const formattedBookingsUser = { [type]: [] };

  list.forEach(booking => {
    enhanceBooking(booking);
    formattedBookingsUser[type].push(booking);
  });

  return {
    ...data,
    results: formattedBookingsUser
  };
}

export function enhanceBookingsSearch(list) {
  let formattedBookingsSearch = {
    all: [],
    carSharings: {
      private: [],
      business: []
    },
    rideSharings: [],
    metadata: {}
  };

  list.results.forEach(function(booking, index) {
    enhanceBooking(booking);
    // provide unique _id for use in React key attribute

    if (booking.start.parking) {
      booking._id = booking.start.parking.id + '_' + booking.vehicle.id;
    } else {
      booking._id = booking.vehicle.id;
    }

    if (booking.carSharingInfo) {
      booking._id += '_' + booking.carSharingInfo.usageType;
    }

    formattedBookingsSearch.all.push(booking);

    if (booking.type === BOOKING_TYPE_CAR_SHARING) {
      if (booking.carSharingInfo.usageType === BOOKING_USAGE_TYPE_PRIVATE) {
        formattedBookingsSearch.carSharings.private.push(booking);
      } else {
        formattedBookingsSearch.carSharings.business.push(booking);
      }
    } else {
      formattedBookingsSearch.rideSharings.push(booking);
    }
  });

  formattedBookingsSearch.metadata = list.metadata;

  return formattedBookingsSearch;
}

export function enhanceBooking(booking) {
  if (booking.start.address && !booking.start.address.formattedAddress)
    booking.start.address.formattedAddress = assembleBeFormattedAddress(booking.start.address);
  if (booking.end.address && !booking.end.address.formattedAddress)
    booking.end.address.formattedAddress = assembleBeFormattedAddress(booking.end.address);
  return booking;
}

export function addAddressPart(part, sep = ', ') {
  return part ? part + sep : '';
}

function assembleBeFormattedAddress(address = {}) {
  return getFormattedAddress({
    streetName: address[BE_ADDRESS_PROPS.STREET_NAME],
    postalCode: address[BE_ADDRESS_PROPS.POSTAL_CODE],
    city: address[BE_ADDRESS_PROPS.CITY],
    countryIso: address[BE_ADDRESS_PROPS.COUNTRY_ISO],
    streetNumber: address[BE_ADDRESS_PROPS.STREET_NUMBER]
  });
}

export function getFormattedAddress({ streetName, postalCode, city, countryIso, streetNumber }) {
  const space = ' ';
  const coma = ', ';
  const end = '';

  return (
    addAddressPart(streetNumber, space) +
    addAddressPart(streetName, coma) +
    addAddressPart(postalCode, space) +
    addAddressPart(city, coma) +
    addAddressPart(countryIso, end)
  );
}

export function checkProfileStatus() {
  return (dispatch, getState) => {
    const state = getState();
    const userInfo = state.user.userInfo;
    const status = _get(userInfo, 'status');
    const id = _get(userInfo, 'id');

    if (!status) {
      return dispatch(getProfileData(id));
    }
    return Promise.resolve;
  };
}
