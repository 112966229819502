import { UPDATE_LOCATION } from 'react-router-redux';

import * as types from '../constants/actionTypes-constants';

export default function help(
  state = {
    helpActiveTabIndex: 0,
    showAnswersToIssuesList: [],
    isMobileView: false
  },
  action
) {
  switch (action.type) {
    case types.HELP_ACTIVE_TAB_INDEX_UPDATE:
      return Object.assign({}, state, {
        helpActiveTabIndex: action.index
      });

    case types.HELP_ANSWER_ISSUE_OPENED_STATE_TOGGLE:
      let newAnswersToIssuesList = state.showAnswersToIssuesList.slice(0);
      let index = null;
      for (let i = 0, length = newAnswersToIssuesList.length; i < length; i++) {
        if (newAnswersToIssuesList[i] === action.id) {
          index = i;
          break;
        }
      }
      if (index === null) {
        newAnswersToIssuesList.push(action.id);
      } else {
        newAnswersToIssuesList.splice(index, 1);
      }
      return Object.assign({}, state, {
        showAnswersToIssuesList: newAnswersToIssuesList
      });

    case types.HELP_STATUS_MOBILE_VIEW_SET:
      return Object.assign({}, state, {
        isMobileView: action.isMobile
      });

    default:
      return state;
  }
}
