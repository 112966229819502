import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import _reduce from 'lodash/collection/reduce';
import _includes from 'lodash/collection/includes';
import _isArray from 'lodash/lang/isArray';

class TabsItems extends Component {
  constructor() {
    super();
  }

  handleClick(tab, e) {
    e.preventDefault();
    this.props.onChangeTab(tab);
  }

  hideIndex(id) {
    const { hideIndex } = this.props;
    if (_isArray(hideIndex)) {
      return _includes(hideIndex, id);
    } else {
      return hideIndex === id;
    }
  }

  render() {
    let tabItem = _reduce(
      this.props.tabList,
      (tabs, tab, index) => {
        let boundHandleClick = this.handleClick.bind(this, tab);

        let pelletElement = !tab.numberItems ? (
          ''
        ) : (
          <span
            className={`tabsItem_number ${this.props.currentTab === tab.id ? '' : 'tabsItem_number--variant1Reverse'}`}
          >
            {tab.numberItems}
          </span>
        );

        let indexItem = !this.props.showTabItemsIndex ? (
          ''
        ) : (
          <span
            className={`
          tabsItem_number
          ${this.props.currentTab === tab.id ? 'tabsItem_number--reverse' : 'tabsItem_number--variant1Reverse'}`}
          >
            {index + 1}
          </span>
        );

        let tabItemButton = !this.props.actionButtons ? (
          <span className="tabsItem_label">
            <FormattedMessage id={tab.name} />
          </span>
        ) : (
          <button className="tabsItem_button" onClick={boundHandleClick}>
            <span className="tabsItem_label">
              <FormattedMessage id={tab.name} />
            </span>
            {pelletElement}
          </button>
        );

        if (this.hideIndex(tab.id)) return tabs;

        tabs.push(
          <li className={`tabsItem ${this.props.currentTab === tab.id ? 'tabsItem--current' : ''}`} key={tab.id}>
            {indexItem}

            {tabItemButton}
          </li>
        );

        return tabs;
      },
      []
    );

    return (
      <nav>
        <ul className="tabsItems">{tabItem}</ul>
      </nav>
    );
  }
}

TabsItems.displayName = 'TabsItems';

TabsItems.propTypes = {
  url: PropTypes.string,
  tabList: PropTypes.array,
  showTabItemsIndex: PropTypes.bool,
  actionButtons: PropTypes.bool,
  hideIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.array])
};

export default TabsItems;
