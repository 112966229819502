import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { routeActions } from 'react-router-redux';

import EkButton from '../../components/EkButton/EkButton';
import routes from '../../constants/routes-constants';

class Error extends Component {
  constructor() {
    super();
    this.handleBackToSite = this.handleBackToSite.bind(this);
  }

  handleBackToSite() {
    const { dispatch } = this.props;
    dispatch(routeActions.push(routes.home.path));
  }

  render() {
    // noinspection HtmlUnknownTarget
    return (
      <div className="errorPage">
        <div className="errorPage_poster">
          <header className="errorPage_header">
            <span className="errorPage_logo">{}</span>
          </header>

          <section className="errorPage_content">
            <h2 className="errorPage_title">
              <FormattedMessage id="errorPage_title" />
            </h2>
            <p className="errorPage_subtitle">
              <FormattedMessage id="errorPage_back" />
            </p>
            <EkButton
              type="button"
              skinType="medium"
              customClass="errorPage_site_button"
              onAction={this.handleBackToSite}
            >
              <FormattedMessage id="errorPage_site_button" />
            </EkButton>
          </section>
        </div>
      </div>
    );
  }
}

Error.displayName = 'Error';

Error.propTypes = {};

export default connect(state => {
  const {} = state;
  return {};
})(Error);
