import React, { PropTypes } from 'react';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

const externalURLs = defineMessages({
  mentions: {
    id: 'footer_society_mentions_url',
    defaultMessage: '/'
  }
});

const CookieConsent = ({ intl, onCallback }) => {
  return (
    <div className="cookieConsent">
      <div className="cookieConsent_title">
        <FormattedMessage id="cookie_consent_title" />
      </div>
      <div className="cookieConsent_link">
        <a href={intl.formatMessage(externalURLs.mentions)}>
          <FormattedMessage id="cookie_consent_link" />
        </a>
        <span onClick={onCallback} className="cookieConsent_close">
          ✕
        </span>
      </div>
      <div className="cookieConsent_text">
        <FormattedMessage id="cookie_consent_text" />
      </div>
    </div>
  );
};

CookieConsent.displayName = 'CookieConsent';

CookieConsent.propTypes = {
  intl: PropTypes.object,
  onCallback: PropTypes.func.isRequired
};

export default injectIntl(CookieConsent);
