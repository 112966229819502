import * as types from '../constants/actionTypes-constants';

export function addFlashMessage(message) {
  return {
    type: types.FLASH_MESSAGE_ADD,
    message
  };
}

export function removeFlashMessage(id) {
  return {
    type: types.FLASH_MESSAGE_REMOVE,
    id
  };
}
