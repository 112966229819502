import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import initializeStore from './store/all-store';
import Root from './containers/Root';

const store = initializeStore();

function loadGoogleMapsApi() {
  const apiKey = 'AIzaSyCVyfs-uSw_HOF7kaTMOOGqP5IwaXJF9UI';
  const mapsUrl = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=';
  const googleMapsScript = document.createElement('script');

  googleMapsScript.setAttribute('src', mapsUrl + apiKey);
  document.head.appendChild(googleMapsScript);
}

loadGoogleMapsApi(); // this is used to set initial {lat, lng} for Bookings map

ReactDOM.render(
  <Provider store={store}>
    <Root />
  </Provider>,
  document.getElementById('mainContainer')
);
