import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import BoxedInput from '../BoxedInput/BoxedInput';
import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { togglePasswordVisibility, toggleForgotPasswordMode } from '../../actions/all-actions';
import { createValidator, notEmpty, email, password, length } from '../../validation/all-validation';
import { PASSWORD_FIELD_NAME } from '../../constants/generic-constants';
import { scrollToFirstError } from '../../utils/utils';

class LoginForm extends Component {
  constructor() {
    super();
    this.handleTogglePasswordVisibility = this.handleTogglePasswordVisibility.bind(this);
    this.handleToggleForgotPasswordMode = this.handleToggleForgotPasswordMode.bind(this);
  }

  handleTogglePasswordVisibility() {
    const { dispatch } = this.props;
    dispatch(togglePasswordVisibility());
  }

  handleToggleForgotPasswordMode() {
    const { dispatch } = this.props;
    dispatch(toggleForgotPasswordMode());
  }

  render() {
    const {
      fields: { login, password, rememberMe },
      handleSubmit,
      serverError
    } = this.props;

    var serverErrorKey;
    if (serverError) {
      switch (serverError.code) {
        case 401:
          serverErrorKey = 'login_invalid_credentials';
          break;
        case 403:
          serverErrorKey = 'login_anonymized_credentials';
          break;
        case 422:
          serverErrorKey = 'user_not_enabled';
          break;
        default:
          serverErrorKey = 'error_server_unknown';
          break;
      }
    }

    return (
      <form action="#" className="loginForm" onSubmit={handleSubmit(this.props.onCallback)}>
        {serverError ? (
          <div className="loginForm_mainErrorMsg">
            <FormattedMessage id={serverErrorKey} />
          </div>
        ) : null}

        <FieldErrorMsg field={login} customClass="fieldErrorMsg--loginForm" />
        <BoxedInput
          skinType="email"
          type="email"
          placeholder="Email"
          customClass="loginForm_boxedInput"
          field={login}
        />

        <FieldErrorMsg field={password} customClass="fieldErrorMsg--loginForm" />
        <BoxedInput
          skinType="password"
          type="password"
          showPassword={this.props.showPassword}
          placeholder="Password"
          onAction={this.handleTogglePasswordVisibility}
          actionText="loginForm_password_actionText"
          customClass="loginForm_boxedInput"
          field={password}
        />

        <EkButton customClass="loginForm_submitBtn" onAction={handleSubmit(this.props.onCallback)} fullWidth>
          <FormattedMessage id="login_submitBtn" />
        </EkButton>

        <button type="button" className="loginForm_forgotPassword" onClick={this.handleToggleForgotPasswordMode}>
          <FormattedMessage id="login_forgotPassword" />
        </button>

        <div className="loginForm_rememberMe">
          <LabeledCheckbox id="rememberMe" field={rememberMe}>
            <FormattedMessage id="login_rememberMe" />
          </LabeledCheckbox>
        </div>
      </form>
    );
  }
}

LoginForm.displayName = 'LoginForm';

LoginForm.propTypes = {
  action: PropTypes.func,
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func,
  serverError: PropTypes.object
};

LoginForm = reduxForm(
  {
    onSubmitFail: scrollToFirstError,
    form: 'login',
    fields: ['login', PASSWORD_FIELD_NAME, 'rememberMe'],
    touchOnChange: false,
    validate: createValidator({
      login: [notEmpty(), email(), length({ max: 75 })],
      password: [notEmpty(), password(), length({ min: 8, max: 25 })]
    })
  },
  state => {
    // mapStateToProps
    return {
      initialValues: {
        login: state.user.defaultLogin,
        rememberMe: state.user.defaultLogin ? true : false
      }
    };
  }
)(LoginForm);

export default connect(state => {
  const {
    user: { showPassword }
  } = state;

  return {
    showPassword
  };
})(LoginForm);
