import React, { Component } from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { routeActions } from 'react-router-redux';
import { MEMBER_STATUS_TO_COMPLETE } from '../../constants/backend-constants';

import LoginForm from '../../components/LoginForm/LoginForm';
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import {
  requestLogin,
  callLoginError,
  requestResetPassword,
  callResetPasswordSuccess,
  callResetPasswordError,
  logout,
  setToken,
  requestTokenRefresh,
  addFlashMessage,
  exposeLoginIfStored,
  clearTemporaryImpersonateToken
} from '../../actions/all-actions';
import config from '../../constants/config-constants';
import routes from '../../constants/routes-constants';
import { namedCompose } from '../../utils/utils';
import { FLASH_MESSAGE_TYPE_ERROR } from '../../constants/generic-constants';

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.setMessages();
  }

  componentWillMount() {
    this.prepareState();
  }

  prepareState() {
    const { dispatch, temporaryImpersonateToken } = this.props;

    dispatch(logout());

    if (temporaryImpersonateToken) {
      dispatch(setToken(temporaryImpersonateToken));
      dispatch(clearTemporaryImpersonateToken());
      return dispatch(requestTokenRefresh()).then(
        userInfo => {
          dispatch(routeActions.push(routes.home.path));
        },
        err => {
          dispatch(
            addFlashMessage({
              contentKey: 'impersonate_token_expired',
              type: FLASH_MESSAGE_TYPE_ERROR,
              delayToNextRoute: true
            })
          );
          dispatch(routeActions.push(routes.login.path));
        }
      );
    } else {
      dispatch(exposeLoginIfStored());
    }
  }

  setMessages() {
    this.urls = defineMessages({
      phone: {
        id: 'values_contactPhone',
        defaultMessage: ' '
      }
    });
  }

  handleLogin() {
    const { dispatch } = this.props;

    if (this.props.forgotPasswordMode) {
      dispatch(requestResetPassword())
        .then(() => {
          dispatch(callResetPasswordSuccess());
        })
        .catch(error => {
          if (!error.type) {
            // make sure native errors not swallowed
            console.error(error.stack); // eslint-disable-line
          }
          dispatch(callResetPasswordError(error));
        });
    } else {
      dispatch(requestLogin())
        .then(userInfo => {
          if (userInfo.status === MEMBER_STATUS_TO_COMPLETE) {
            const companyId = userInfo.company.id;
            dispatch(routeActions.push(routes.subscribe.path.replace(':companyId', companyId)));
          } else dispatch(routeActions.push(routes.home.path));
          return userInfo;
        })
        .catch(error => {
          if (!error.type) {
            // make sure native errors not swallowed
            console.error(error.stack); // eslint-disable-line
          }
          dispatch(callLoginError(error));
        });
    }
  }

  getCustomerPhone() {
    const { formatMessage } = this.props.intl;
    const phone = formatMessage(this.urls.phone).trim();

    if (phone)
      return (
        <ul className="loginBox_customerService">
          <li className="loginBox_customerService_title">
            <FormattedMessage id="loginPage_customerService" />
          </li>

          <li className="loginBox_customerService_item">{phone}</li>
        </ul>
      );
  }

  render() {
    // noinspection HtmlUnknownTarget
    return (
      <div className="loginPage">
        <video autoPlay={!config.devMode} loop className="loginPage_bkgVideo" muted>
          <source src="../img/glide-video.mp4" type="video/mp4" />
        </video>

        <FlashMessageDisplayer />

        <header className="loginPage_header">
          <span className="loginPage_logo">{}</span>
        </header>

        <section className="loginPage_content">
          <h2 className="loginPage_title">
            <FormattedMessage id="loginPage_title" />
          </h2>
          <p className="loginPage_subtitle">
            <FormattedMessage id="loginPage_subtitle" />
          </p>

          <div className="loginBox">
            {!this.props.forgotPasswordMode ? (
              <LoginForm onCallback={this.handleLogin} serverError={this.props.loginServerError} />
            ) : (
              <ResetPasswordForm onCallback={this.handleLogin} serverError={this.props.loginServerError} />
            )}

            {this.getCustomerPhone()}
          </div>
        </section>
      </div>
    );
  }
}

Login.displayName = 'Login';

Login.propTypes = {};

export default namedCompose(
  connect(state => {
    const {
      user: { temporaryImpersonateToken, loginServerError, forgotPasswordMode }
    } = state;

    return {
      loginServerError,
      forgotPasswordMode,
      temporaryImpersonateToken
    };
  }),
  injectIntl
)(Login);
