import { routeActions } from 'react-router-redux';
import _has from 'lodash/object/has';
import _get from 'lodash/object/get';

import { getStore } from '../store/all-store';
import config from '../constants/config-constants';
import routes from '../constants/routes-constants';
import { isAuthenticated } from '../api/ApiCaller';
import { getQueryParams, trySet, append } from '../utils/utils';
import { BOOKING_USAGE_TYPE_BUSINESS, BOOKING_USAGE_TYPE_PRIVATE } from '../constants/backend-constants';

import {
  logout,
  exposeLoginIfStored,
  storeCompanyId,
  getCompanyInfo,
  getCustomFieldsProfile,
  getNotificationsList,
  closeHeaderProfileMenu,
  setToken,
  requestTokenRefresh,
  requestBookingSearch,
  callBookingSearchSuccess,
  getPaymentInfos,
  storeUserPaymentInfos,
  restoreSubscribeFormState,
  checkResetPasswordTokenRequest,
  storeResetPasswordToken,
  addFlashMessage,
  setMapResetFlag,
  requestEditPaymentInfos,
  callPaymentInfosSuccess,
  updateUserAccountActiveTabIndex,
  setMobileViewStatus,
  setBookingToCancel,
  scrollToTop,
  getSettings,
  setBookingAllFiltersSelected,
  setBookingFacets,
  resetBookingAllFiltersSelected,
  updateCurrentRouteName,
  getPaymentUrl,
  getPaymentUrlWithMember,
  getSubscriptionCustomFields,
  getBookingsCustomFields,
  requestValidateAccount,
  getCompanyCustomizations,
  updateBookingPastList,
  updateBookingUpcomingList,
  setTemporaryImpersonateToken
} from '../actions/all-actions';
import { FLASH_MESSAGE_TYPE_SUCCESS, FLASH_MESSAGE_TYPE_ERROR } from '../constants/generic-constants';
import { checkResetPasswordWhiteBrand } from '../actions/user-actions';
import { checkProfileStatus } from '../api/data-enhancer';

export function onEnterApp() {}

export function onEnterNoRoute() {
  return nextState => {
    const store = getStore();

    if (isAuthenticated()) {
      // go to default route
      setTimeout(function() {
        // react-router will render a blank page if we don't go to the next event loop before redirecting
        store.dispatch(routeActions.push(routes.error.path));
      }, 0);
    } else {
      // go to default route
      setTimeout(function() {
        // react-router will render a blank page if we don't go to the next event loop before redirecting
        store.dispatch(routeActions.push(config.defaultRoute));
      }, 0);
    }
  };
}

export function onEnterRoute(routeName) {
  return (nextState, replaceState, callback) => {
    const store = getStore();

    // autologin from localstorage
    if (!isAuthenticated() && routes[routeName].needsAuthentication) {
      let storedToken = window.localStorage.getItem('token');
      if (storedToken) {
        store.dispatch(setToken(storedToken));

        // token refresh will hydrate the  userInfo object
        store.dispatch(requestTokenRefresh()).then(userInfo => {
          store.dispatch(routeActions.replace(window.location.hash.slice(1)));
        });
        return;
      }
    }

    if (!isAuthenticated() && routes[routeName].needsAuthentication) {
      if (routeName === config.defaultRoute) {
        throw new Error('defaultRoute cannot require authentication!');
      }
      replaceState({}, config.defaultRoute);
      return callback();
    }

    function afterAsync() {
      // defined actions that need to be dispatched before the page instantiates but after the async calls
      store.dispatch(updateCurrentRouteName(routeName));
    }

    function instantCallback() {
      afterAsync();
      callback();
    }

    store.dispatch(closeHeaderProfileMenu());

    return routeHooks[routeName]
      ? routeHooks[routeName]({
          store,
          nextState,
          replaceState,
          afterAsync,
          callback
        })
      : instantCallback();
  };
}

const routeHooks = {
  redirect({ store, nextState, replaceState, afterAsync, callback }) {
    callback();
    store.dispatch(routeActions.push(decodeURIComponent(nextState.params.path)));
  },

  subscribe({ store, nextState, replaceState, afterAsync, callback }) {
    const {
      params: { companyId },
      location: { query }
    } = nextState;

    // TOIMPROVE: we could notify the user too
    if (!companyId) {
      throw 'No companyId provided for subscribe, aborting.';
    }

    store.dispatch(storeCompanyId(companyId));
    store.dispatch(getPaymentUrl(companyId));

    if (query.paymentInfosSet) {
      store.dispatch(storeUserPaymentInfos(query));
      store.dispatch(restoreSubscribeFormState());
    }

    return Promise.all([
      store.dispatch(getCompanyInfo(companyId)),
      store.dispatch(getSubscriptionCustomFields(companyId))
    ]).then(
      data => {
        afterAsync();
        callback();
      },
      err => {
        store.dispatch(routeActions.push(config.errorRoute));
      }
    );
  },

  validateAccount({ store, nextState, afterAsync, callback }) {
    function showPage() {
      afterAsync();
      callback();
    }

    store.dispatch(requestValidateAccount(nextState.params.token)).then(showPage);
  },

  whiteBrandActivate({ store, nextState, afterAsync, callback }) {
    function showPage() {
      afterAsync();
      callback();
    }

    const token = nextState.params.token;

    store
      .dispatch(getCompanyCustomizations(token))
      .then(() => store.dispatch(requestValidateAccount(token)).then(showPage));
  },

  login({ store, nextState, replaceState, afterAsync, callback }) {
    const {
      location: {
        query: { impersonateToken }
      }
    } = nextState;

    store.dispatch(setTemporaryImpersonateToken(impersonateToken));
    afterAsync();
    callback();
  },

  home({ store, nextState, replaceState, afterAsync, callback }) {
    //const { someParam } = routing.params;

    store.dispatch(getNotificationsList()).then(() => {
      afterAsync();

      callback();
    });
  },

  bookingResult({ store, nextState, replaceState, afterAsync, callback }) {
    const {
      location: { query }
    } = nextState;

    const state = store.getState();

    const contract = _get(state, 'user.userInfo.company.contract');
    const hasPrivateCarSharing = _get(contract, 'privateCarSharing');
    const hasBusinessCarSharing = _get(contract, 'businessCarSharing');

    let params;
    try {
      params = JSON.parse(decodeURIComponent(nextState.params.search));
      let types = [];
      if (hasBusinessCarSharing) types.push(BOOKING_USAGE_TYPE_BUSINESS);
      if (hasPrivateCarSharing) types.push(BOOKING_USAGE_TYPE_PRIVATE);
      trySet(params, 'usageTypes', append(types));

      if (
        (params.carOptions && params.carOptions.length > 0) ||
        (params.fuelTypes && params.fuelTypes.length > 0) ||
        (params.fuelLevel && params.fuelLevel.length > 0) ||
        (params.usages && params.usages.length > 0) ||
        (params.transmissionTypes && params.transmissionTypes.length > 0) ||
        params.startParkingId
      ) {
        let filtersSelected = {};

        if (params.usages && params.usages.length > 0) {
          let usagesTypes = [];
          params.usages.forEach((item, index) => {
            usagesTypes.push(item);
          });
          filtersSelected.usages = usagesTypes;
        }

        if (params.fuelTypes && params.fuelTypes.length > 0) {
          let fuelTypes = [];
          params.fuelTypes.forEach((item, index) => {
            fuelTypes.push(item);
          });
          filtersSelected.types = fuelTypes;
        }

        if (params.fuelLevel && params.fuelLevel.length > 0) {
          let fuelLevel = [];
          params.fuelLevel.forEach((item, index) => {
            fuelLevel.push(item);
          });
          filtersSelected.fuelLevel = fuelLevel;
        }

        if (params.carOptions && params.carOptions.length > 0) {
          let accessories = [];
          params.carOptions.forEach((item, index) => {
            accessories.push(item);
          });
          filtersSelected.carOptions = accessories;
        }

        if (params.transmissionTypes && params.transmissionTypes.length > 0) {
          let transmissions = [];
          params.transmissionTypes.forEach(item => {
            transmissions.push(item);
          });
          filtersSelected.transmissionTypes = transmissions;
        }

        store.dispatch(setBookingAllFiltersSelected(filtersSelected));
      } else {
        store.dispatch(resetBookingAllFiltersSelected());
      }
    } catch (err) {
      console.warn('Invalid search parameters'); // eslint-disable-line
      if (!isAuthenticated()) {
        store.dispatch(routeActions.push(config.defaultRoute));
      } else {
        store.dispatch(routeActions.push(config.defaultLoggedRoute));
      }
      return;
    }

    return store.dispatch(requestBookingSearch(params)).then(list => {
      afterAsync();

      store.dispatch(setBookingFacets(list.metadata.facets));

      if (!store.getState().googleMap.resetFlag) {
        store.dispatch(setMapResetFlag());
      }

      store.dispatch(callBookingSearchSuccess(list));

      if (query.paymentInfosSet) {
        store.dispatch(getPaymentUrlWithMember(state.user.userInfo.id));
        store.dispatch(storeUserPaymentInfos(query));
        store.dispatch(requestEditPaymentInfos()).then(() => {
          store.dispatch(callPaymentInfosSuccess());
          store.dispatch(getPaymentInfos(state.user.userInfo.id)).then(data => {
            store.dispatch(
              addFlashMessage({
                contentKey: 'bookingResult_add_payment_succeeded',
                type: FLASH_MESSAGE_TYPE_SUCCESS
              })
            );
          });
        });
      } else {
        store.dispatch(getPaymentInfos(state.user.userInfo.id));

        if (!state.user.paymentUrl) {
          store.dispatch(getPaymentUrlWithMember(state.user.userInfo.id));
        }
      }

      callback();
    });
  },

  myBookings({ store, nextState, replaceState, afterAsync, callback }) {
    return Promise.all([store.dispatch(updateBookingPastList()), store.dispatch(updateBookingUpcomingList())]).then(
      () => {
        afterAsync();
        callback();
      },
      err => {
        callback(err);
      }
    );
  },

  bookingSummary({ store, afterAsync, callback }) {
    //const { someParam } = routing.params;
    const state = store.getState();

    if (state.booking.currentBooking === null) {
      store.dispatch(routeActions.push(routes.home.path));
    } else {
      return Promise.all([
        store.dispatch(getCompanyInfo(state.user.userInfo.company.id)),
        store.dispatch(getBookingsCustomFields(state.user.userInfo.company.id))
      ]).then(
        () => {
          afterAsync();

          callback();
        },
        err => {
          callback(err);
        }
      );
    }
  },

  editBooking({ store, nextState, replaceState, afterAsync, callback }) {
    const state = store.getState();

    if (state.booking.currentBooking === null) {
      store.dispatch(routeActions.push(routes.home.path));
    } else {
      store.dispatch(setBookingToCancel(state.booking.currentBooking.id));
      store.dispatch(scrollToTop);

      return store.dispatch(getCompanyInfo(state.user.userInfo.company.id)).then(() => {
        afterAsync();
        callback();
      });
    }
  },

  bookingSuccess({ store, nextState, replaceState, afterAsync, callback }) {
    //const { someParam } = routing.params;
    const state = store.getState();

    if (state.booking.currentBooking === null) {
      store.dispatch(routeActions.push(routes.home.path));
    } else {
      afterAsync();

      callback();
    }
  },

  account({ store, nextState, afterAsync, callback }) {
    const {
      location: { query }
    } = nextState;

    const state = store.getState();
    const { userInfo } = state.user;

    store.dispatch(getCompanyInfo(userInfo.company.id));
    store.dispatch(getSettings(userInfo.id));
    store.dispatch(getCustomFieldsProfile(userInfo.id));

    if (_has(userInfo, 'company.id')) {
      store.dispatch(getSubscriptionCustomFields(userInfo.company.id));
    }

    store.dispatch(checkProfileStatus());

    if (query.paymentInfosSet) {
      store.dispatch(storeUserPaymentInfos(query));
      store.dispatch(requestEditPaymentInfos()).then(
        () => {
          store.dispatch(callPaymentInfosSuccess());
          return store.dispatch(getPaymentInfos(userInfo.id)).then(
            () => {
              afterAsync();
              store.dispatch(updateUserAccountActiveTabIndex(2));
              store.dispatch(
                addFlashMessage({
                  contentKey: 'profileForm_update_member_succeeded',
                  type: FLASH_MESSAGE_TYPE_SUCCESS
                })
              );
              callback();
            },
            err => {
              callback(err);
            }
          );
        },
        err => {
          callback(err);
        }
      );
    } else {
      return store.dispatch(getPaymentInfos(userInfo.id)).then(
        () => {
          afterAsync();
          callback();
        },
        err => {
          callback(err);
        }
      );
    }
  },

  resetPassword({ store, nextState, afterAsync, callback }) {
    const {
      params: { resetPasswordToken }
    } = nextState;

    return store.dispatch(checkResetPasswordTokenRequest(resetPasswordToken)).then(
      () => {
        afterAsync();
        store.dispatch(storeResetPasswordToken(resetPasswordToken));
        callback();
      },
      () => {
        store.dispatch(
          addFlashMessage({
            contentKey: 'resetPassword_token_expired',
            type: FLASH_MESSAGE_TYPE_ERROR,
            delayToNextRoute: true
          })
        );
        store.dispatch(routeActions.push(routes.login.path));
      }
    );
  },

  changePassword({ store, nextState, afterAsync, callback }) {
    const {
      params: { resetPasswordToken }
    } = nextState;

    return store.dispatch(getCompanyCustomizations(resetPasswordToken)).then(() => {
      return store.dispatch(checkResetPasswordWhiteBrand(resetPasswordToken)).then(() => {
        afterAsync();
        callback();
      });
    });
  },

  help({ store, nextState, replaceState, afterAsync, callback }) {
    afterAsync();

    let queryParams = getQueryParams();

    let isMobile = queryParams.isMobile === '1';
    if (isMobile) {
      store.dispatch(setMobileViewStatus(isMobile));
    }

    callback();
  }
};
