import version from '../../../generated-version.json';
import i18nRevManifest from '../../../generated-rev-manifest-i18n.json';
import routes from './routes-constants';
import theme from './theme-constants';
import { getQueryParams } from '../utils/utils';
import {
  BOOKING_USAGE_TYPE_BUSINESS,
  BOOKING_USAGE_TYPE_PRIVATE,
  CAR_ACCESSORY_TYPE_AIR_CONDITIONING,
  CAR_ACCESSORY_TYPE_BLUETOOTH,
  CAR_ACCESSORY_TYPE_FOLDING_SEATS,
  CAR_ACCESSORY_TYPE_GPS,
  CAR_ACCESSORY_TYPE_LARGE_TRUCK,
  CAR_ACCESSORY_TYPE_RADIO_CD,
  CAR_FUEL_TYPE_DIESEL,
  CAR_FUEL_TYPE_ELECTRIC,
  CAR_FUEL_TYPE_HYBRID,
  CAR_FUEL_TYPE_LPG,
  CAR_FUEL_TYPE_PETROL,
  CAR_TRANSMISSION_TYPE_AUTOMATIC,
  CAR_TRANSMISSION_TYPE_MANUAL
} from './backend-constants';

function getConfig() {
  const overridableDefaultConfig = {
    defaultRoute: routes.login.path,
    defaultLoggedRoute: routes.home.path,
    errorRoute: routes.error.path,
    defaultLocale: 'en'
  };

  const nonOverridableDefaultConfig = {
    phoneCountryPrefixes: [
      '+1',
      '+7',
      '+20',
      '+27',
      '+30',
      '+31',
      '+32',
      '+33',
      '+34',
      '+36',
      '+39',
      '+40',
      '+41',
      '+43',
      '+44',
      '+45',
      '+46',
      '+47',
      '+48',
      '+49',
      '+51',
      '+52',
      '+53',
      '+54',
      '+55',
      '+56',
      '+57',
      '+58',
      '+590',
      '+60',
      '+61',
      '+62',
      '+63',
      '+64',
      '+65',
      '+66',
      '+81',
      '+82',
      '+84',
      '+86',
      '+90',
      '+91',
      '+92',
      '+93',
      '+94',
      '+95',
      '+98',
      '+212',
      '+213',
      '+216',
      '+218',
      '+220',
      '+221',
      '+222',
      '+223',
      '+224',
      '+225',
      '+226',
      '+227',
      '+228',
      '+229',
      '+230',
      '+231',
      '+232',
      '+233',
      '+234',
      '+235',
      '+236',
      '+237',
      '+238',
      '+239',
      '+240',
      '+241',
      '+242',
      '+243',
      '+244',
      '+245',
      '+246',
      '+247',
      '+248',
      '+249',
      '+250',
      '+251',
      '+252',
      '+253',
      '+254',
      '+255',
      '+256',
      '+257',
      '+258',
      '+260',
      '+261',
      '+262',
      '+263',
      '+264',
      '+265',
      '+266',
      '+267',
      '+268',
      '+269',
      '+284',
      '+290',
      '+291',
      '+297',
      '+298',
      '+299',
      '+340',
      '+345',
      '+350',
      '+351',
      '+352',
      '+353',
      '+354',
      '+355',
      '+356',
      '+357',
      '+358',
      '+359',
      '+370',
      '+371',
      '+372',
      '+373',
      '+374',
      '+375',
      '+376',
      '+378',
      '+380',
      '+381',
      '+385',
      '+386',
      '+387',
      '+389',
      '+420',
      '+421',
      '+423',
      '+473',
      '+500',
      '+501',
      '+502',
      '+503',
      '+504',
      '+505',
      '+506',
      '+507',
      '+508',
      '+509',
      '+591',
      '+592',
      '+593',
      '+594',
      '+595',
      '+596',
      '+597',
      '+598',
      '+599',
      '+670',
      '+671',
      '+672',
      '+673',
      '+674',
      '+675',
      '+676',
      '+677',
      '+678',
      '+679',
      '+680',
      '+681',
      '+682',
      '+683',
      '+684',
      '+685',
      '+686',
      '+687',
      '+688',
      '+689',
      '+690',
      '+691',
      '+692',
      '+767',
      '+787',
      '+809',
      '+850',
      '+852',
      '+853',
      '+855',
      '+856',
      '+868',
      '+869',
      '+876',
      '+880',
      '+886',
      '+960',
      '+961',
      '+962',
      '+963',
      '+964',
      '+965',
      '+966',
      '+967',
      '+968',
      '+971',
      '+972',
      '+973',
      '+974',
      '+975',
      '+976',
      '+977',
      '+993',
      '+994',
      '+995',
      '+996'
    ].sort(),
    prefixPhoneCountryCode: {
      1: [
        'US',
        'AG',
        'AI',
        'AS',
        'BB',
        'BM',
        'BS',
        'CA',
        'DM',
        'DO',
        'GD',
        'GU',
        'JM',
        'KN',
        'KY',
        'LC',
        'MP',
        'MS',
        'PR',
        'SX',
        'TC',
        'TT',
        'VC',
        'VG',
        'VI'
      ],
      7: ['RU', 'KZ'],
      20: ['EG'],
      27: ['ZA'],
      30: ['GR'],
      31: ['NL'],
      32: ['BE'],
      33: ['FR'],
      34: ['ES'],
      36: ['HU'],
      39: ['IT', 'VA'],
      40: ['RO'],
      41: ['CH'],
      43: ['AT'],
      44: ['GB', 'GG', 'IM', 'JE'],
      45: ['DK'],
      46: ['SE'],
      47: ['NO', 'SJ'],
      48: ['PL'],
      49: ['DE'],
      51: ['PE'],
      52: ['MX'],
      53: ['CU'],
      54: ['AR'],
      55: ['BR'],
      56: ['CL'],
      57: ['CO'],
      58: ['VE'],
      60: ['MY'],
      61: ['AU', 'CC', 'CX'],
      62: ['ID'],
      63: ['PH'],
      64: ['NZ'],
      65: ['SG'],
      66: ['TH'],
      81: ['JP'],
      82: ['KR'],
      84: ['VN'],
      86: ['CN'],
      90: ['TR'],
      91: ['IN'],
      92: ['PK'],
      93: ['AF'],
      94: ['LK'],
      95: ['MM'],
      98: ['IR'],
      211: ['SS'],
      212: ['MA', 'EH'],
      213: ['DZ'],
      216: ['TN'],
      218: ['LY'],
      220: ['GM'],
      221: ['SN'],
      222: ['MR'],
      223: ['ML'],
      224: ['GN'],
      225: ['CI'],
      226: ['BF'],
      227: ['NE'],
      228: ['TG'],
      229: ['BJ'],
      230: ['MU'],
      231: ['LR'],
      232: ['SL'],
      233: ['GH'],
      234: ['NG'],
      235: ['TD'],
      236: ['CF'],
      237: ['CM'],
      238: ['CV'],
      239: ['ST'],
      240: ['GQ'],
      241: ['GA'],
      242: ['CG'],
      243: ['CD'],
      244: ['AO'],
      245: ['GW'],
      246: ['IO'],
      247: ['AC'],
      248: ['SC'],
      249: ['SD'],
      250: ['RW'],
      251: ['ET'],
      252: ['SO'],
      253: ['DJ'],
      254: ['KE'],
      255: ['TZ'],
      256: ['UG'],
      257: ['BI'],
      258: ['MZ'],
      260: ['ZM'],
      261: ['MG'],
      262: ['RE', 'YT'],
      263: ['ZW'],
      264: ['NA'],
      265: ['MW'],
      266: ['LS'],
      267: ['BW'],
      268: ['SZ'],
      269: ['KM'],
      290: ['SH', 'TA'],
      291: ['ER'],
      297: ['AW'],
      298: ['FO'],
      299: ['GL'],
      350: ['GI'],
      351: ['PT'],
      352: ['LU'],
      353: ['IE'],
      354: ['IS'],
      355: ['AL'],
      356: ['MT'],
      357: ['CY'],
      358: ['FI', 'AX'],
      359: ['BG'],
      370: ['LT'],
      371: ['LV'],
      372: ['EE'],
      373: ['MD'],
      374: ['AM'],
      375: ['BY'],
      376: ['AD'],
      377: ['MC'],
      378: ['SM'],
      380: ['UA'],
      381: ['RS'],
      382: ['ME'],
      383: ['XK'],
      385: ['HR'],
      386: ['SI'],
      387: ['BA'],
      389: ['MK'],
      420: ['CZ'],
      421: ['SK'],
      423: ['LI'],
      500: ['FK'],
      501: ['BZ'],
      502: ['GT'],
      503: ['SV'],
      504: ['HN'],
      505: ['NI'],
      506: ['CR'],
      507: ['PA'],
      508: ['PM'],
      509: ['HT'],
      590: ['GP', 'BL', 'MF'],
      591: ['BO'],
      592: ['GY'],
      593: ['EC'],
      594: ['GF'],
      595: ['PY'],
      596: ['MQ'],
      597: ['SR'],
      598: ['UY'],
      599: ['CW', 'BQ'],
      670: ['TL'],
      672: ['NF'],
      673: ['BN'],
      674: ['NR'],
      675: ['PG'],
      676: ['TO'],
      677: ['SB'],
      678: ['VU'],
      679: ['FJ'],
      680: ['PW'],
      681: ['WF'],
      682: ['CK'],
      683: ['NU'],
      685: ['WS'],
      686: ['KI'],
      687: ['NC'],
      688: ['TV'],
      689: ['PF'],
      690: ['TK'],
      691: ['FM'],
      692: ['MH'],
      800: ['001'],
      808: ['001'],
      850: ['KP'],
      852: ['HK'],
      853: ['MO'],
      855: ['KH'],
      856: ['LA'],
      870: ['001'],
      878: ['001'],
      880: ['BD'],
      881: ['001'],
      882: ['001'],
      883: ['001'],
      886: ['TW'],
      888: ['001'],
      960: ['MV'],
      961: ['LB'],
      962: ['JO'],
      963: ['SY'],
      964: ['IQ'],
      965: ['KW'],
      966: ['SA'],
      967: ['YE'],
      968: ['OM'],
      970: ['PS'],
      971: ['AE'],
      972: ['IL'],
      973: ['BH'],
      974: ['QA'],
      975: ['BT'],
      976: ['MN'],
      977: ['NP'],
      979: ['001'],
      992: ['TJ'],
      993: ['TM'],
      994: ['AZ'],
      995: ['GE'],
      996: ['KG'],
      998: ['UZ']
    },
    maxDriverLicenceSize: 1024 * 1024 * 2, // 2Mo
    availableLanguages: ['en', 'fr', 'es', 'it', 'pt', 'de'],
    availableHours: [
      '00',
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23'
    ],
    availableMinutes: ['00', '15', '30', '45'],
    gMapsApiKey: 'AIzaSyCVyfs-uSw_HOF7kaTMOOGqP5IwaXJF9UI',
    numberIssuesVehicleHelpSection: 4,
    numberIssuesCarSharingHelpSection: 6,
    numberIssuesDamagesHelpSection: 2,
    amountToTake: '2,00'
  };

  const browserLocale = (window.navigator.language || window.navigator.userLanguage).split('-')[0];

  let config = Object.assign(
    {},
    overridableDefaultConfig,
    window.GLIDE.settings,
    { browserLocale },
    { version },
    { i18nRevManifest },
    { theme },
    nonOverridableDefaultConfig
  );

  // override dev config at runtime
  const queryParams = getQueryParams();
  if (queryParams.devMode) {
    config.devMode = queryParams.devMode !== 'false' && queryParams.devMode !== '0';
  }
  if (queryParams.debugMode) {
    config.debugMode = queryParams.debugMode !== 'false' && queryParams.debugMode !== '0';
  }
  if (queryParams.useLogger) {
    config.useLogger = queryParams.useLogger !== 'false' && queryParams.useLogger !== '0';
  }
  if (queryParams.useFakePayment) {
    config.useFakePayment = queryParams.useFakePayment !== 'false' && queryParams.useFakePayment !== '0';
  }
  if (queryParams.assetsBaseUrl) {
    config.assetsBaseUrl = queryParams.assetsBaseUrl;
  }
  if (queryParams.apiBaseUrl) {
    config.apiBaseUrl = queryParams.apiBaseUrl;
  }

  config.payzen = {
    certificat: '8206884677046560',
    shopId: '43839225',
    actionMode: 'INTERACTIVE',
    version: 'V2',
    name: 'Glide',
    mode: config.useFakePayment ? 'TEST' : 'PRODUCTION',
    url: 'https://secure.payzen.eu/vads-payment/',
    returnMode: 'GET'
  };

  config.bookingFilters = {
    accessories: [
      CAR_ACCESSORY_TYPE_AIR_CONDITIONING,
      CAR_ACCESSORY_TYPE_LARGE_TRUCK,
      CAR_ACCESSORY_TYPE_FOLDING_SEATS,
      CAR_ACCESSORY_TYPE_RADIO_CD,
      CAR_ACCESSORY_TYPE_BLUETOOTH,
      CAR_ACCESSORY_TYPE_GPS
    ],
    fuelTypes: [
      CAR_FUEL_TYPE_ELECTRIC,
      CAR_FUEL_TYPE_PETROL,
      CAR_FUEL_TYPE_DIESEL,
      CAR_FUEL_TYPE_HYBRID,
      CAR_FUEL_TYPE_LPG
    ],
    transmissionTypes: [CAR_TRANSMISSION_TYPE_AUTOMATIC, CAR_TRANSMISSION_TYPE_MANUAL],
    usageTypes: [BOOKING_USAGE_TYPE_BUSINESS, BOOKING_USAGE_TYPE_PRIVATE]
  };

  // expose config on window for debug purposes
  window._glideConfig = config;

  return config;
}

export default getConfig();
