import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormattedDate } from 'react-intl';
import Modal from 'react-modal';

import Notification from '../Notification/Notification';
import EkButton from '../../components/EkButton/EkButton';
import BaseTitle from '../../components/BaseTitle/BaseTitle';
import DamageReportingModal from '../../components/DamageReportingModal/DamageReportingModal';
import { NOTIFICATION_TYPE_FINE } from '../../constants/backend-constants';
import {
  toggleNotificationsMoreDetails,
  increaseNotificationsMaxItems,
  setNotificationsList,
  openDamageReportingModal,
  getBookingFeedBack,
  setNotificationRead
} from '../../actions/all-actions';

class ListNotifications extends Component {
  constructor() {
    super();
    this.handleDisplayMoreDetails = this.handleDisplayMoreDetails.bind(this);
    this.handleDisplayMoreNotifications = this.handleDisplayMoreNotifications.bind(this);
    this.handleNothingToReport = this.handleNothingToReport.bind(this);
    this.handleOpenDamageReportingModal = this.handleOpenDamageReportingModal.bind(this);
  }

  handleDisplayMoreDetails(id) {
    const { dispatch } = this.props;
    dispatch(toggleNotificationsMoreDetails(id));
  }

  handleDisplayMoreNotifications() {
    const { dispatch } = this.props;
    dispatch(increaseNotificationsMaxItems());
  }

  handleNothingToReport(id) {
    const { dispatch, list } = this.props;
    let newListNotifs = list;
    list.forEach((notif, index) => {
      if (notif.id === id) {
        newListNotifs.splice(index, 1);
      }
    });
    dispatch(setNotificationsList(newListNotifs));
    dispatch(setNotificationRead(id, true));
  }

  handleOpenDamageReportingModal(item) {
    const { dispatch } = this.props;

    dispatch(getBookingFeedBack(item.id)).then(() => {
      dispatch(openDamageReportingModal(item));
    });
  }

  renderNotificationsList(notificationsList, restrictedNotificationsList) {
    const { showMoreDetailsList, openedDamageReportingModalBooking } = this.props;
    let notificationsItems;

    const notificationDate = date => {
      return <FormattedDate value={new Date(date)} day="numeric" month="numeric" year="numeric" />;
    };

    const carSharingDescription = booking => {
      return (
        <span>
          <FormattedDate
            value={new Date(booking.start.date)}
            weekday="long"
            day="numeric"
            month="numeric"
            year="numeric"
          />
        </span>
      );
    };

    const notificationDetails = notification => {
      let details =
        showMoreDetailsList.indexOf(notification.id) === -1 ? (
          ''
        ) : (
          <dl className="boxNotifItems">
            <dt className="boxNotifItem_key">
              <FormattedMessage id="notification_label_date" /> :
            </dt>
            <dd className="boxNotifItem_label">
              <FormattedDate
                value={new Date(notification.fineInfos.date)}
                day="numeric"
                month="numeric"
                year="numeric"
              />
            </dd>

            <dt className="boxNotifItem_key">
              <FormattedMessage id="notification_label_amount" /> :
            </dt>
            <dd className="boxNotifItem_label">{notification.fineInfos.cost} €</dd>

            <dt className="boxNotifItem_key">
              <FormattedMessage id="notification_label_status" /> :
            </dt>
            <dd className="boxNotifItem_label">{notification.fineInfos.status}</dd>

            <dt className="boxNotifItem_key">
              <FormattedMessage id="notification_label_comment" /> :
            </dt>
            <dd className="boxNotifItem_label">{notification.fineInfos.comment}</dd>
          </dl>
        );

      return details;
    };

    if (notificationsList) {
      notificationsItems = restrictedNotificationsList.map(function(notification, index) {
        let handleDisplayMoreDetailsBound = this.handleDisplayMoreDetails.bind(this, notification.id);
        let showMoreDetails = showMoreDetailsList.indexOf(notification.id) !== -1 ? true : false;
        let handleNothingToReportAction = this.handleNothingToReport.bind(this, notification.id);
        let handleReportAction = this.handleOpenDamageReportingModal.bind(this, notification.concernedBooking);
        let allowReport =
          notification.concernedBooking &&
          notification.concernedBooking.allowedActions &&
          notification.concernedBooking.allowedActions.report === true
            ? true
            : false;

        if (notification.notificationType === NOTIFICATION_TYPE_FINE) {
          return (
            <li className="notificationsItem" key={notification.id}>
              <Notification
                skinNotifType="alert"
                type={notification.notificationType}
                date={notificationDate(notification.date)}
                messageKey="notification_fine_defaultMessage"
                customButtonClass={`boxNotif_buttonToggle ${showMoreDetails ? '_is_opened' : ''}`}
                buttonLabelKey="notification_moreDetails_button"
                moreDetails={notificationDetails(notification)}
                onAction={handleDisplayMoreDetailsBound}
                onNoReportAction={handleNothingToReportAction}
              />
            </li>
          );
        } else {
          return (
            <li className="notificationsItem" key={notification.id}>
              <Notification
                skinNotifType="carSharing"
                type={notification.notificationType}
                date={notificationDate(notification.date)}
                message={notification.concernedBooking ? carSharingDescription(notification.concernedBooking) : ''}
                reportLabelKey="notification_noReport_label"
                buttonLabelKey="notification_reportDamages_button"
                buttonSkinType="variant1Reverse"
                onAction={handleReportAction}
                onNoReportAction={handleNothingToReportAction}
                reportable={allowReport}
              />

              {!openedDamageReportingModalBooking ? (
                ''
              ) : (
                <DamageReportingModal booking={notification.concernedBooking} />
              )}
            </li>
          );
        }
      }, this);
    }

    return notificationsItems;
  }

  render() {
    const { list, notificationsMaxItems, notificationsRestrictedList } = this.props;

    return (
      <section className="notifications" id="notifications">
        <BaseTitle customClass="notifications_title" skinType="variant1" title="notification_title">
          <span className="notifications_number notifications_number--variant1">{list.length}</span>
        </BaseTitle>

        <ul className="notificationsItems">{this.renderNotificationsList(list, notificationsRestrictedList)}</ul>

        {list.length <= notificationsMaxItems ? (
          ''
        ) : (
          <EkButton
            type="button"
            customClass="notifications_moreButton"
            skinType="variant1"
            onAction={this.handleDisplayMoreNotifications}
            fullWidth
          >
            <FormattedMessage id="notification_more_button" />
          </EkButton>
        )}
      </section>
    );
  }
}

ListNotifications.displayName = 'ListNotifications';

ListNotifications.propTypes = {};

export default connect(state => {
  const {
    notifications: { list, notificationsMaxItems, notificationsRestrictedList, showMoreDetailsList },
    booking: { openedDamageReportingModalBooking }
  } = state;

  return {
    list,
    notificationsMaxItems,
    showMoreDetailsList,
    notificationsRestrictedList,
    openedDamageReportingModalBooking
  };
})(ListNotifications);
