import React, { Component, PropTypes } from 'react';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import BaseTitle from '../BaseTitle/BaseTitle';
import FormRow from '../FormRow/FormRow';
import LabeledCheckbox from '../LabeledCheckbox/LabeledCheckbox';
import BoxedSelect from '../BoxedSelect/BoxedSelect';
import BoxedInput from '../BoxedInput/BoxedInput';
import EkButton from '../EkButton/EkButton';
import FieldErrorMsg from '../FieldErrorMsg/FieldErrorMsg';
import { createValidator, notEmpty } from '../../validation/all-validation';
import { scrollToFirstError } from '../../utils/utils';
import { PARAMETERS_TAB_FORM_NAME } from '../../constants/generic-constants';

class ParametersForm extends Component {
  render() {
    let sendingTimeNotifications = [
      {
        labelKey: 'parametersForm_notification_noSMs',
        value: '-1'
      },
      {
        labelKey: 'parametersForm_notification_15min',
        value: '15'
      },
      {
        labelKey: 'parametersForm_notification_30min',
        value: '30'
      },
      {
        labelKey: 'parametersForm_notification_45min',
        value: '45'
      }
    ];

    const {
      fields: {
        sendMailForPersonalChange,
        sendMailForCallCenterChange,
        smsBeforeDepartureTime,
        smsBeforeArrivalTime,
        defaultSite,
        commercialOffers
      },
      handleSubmit,
      locale
    } = this.props;

    return (
      <div className="parametersFormPage_form">
        <form
          className="parametersForm"
          method="post"
          action="#"
          onSubmit={handleSubmit(this.props.onCallback)}
          noValidate
        >
          <section className="parametersForm_section">
            <BaseTitle title="parametersForm_parameters_title" />

            <div className="parametersForm_container">
              <h2 className="parametersForm_subtitle">
                <FormattedMessage id="parametersForm_emailNotifications_title" />
              </h2>

              <p className="parametersForm_emailLabel">
                <FormattedMessage id="parametersForm_emailNotifications_label" />
              </p>

              <FormRow customClass="parametersForm_row parametersForm_rowEmailNotifications">
                <ul className="parametersForm_rowItems">
                  <li className="parametersForm_rowItem">
                    <LabeledCheckbox
                      formRowItemKey="parametersForm_emailNotifications_personal"
                      id="emailNotifications_personal"
                      customLabelClass="parametersForm_labeledCheckboxLabel"
                      field={sendMailForPersonalChange}
                    >
                      <FormattedMessage id="parametersForm_emailNotifications_personal" />
                    </LabeledCheckbox>
                    <FieldErrorMsg field={sendMailForPersonalChange} customClass="fieldErrorMsg--parametersForm" />
                  </li>
                  <li className="parametersForm_rowItem">
                    <LabeledCheckbox
                      formRowItemKey="parametersForm_emailNotifications_callCenter"
                      id="emailNotifications_callCenter"
                      customLabelClass="parametersForm_labeledCheckboxLabel"
                      field={sendMailForCallCenterChange}
                    >
                      <FormattedMessage id="parametersForm_emailNotifications_callCenter" />
                    </LabeledCheckbox>
                    <FieldErrorMsg field={sendMailForCallCenterChange} customClass="fieldErrorMsg--parametersForm" />
                  </li>
                </ul>
              </FormRow>
            </div>

            <div className="parametersForm_container">
              <h2 className="parametersForm_subtitle">
                <FormattedMessage id="parametersForm_mobileNotifications_title" />
              </h2>

              <FormRow customClass="parametersForm_row">
                <BoxedSelect
                  formRowItemKey="parametersForm_mobileNotifications_departure"
                  id="mobileNotifications_departure"
                  customClass="parametersForm_boxedSelectWrapper"
                  customLabelClass="parametersForm_boxedSelectLabel"
                  labelKey="parametersForm_mobileNotifications_departure"
                  options={sendingTimeNotifications}
                  field={smsBeforeDepartureTime}
                  fullWidth
                >
                  <FieldErrorMsg field={smsBeforeDepartureTime} customClass="fieldErrorMsg--parametersForm" />
                </BoxedSelect>

                <BoxedSelect
                  formRowItemKey="parametersForm_mobileNotifications_arrival"
                  id="mobileNotifications_arrival"
                  customClass="parametersForm_boxedSelectWrapper"
                  customLabelClass="parametersForm_boxedSelectLabel"
                  labelKey="parametersForm_mobileNotifications_arrival"
                  options={sendingTimeNotifications}
                  field={smsBeforeArrivalTime}
                  fullWidth
                >
                  <FieldErrorMsg field={smsBeforeArrivalTime} customClass="fieldErrorMsg--parametersForm" />
                </BoxedSelect>
              </FormRow>
            </div>

            <div className="parametersForm_container">
              <h2 className="parametersForm_subtitle">
                <FormattedMessage id="parametersForm_saveLocation_title" />
              </h2>

              <FormRow customClass="parametersForm_row">
                <BoxedInput
                  formRowItemKey="parametersForm_default_site"
                  type="text"
                  id="defaultSite"
                  customClass="parametersForm_boxedInput"
                  field={defaultSite}
                  placeholder={locale === 'fr' ? 'Aucun site favori' : 'No default favorite'}
                  readOnly
                  hideLabel
                />
              </FormRow>
            </div>
          </section>

          <FormRow>
            <label className="subscribeForm_commercialOffers" htmlFor="commercialOffers">
              <LabeledCheckbox
                id="commercialOffers"
                customLabelClass="subscribeForm_commercialOffersLabel"
                field={commercialOffers}
              >
                <span>
                  <FormattedMessage id="subscribeForm_commercialOffers_label" />
                </span>
              </LabeledCheckbox>
            </label>
          </FormRow>

          <FormRow customWrapperClass="parametersForm_actions" customClass="parametersForm_row">
            <EkButton
              formRowItemKey="parametersForm_submitBtn"
              type="submit"
              customClass="parametersForm_actionsButton _is_alone"
              onAction={handleSubmit(this.props.onCallback)}
            >
              <FormattedMessage id="ekButton_defaultSaveButton" />
            </EkButton>
          </FormRow>
        </form>
      </div>
    );
  }
}

ParametersForm.displayName = 'ParametersForm';

ParametersForm.propTypes = {
  action: PropTypes.func,
  fields: PropTypes.object,
  onCallback: PropTypes.func,
  handleSubmit: PropTypes.func
};

ParametersForm = reduxForm({
  onSubmitFail: scrollToFirstError,
  form: PARAMETERS_TAB_FORM_NAME,
  fields: [
    'sendMailForPersonalChange',
    'sendMailForCallCenterChange',
    'smsBeforeDepartureTime',
    'smsBeforeArrivalTime',
    'defaultSite',
    'commercialOffers'
  ],
  validate: createValidator({
    smsBeforeDepartureTime: [notEmpty()],
    smsBeforeArrivalTime: [notEmpty()]
  })
})(ParametersForm);

export default connect(state => {
  const {
    i18n: { locale }
  } = state;

  return {
    locale
  };
})(ParametersForm);
