import React, { Component, PropTypes } from 'react';
import { routeActions } from 'react-router-redux';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import FlashMessageDisplayer from '../../components/FlashMessageDisplayer/FlashMessageDisplayer';
import routes from '../../constants/routes-constants';

import { togglePasswordVisibility, callResetPasswordApplyRequest, addFlashMessage } from '../../actions/all-actions';

import { FLASH_MESSAGE_TYPE_SUCCESS } from '../../constants/generic-constants';
import NewPasswordForm from '../../components/NewPasswordForm/NewPasswordForm';
import { connect } from 'react-redux';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleTogglePasswordVisibility = this.handleTogglePasswordVisibility.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTogglePasswordVisibility() {
    const { dispatch } = this.props;
    dispatch(togglePasswordVisibility());
  }

  handleSubmit() {
    const { dispatch } = this.props;
    dispatch(callResetPasswordApplyRequest())
      .then(() => {
        dispatch(
          addFlashMessage({
            contentKey: 'resetPassword_update_success',
            type: FLASH_MESSAGE_TYPE_SUCCESS,
            delayToNextRoute: true
          })
        );
        dispatch(routeActions.push(routes.login.path));
      })
      .catch(error => {
        if (!error.type) {
          // make sure native errors not swallowed
          console.error(error.stack); // eslint-disable-line
        }
      });
  }

  render() {
    return (
      <div className="resetPasswordPage">
        <Header />
        <FlashMessageDisplayer />
        <div className="resetPasswordPage_body">
          <NewPasswordForm formSubmit={this.handleSubmit} whiteBrand={false} />
        </div>
        <Footer />
      </div>
    );
  }
}

ResetPassword.displayName = 'ResetPassword';
export default connect()(ResetPassword); // add dispatch
